/* eslint-disable no-underscore-dangle */
import {
  SET_ACTIVE_ROOM,
  RESET_ACTIVE_ROOM,
  UPDATE_SEARCH_USER,
  UPDATE_RECENT_USER,
  UPDATE_MESSAGES,
  UPDATE_RECENT_SEARCH_KEY,
  NEW_MESSAGE,
  SET_READ_MESSAGE,
  NOTIFY_MESSAGE,
  SET_LOADER,
  LOAD_CHAT
} from './types';
import axios from '../../axios';

export const setActiveRoom = room => dispatch => {
  dispatch({
    type: SET_ACTIVE_ROOM,
    payload: room,
  });
  dispatch(getMessages(room));
};

export const resetActiveRoom = () => {
  return {
    type: RESET_ACTIVE_ROOM,
  };
};

export const searchUser = username => async dispatch => {
  try {
    const response = await axios.get(`/api/message/search?username=${username}`);
    console.log(response);
    if (response.status === 200) {
      dispatch({
        type: UPDATE_SEARCH_USER,
        payload: response.data.user,
      });
    }
  } catch (error) {
    console.log('Message: Error searching user', error);
  }
};

export const recentMessage = (sortBy) => async dispatch => {
  try {
    if(sortBy ==='updatedtime') dispatch(setLoader(true));
    const response = await axios.get(`/api/message/recentMessage?sortBy=''`);
    if (response.status === 200) {
      let count=0;
      response.data.user && response.data.user.map(user=>{
        if(user.count>0){
        count =count+ user.count;
        }
      });
      dispatch(setUnreadMessageCount(count));
      dispatch({
        type: UPDATE_RECENT_USER,
        payload: response.data.user,
      });
      dispatch(setLoader(false));
    }
  } catch (error) {
    dispatch({
      type: UPDATE_RECENT_USER,
      payload: [],
    });
    dispatch(setLoader(false));
    console.log('Message: Error searching user', error.response);
  }
};

export const setLoader = loaderState => {
  return {
    type: SET_LOADER,
    payload: loaderState,
  };
};

export const setChatLoader = loaderState => {
  return {
    type: LOAD_CHAT,
    payload: loaderState,
  };
};

export const setUnreadMessageCount = (count) => dispatch => {
  dispatch({
    type: NOTIFY_MESSAGE,
    payload:count
  })
}

export const searchRecent = search => {
  return {
    type: UPDATE_RECENT_SEARCH_KEY,
    payload: search,
  };
};

export const setRead = room => {
  return {
    type: SET_READ_MESSAGE,
    payload: room,
  };
};

export const getMessages = room => async dispatch => {
  try {
    dispatch(setChatLoader(true))
    const response = await axios.get(`/api/message/${room}`);
    if (response.status === 200) {
      dispatch(setChatLoader(false))
      dispatch({
        type: UPDATE_MESSAGES,
        payload: response.data.messages,
      });
    }
  } catch (error) {
    dispatch(setChatLoader(false))
    if (error.response.status === 400) {
      dispatch({
        type: UPDATE_MESSAGES,
        payload: [],
      });
    }
    console.log('Message: Error searching user', error);
  }
};

export const updateMessage = ({ message, room, from }) => async dispatch => {
  const updatedtime = new Date()
  const _id =
    Math.random()
      .toString(36)
      .substring(2) + Date.now().toString(36);

  dispatch({
    type: NEW_MESSAGE,
    payload: { _id, message, from, room ,updatedtime},
  });
};
