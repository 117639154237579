/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './UserSettings.css';

import {
  getSharedResults,
  sortSharedResults,
  updateAssessmentResultStatus,
} from '../../../store/actions/settingsActions';
import SharedAssessmentResults from './SharedAssessmentResults';

class PersonalAsessmentSettings extends React.Component {
  componentDidMount = () => {
    const { getSharedResults } = this.props;
    getSharedResults();
  };

  render() {
    const { sharedResults } = this.props;
    const sortedSharedResults = sharedResults.sort((a, b) => {
      return new Date(b.shared_at) - new Date(a.shared_at);
    });
    return (
      <div id="personal-asessment-settings">
        <h1 className="assement-title">Personal Assessment Access</h1>
        <p className="assement-subtitle">
          Here you can see the list of practitioner whom you are shared your assessment results
          with.
        </p>
        <div className="asessment-practitioner-list table-responsive-md">
          {sortedSharedResults.length > 0 && (
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">
                    Shared Date
                    {/* 
                  <span
                    onClick={() => {
                      sortSharedResults(sharedResults, { newDate: false });
                    }}
                  >
                    sort
                  </span> */}
                  </th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {sortedSharedResults.map(result => {
                  return <SharedAssessmentResults key={result._id} result={result} />;
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  getSharedResults,
  sortSharedResults,
  updateAssessmentResultStatus,
};
const mapStateToProps = state => ({
  sharedResults: state.settings.sharedResults,
});
PersonalAsessmentSettings.propTypes = {
  sharedResults: PropTypes.arrayOf(PropTypes.shape({})),
  getSharedResults: PropTypes.func.isRequired,
  // sortSharedResults: PropTypes.func.isRequired,
};
PersonalAsessmentSettings.defaultProps = {
  sharedResults: [],
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalAsessmentSettings);
