/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../common/Button/Button';
import './PractitionerProfileSettings.css';
import PractitionerProfileForm from './PractitionerProfileForm';
import ProfessionalServices from './ProfessionalServices';
import ProfessionalBackground from './ProfessionalBackground';
import TimeAvailabilitySettings from './TimeAvailabilitySettings';
import SessionPrice from './SessionPrice';
import AcceptInsurance from './AcceptInsurance';
// import PractitionerLocation from './PractitionerLocation';
import SpiritualBackground from './SpiritualBackground';
import ProfileUpload from '../../Practitioner/PractitionerRegistrationContainer/PractitinerRegistration/ProfileUpload';
import PractitionerBusinessBackground from './PractitionerBusinessBackground';
import { updateUserProfile } from '../../../../store/actions/settingsActions';
import { getCountries, getStates, getCities } from '../../../../store/actions/appActions';

import {
  isValidYear,
  isValidDOB,
  isEmpty,
  isValidURL,
  isNumeric,
  isName,
  isValidPhoneNumber
} from '../../../../utils/validators';
import MemberProfileSettings from '../../UserSettings/MemberProfileSettings';
import MultiSearchDropDown from '../../../common/MultiSearchDropDown/MultiSearchDropDown';
import TextArea from '../../../common/TextArea/TextArea';

const validators = values => {
  const errors = {};
  if (!values.u_first_name) {
    errors.u_first_name = 'This field is required';
  } else if (!isName(values.u_first_name)) {
    errors.u_first_name = 'Invalid name';
  }
  if (values.u_last_name && !isName(values.u_last_name)) {
    errors.u_last_name = 'Invalid name';
  }

  if (!isEmpty(values.u_gender)) {
    errors.u_gender = 'This field is required';
  }
  if (!isValidDOB(values.u_dob)) {
    errors.u_dob = 'You must be at least 16 years old.';
  }

  if (!isEmpty(values.about_me)) {
    errors.about_me = 'This field is required';
  }

  if (!values.specializations) {
    errors.specializations = 'Select your specialization.';
  } else if (values.specializations.length === 0) {
    errors.specializations = 'Select your specialization.';
  }

  if (!isEmpty(values.business_name)) {
    errors.business_name = 'This field is required';
  }
  if (!isEmpty(values.phone_number)) {
    errors.phone_number = 'This field is required.';
  } else if (!isValidPhoneNumber(values.phone_number)) {
    errors.phone_number = 'Invalid phone number.';
  }
  if (!isEmpty(values.address)) {
    errors.address = 'This field is required.';
  }
  if (isEmpty(values.website)) {
    if (!isValidURL(values.website)) {
      errors.website = 'please enter a valid url.';
    }
  }
  if (!isEmpty(values.country)) {
    errors.country = 'This field is required.';
  }
  if (!isEmpty(values.state)) {
    errors.state = 'This field is required.';
  }
  if (!isEmpty(values.city)) {
    errors.city = 'This field is required.';
  }
  if (!values.experience && !isEmpty(values.experience)) {
    errors.experience = 'This field is required.';
  } else if (!isNumeric(values.experience)) {
    errors.experience = 'Should be Number.';
  }

  if(!values.location) {
    errors.location = 'This field is required.';
  }
  // else {
  //   if(!values.location.address) {
  //     errors.location = 'This field is required.';
  //   }
  // }

  // errors.professional_service = validateProfessionalService(values.professional_service);
  if (!values.types_of_service) {
    errors.types_of_service = 'This field is required.';
  } else if (values.types_of_service.length === 0) {
    errors.types_of_service = 'This field is required.';
  }
  if (!values.session_type) {
    errors.session_type = 'This field is required.';
  } else if (values.session_type.length === 0) {
    errors.session_type = 'This field is required.';
  }

  if (values.p_licensed === true) {
    if (!values.p_licenses || values.p_licenses.length === 0) {
      errors.p_licenses = 'Please choose institution and issued year.';
    } else {
      const institutionArrayError = [];
      values.p_licenses.forEach((institution, index) => {
        const institutionErrors = {};
        if (!institution || !institution.institution) {
          institutionErrors.institution = 'Select Institution';
          institutionArrayError[index] = institutionErrors;
        }
        if (!institution || !institution.year) {
          institutionErrors.year = 'Choose year';
          institutionArrayError[index] = institutionErrors;
        } else if (!institution || !isValidYear(institution.year)) {
          institutionErrors.year = 'Invalid Year';
          institutionArrayError[index] = institutionErrors;
        }
      });
      if (institutionArrayError.length) {
        errors.p_licenses = institutionArrayError;
      }
    }
  }

  if (values.p_certified === true) {
    if (!values.p_certifications || values.p_certifications.length === 0) {
      errors.p_certifications = 'Please choose institution and issued year.';
    } else {
      const institutionArrayError = [];
      values.p_certifications.forEach((institution, index) => {
        const institutionErrors = {};
        if (!institution || !institution.institution) {
          institutionErrors.institution = 'Select Institution';
          institutionArrayError[index] = institutionErrors;
        }
        if (!institution || !institution.year) {
          institutionErrors.year = 'Choose year';
          institutionArrayError[index] = institutionErrors;
        } else if (!institution || !isValidYear(institution.year)) {
          institutionErrors.year = 'Invalid Year';
          institutionArrayError[index] = institutionErrors;
        }
      });
      if (institutionArrayError.length) {
        errors.p_certifications = institutionArrayError;
      }
    }
  }

  if (!values.time_availability || values.time_availability.length === 0) {
    errors.time_availability = 'Please choose your business hour';
  }

  // if (!isEmpty(values.session_type)) {
  //   errors.session_per = 'This field is required.';
  // }

  if (values.accept_insurance) {
    if (!values.accepted_insurances || values.accepted_insurances.length === 0) {
      errors.accepted_insurances = 'This field is required.';
    }
  }
  return errors;
};

const scrollToFirstError = errors => {
  let fieldName = Object.keys(errors)[0];
  if(document.querySelectorAll(`[name="${fieldName}"]`)[0]){
    document.querySelectorAll(`[name="${fieldName}"]`)[0].scrollIntoView({
      block: "center",
    });
  }
}

class PractitionerProfileSettings extends React.Component {
  updateProfile = values => {
    const { updateUserProfile } = this.props;
    updateUserProfile(values);
  };

  render() {
    const { profile, handleSubmit, categories } = this.props;
    return (
      <div id="practitioner-profile-settings">
        <Form onSubmit={handleSubmit(this.updateProfile)}>
          <div
            className="offset-md-10 col-md-2 col-12 d-flex justify-content-end save-changes"
            style={{
              paddingRight: 0,
              position: 'sticky',
              top: '1rem',
              zIndex: 100,
            }}
          >
            <Button onClick={() => {}} type="submit">
              <span>Save Changes</span>
            </Button>
          </div>

          <div className="mt-3">
            <div className="practitioner-profile-settings-details">
              <ProfileUpload varient="primary" />
              <PractitionerProfileForm />
            </div>
            {profile && profile.u_member_profile_completion_status && (
              <div className="my-3">
                <h5 className="font-weight-bold">Member Profile Settings</h5>
                <MemberProfileSettings />
              </div>
            )}
            {profile && profile.u_prac_profile_completion_status && (
              <div className="my-3">
                <h5 className="font-weight-bold">Practitioner Profile Settings</h5>
                <div className="professional-services">
                  <div className="row">
                    <div className="col-md-12 col-12 mb-3">
                      <label className="sub-title">ABOUT ME</label>
                      <Field
                        name="about_me"
                        rows={4}
                        info="(Should not exceed more than 255 Characters)"
                        maxLength={255}
                        component={TextArea}
                      />
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                      <label className="sub-title">SPECIALITIES</label>
                      <Field
                        name="specializations"
                        component={props => (
                          <MultiSearchDropDown
                            placeholder="Select your specializations"
                            options={categories}
                            other
                            onSelect={value => {
                              props.input.onChange(value);
                            }}
                            value={props.input.value}
                            varient="primary"
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <PractitionerBusinessBackground />
                <ProfessionalServices />
                <SpiritualBackground />
                <ProfessionalBackground />
                <TimeAvailabilitySettings />
                <SessionPrice />
                <AcceptInsurance />
                {/* <PractitionerLocation /> */}
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const initialValues = {};
  if (
    state.settings.profile &&
    state.settings.profile.practitionerProfile &&
    state.settings.profile.practitionerProfile
  ) {
    initialValues.p_licensed = state.settings.profile.practitionerProfile.p_licensed;
    initialValues.p_certified = state.settings.profile.practitionerProfile.p_certified;
    initialValues.p_self_taught = state.settings.profile.practitionerProfile.p_self_taught;
    initialValues.p_licenses = state.settings.profile.practitionerProfile.p_licenses.map(item => {
      return { institution: item.institution._id, year: item.year };
    });
    initialValues.p_certifications = state.settings.profile.practitionerProfile.p_certifications.map(
      item => {
        return { institution: item.institution._id, year: item.year };
      }
    );
    if(state.settings.profile.practitionerProfile.p_location_cordinates) {
      const coordinates = {
        lat: state.settings.profile.practitionerProfile.p_location_cordinates.coordinates[1],
        lng: state.settings.profile.practitionerProfile.p_location_cordinates.coordinates[0]
      };
      initialValues.location = {
        address: state.settings.profile.practitionerProfile.p_location_address,
        coordinates,
    }
  }
  }
  return {
    profile: state.settings.profile,
    categories: state.app.categories,
    initialValues: {
      u_first_name: state.auth.currentUser && state.auth.currentUser.u_first_name,
      u_last_name: state.auth.currentUser && state.auth.currentUser.u_last_name,
      u_gender: state.auth.currentUser && state.auth.currentUser.u_gender,
      u_dob:
        state.auth.currentUser &&
        state.auth.currentUser.u_dob &&
        new Date(state.auth.currentUser.u_dob),
      // practitioner profile
      business_name:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_business_name,
      phone_number:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_phone_number,
      hide_business_info:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_hide_business_info,
      address:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_address,
      website:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_website,
      country:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_country,
      state:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_state,
      city:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_city,
      experience:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_experience,

      about_me:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_about,
      specializations:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_specialization &&
        state.settings.profile.practitionerProfile.p_specialization.map(item => item._id),
      session_price_type:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_session_price_type,
      session_price:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_session_price,
      session_type:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        (Array.isArray(state.settings.profile.practitionerProfile.p_session_type)
          ? state.settings.profile.practitionerProfile.p_session_type
          : [state.settings.profile.practitionerProfile.p_session_type]),
      types_of_service:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        (Array.isArray(state.settings.profile.practitionerProfile.p_service_type)
          ? state.settings.profile.practitionerProfile.p_service_type
          : [state.settings.profile.practitionerProfile.p_service_type]),
      professional_type:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_professional_type,
      bestowned_institutions:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_bestowed_institutions.map(item => {
          return { institution: item.institution._id, year: item.year };
        }),
      accept_insurance:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_accept_insurance,
      accepted_insurances:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_accepted_insurances,
      spiritual_orientations:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_spiritual_orientations,
      cultural_heritages:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_cultural_heritages,
      p_set_time_per_day:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_set_time_per_day,
      time_availability:
        state.settings.profile &&
        state.settings.profile.practitionerProfile &&
        state.settings.profile.practitionerProfile.p_time_availability,

      c_relationship_status:
        state.settings.profile &&
        state.settings.profile.memberProfile &&
        state.settings.profile.memberProfile.c_relationship_status,
      c_sexual_orientation:
        state.settings.profile &&
        state.settings.profile.memberProfile &&
        state.settings.profile.memberProfile.c_sexual_orientation,
      c_intrests:
        state.settings.profile &&
        state.settings.profile.memberProfile &&
        state.settings.profile.memberProfile.c_intrests,
      // p_licensed: true,
      // p_licenses:
      //   state.settings.profile &&
      //   state.settings.profile.practitionerProfile &&
      //   state.settings.profile.practitionerProfile.p_licenses.map(item => {
      //     return { institution: item.institution._id, year: item.year };
      //   }),
      // p_certified: true,
      // p_certifications:
      //   state.settings.profile &&
      //   state.settings.profile.practitionerProfile &&
      //   state.settings.profile.practitionerProfile.p_certifications.map(item => {
      //     return { institution: item.institution._id, year: item.year };
      //   }),
      ...initialValues,
    },
  };
};

const mapDispatchToProps = {
  getCountries,
  getStates,
  getCities,
  updateUserProfile,
};

PractitionerProfileSettings.propTypes = {
  profile: PropTypes.shape({
    practitionerProfile: PropTypes.shape({
      p_country: PropTypes.string,
      p_state: PropTypes.string,
    }),
    u_prac_profile_completion_status: PropTypes.bool,
    u_member_profile_completion_status: PropTypes.bool,
  }),
  categories: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  // getCountries: PropTypes.func.isRequired,
  // getStates: PropTypes.func.isRequired,
  // getCities: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
};

PractitionerProfileSettings.defaultProps = {
  profile: null,
  categories: [],
};

PractitionerProfileSettings = reduxForm({
  form: 'memberprofilesettings',
  validate: validators,
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(PractitionerProfileSettings);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PractitionerProfileSettings);
