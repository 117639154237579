/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Talktext, Talkbubble, HimAvatar, ChatBubble, Texttime } from './style';
import moment from 'moment';

const Him = ({ message, u_image_url, time }) => {
  const profileImage = u_image_url || '/images/user.svg';

  return (
    <>
      <ChatBubble>
        <div className="col">
          <div className="row align-items-center">
            <div>
              <HimAvatar src={profileImage} className="rounded-circle" />
              <Texttime>{moment(new Date(time)).format('hh:mm A')} </Texttime>
            </div>
            <Talkbubble>
              <Talktext>{message}</Talktext>
            </Talkbubble>
          </div>
        </div>
      </ChatBubble>
    </>
  );
};

Him.propTypes = {
  message: PropTypes.string.isRequired,
  u_image_url: PropTypes.string,
};
Him.defaultProps = {
  u_image_url: '',
};
export default Him;
