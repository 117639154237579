import React from 'react'
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import './DragNDrop.css'



const DragNDrop = (props) => {
    const { children, accept} = props;
    return (
        <Dropzone onDrop={props.onFileChoose} accept={accept}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} className="h-100"/>
                        {children}
                    </div>
                </section>
            )}
        </Dropzone>
    )
}

export default connect()(DragNDrop);