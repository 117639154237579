/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import './PractitionerOffers.css';

const PractitionerOffers = ({ promotions, className }) => {
  let PromotionsList = promotions.map(promotion => (
    <div key={promotion._id} className="prac_offers_body">
      <div className="d-flex">
        <img src="/icons/percentage.svg" height="30px" weight="30px" alt="percentage" />
        <h6 className="m-1 p-0">{promotion.promotion_title}</h6>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <p>End Date -</p>
        <p className="date">{moment(promotion.promotion_end_date).format('DD MMM YYYY')}</p>
      </div>
    </div>
  ));
  if (PromotionsList.length === 0) {
    PromotionsList = (
      <div className="text-center py-5 px-3">
        <p className="m-0">No Promotions</p>
      </div>
    );
  }
  return (
    <div className={['prac_offers', className].join(' ')}>
      <div className="prac_offers_header">
        <h6 className="m-0">Your Promotions</h6>
      </div>
      <div className="prac_offers_body_container">{PromotionsList}</div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
  };
};

PractitionerOffers.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};
PractitionerOffers.defaultProps = {
  className: '',
};

export default connect(mapStateToProps)(PractitionerOffers);
