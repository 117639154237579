import {
  SET_ERROR,
  SET_LOADER,
  SET_COUNTRIES_LIST,
  SET_STATES_LIST,
  SET_CITIES_LIST,
  SET_META_DATA,
  SET_POPULAR_CONTENTS,
  SET_RECENT_CONTENTS,
  SET_FEATURED_PRACTITIONERS,
  SET_USER_TYPE_MODAL,
  SET_PAYMENT_CHECK_PLAN,
  SET_FOCUS_LIST,
  OPEN_FEEDBACK_MODAL,
  SET_INTEREST_LIST,
  SET_USER_LOCATION,
  SET_TESTIMONIALS,
} from '../actions/types';

const initialState = {
  loading: false,
  userTypeModal: false,
  countries: [],
  states: [],
  cities: [],
  cultural_heritages: [],
  spiritual_orientations: [],
  popular_contents: [],
  featured_practitioners: [],
  recent_contents: [],
  universities: [],
  insurance_types: [],
  categories: [],
  focuses: [],
  error: null,
  paymentPricePlan: null,
  showFeedBackModal: false,
  interest_list: [],
  location: null,
  testimonials: [],
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_COUNTRIES_LIST: {
      return {
        ...state,
        countries: action.payload,
      };
    }
    case SET_STATES_LIST: {
      return {
        ...state,
        states: action.payload,
      };
    }
    case SET_CITIES_LIST: {
      return {
        ...state,
        cities: action.payload,
      };
    }
    case SET_INTEREST_LIST: {
      return {
        ...state,
        interest_list: action.payload,
      };
    }

    case SET_META_DATA: {
      return {
        ...state,
        countries: action.payload.countries,
        cultural_heritages: action.payload.cultural_heritages,
        spiritual_orientations: action.payload.spiritual_orientations,
        universities: action.payload.universities,
        insurance_types: action.payload.insurances,
        categories: action.payload.categories,
      };
    }

    case SET_USER_TYPE_MODAL: {
      return {
        ...state,
        userTypeModal: action.payload,
      };
    }

    case SET_FOCUS_LIST: {
      return {
        ...state,
        focuses: action.payload,
      };
    }

    case SET_POPULAR_CONTENTS: {
      return {
        ...state,
        popular_contents: action.payload,
      };
    }

    case SET_RECENT_CONTENTS: {
      return {
        ...state,
        recent_contents: action.payload,
      };
    }

    case SET_FEATURED_PRACTITIONERS: {
      return {
        ...state,
        featured_practitioners: action.payload,
      };
    }
    case SET_PAYMENT_CHECK_PLAN: {
      return {
        ...state,
        paymentPricePlan: action.payload,
      };
    }
    case OPEN_FEEDBACK_MODAL: {
      return {
        ...state,
        showFeedBackModal: action.payload,
      };
    }
    case SET_USER_LOCATION: {
      return {
        ...state,
        location: action.payload
      }
    }
    case SET_TESTIMONIALS: {
      return {
        ...state,
        testimonials: action.payload
      }
    }
    default:
      return state;
  }
};

export default appReducer;
