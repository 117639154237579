/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import Player from 'griffith';
import Navbar from '../../shared/Navbar/Navbar';
import ContentListCard from '../../shared/Cards/ContentListCard/ContentListCard';
import Footer from '../../shared/Footer/Footer';
import * as data from '../../../data/data.json';
import Report from '../../common/Report/Report';
import CommentComponent from '../../common/Comment/Comment';
import Rating from '../../common/Rating/Rating';
import './ViewArticle.css';
import { getContent, rateContent, bookmarkContent } from '../../../store/actions/contentAction';
import { createSoundCloudUrl } from '../../../utils/helper';
import PurchaseModal from '../../common/PurchaseModal/PurchaseModal';
import { BASE_URL } from '../../../config';

const { popularArticles } = data.default;
class ViewArticle extends Component {
  descriptionRef = React.createRef();

  constructor() {
    super();
    this.state = {
      showReport: false,
      showmore: false,
    };
  }

  componentDidMount() {
    const { match, getContent, history } = this.props;
    const { content_id } = match.params;
    window.scrollTo(0, 0);
    getContent(content_id, err => {
      if (err) {
        history.push(`/not-found`);
      }
      const video = document.querySelector('video');
      if (video) {
        video.addEventListener(
          'ended',
          () => {
            const element = document.querySelector('[class*="_1d7iuxk"]');
            if (element) {
              element.innerHTML = '<img src="/images/replay.png" />';
            }
          },
          false
        );
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { match, getContent, premiumUser, contentPurchased, history } = this.props;
    const { content_id } = match.params;
    if (content_id !== prevProps.match.params.content_id || premiumUser !== prevProps.premiumUser) {
      window.scrollTo(0, 0);
      getContent(content_id, err => {
        if (err) {
          history.push(`/${content_id}/not-found`);
        }
      });
    }
    if (prevProps.contentPurchased !== contentPurchased && contentPurchased) {
      getContent(content_id, err => {
        if (err) {
          history.push(`/${content_id}/not-found`);
        }
      });
    }
  }

  bookmarkContentHandler = () => {
    const { bookmarkContent, match } = this.props;
    const { content_id } = match.params;
    bookmarkContent(content_id);
  };

  renderPopularContents = () => {
    const popularContents = popularArticles.map((content, i) => {
      let cards;
      if (i < 9) {
        cards = (
          <div key={i} style={{ cursor: 'pointer' }}>
            <ContentListCard content={content} />
          </div>
        );
      }
      return cards;
    });
    return popularContents;
  };

  renderContentsFromPract = () => {
    const { content, history } = this.props;
    const List = content.moreFormSamePractitioner.map(cont => (
      <ContentListCard
        key={cont._id}
        content={cont}
        onClick={() => history.push(`/content/${cont._id}`)}
      />
    ));
    return List;
  };

  toggleReport = () => {
    const { showReport } = this.state;
    this.setState({ showReport: !showReport });
  };

  renderTitle = () => {
    const {
      content: { content_title },
      bookmarkStatus,
    } = this.props;
    return (
      <div
        className="d-flex justify-content-between py-3"
        style={{ borderBottom: ' solid 1px #eaedf3' }}
      >
        <h2 className="font-weight-bold m-0 pr-4 content-title text-vertical-truncate line-2">
          {content_title}
        </h2>
        <img
          src={bookmarkStatus ? '/icons/bookmark-fill.svg' : '/icons/bookmark-outline.svg'}
          onClick={this.bookmarkContentHandler}
          role="presentation"
          height="20"
          className="mt-2"
          alt="bookmark"
        />
      </div>
    );
  };

  changeVideoUrl = (url) => {
    const urlType = url.match(/youtube/g) || url.match(/youtu.be/g) ? 'youtube' : url.match(/vimeo/g) ? 'vimeo' : '';
    let videoUrl;
    if (urlType === 'youtube') {
      videoUrl = url.replace("watch?v=", "embed/");
      if(url.match(/youtu.be/g)) { videoUrl = url.replace("youtu.be", "youtube.com/embed/"); }
    } else if (urlType === 'vimeo') {
      const vimeo = url.split('/');
      const vimeoID = vimeo[vimeo.length - 1];
      videoUrl = "https://player.vimeo.com/video/" + vimeoID
    }
    return videoUrl
  }

  renderContent = () => {
    const { content } = this.props;
    const articleContent = content.article_content ? content.article_content : null;
    let sources = {};
    if (
      (content.content_type === 'video' || content.content_type === 'audio') &&
      content.content_file_id
    ) {
      sources = {
        hd: {
          play_url: content.content_file_id.file_url,
        },
        sd: {
          play_url: content.content_file_id.file_url,
        },
      };
    }
    return (
      <div className="video-content pt-3">
        {content.content_type === 'video' && (
          <>
            <div className="wrapper">
              {content.content_video_url ? 
                <iframe src={this.changeVideoUrl(content.content_video_url)}
                  frameBorder='0'
                  allow='autoplay; encrypted-media'
                  allowFullScreen
                  title='video'
                  style={{height: 300, width: '100%', height: '100%'}}
                />
               :
              <Player
                id="content-video"
                sources={sources}
                cover={content.content_thumbnail_url}
                locale="en"
              />
            }
            </div>
            {this.renderTitle()}
            {this.renderUserDetails()}
          </>
        )}
        {content.content_type === 'audio' && (
          <div>
            {content.content_soundcloud_url ? (
              <div>
                <iframe
                  width="100%"
                  height="124"
                  scrolling="no"
                  title="audio"
                  frameBorder="no"
                  allow="autoplay"
                  src={createSoundCloudUrl(content.content_soundcloud_url)}
                />
              </div>
            ) : (
              // <div className="row no-gutters align-items-center bg-light">
              //   <div className="col-3">
              //     <img
              //       className="w-100 thumbnail-image"
              //       src={content.content_thumbnail_url}
              //       alt="thumbnail"
              //       style={{ objectFit: 'cover' }}
              //     />
              //   </div>
              //   <div className="col pl-3">
              //     <audio src={content.content_file_id.file_url} controls style={{ width: '100%' }}>
              //       {/* <source src={content.content_file_id.file_url} type="audio/ogg" /> */}
              //     </audio>
              //   </div>
              // </div>
              <div className="wrapper">
                {content.content_video_url ? 
                  <iframe src={this.changeVideoUrl(content.content_video_url)}
                    frameBorder='0'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                    style={{height: 300, width: '100%', height: '100%'}}
                  />
                :
                <Player
                  id="content-video"
                  sources={sources}
                  cover={content.content_thumbnail_url}
                  locale="en"
                />
                }
              </div>
            )}
            {this.renderTitle()}
            {this.renderUserDetails()}
          </div>
        )}
        {content.content_type === 'article' && (
          <div>
            {/* <img
              className="w-100 thumbnail-image"
              src={content.content_thumbnail_url}
              alt="thumbnail"
            /> */}
            {this.renderTitle()}
            {this.renderUserDetails()}
            <div
              className="pb-3 pt-3 article-section clearfix mb-4"
              dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(articleContent)) }}
            />
          </div>
        )}
      </div>
    );
  };

  renderUserDetails = () => {
    const { showmore } = this.state;
    const {
      content: {
        content_owner,
        content_description,
        content_type,
        content_views,
        content_created_at,
      },
    } = this.props;
    return (
      <>
        <div className="d-flex justify-content-between pt-3 pb-3">
          <div className="user-details d-flex">
            <Link to={`/${content_owner.u_prac_username}`}>
              <img
                className="mt-1"
                src={content_owner.u_image_url || '/images/user.svg'}
                alt="user"
              />
            </Link>
            <div className="pl-2">
              <Link
                to={`/${content_owner.u_prac_username}`}
                className="text-capitalize text-dark"
              >{`${content_owner && content_owner.u_first_name} ${(content_owner &&
                content_owner.u_last_name) ||
                ''}`}</Link>
              <span className="date d-block">
                {moment(content_created_at).format('DD MMM YYYY')}
              </span>
            </div>
          </div>
          {content_views && (
            <p className="mb-0 font-weight-bold" style={{ color: '#9c9c9c' }}>
              {content_views} views
            </p>
          )}
        </div>
        {content_type !== 'article' && (
          <div>
            <span
              ref={this.descriptionRef}
              className="content-description"
              style={{
                overflow: showmore ? 'inherit' : 'hidden',
                WebkitLineClamp: showmore ? 'unset' : '2',
              }}
            >
              {content_description}
            </span>
            {!showmore && (
              <span className="see-more" onClick={() => this.setState({ showmore: true })}>
                See More
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  ratingHandler = rating => {
    const { rateContent, match, content } = this.props;
    const { content_id } = match.params;
    const newRate = !(content && content.my_rating);
    rateContent(content_id, rating, newRate);
  };

  renderCatagories = () => {
    const {
      content: { content_categories },
    } = this.props;

    let categories = [];
    const categories1 = content_categories && content_categories.slice(0, 4);
    const categories2 = content_categories && content_categories.slice(4);

    categories =
      categories1 &&
      categories1.map(cat => (
        <OverlayTrigger
          key={cat._id}
          placement="top"
          overlay={
            <Tooltip>
              <span className="text-capitalize">{cat.category_name}</span>
            </Tooltip>
          }
        >
          <div key={cat._id} className="catagory-chips pr-2 pl-2 mr-2 text-capitalize">
            {cat.category_name}
          </div>
        </OverlayTrigger>
      ));
    if (categories2 && categories2.length) {
      categories.push(
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {categories2.map(cat => (
                <span key={cat._id} className="text-capitalize d-block text-left">
                  {cat.category_name}
                </span>
              ))}
            </Tooltip>
          }
        >
          <div className="catagory-chips pr-2 pl-2 mr-2 text-capitalize">
            +{categories2.length} more
          </div>
        </OverlayTrigger>
      );
    }
    return categories;
  };

  renderTrendingCatagory = () => {
    const { focuses } = this.props
    const catgories = focuses.map((focus, i) => {
      return (
        <Link to={`/category/${focus.focus}`} key={focus._id}>
          <div
            className="mr-3 p-1 mb-3"
            style={{ border: 'solid 1px #cbc9c9', borderRadius: '3px' }}
          >
            {' '}
            {focus.focus_display_name}
          </div>
        </Link>
      );
    });
    return catgories;
  };

  loadSkelton = () => {
    return [1, 2, 3].map(skelton => {
      return (
        <div key={skelton} className="row pt-5 justify-content-between">
          <div className="col-lg-8">
            <div className="p-5 w-100 shine col" />
            <div className="col d-flex flex-column px-0">
              <div className="p-4 mt-3 w-100 shine col-8" />
              <div className="p-3 mt-3 w-100 shine col-3" />
            </div>
            <div className="col d-flex flex-column px-0">
              <div className="p-4 mt-3 w-100 shine col-8" />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-3 w-100 shine col" />
            <div className="p-4 mt-3 w-100 shine col-8" />
            <div className="p-4 mt-3 w-100 shine col-8" />
            <div className="p-4 mt-3 w-100 shine col-8" />
            <div className="p-4 mt-3 w-100 shine col-8" />
          </div>
        </div>
      );
    });
  };

  render() {
    const { showReport } = this.state;
    const { contentLoader, content, premiumUser, match, contentPurchased, user } = this.props;
    let showPurchaseModal = false;
    if (
      (content && content.content_access_type === 'exclusive' && !contentPurchased) ||
      (!premiumUser && !contentPurchased)
    ) {
      showPurchaseModal = true;
    }
    return (
      <div className="article-view">
        <Navbar />
        <div className="container">
          {contentLoader ||
          (content &&
            content.content_access_type === 'exclusive' &&
            !contentPurchased &&
            (user && user._id !== content.content_owner._id)) ||
          (!premiumUser &&
            !contentPurchased &&
            content &&
            (user === null || (user && user._id !== content.content_owner._id)) &&
            content.content_access_type !== 'free') ? (
            this.loadSkelton()
          ) : (
            <div className="row">
              <div className="col-lg-8 article-content">
                {content && <div>{this.renderContent()}</div>}
                {content && (
                  <div className="d-flex justify-content-between mb-2 pt-4">
                    <div className="d-flex">{this.renderCatagories()}</div>
                    <div className="share-icons">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`http://www.facebook.com/sharer.php?u=${BASE_URL}/content/${match.params.content_id}`}
                      >
                        <i className="icon-facebook text-black pr-3 mr-2 fb" />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://twitter.com/intent/tweet?url=${BASE_URL}/content/${match.params.content_id}`}
                      >
                        <i className="icon-twitter text-black twitter" />
                      </a>
                    </div>
                  </div>
                )}
                <div className="review-box">
                  <div className="row" style={{ padding: '0 15px 0 15px' }}>
                    <div className="col mt-1" />
                    <div className="ml-3 mr-3">
                      <div className="rectangle" />
                    </div>
                    <div className="col mt-1" />
                  </div>

                  {showReport ? (
                    <div className="expand-container">
                      <Report
                        title="Report Content"
                        contentId={match.params.content_id}
                        onCancel={this.toggleReport}
                      />
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-between mt-2"
                      style={{ borderBottom: '1px solid #eaedf3' }}
                    >
                      <p
                        role="presentation"
                        style={{ cursor: 'pointer', color: '#9c9c9c', fontSize: '0.75rem' }}
                        onClick={this.toggleReport}
                      >
                        Report
                      </p>
                    </div>
                  )}
                  <div>
                    <div className="p-4 text-center">
                      <h6 style={{ fontWeight: 'bold' }}>
                        Help us evaluate the quality of the content.
                      </h6>
                      <div className="pt-2 pb-3">
                        <Rating
                          height="33px"
                          width="33px"
                          initialRating={(content && content.my_rating) || 0}
                          onChange={this.ratingHandler}
                        />
                      </div>
                      <p className="pt-4 mb-0"> {content && content.ratingCount} Ratings</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="pt-4">
                    <CommentComponent contentId={match.params.content_id} />
                  </div>
                </div>
                <div
                  className="pt-3 trending-categories mb-4"
                  style={{ borderBottom: 'solid 1px #eaedf3' }}
                >
                  <h6 className="pb-3">Trending Categories</h6>
                  <div className="mb-2 d-flex flex-wrap pt-3">{this.renderTrendingCatagory()}</div>
                </div>
              </div>
              <div className="col-lg-3 offset-lg-1 mb-3">
                {content &&
                  content.moreFormSamePractitioner &&
                  content.moreFormSamePractitioner.length > 0 && (
                    <div className="recommented-view mt-2">
                      <div className="pt-2 pb-2 heading">
                        <h5 className="mb-0">More from this Practitioner</h5>
                      </div>
                      {this.renderContentsFromPract()}
                    </div>
                  )}
                {/* <div className="recommented-view mt-5">
                  <div className="pt-2 pb-2 heading">
                    <h5 className="mb-0">Recommented for you</h5>
                  </div>
                  {this.renderPopularContents()}
                </div> */}
              </div>
            </div>
          )}
        </div>
        <Footer />
        {content &&
          content.content_access_type !== 'free' &&
          (user === null || (user && user._id !== content.content_owner._id)) && (
            <PurchaseModal content={content} show={showPurchaseModal} />
          )}
      </div>
    );
  }
}

ViewArticle.propTypes = {
  content: PropTypes.shape({
    content_title: PropTypes.string,
    content_type: PropTypes.string,
    content_description: PropTypes.string,
    content_access_type: PropTypes.string,
    content_thumbnail_url: PropTypes.string,
    content_soundcloud_url: PropTypes.string,
    article_content: PropTypes.string,
    content_file_id: PropTypes.shape({
      file_url: PropTypes.string,
    }),
    content_owner: PropTypes.shape({
      u_first_name: PropTypes.string,
      u_image_url: PropTypes.string,
      u_last_name: PropTypes.string,
      u_prac_username: PropTypes.string,
    }),
    moreFormSamePractitioner: PropTypes.arrayOf(PropTypes.object),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      content_id: PropTypes.string,
    }),
  }).isRequired,
  getContent: PropTypes.func.isRequired,
  rateContent: PropTypes.func.isRequired,
  bookmarkContent: PropTypes.func.isRequired,
  bookmarkStatus: PropTypes.bool,
};

ViewArticle.defaultProps = {
  content: null,
  bookmarkStatus: false,
};

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
    content: state.content.content,
    bookmarkStatus: state.content.bookmarkStatus,
    contentLoader: state.content.contentLoader,
    premiumUser: state.payment.premiumUser,
    contentPurchased: state.payment.contentPurchased,
    focuses: state.app.focuses,
  };
};
const mapDispatchToProps = {
  getContent,
  rateContent,
  bookmarkContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewArticle);
