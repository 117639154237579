/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MultiSearchDropDown } from '../../../common/MultiSearchDropDown/MultiSearchDropDown';

const SpiritualBackground = ({ spiritual_orientations, cultural_heritages }) => {
  return (
    <div className="professional-services">
      <h1 className="professional-title">Personal Background</h1>
      <div className="row">
        <div className="col-12 mb-5">
          <label className="sub-title">SPIRITUAL ORIENTATION</label>
          <Field
            name="spiritual_orientations"
            component={props => (
              <MultiSearchDropDown
                placeholder="Spiritual Orientations"
                options={spiritual_orientations}
                varient="primary"
                other
                onSelect={value => {
                  props.input.onChange(value);
                }}
                value={props.input.value}
                {...props}
              />
            )}
          />
        </div>
        <div className="col-12 mb-4">
          <label className="sub-title">CULTURAL HERITAGE</label>
          <Field
            name="cultural_heritages"
            component={props => (
              <MultiSearchDropDown
                placeholder="Cultural Heritage"
                options={cultural_heritages}
                varient="primary"
                other
                onSelect={value => {
                  props.input.onChange(value);
                }}
                value={props.input.value}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

SpiritualBackground.propTypes = {
  cultural_heritages: PropTypes.arrayOf(PropTypes.shape({})),
  spiritual_orientations: PropTypes.arrayOf(PropTypes.shape({})),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
};

SpiritualBackground.defaultProps = {
  cultural_heritages: [],
  spiritual_orientations: [],
  input: {},
};

const mapStateToProps = state => {
  return {
    cultural_heritages: state.app.cultural_heritages,
    spiritual_orientations: state.app.spiritual_orientations,
  };
};

export default connect(mapStateToProps)(SpiritualBackground);
