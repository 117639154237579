/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PractitionerRegistrationHeader from './PractitionerRegistrationHeader';
// import SearchDropDown from '../../../../common/SearchDropDown/SearchDropDown';
import { getStates, getCities } from '../../../../../store/actions/appActions';
import MatInput from '../../../../common/MatInput/MatInput';
import SelectInput from '../../../../common/SelectInput/SelectInput';
import Location from '../../../../common/Location/Location';
// import MaterialInput from '../../../../common/MaterialInput/MaterialInput';

const normalizeYears= value => {
  let onlyNums = value.replace(/[^0-9]/, '')
  return onlyNums.slice(0,2)
}
const PractitinerRegistration1 = ({
  countries,
  states,
  cities,
  getStates,
  getCities,
  updateField,
}) => {
  return (
    <div id="practitioner-registration-1" className=" py-md-5 py-4 mt-md-5">
      <div className="row">
        <div className="col-12">
          <PractitionerRegistrationHeader
            title="Practitioner Information"
            info="Let's start with your Practitioner details"
          />
        </div>
        <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">Name</label>
          <Field name="p_business_name" placeholder="Name" component={MatInput} />
        </div>
        <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">
            Phone Number
          </label>
          <Field name="p_phone_number" placeholder="Phone Number" component={MatInput} />
        </div>
        <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">Address</label>
          <Field name="p_address" placeholder="Address" component={MatInput} />
        </div>
        <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">Website</label>
          <Field name="p_website" placeholder="https://www.example.com" component={MatInput} />
        </div>
        {/* <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">Country</label>
          <Field
            name="p_country"
            varient="material"
            onChange={e => {
              updateField('p_state', null);
              updateField('p_city', null);
              getStates(e.target.value);
            }}
            component={SelectInput}
          >
            <option value="">Country</option>
            {countries.map(c => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
          </Field>
        </div>
        <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">State</label>
          <Field
            name="p_state"
            varient="material"
            component={SelectInput}
            onChange={e => {
              updateField('p_city', null);
              getCities(e.target.value);
            }}
          >
            <option value="">State</option>
            {states.map(c => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
          </Field>
        </div>
        <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">City</label>
          <Field name="p_city" varient="material" component={SelectInput}>
            <option value="">City</option>
            {cities.map(c => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
          </Field>
        </div> */}
        <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">
            Years Professionally Practicing
          </label>
          <Field
            name="p_experience"
            placeholder="Years Professionally Practicing"
            component={MatInput}
            normalize={normalizeYears}
          />
        </div>
        <div className="col-6 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">
            Location
          </label>
          <Field
            name="location"
            component={({ input, ...rest }) => 
              <Location onLocationSelect={(loc)=> input.onChange(loc)} value={input.value} varient="material" {...rest} />
            }
          />
        </div>
      </div>
    </div>
  );
};

PractitinerRegistration1.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  getStates: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    countries: state.app.countries,
    states: state.app.states,
    cities: state.app.cities,
  };
};

const mapDispatchToProps = {
  getStates,
  getCities,
  updateField: (field, value) => change('practitionerProfileForm', field, value),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PractitinerRegistration1);
