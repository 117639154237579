/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { notification } from '../../socket.client';
import SessionRequestItem from './SessionRequestItem';
import './SessionRequest.css';
import {
  getSessionRequests,
  updateSessionRequest,
} from '../../store/actions/practitionerRegAction';

class SessionRequest extends Component {
  wrapperRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { getSessionRequests, updateSessionRequest } = this.props;
    this.setState({ loading: true });
    getSessionRequests(() => {
      this.setState({ loading: false });
    });
    const user = JSON.parse(localStorage.getItem('user'));
    notification.emit('room', `room:${user._id}`);
    notification.on('sessionRequest', sessionRequest => {
      updateSessionRequest(sessionRequest);
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const { isOpen, loading } = this.state;
    const { sessionRequests } = this.props;

    let sessionRequestList = sessionRequests.map(session => (
      <SessionRequestItem key={session._id} sessionRequest={session} />
    ));

    if (sessionRequestList.length === 0) {
      sessionRequestList = (
        <div className="px-3 py-5 text-center">
          <p className="mb-0 font-medium" style={{ fontSize: '1rem' }}>
            No New Session Requests.
          </p>
        </div>
      );
    }

    const newRequests = sessionRequests.filter(sr => sr.session_request_read_status === false);
    return (
      <div
        ref={this.wrapperRef}
        className="session-request-wrapper mr-4 mb-1"
        style={{ fontSize: '6px' }}
      >
        {newRequests.length > 0 && <span className="session-notification-indicater" />}
        <svg
          onClick={() => this.setState({ isOpen: !isOpen })}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 15 15"
          fill="#9c9c9c"
          className="cursor-pointer"
        >
          <path
            fillRule="nonzero"
            d="M15 12.07a.586.586 0 0 1-.586.586h-1.758v1.758a.586.586 0 1 1-1.172 0v-1.758H9.727a.586.586 0 1 1 0-1.172h1.757V9.727a.586.586 0 1 1 1.172 0v1.757h1.758c.324 0 .586.263.586.586zm-4.688 2.344a.586.586 0 0 1-.585.586h-7.97A1.76 1.76 0 0 1 0 13.242v-1.084c0-1.011.434-1.972 1.19-2.636.612-.538 1.784-1.335 3.669-1.75a4.33 4.33 0 0 1-1.695-3.436A4.34 4.34 0 0 1 7.5 0a4.34 4.34 0 0 1 4.336 4.336A4.34 4.34 0 0 1 7.5 8.672c-3.22 0-4.945 1.21-5.537 1.73a2.336 2.336 0 0 0-.791 1.756v1.084c0 .323.263.586.586.586h7.969c.323 0 .585.262.585.586zM7.5 7.5a3.168 3.168 0 0 0 3.164-3.164A3.168 3.168 0 0 0 7.5 1.172a3.168 3.168 0 0 0-3.164 3.164A3.168 3.168 0 0 0 7.5 7.5z"
          />
        </svg>
        {isOpen && (
          <div id="session-request-popover" className="session-requests">
            <h5 className="title px-3 py-2 m-0">Requests to Write a Review</h5>
            {loading && <div className="text-center p-5">Loading...</div>}
            {!loading && sessionRequestList}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sessionRequests: state.practitionerReg.sessionRequests,
});

// const mapDispatchToProps = (dispatch) => ({
//     getSessionRequests,
//     updateSessionRequest: (sessionRequest) => {
//         dispatch({
//             type: UPDATE_SESSION_REQUESTS,
//             payload: sessionRequest
//         })
//     }
// })

SessionRequest.propTypes = {
  sessionRequests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getSessionRequests: PropTypes.func.isRequired,
  updateSessionRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getSessionRequests,
  updateSessionRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionRequest);
