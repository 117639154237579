/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './PractitionerCard.css';

// const popover = () => (
//   <Popover className="percentage-popover" id="popover-basic" arrowProps="triangle">
//     <div className="d-flex p-2">
//       <div className="mt-1">
//         <img src="/images/percentage.png" alt="" height="30" width="30" />
//       </div>
//       <p className="font-weight-bold mb-0">20% Discount on Audio, Videos and articles</p>
//     </div>
//   </Popover>
// );

const renderCatagories = p_specialization => {
  const remainingCatagory =
    p_specialization && p_specialization.length > 3 && p_specialization.slice(4);
  const catagory = p_specialization.map((cat, i) => {
    if (i < 4) {
      return (
        <div key={cat} className={`col-4 pl-1 pr-1 ${i === 3 && 'col-5 mt-2'}`}>
          <OverlayTrigger
            placement="top-end"
            overlay={
              <Tooltip id={`tooltip-${i}`}>
                <p className="mb-0 text-capitalize" style={{ fontSize: '0.75rem' }}>
                  {' '}
                  {cat}
                </p>
              </Tooltip>
            }
          >
            <div className="catagory-chips truncate first-row col py-1 px-2 mr-2 text-capitalize">
              {cat}
            </div>
          </OverlayTrigger>
        </div>
      );
    }
    if (i === p_specialization.length - 1) {
      return (
        <div className="col-5 mt-2">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip className="d-flex flex-column" id="overlay-example">
                {remainingCatagory.map(category => (
                  <p
                    className="mb-0 text-capitalize"
                    key={category}
                    style={{ fontSize: '0.75rem' }}
                  >
                    {' '}
                    {category}
                  </p>
                ))}
              </Tooltip>
            }
          >
            <div className="catagory-chips col p-1 text-capitalize">
              +{p_specialization.length - 4} more
            </div>
          </OverlayTrigger>
        </div>
      );
    }
    return null;
  });
  return catagory;
};

const PractitionerCard = ({ practitioner, onClick }) => {
  const {
    p_specialization,
    p_about,
    p_user: { u_first_name, u_prac_username, u_image_url } = {},
    rating,
  } = practitioner;
  const profileImage = u_image_url || '/images/user.svg';
  return (
    <div onClick={() => onClick(u_prac_username)} role="presentation">
      <div className="card practitioner-card">
        <div className="card-body d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="d-flex rate-section">
              <img className="ml-1" src="/icons/star.svg" alt="star" />
              <span className="ml-1 mr-1">{(rating && rating.toFixed(1)) || 0}</span>
            </div>
            {/* <OverlayTrigger trigger="click" placement="right" overlay={popover()} rootClose>
              <img src="/images/percentage.png" alt="" height="18" width="18" />
            </OverlayTrigger> */}
          </div>
          <div className="d-flex flex-column align-items-center practitioner-card-content">
            <img className="user-image" src={profileImage} alt="" />
            <h5 className="text-capitalize">{u_first_name}</h5>
            <div className="mt-3">
              <p className="text-center text-capitalize position-relative text-vertical-truncate">{p_about}</p>
            </div>
            <div className="w-100 row pt-2 justify-content-center mt-auto">
              {p_specialization && renderCatagories(p_specialization)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PractitionerCard.propTypes = {
  practitioner: PropTypes.shape({
    p_specialization: PropTypes.arrayOf(PropTypes.object),
    p_about: PropTypes.string,
    p_user: PropTypes.shape({
      u_first_name: PropTypes.string,
      u_username: PropTypes.string,
      u_image_url: PropTypes.string,
    }),
    rating: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
};
PractitionerCard.defaultProps = {
  onClick: () => {},
};

export default PractitionerCard;
