/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import PageLoader from '../../shared/PageLoader/PageLoader';
import { confirmEmail, clearError } from '../../../store/actions/authActions';
import './VerifyEmail.css';
import NotFound from '../../common/NotFound/NotFound';

class VerifyEmail extends Component {
  componentDidMount() {
    const { match, confirmEmail, history } = this.props;
    const { token } = match.params;
    setTimeout(() => {
      confirmEmail(token, history);
    }, 2000);
  }

  componentWillUnmount() {
    const { clearError } = this.props;
    clearError();
  }

  render() {
    const { error } = this.props;
    return (
      <div id="verify-email-page" className="d-flex justify-content-center align-items-center">
        {!error ? (
          <div className="text-center">
            <img className="mb-4" src="/images/email-sent-success.png" alt="mail-icon" />
            <h3 className="mb-1">You are awesome</h3>
            <p className="m-0">We are verifying your email address.</p>
            <p className="m-0">Please wait...</p>
            <PageLoader className="mt-5" />
          </div>
        ) : (
          <NotFound />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.auth.error,
});

const mapDispatchToProps = {
  confirmEmail,
  clearError,
};

VerifyEmail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  confirmEmail: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
};

VerifyEmail.defaultProps = {
  error: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail);
