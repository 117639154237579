/* eslint-disable no-case-declarations */
import {
  SET_ACTIVE_ROOM,
  RESET_ACTIVE_ROOM,
  UPDATE_SEARCH_USER,
  UPDATE_RECENT_USER,
  UPDATE_RECENT_SEARCH_KEY,
  UPDATE_MESSAGES,
  NEW_MESSAGE,
  SET_READ_MESSAGE,
  NOTIFY_MESSAGE,
  LOAD_CHAT
} from '../actions/types';

const initialState = {
  activeUser: null,
  recent: [],
  searchResult: [],
  room: null,
  messages: [],
  searchRecentKey: '',
  totalUnreadMessage:0,
  loadChat:false
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CHAT:
      return {
        ...state,
        loadChat:action.payload
      }
    case SET_ACTIVE_ROOM:
      return {
        ...state,
        room: action.payload,
        messages: [],
      };
    case RESET_ACTIVE_ROOM:
      return {
        ...state,
        room: null,
      };
    case NOTIFY_MESSAGE:
      return {
        ...state,
        totalUnreadMessage: action.payload,
      };
    case UPDATE_SEARCH_USER:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    case UPDATE_RECENT_USER:
      return {
        ...state,
        recent: [...action.payload],
      };
    case UPDATE_RECENT_SEARCH_KEY:
      return {
        ...state,
        searchRecentKey: action.payload,
      };

    case UPDATE_MESSAGES:
      return {
        ...state,
        messages: [...action.payload],
      };
    case NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case SET_READ_MESSAGE:
      const newState = state.recent.map(recent =>
        recent.room === action.payload ? { ...recent, count: 0 } : recent
      );

      return {
        ...state,
        recent: [...newState],
      };

    default:
      return state;
  }
};

export default messageReducer;
