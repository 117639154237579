/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import Switch from '../../common/Switch/Switch';
import { getProfile } from '../../../store/actions/authActions';
import { updateNotificationSettings } from '../../../store/actions/notificationAction';

class Notifications extends React.Component {
  render() {
    const { updateNotificationSettings, user } = this.props;
    return (
      <div id="notifications-settings">
        <div className="row">
          <div className="col-md-12 col-12">
            {user && user.u_prac_profile_completion_status && (
              <div className="d-flex justify-content-between">
                <div>
                  <h1 className="notification-settings-title">Comments</h1>
                  <p className="notification-settings-description">
                    Enable notifications when users comments on your content.
                  </p>
                </div>
                <Field
                  name="comments"
                  component={props => (
                    <Switch
                      id="coments"
                      checked={props.input.value}
                      onChange={event => {
                        updateNotificationSettings({ comments: event.target.checked });
                        props.input.onChange(event);
                      }}
                      classType="dark-switch"
                    />
                  )}
                />
              </div>
            )}
            <div className="d-flex justify-content-between">
              <div>
                <h1 className="notification-settings-title">Content Suggestion</h1>
                <p className="notification-settings-description">
                  Enable notification when a bookmarked practitioner posts new content.
                </p>
              </div>
              <Field
                name="content_suggestion"
                component={props => (
                  <Switch
                    id="content_suggestion"
                    checked={props.input.value}
                    onChange={event => {
                      updateNotificationSettings({ content_suggestion: event.target.checked });
                      props.input.onChange(event);
                    }}
                    classType="dark-switch"
                  />
                )}
              />
            </div>
            {/* <div className="d-flex justify-content-between">
              <div>
                <h1 className="notification-settings-title">Message</h1>
                <p className="notification-settings-description">
                  Enable email notifications when you receive a message.
                </p>
              </div>
              <Field
                name="messages"
                component={props => (
                  <Switch
                    id="messages"
                    checked={props.input.value}
                    onChange={event => {
                      updateNotificationSettings({ messages: event.target.checked });
                      props.input.onChange(event);
                    }}
                    classType="dark-switch"
                  />
                )}
              />
            </div> */}
            {/* <div className="d-flex justify-content-between">
              <div>
                <h1 className="notification-settings-title">Deals</h1>
                <p className="notification-settings-description">
                  Enable notifications when a bookmarked practitioner posts a new promotion.
                </p>
              </div>
              <Field
                name="deals"
                component={props => (
                  <Switch
                    id="deals"
                    checked={props.input.value}
                    onChange={event => {
                      updateNotificationSettings({ deals: event.target.checked });
                      props.input.onChange(event);
                    }}
                    classType="dark-switch"
                  />
                )}
              />
            </div> */}
            <div className="d-flex justify-content-between">
              <div>
                <h1 className="notification-settings-title">Rating</h1>
                <p className="notification-settings-description">
                  Enable notifications when users give you a rating and review.
                </p>
              </div>
              <Field
                name="rating"
                component={props => (
                  <Switch
                    id="rating"
                    checked={props.input.value}
                    onChange={event => {
                      updateNotificationSettings({ rating: event.target.checked });
                      props.input.onChange(event);
                    }}
                    classType="dark-switch"
                  />
                )}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <h1 className="notification-settings-title">Rating Reminder</h1>
                <p className="notification-settings-description">
                  Enable notification to write a review on completed session with a practitioner.
                </p>
              </div>
              <Field
                name="rating_reminder"
                component={props => (
                  <Switch
                    id="rating_reminder"
                    checked={props.input.value}
                    onChange={event => {
                      updateNotificationSettings({ rating_reminder: event.target.checked });
                      props.input.onChange(event);
                    }}
                    classType="dark-switch"
                  />
                )}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <h1 className="notification-settings-title">Session Request</h1>
                <p className="notification-settings-description">
                  Enable notification when a practitioner accepts your Session request.
                </p>
              </div>
              <Field
                name="session_request"
                component={props => (
                  <Switch
                    id="session_request"
                    checked={props.input.value}
                    onChange={event => {
                      updateNotificationSettings({ session_request: event.target.checked });
                      props.input.onChange(event);
                    }}
                    classType="dark-switch"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getProfile,
  updateNotificationSettings,
};

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
    profile: state.settings.profile,
    initialValues: {
      comments: state.auth.currentUser && state.auth.currentUser.notifications.comments,
      content_suggestion:
        state.auth.currentUser && state.auth.currentUser.notifications.content_suggestion,
      // messages: state.auth.currentUser && state.auth.currentUser.notifications.messages,
      // deals: state.auth.currentUser && state.auth.currentUser.notifications.deals,
      rating: state.auth.currentUser && state.auth.currentUser.notifications.rating,
      rating_reminder:
        state.auth.currentUser && state.auth.currentUser.notifications.rating_reminder,
      session_request:
        state.auth.currentUser && state.auth.currentUser.notifications.session_request,
    },
  };
};

Notifications.propTypes = {
  getProfile: PropTypes.func.isRequired,
  updateNotificationSettings: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'notificationSettings',
    enableReinitialize: true,
  })(Notifications)
);
