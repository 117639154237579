import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './PractitionerBusiness.css';

class PractitionerBusiness extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { business, ownProfile } = this.props;

    let BusinessInfo = (
      <div className="px-2 py-3 text-center">
        <img src="/images/business-empty.svg" className="mb-2" alt="business" />
        <p className="display2 text-secondary">No Business Added !</p>
        {ownProfile && 
          <Link to="/link-business" className="btn gt-btn">
            Add Business
          </Link>
        }
      </div>
    );

    if (business) {
      const members = business.members.map(member => (
        <Link to={`/${member.u_prac_username}`}>
          <div
            className="prac_mem_icon"
            data-name={`${member.u_first_name} ${member.u_last_name || ''}`}
            key={member._id}
          >
            <img src={member.u_image_url || '/images/user.svg'} alt="members" />
          </div>
        </Link>
      ));

      if (members.length >= 7) {
        const count = members.splice(5).length;
        members.push(
          <div className="prac_mem_count">
            <p>+{count}</p>
          </div>
        );
      }

      BusinessInfo = (
        <div>
          <div className="prac_buisness_details text-center">
            <img
              src={business.business_logo_url || '/images/business-empty.svg'}
              alt=""
              className="mb-3"
              style={{ height: '120px', width: '150px', objectFit: 'contain' }}
            />
            <h5 className="mb-0 text-vertical-truncate line-2">{business.business_name}</h5>
            <p>{business.business_address_line1}</p>
            <a
              href={business.business_website}
              className="display2"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#ccb385' }}
            >
              {business.business_website}
            </a>
          </div>
          <div className="prac_members position-relative">
            <p className="mb-0">Members</p>
            <div className="prac_member_icons d-flex pl-4 pb-4">{members}</div>
          </div>
        </div>
      );
    }
    return (
      <div className="prac_business mb-4">
        <div className="prac_business_header border-bottom border-gt">
          <h6 className="p-0 m-0">Business</h6>
        </div>
        {BusinessInfo}
      </div>
    );
  }
}

export default connect()(PractitionerBusiness);
