import React from 'react';

const ContentSkelton = () => {
  return (
    <div className="card content-card-skeleton">
      <div className="img-placehoder shine" style={{ height: '9rem' }} />
      <div className="p-3 text-left">
        <p className="d-block line-placeholder header-placeholder-h2 w-50 shine mb-3" />
        <p className="d-block line-placeholder w-100 shine" />
        <p className="d-block line-placeholder w-75 shine" />
        <p className="d-block line-placeholder w-50 shine" />
        <div className="footer d-flex justify-content-between mt-4">
          <span className="line-placeholder shine" />
          <span className="line-placeholder line-placeholder-smaller shine" />
        </div>
      </div>
    </div>
  );
};

// ContentSkelton.propTypes = {};

export default ContentSkelton;
