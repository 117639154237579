import io from 'socket.io-client';

let baseURL = '';
if (process.env.REACT_APP_ENV === 'development') baseURL = 'http://localhost:7000';
else if (process.env.REACT_APP_ENV === 'production') baseURL = 'https://api.guidetrue.com';
else baseURL = 'https://dev-api.guidetrue.com';

export const notification = io(`${baseURL}/notification`);
export const commentNameSpace = io(`${baseURL}/comment`);
export const messageNameSpace = io(`${baseURL}/message`);

notification.on('connect', () => {
  console.log('connected........');
});
