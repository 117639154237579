/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getProfile } from '../../../store/actions/authActions';
import UserProfile from '../UserProfile/UserProfile';
import PractitionerProfile from '../Practitioner/PractitionerProfile/PractitionerProfile';
import Navbar from '../../shared/Navbar/Navbar';
import './Profile.css';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { getProfile, history, match } = this.props;
    const { username } = match.params;
    this.setState({ loading: true });
    getProfile(username, err => {
      window.scrollTo(0, 0)
      this.setState({ loading: false });
      if (err) {
        history.push(`/not-found`);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { getProfile, history, match } = this.props;
    const { username } = match.params;
    if (username !== prevProps.match.params.username) {
      this.setState({ loading: true });
      getProfile(username, err => {
        window.scrollTo(0, 0)
        this.setState({ loading: false });
        if (err) {
          history.push(`/${username}/not_found`);
        }
      });
    }
  }

  render() {
    const { profile } = this.props;
    const { loading } = this.state;
    const Loading = (
      <div id="profile-skeleton">
        <div className="container py-3">
          <div className="row">
            <div className="col-lg-3">
              <div className="bg-white rounded border border-gt p-3">
                <div className="text-center border-bottom border-gt py-3">
                  <div className="d-block img-placeholder img-fluid shine rounded-circle mb-2 mx-auto" />
                  <p className="line-placeholder header-placeholder-h1 shine text-center w-50 mx-auto" />
                </div>
                <div className="border-bottom border-gt py-3">
                  <p className="line-placeholder header-placeholder-h2 shine" />
                  <p className="line-placeholder shine w-100" />
                  <p className="line-placeholder shine w-75" />
                  <p className="line-placeholder shine w-50" />
                </div>
                <div className="py-3">
                  <p className="line-placeholder header-placeholder-h2 shine" />
                  <p className="line-placeholder shine w-100" />
                  <p className="line-placeholder shine w-75" />
                  <p className="line-placeholder shine w-50" />
                </div>
              </div>
              <div className="bg-white rounded mt-3">
                <div className="border-bottom border-gt px-3 py-2">
                  <p className="line-placeholder header-placeholder-h2 w-25 shine mb-0" />
                </div>
                <div className="p-3" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bg-white rounded border border-gt p-3">
                <p className="line-placeholder header-placeholder-h2 shine w-25 mb-2" />
                <div className="d-flex border-bottom border-gt pb-1">
                  <p className="line-placeholder header-placeholder-h2 shine mr-1" />
                  <p className="line-placeholder header-placeholder-h2 shine mx-1" />
                  <p className="line-placeholder header-placeholder-h2 shine mx-1" />
                  <p className="line-placeholder header-placeholder-h2 shine mx-1" />
                </div>
                <div className="mt-3">
                  <div className="d-flex mb-2">
                    <div className="img-placeholder-small img-fluid shine mr-3" />
                    <div className="w-100">
                      <p className="line-placeholder header-placeholder-h2 shine w-50 mb-2" />
                      <p className="line-placeholder shine w-100" />
                      <p className="line-placeholder shine w-75" />
                      <p className="line-placeholder shine w-50" />
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="img-placeholder-small img-fluid shine mr-3" />
                    <div className="w-100">
                      <p className="line-placeholder header-placeholder-h2 shine w-50 mb-2" />
                      <p className="line-placeholder shine w-100" />
                      <p className="line-placeholder shine w-75" />
                      <p className="line-placeholder shine w-50" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded mt-3">
                <div className="border-bottom border-gt px-3 py-2">
                  <p className="line-placeholder header-placeholder-h2 w-25 shine mb-0" />
                </div>
                <div className="p-3">
                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="img-placeholder-xm rounded-circle shine" />
                      <p className="line-placeholder header-placeholder-h2 w-25 shine my-0 ml-2" />
                    </div>
                    <p className="line-placeholder shine m-0 w-100" />
                    <p className="line-placeholder shine m-0 w-75" />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="img-placeholder-xm rounded-circle shine" />
                      <p className="line-placeholder header-placeholder-h2 w-25 shine my-0 ml-2" />
                    </div>
                    <p className="line-placeholder shine m-0 w-100" />
                    <p className="line-placeholder shine m-0 w-75" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="bg-white rounded">
                <div className="border-bottom border-gt px-3 py-2">
                  <p className="line-placeholder header-placeholder-h2 shine mb-0" />
                </div>
                <div className="p-3">
                  <div className="d-flex justify-content-between mb-3">
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                    <p className="line-placeholder shine mb-0" />
                  </div>
                </div>
              </div>
              <div className="bg-white rounded mt-3">
                <div className="border-bottom border-gt px-3 py-2">
                  <p className="line-placeholder header-placeholder-h2 w-25 shine mb-0" />
                </div>
                <div className="p-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let Profile = null;
    if (!loading && profile) {
      Profile = profile.profileType === 'member' ? <UserProfile /> : <PractitionerProfile />;
    } else {
      Profile = Loading;
    }

    return (
      <>
        <Navbar />
        {Profile}
      </>
    );
  }
}

Profile.propTypes = {
  profile: PropTypes.shape({
    u_type: PropTypes.string,
  }),
  getProfile: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      username: PropTypes.string,
    }),
  }).isRequired,
};

Profile.defaultProps = {
  profile: null,
};

const mapStateToProps = state => ({
  profile: state.auth.profile,
});

const mapDispatchToProps = {
  getProfile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
