import React from 'react';
import PropTypes from 'prop-types';
import './GTCard.css';

const GTCard = ({ title, children, className, header }) => {
  return (
    <div className="gt-card">
      <div className={['gt-card-header d-flex align-items-center p-3', className].join(' ')}>
        <h6>{title}</h6>
        {header && header}
      </div>
      <div className="gt-card-body">{children}</div>
    </div>
  );
};

GTCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  header: PropTypes.oneOfType(PropTypes.string, PropTypes.element),
  className: PropTypes.string.isRequired,
};

GTCard.defaultProps = {
  header: '',
};

export default GTCard;
