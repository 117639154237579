import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const LinkBusinessComplete = ({ user }) => (
  <div
    id="link-business-complete"
    className="d-flex flex-column justify-content-center align-items-center h-100"
  >
    <h3>You have successfully linked your business.</h3>
    <p>
      Once approved, the business organization details will be shown in your profile, and you will
      be displayed on the organization's profile.
    </p>
    <img className="img-fluid success" src="/images/business-linked.svg" alt="business-linked" />
    {user && (
      <Link className="btn gt-btn-link" to={`/${user.u_prac_username}`}>
        Go to Profile
      </Link>
    )}
  </div>
);

const mapStateToProps = state => ({
  user: state.auth.currentUser,
});

export default connect(mapStateToProps)(LinkBusinessComplete);
