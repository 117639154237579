/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import DatePicker from 'react-datepicker';
import './DatePicker.css';

class GTDatePicker extends Component {
  state = {
    selectedDate: this.props.selected,
  };

  onSelect = selectedDate => {
    const { meta } = this.props;
    const { input } = this.props;
    this.props.dispatch(change(meta.form, input.name, selectedDate));
  };

  render() {
    const { input, meta, className, ...rest } = this.props;
    return (
      <div className="position-relative gt-datepicker">
        <img src="/icons/date-picker.svg" alt="datepicker" />
        <DatePicker
          {...rest}
          className={[className, meta.error && meta.touched ? 'invalid' : '']}
          selected={this.props.input.value}
          onChange={this.onSelect}
          // popperPlacement="auto"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        {meta && meta.error && meta.touched && (
          <span className="invalid-feedback d-block">{meta.error}</span>
        )}
      </div>
    );
  }
}

export default connect()(GTDatePicker);
