import React, { Component } from 'react';
import { isEmailValid } from '../../../../../utils/validators';
import './EmailInput.css';

class EmailInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emails: props.value || [],
      error: false,
    };
  }

  onChange = event => this.setState({ email: event.target.value });

  onEnter = event => {
    event.stopPropagation();
    const { onChange } = this.props;
    const { emails, email } = this.state;
    this.setState({ error: false });
    if (event.which === 13 && email) {
      event.preventDefault();
      if (isEmailValid(email)) {
        emails.push(email);
        onChange(emails);
        this.setState({ emails, email: '' });
      } else {
        this.setState({ error: true });
      }
    }
  };

  onRemoveEmail = email => {
    const { emails } = this.state;
    const { onChange } = this.props;
    this.setState({ emails: emails.filter(mail => mail !== email) }, () => {
      onChange(this.state.emails);
    });
  };

  render() {
    const { emails, email, error } = this.state;
    const { varient } = this.props;
    return (
      <div id="email-inputs" className={varient}>
        <div className="position-relative mb-3 email-input-wrapper">
          <input
            className="form-control"
            value={email}
            onChange={this.onChange}
            onKeyDown={this.onEnter}
            placeholder="Enter the email address"
          />
          {error && <span className="invalid-feedback d-block">Invalid email entered.</span>}
        </div>
        <div className="d-flex flex-wrap selected-emails">
          {emails.map((email, i) => (
            <span key={`email-${i}`}>
              {email}
              <img src="/icons/close.svg" onClick={() => this.onRemoveEmail(email)} alt="close" />
            </span>
          ))}
        </div>
      </div>
    );
  }
}
export default EmailInput;
