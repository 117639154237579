/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './AssessmentQuestionForm.css';
import { animateScroll as scroller } from 'react-scroll';
import Question from './Question/Question';
import { startAssessment } from '../../../../store/actions/assessmentActions';
import Button from '../../../common/Button/Button';

class AssessmentQuestionForm extends Component {
  constructor(props) {
    super(props);
    this.formElem = React.createRef();
  }

  getContainer = () => this.formElem.current;

  nextHandler = () => {
    const nodes = Array.from(this.formElem.current.childNodes).slice();
    const nodeIndex = nodes.findIndex(node => node.classList.contains('active'));
    if (nodeIndex > -1 && nodeIndex + 1 < nodes.length) {
      const nextNode = nodes[nodeIndex + 1];
      scroller.scrollTo(nextNode.offsetTop - 200, {
        duration: 300,
        delay: 0,
        smooth: true,
        containerId: 'ContainerScroll',
      });
    }
  };

  // buttonStatus = () => {
  //   const nodes = Array.from(this.formElem.current.childNodes);
  //   const nodeIndex = nodes.findIndex(node => node.classList.contains('active'));
  //   if (nodeIndex >= nodes.length - 1) return true;
  //   return false;
  // };

  backHandler = () => {
    const nodes = Array.from(this.formElem.current.childNodes);
    const nodeIndex = nodes.findIndex(node => node.classList.contains('active'));
    if (nodeIndex > -1 && nodeIndex - 1 > 0) {
      const prevNode = nodes[nodeIndex - 1];
      scroller.scrollTo(prevNode.offsetTop - 200, {
        duration: 300,
        delay: 0,
        smooth: true,
        containerId: 'ContainerScroll',
      });
    }
  };

  onShowResultHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    const { questions, answers, history, user } = this.props;
    const index = questions.findIndex(
      question => !(answers.findIndex(answer => answer.question_id === question._id) >= 0)
    );
    if (index > -1) {
      this.gotoQuestion(questions[index]._id);
      return false;
    }
    history.push(`/assessment-result/${user.u_username}`);
  };

  gotoQuestion = question_id => {
    const nodes = Array.from(this.formElem.current.childNodes);
    const question = nodes.find(node => node.id === question_id);
    scroller.scrollTo(question.offsetTop - 200, {
      duration: 1000,
      delay: 500,
      smooth: true,
      containerId: 'ContainerScroll',
    });
  };

  getRemainingAnswer = () => {
    const { questions, answers } = this.props;
    return questions.length - answers.length;
  };

  scrollHandler = event => {
    const nodes = Array.from(this.formElem.current.childNodes);
    nodes.forEach((qNode, i) => {
      const etop = qNode.offsetTop;
      const diff = etop - this.formElem.current.scrollTop;
      if (diff > 100 && diff < 350) {
        nodes.forEach(q => q.classList.remove('active'));
        qNode.classList.add('active');
      }
    });
  };

  onQuestionClickHandler = (target, currentTaget) => {
    if (
      (target.classList.contains('btn-scale') ||
        target.nodeName === 'LABEL' ||
        target.nodeName === 'INPUT') &&
      currentTaget.classList.contains('active')
    )
      return false;
    scroller.scrollTo(currentTaget.offsetTop - 200, {
      duration: 300,
      delay: 0,
      smooth: true,
      containerId: 'ContainerScroll',
    });
  };

  componentDidMount() {
    this.props.startAssessment(() => {
      const { questions, answers } = this.props;
      if (answers.length >= questions.length) {
        scroller.scrollToBottom({
          duration: 600,
          delay: 50,
          smooth: true,
          containerId: 'ContainerScroll',
        });
      }
      Array.from(this.formElem.current.childNodes)[0].classList.add('active');
      const index = questions.findIndex(
        question => !(answers.findIndex(answer => answer.question_id === question._id) >= 0)
      );
      if (index >= 0) this.gotoQuestion(questions[index]._id);
    });
  }

  render() {
    const { questions, answers, loading, progress, isAnswering } = this.props;
    return (
      <>
        {!loading && (
          <form
            ref={this.formElem}
            className="position-relative assessment-form"
            id="ContainerScroll"
            onScroll={this.scrollHandler}
          >
            {questions.map(qstn => (
              <Question
                key={qstn._id}
                question={qstn}
                getContainer={this.getContainer}
                onClick={this.onQuestionClickHandler}
              />
            ))}
            {/* {this.props.progress >= 100 && (
              <div className="col-12 mb-5">
                <p>
                  <span className="d-block font-weight-bold">Completed</span>
                  Thank you for completed this assessment and check your assessment result
                </p>
                <Button onClick={this.onShowResultHandler}>Show Result</Button>
              </div>
            )} */}
          </form>
        )}
        <div className="assessment-footer">
          <div className="container position-relative h-100">
            <div className="row h-100 align-items-center d-lg-flex d-none">
              <div className="col-lg-8 d-flex align-items-center">
                <div className="position-relative w-100">
                  <p className="mb-1">{progress}% Completed</p>
                  <ProgressBar now={progress} />
                  {isAnswering && (
                    <div className="position-absolute" style={{ right: 0, bottom: '0.5rem' }}>
                      <Spinner animation="border" variant="light" size="sm" />
                    </div>
                  )}
                </div>
                <div className="d-flex ml-auto px-3">
                  <button type="button" onClick={this.backHandler} className="nav-arrow-btn mr-3">
                    Back
                  </button>
                  <button type="button" onClick={this.nextHandler} className="nav-arrow-btn">
                    Next
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ml-auto float-right">
                  {this.getRemainingAnswer() > 0 ? (
                    <span className="mr-3">{`${this.getRemainingAnswer()} Questions Pending`}</span>
                  ) : (
                    <span className="mr-3">Finished</span>
                  )}
                  <Button
                    className="btn gt-btn"
                    disabled={progress < 100}
                    onClick={this.onShowResultHandler}
                  >
                    Show Result
                  </Button>
                </div>
              </div>
            </div>
            <div className="d-lg-none d-flex align-items-center h-100">
              <span>{answers.length}/44,</span>
              <span className="ml-3">{progress}%</span>
              <div className="ml-auto">
                <button
                  type="button"
                  onClick={this.backHandler}
                  className="nav-arrow-btn btn-sm p-2 mr-2"
                >
                  <i className="icon-arrow-up" />
                </button>
                <button
                  type="button"
                  onClick={this.nextHandler}
                  className="nav-arrow-btn btn-sm p-2 mr-2"
                >
                  <i className="icon-arrow-down" />
                </button>
              </div>
              <button
                type="button"
                className="btn btn-sm gt-btn py-2"
                onClick={this.onShowResultHandler}
                disabled={progress < 100}
                style={{ lineHeight: 1 }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    questions: state.assessment.questions,
    answers: state.assessment.answers,
    progress: state.assessment.progress,
    isAnswering: state.assessment.isAnswering,
    loading: state.app.loading,
    user: state.auth.currentUser,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { startAssessment }
  )(AssessmentQuestionForm)
);
