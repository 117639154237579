/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Input from '../../common/Input/Input';
import { updateUserProfile } from '../../../store/actions/settingsActions';
import {
  isTwitterUrl,
  isFacebookUrl,
  isInstagramUrl,
  isLinkedInUrl,
} from '../../../utils/validators';

const validater = values => {
  const errors = {};
  if (values.twitter_link && !isTwitterUrl(values.twitter_link)) {
    errors.twitter_link = 'Invalid twitter url';
  }
  if (values.facebook_link && !isFacebookUrl(values.facebook_link)) {
    errors.facebook_link = 'Invalid facebook url';
  }
  if (values.instagram_link && !isInstagramUrl(values.instagram_link)) {
    errors.instagram_link = 'Invalid instagram url';
  }
  if (values.linkedin_link && !isLinkedInUrl(values.linkedin_link)) {
    errors.linkedin_link = 'Invalid linkedin url';
  }
  return errors;
};

class LinkedAccounts extends React.Component {
  updateOnlineLinks = values => {
    const { updateUserProfile } = this.props;
    if (Object.keys(values).length !== 0) {
      updateUserProfile(values, 'links');
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.updateOnlineLinks)}>
        <div className="general-settings">
          <div className="row">
            <h1 className="change-password ml-3">Linked Accounts</h1>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 mb-3">
              <label className="subtitle">Twitter</label>
              <Field
                name="twitter_link"
                type="text"
                placeholder="https://www.twitter.com"
                component={Input}
              />
            </div>
            <div className="col-md-6 col-12 mb-3">
              <label className="subtitle">Facebook</label>
              <Field
                name="facebook_link"
                type="text"
                placeholder="https://www.facebook.com"
                component={Input}
              />
            </div>
            <div className="col-md-6 col-12 mb-3">
              <label className="subtitle">Instagram</label>
              <Field
                name="instagram_link"
                type="text"
                placeholder="https://instagram.com"
                component={Input}
              />
            </div>
            <div className="col-md-6 col-12 mb-3">
              <label className="subtitle">LinkedIn</label>
              <Field
                name="linkedin_link"
                type="text"
                placeholder="https://www.linkedin.com"
                component={Input}
              />
            </div>
          </div>
          <button type="submit" className="btn gt-btn mt-4">
            Update
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = {
  updateUserProfile,
};

const mapStateToProps = state => ({
  profile: state.auth.profile,
  initialValues: {
    twitter_link:
      state.settings.profile &&
      state.settings.profile.practitionerProfile &&
      state.settings.profile.practitionerProfile.twitter_link,
    facebook_link:
      state.settings.profile &&
      state.settings.profile.practitionerProfile &&
      state.settings.profile.practitionerProfile.facebook_link,
    instagram_link:
      state.settings.profile &&
      state.settings.profile.practitionerProfile &&
      state.settings.profile.practitionerProfile.instagram_link,
    linkedin_link:
      state.settings.profile &&
      state.settings.profile.practitionerProfile &&
      state.settings.profile.practitionerProfile.linkedin_link,
  },
});

LinkedAccounts.propTypes = {
  updateUserProfile: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'linkedAccounts',
    enableReinitialize: true,
    validate: validater,
  })(LinkedAccounts)
);
