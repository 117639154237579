import React from 'react';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import * as data from '../../../data/data.json';
import Singlefaq from './singlefaq';
import './Faq.css';

const { faq } = data.default;

const renderFaq = () => {
  const contents = faq.map(qa => <Singlefaq key={qa.id} qa={qa} />);
  return contents;
};

const FaqComponent = () => {
  return (
    <div className="faq-container">
      <Navbar />
      <div className="container p-5">
        <h3 className="font-weight-bold">FAQ</h3>
        <div>{renderFaq()}</div>
      </div>
      <Footer />
    </div>
  );
};
export default FaqComponent;
