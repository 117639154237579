/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Footer from '../../shared/Footer/Footer';
// import CategoryContent from './CategoryContent';
import SlidingCard from '../../shared/SlidingCard/SlidingCard';
import settings from '../../shared/SlidingCard/settings';
import Navbar from '../../shared/Navbar/Navbar';
import './CategoryView.css';
import getCategoryDetailes from '../../../store/actions/categoryAction';
import ContentCard from '../../shared/Cards/ContentCard/ContentCard';
import PractitionerCard from '../../shared/Cards/PractitionerCard/PractitionerCard';
import CategoryViewSkeleton from './CategoryViewSkeleton';

class CategoryView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      title: null,
      activeTab: 'article',
      loading: false,
    };
  }

  componentDidMount() {
    // const title = this.props.match.params.name.replace(/_/g, ' ');
    this.setState({ loading: true });
    const { getCategoryDetailes, match } = this.props;
    window.scrollTo(0,0)
    getCategoryDetailes(match.params.name, () => {
      this.setState({ loading: false });
    });
  }

  displayName = () => {
    const { match, focuses } = this.props;
    if (focuses.length) {
      const focus = focuses.find(foc => foc.focus === match.params.name);
      return focus.focus_display_name;
    }
    return '';
  };

  // eslint-disable-next-line camelcase
  onContentClick = content_id => this.props.history.push(`/content/${content_id}`);

  render() {
    const { activeTab, loading } = this.state;
    let { trending, premium } = this.props;
    const { contributers, focus } = this.props;
    trending = trending.filter(cont => cont.content_type === activeTab);
    premium = premium.filter(cont => cont.content_type === activeTab);
    const Trending = trending.map(content => (
      <ContentCard
        key={content._id}
        content={content}
        onClick={() => this.onContentClick(content._id)}
      />
    ));
    const Premium = premium.map(content => (
      <ContentCard
        key={content._id}
        content={content}
        onClick={() => this.onContentClick(content._id)}
      />
    ));
    const Contributors = contributers.map(practitioner => (
      <PractitionerCard
        key={practitioner._id}
        practitioner={practitioner}
        onClick={username => this.props.history.push(`/${username}`)}
      />
    ));
    return (
      <div className="category-view">
        <Navbar />
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(3, 17, 33, 0), #031121),url('/images/catagoryview.png')`,
            height: '27.75rem',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
          }}
        >
          <div>
            <div>
              <p className="heading m-0">{focus && focus.focus_display_name}</p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-align-center pr-4">
                <p className="count m-0">{focus && focus.contentCount}+</p>
                <p className="type m-0">Contents</p>
              </div>
              <div
                className="mb-1 mt-1"
                style={{ width: '2px', backgroundColor: '#ffffff', opacity: '.3' }}
              />
              <div className="text-align-center pl-4">
                <p className="count m-0">{focus && focus.contributerCount}+</p>
                <p className="type m-0">Contributors</p>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          className="tabs justify-content-center p-2"
          activeKey={activeTab}
          onSelect={selectedTab => this.setState({ activeTab: selectedTab })}
        >
          <Tab
            eventKey="article"
            title={
              <span className="mr-3">
                <svg
                  className="mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                >
                  <path
                    fill="#9C9C9C"
                    fillRule="nonzero"
                    d="M19.17 1.06A13.1 13.1 0 0 0 17 .87a12.94 12.94 0 0 0-7 2.05 12.94 12.94 0 0 0-7-2 13.1 13.1 0 0 0-2.17.19 1 1 0 0 0-.83 1v12a1 1 0 0 0 1.17 1 10.9 10.9 0 0 1 8.25 1.91l.12.07h.11a.91.91 0 0 0 .7 0h.11l.12-.07A10.9 10.9 0 0 1 18.83 15 1 1 0 0 0 20 14V2a1 1 0 0 0-.83-.94zM9 14.35a12.87 12.87 0 0 0-6-1.48H2v-10a8.69 8.69 0 0 1 1 0 10.86 10.86 0 0 1 6 1.8v9.68zm9-1.44h-1a12.87 12.87 0 0 0-6 1.48V4.67a10.86 10.86 0 0 1 6-1.8 8.69 8.69 0 0 1 1 0v10.04zm1.17 4.15a13.1 13.1 0 0 0-2.17-.19 12.94 12.94 0 0 0-7 2.05 12.94 12.94 0 0 0-7-2.05 13.1 13.1 0 0 0-2.17.19A1 1 0 0 0 0 18.21a1 1 0 0 0 1.17.79 10.9 10.9 0 0 1 8.25 1.91 1 1 0 0 0 1.16 0A10.9 10.9 0 0 1 18.83 19a1 1 0 0 0 1.17-.79 1 1 0 0 0-.83-1.15z"
                    opacity=".5"
                  />
                </svg>
                <span className="d-none d-sm-inline">Article</span>
              </span>
            }
          />

          <Tab
            eventKey="videos"
            title={
              <span className="mr-3 ml-3">
                <svg
                  className="mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="12"
                  viewBox="0 0 20 12"
                >
                  <path
                    fill="#9C9C9C"
                    fillRule="nonzero"
                    d="M19.53 1.15a1 1 0 0 0-1 0L15 2.89A3 3 0 0 0 12 0H3a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h9a3 3 0 0 0 3-2.89l3.56 1.78A1 1 0 0 0 19 11a1 1 0 0 0 1-1V2a1 1 0 0 0-.47-.85zM13 9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v6zm5-.62l-3-1.5V5.12l3-1.5v4.76z"
                    opacity=".5"
                  />
                </svg>
                <span className="d-none d-sm-inline">Videos</span>{' '}
              </span>
            }
          />

          <Tab
            eventKey="audio"
            title={
              <span className=" ml-3">
                <svg
                  className="mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#9C9C9C"
                    fillRule="nonzero"
                    d="M19.65.24a1 1 0 0 0-.8-.23l-13 2A1 1 0 0 0 5 3v10.35A3.45 3.45 0 0 0 3.5 13 3.5 3.5 0 1 0 7 16.5V8.86l11-1.69v4.18a3.45 3.45 0 0 0-1.5-.35 3.5 3.5 0 1 0 3.5 3.5V1a1 1 0 0 0-.35-.76zM3.5 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm13-2a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM18 5.14L7 6.83v-3l11-1.66v2.97z"
                    opacity=".5"
                  />
                </svg>
                <span className="d-none d-sm-inline">Audio</span>
              </span>
            }
          />
        </Tabs>
        {!loading && (
          <div className="filtered-content">
            <div className="category-content container" style={{ marginTop: '10vh' }}>
              {trending.length > 0 && (
                <SlidingCard settings={settings} cardNumber={4} heading="Hot & Trending">
                  {Trending}
                </SlidingCard>
              )}
              {premium.length > 0 && (
                <SlidingCard settings={settings} cardNumber={4} heading="Premium & Exclusive">
                  {Premium}
                </SlidingCard>
              )}
              {contributers.length > 0 && (
                <SlidingCard settings={settings} cardNumber={4} heading="Top Contributors">
                  {Contributors}
                </SlidingCard>
              )}
            </div>
          </div>
        )}
        {loading && (
          <div className="container mt-5">
            <CategoryViewSkeleton />
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

CategoryView.propTypes = {
  focus: PropTypes.shape({
    focus_display_name: PropTypes.string,
    contentCount: PropTypes.number,
    contributerCount: PropTypes.number,
  }),
  trending: PropTypes.arrayOf(PropTypes.object),
  premium: PropTypes.arrayOf(PropTypes.object),
  contributers: PropTypes.arrayOf(PropTypes.object),
  getCategoryDetailes: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
CategoryView.defaultProps = {
  focus: null,
  trending: [],
  premium: [],
  contributers: [],
};

const mapStateToProps = state => ({
  focus: state.category.focus,
  trending: state.category.trending,
  premium: state.category.premium,
  contributers: state.category.contributers,
});

export default connect(
  mapStateToProps,
  { getCategoryDetailes }
)(CategoryView);
