/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { notification } from '../../socket.client';
import {
  getNotifications,
  updateNotification,
  markAllAsRead,
  markAllAsSeen,
  markAsRead,
} from '../../store/actions/notificationAction';
import NotificationItem from './NotificationItem';
import './Notifications.css';
import { updateSessionStatus } from '../../store/actions/practitionerRegAction';

class Notifications extends Component {
  wrapperRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { updateNotification, updateSessionStatus, getNotifications } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    notification.emit('room', `room:${user._id}`);
    notification.on('notification', notification => {
      updateNotification(notification);
      if (notification.notification_type === 'SESSION_ACCEPT') {
        updateSessionStatus('accepted');
      }
    });
    getNotifications();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  openNotifications = () => {
    const { isOpen } = this.state;
    const { markAllAsSeen } = this.props;
    this.setState({ isOpen: !isOpen });
    if (this.getUnSeenNotifications().length) {
      markAllAsSeen();
    }
  };

  markAllAsReadHandler = () => {
    const { markAllAsRead } = this.props;
    if (this.getUnReadNotifications().length) {
      markAllAsRead();
    }
  };

  handleClickOutside = event => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  getUnSeenNotifications = () => {
    const { notifications } = this.props;
    return notifications.filter(notification => {
      return notification.reciever[0].seen_status === false;
    });
  };

  getUnReadNotifications = () => {
    const { notifications } = this.props;
    return notifications.filter(notification => {
      return notification.reciever[0].read_status === false;
    });
  };

  onNotificationClick = notification => {
    const { markAsRead, history, currentUser } = this.props;
    if (notification.reciever[0].read_status === false) {
      markAsRead(notification._id);
    }
    this.setState({ isOpen: false });
    if (notification.notification_type === 'ADD_REVIEW') {
      history.push(`/${currentUser.u_prac_username}`);
    } else if (notification.notification_type === 'SESSION_ACCEPT') {
      history.push(`/${notification.sender.u_prac_username}`);
    } else if (notification.notification_type === 'ASSESSMENT_RESULT_REQUEST') {
      history.push(`/${notification.sender.u_prac_username}`);
    } else if (
      notification.notification_type === 'NEW_CONTENT' ||
      notification.notification_type === 'NEW_COMMENT'
    ) {
      history.push(`/content/${notification.reference._id}`);
    } else if(notification.notification_type === 'SHARED_RESULT') {
      history.push(`/assessment-result/${notification.sender.u_username}`);
    } else {
      history.push(`/${notification.sender.u_username}`);
    }
  };

  render() {
    const { isOpen } = this.state;
    const { notifications } = this.props;

    let notificationsList = notifications.map(notification => (
      <NotificationItem
        key={notification._id}
        notification={notification}
        onClick={this.onNotificationClick}
      />
    ));
    if (notificationsList.length === 0) {
      notificationsList = (
        <div className="px-3 py-5 text-center">
          <p className="mb-0 font-medium">No Notifications Yet.</p>
        </div>
      );
    }
    const showIndicator = this.getUnSeenNotifications().length > 0;
    const isUnReadNotifications = this.getUnReadNotifications().length > 0;

    return (
      <div ref={this.wrapperRef} className="notification-wrapper">
        {showIndicator && <span className="notification-indicater" />}
        <i
          role="presentation"
          className="icon-bell mr-4 text-secondary cursor-pointer"
          onClick={this.openNotifications}
        />
        {isOpen && (
          <div id="notifications-popover">
            <div className="d-flex notification-header">
              <h5 className="title px-3 py-2 m-0">Notifications</h5>
              {isUnReadNotifications && (
                <button
                  type="button"
                  onClick={this.markAllAsReadHandler}
                  className="btn btn-sm btn-link gt-btn-link ml-auto"
                >
                  Mark all as read
                </button>
              )}
            </div>
            <div className="notifications">{notificationsList}</div>
          </div>
        )}
      </div>
    );
  }
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentUser: PropTypes.shape({
    u_username: PropTypes.string,
    u_prac_username: PropTypes.string,
  }).isRequired,
  getNotifications: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
  updateSessionStatus: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  markAllAsSeen: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  notifications: state.notification.notifications,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
  getNotifications,
  updateNotification,
  updateSessionStatus,
  markAllAsRead,
  markAllAsSeen,
  markAsRead,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notifications)
);
