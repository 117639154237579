import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './PractitionerSettings.css';
import { getSalesHistory } from '../../../store/actions/settingsActions';

class SalesHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    const { getSalesHistory } = this.props;
    this.setState({ loading: true });
    getSalesHistory(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { sales } = this.props;
    const { loading } = this.state;
    const Sales = sales.map((sale, index) => (
      <tr key={sale._id} style={{ opacity: sale.purchased_content_id.content_active ? 1 : 0.7  }}>
        <td>{index + 1}</td>
        <td>
          {
            sale.purchased_content_id.content_active ? 
            <Link to={`/content/${sale.purchased_content_id._id}`} className="text-dark">
              {sale.purchased_content_id.content_title}
            </Link>
            : sale.purchased_content_id.content_title
          }
        </td>
        <td>{moment(sale.created_at).format('DD MMM YYYY')}</td>
      </tr>
    ));

    let SalesTable = (
      <div className="table-responsive-md">
        <table className="practitioner-time-table table table-borderless">
          <thead>
            <tr>
              <th scope="col" className="practitioner-table-head">
                No.
              </th>
              <th scope="col" className="practitioner-table-head">
                Content Name
              </th>
              <th scope="col" className="practitioner-table-head">
                Sales Date
              </th>
            </tr>
          </thead>
          <tbody>{Sales}</tbody>
        </table>
      </div>
    );
    if (Sales.length === 0) {
      SalesTable = (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '30vh' }}
        >
          <p className="font-medium">No Sales History</p>
        </div>
      );
    }
    return (
      <div id="sales-history">
        <h1 className="shared-results-title">Sales History</h1>
        <p className="shared-results-subtitle">
          Your premium content sales and their details will be listed here.
        </p>
        {!loading && SalesTable}
        {loading && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '30vh' }}
          >
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sales: state.settings.sales,
});

export default connect(
  mapStateToProps,
  { getSalesHistory }
)(SalesHistory);
