/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { PolarArea as PolarAreaChart } from 'react-chartjs';
import RadialChart from './RadialChart/RadialChart';
import * as metaData from '../../../../data/meta.json';

const mapResultChartData = categories => {
  const data = [];
  categories.forEach(category => {
    category.subCategories.forEach(subcategory => {
      data.push({
        value: Math.round(subcategory.subcategory_percentage),
        color: category.color,
      });
    });
  });
  return data;
};

class ResultCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategory: this.props.categories[0],
    };
  }

  setActiveCategory = categoryId => {
    const { categories, onChange } = this.props;
    const category = categories.find(cat => cat._id === categoryId);
    this.setState({ activeCategory: category });
    if (onChange) onChange(category);
  };

  getSubCategoryName = subCategory => {
    if(subCategory === 'eating habits') { return 'Food'}
    else if(subCategory === 'home life') { return 'Shelter'}
    else if(subCategory === 'sleep schedule') { return 'Rest'}
    else if(subCategory === 'quality of physical touch in your life') { return 'Touch'}
    else if(subCategory === 'quality of sexual expression') { return 'Sexuality'}
    else if(subCategory === 'feeling of stability in your life') { return 'Certainity'}
    else if(subCategory === 'enjoyment of variety in your life') { return 'Change'}
    else if(subCategory === 'ability to focus') { return 'Focus'}
    else if(subCategory === 'sense of individual empowerment') { return 'Autonomy'}
    else if(subCategory === 'personal expression') { return 'Expression'}
    else if(subCategory === 'overall relationship with your friends') { return 'Friends'}
    else if(subCategory === 'intimate relationship(s)') { return 'Intimate Relationships'}
    else if(subCategory === 'overall sense of belonging') { return 'connection'}
    else if(subCategory === 'sense of love') { return 'love'}
    else if(subCategory === 'sense of significance') { return 'Significance'}
    else if(subCategory === 'sense of growth') { return 'Growth'}
    else if(subCategory === 'sense of contribution') { return 'Contribution'}
    else if(subCategory === 'sense of spiritual belonging') { return 'Spiritual Belonging'}
    else if(subCategory === 'sense of purpose') { return 'Purpose'}
    else if(subCategory === 'sense of accomplishment') { return 'Accomplishment'}
    else if(subCategory === 'sense of creative expression') { return 'Creativity'}
    else if(subCategory === 'money management') { return 'Management'}
    else return subCategory;
  }

  renderSubCategories = () => {
    const { activeCategory } = this.state;
    const subCategories = activeCategory.subCategories.map(subcategory => (
      <div key={subcategory._id} className="col-md-3 col-4">
        <RadialChart
          // title={subcategory.subcategory_name}
          title={this.getSubCategoryName(subcategory.subcategory_name)}
          radius={50}
          progress={Math.round(subcategory.subcategory_percentage)}
          color={activeCategory.color}
        />
      </div>
    ));
    return subCategories;
  };

  getActiveStateStyle = categoryId => {
    const { activeCategory } = this.state;
    if (activeCategory._id === categoryId) {
      return {
        backgroundColor: `${activeCategory.color}4d`,
        borderLeft: `4px solid ${activeCategory.color}`,
        fontWeight: 'bold',
        color: activeCategory.color,
      };
    }
    return {};
  };

  render() {
    const { categories, total, owner } = this.props;
    const { activeCategory } = this.state;
    const score = Math.round(activeCategory.category_percentage);
    const category = activeCategory.category_name;
    return (
      <div className="assessment-result">
        <div className="row no-gutters">
          <div className="col-lg-4 border-right">
            <div className="result-overview position-relative p-3">
              <div className="result-overview-total">
                <span className="font-medium">{Math.round(total)}%</span>
              </div>
              <PolarAreaChart
                data={mapResultChartData(categories)}
                options={{
                  scaleLineColor: 'rgba(0,0,0,.055)',
                  scaleShowLabelBackdrop: false,
                  segmentStrokeWidth: 1,
                  responsive: true,
                  maintainAspectRatio: false,
                  aspectRatio: 1,
                }}
              />
            </div>
            <div className="d-lg-block d-none">
              <ul className="p-0 m-0">
                {categories.map((category, i) => (
                  <li
                    key={category._id}
                    style={this.getActiveStateStyle(category._id)}
                    onClick={() => this.setActiveCategory(category._id)}
                    role="presentation"
                  >
                    {category.category_name}
                    <span>{`${Math.round(category.category_percentage)}%`}</span>
                  </li>
                ))}
                <li className="mb-3">
                  Total wellness
                  <span>{Math.round(total)}%</span>
                </li>
              </ul>
            </div>
            <div className="d-lg-none">
              <DropdownButton
                className=""
                title={activeCategory.category_name}
                style={this.getActiveStateStyle(category._id)}
              >
                {categories.map((category, i) => (
                  <Dropdown.Item
                    key={category._id}
                    className="text-capitalize"
                    onClick={() => this.setActiveCategory(category._id)}
                  >
                    {category.category_name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>
          <div className="col-lg-8">
            <p className="display1 px-3 font-medium text-capitalize my-3">
              {owner || 'You'} Scored {score}% in {category}
            </p>
            <div className="row">{this.renderSubCategories()}</div>
            <div className="px-3">
              {metaData.default.assessmentResultText[category].map((p, i) => (
                <p key={`p${i}`} className="text-secondary">
                  {p}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultCard;
