/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './Input.css';

const Input = ({ input, meta, onChange, icon, varient, label, ...rest }) => (
  <>
    <div className={['position-relative', label && 'd-flex'].join(' ')}>
      {icon && <i className={['input-icon', icon].join(' ')} />}
      <input
        {...input}
        {...rest}
        className={[
          'form-control',
          'gt-form-control',
          meta && meta.error && meta.touched ? 'invalid' : '',
          varient === 'material' ? 'material' : '',
        ].join(' ')}
        onChange={e => {
          input.onChange(e);
          if (onChange) onChange(e.target.value);
        }}
      />
      {label && label}
    </div>
    {meta && meta.error && meta.touched && (
      <span className="invalid-feedback d-block">{meta.error}</span>
    )}
  </>
);

Input.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
  onChange: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  icon: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Input.defaultProps = {
  input: null,
  meta: null,
  icon: '',
  onChange: () => {},
};

export default Input;
