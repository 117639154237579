import React from 'react';

const Next = ({ className, style, onClick }) => (
  <i
    className={[className, 'c-slick-next'].join(' ')}
    style={{ ...style }}
    onClick={onClick}
    role="presentation"
  />
);

const Prev = ({ className, style, onClick }) => (
  <i
    className={[className, 'c-slick-prev'].join(' ')}
    style={{ ...style, display: 'block' }}
    onClick={onClick}
    role="presentation"
  />
);

const settings = {
  dots: false,
  infinite: false,
  lazyLoad: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <Next />,
  prevArrow: <Prev />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 3,
        infinite: false,
      },
    },

    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
  ],
};

export default settings;
