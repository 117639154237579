/* eslint-disable no-underscore-dangle */
import React from 'react';
import moment from 'moment';
import Him from './Him';
import Me from './Me';
import { Datetext } from './style';

export default function ChatWindow({ currentUserId, messages, imageMe, imageHim }) {
  return messages.map(({ message, from, _id, updatedtime }, i) => {
    return (
      <div>
        {(i === 0 ||
          (messages[i - 1] &&
            !moment(new Date(messages[i - 1].updatedtime)).isSame(
              new Date(updatedtime),
              'day'
            ))) && (
          <div className="row m-0">
            <div
              className="col ml-3 mr-3"
              style={{ borderTop: i !== 0 ? ' 1px solid #eaedf3' : '', marginTop: '0.65rem' }}
            />
            <Datetext>
              {textedDate(
                updatedtime
                // i === 0 ? updatedtime : messages[i + 1] && new Date(messages[i + 1].updatedtime)
              )}
            </Datetext>
            <div
              className="col ml-3 mr-3"
              style={{ borderTop: i !== 0 ? ' 1px solid #eaedf3' : '', marginTop: '0.65rem' }}
            />
          </div>
        )}
        {currentUserId === from ? (
          <Me key={_id} message={message} u_image_url={imageMe} time={updatedtime} />
        ) : (
          <Him key={_id} message={message} u_image_url={imageHim} time={updatedtime} />
        )}
      </div>
    );
  });
}

const textedDate = updatedtime => {
  console.log('>>>>>', updatedtime);

  let date;
  const today = moment().startOf('day');
  const yesterday = moment()
    .subtract(1, 'days')
    .startOf('day');
  if (moment(updatedtime).isSame(today, 'd')) date = 'Today';
  else if (moment(updatedtime).isSame(yesterday, 'd')) date = 'Yesterday';
  else date = moment(updatedtime).format('MMM Do YYYY');
  return date;
};
