/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import RadioSelect from '../../../common/RadioSelect/RadioSelect';
import InputRange from '../../../common/InputRangeSlider/InputRangeSlider';

const SessionPrice = () => {
  return (
    <div className="professional-services">
      <h1 className="professional-title">Session Price</h1>
      <p className="time-description">Choose your general price range for your sessions</p>
      <div className="mt-3">
        <Field
          name="session_price_type"
          component={prop => (
            <RadioSelect options={['hourly', 'session']} varient="content-form" {...prop} />
          )}
        />
      </div>
      <p className="mt-4">Set your session price</p>
      <div className="mt-5">
        <Field
          name="session_price"
          component={props => (
            <InputRange
              varient="primary"
              value={props.input.value || { min: 50, max: 100 }}
              minValue={0}
              maxValue={500}
              step={5}
              {...props}
              formatLabel={value => `$${value}`}
            />
          )}
        />
      </div>
    </div>
  );
};

SessionPrice.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
};
SessionPrice.defaultProps = {
  input: {},
};

export default SessionPrice;
