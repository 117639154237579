import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentCard from '../../shared/Cards/ContentCard/ContentCard';
import { loadPopularContents, setPopularContents } from '../../../store/actions/appActions';
import ContentSkelton from '../../shared/Cards/ContentCard/ContentSkelton';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';

class Popular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasMore: true,
      page: 1,
    };
  }

  componentDidMount() {
    const { clearPopular } = this.props;
    this.setState({ loading: true });
    this.fetchData();
    clearPopular();
    document.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  fetchData = () => {
    const { loadPopularContents: getPopular } = this.props;
    const { page } = this.state;
    this.setState({ loading: true });
    getPopular(page, more => {
      this.setState({
        loading: false,
        hasMore: more,
        page: page + 1,
      });
    });
  };

  scrollHandler = () => {
    const { loading, hasMore } = this.state;
    if (loading || !hasMore) return;
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 200
    ) {
      this.fetchData();
    }
  };

  goToDetails = id => {
    const { history } = this.props;
    history.push(`/content/${id}`);
  };

  render() {
    const { loading } = this.state;
    const { popular } = this.props;
    const PopularContents = popular.map(content => (
      <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
        <ContentCard content={content} onClick={() => this.goToDetails(content._id)} />
      </div>
    ));
    const LoadSkeleton = (
      <div className="row">
        {[1, 2, 3, 54, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
          <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
            <ContentSkelton key={item} />
          </div>
        ))}
      </div>
    );
    return (
      <>
        <Navbar />
        <div className="container my-4" style={{ minHeight: '80vh' }}>
          {loading && !popular.length ? (
            <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
          ) : (
            <h2 className="slider-title mb-4">Popular Contents</h2>
          )}
          <div className="row">{PopularContents}</div>
          {loading && LoadSkeleton}
        </div>
        <Footer />
      </>
    );
  }
}

Popular.propTypes = {
  popular: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadPopularContents: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  popular: state.app.popular_contents,
});

export default connect(
  mapStateToProps,
  { 
    loadPopularContents,
    clearPopular: () => dispatch => dispatch(setPopularContents([]))
 }
)(Popular);
