/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import {
  registerUser,
  clearError,
  saveSocialUser,
  socialLogin,
} from '../../../store/actions/authActions';
import SocialButton from '../../common/SocialButton/SocialButton';
import './Signup.css';
import GTCarousel from '../../common/GTCarousel/GTCarousel';
import EmailSent from '../../shared/EmailSent/EmailSent';
import { isAuthenticated } from '../../../auth.service';
import { getTestimonials } from '../../../store/actions/appActions';

const guides = [
  {
    heading: 'MAKE MONEY FROM YOUR CONTENT',
    content: 'Upload your content, set your price, and get paid for your work',
  },
  {
    heading: 'FREE PUBLICITY & MATCHING',
    content: 'Be seen by your ideal audience and get featured for outstanding performance.',
  },
  {
    heading: 'CUSTOM MATCHING',
    content:
      'Fully personalized search parameters ensure you only invest time with people ideally compatible and sincere about your services',
  },
  {
    heading: 'ACCESS TO CLIENT ASSESSMENT',
    content:
      "Get comprehensive chronological insight of your client's history and progress to help you be most informed and aware of how you help your clients",
  },
  {
    heading: 'STRATEGIC ADVERTISING',
    content:
      'Customizable marketing targeted to your ideal audience, with automated upselling to maximize your impact',
  },
  {
    heading: 'LOW COST, GREAT VALUE',
    content:
      'Take your business to the next level for a̶s̶ ̶l̶o̶w̶ ̶$̶1̶0̶0̶/̶y̶r̶.̶  FREE for a limited time only',
  },
];

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailSent: false,
    };
  }

  componentDidMount() {
    const { getTestimonials } = this.props;
    getTestimonials();
  }
  
  componentWillUnmount() {
    const { clearError } = this.props;
    clearError();
  }

  createAccountHandler = values => {
    const { registerUser, history } = this.props;
    const params = new URLSearchParams(history.location.search);
    values.target = params.get('target');
    values.u_type = params.get('u_type');
    values.join_token = params.get('token');
    registerUser(values, () => {
      this.setState({ emailSent: true, email: values.u_email });
    });
  };

  changeUsername = (e) => {
    // console.log('heyyyyyyyyyyyyyyyyyy', e.target.value);
    this.setState({displayUsername: e.target.value})
  }

  oAuthSuccessHandler = info => {
    const { history, saveSocialUser, socialLogin } = this.props;
    saveSocialUser(info);
    const params = new URLSearchParams(history.location.search);
    const uType = params.get('u_type');
    socialLogin(info, (err, user) => {
      if (err) {
        history.push(`/signup/user/complete?action=signup&u_type=${uType || ''}`);
        return;
      }
      if (user && user.u_type === 'practitioner' && !user.u_prac_profile_completion_status) {
        history.push('create-practitioner-profile');
        return;
      }
      history.push(`user-feeds`);
    });
  };

  render() {
    const { email, emailSent, displayUsername } = this.state;
    const { history } = this.props;
    const params = new URLSearchParams(history.location.search);
    const uType = params.get('u_type');

    let SideBarContent = (
      <ul className="gt-feature-list">
        <li>Get perfectly matched with professionals to achieve your goals.</li>
        <li>Save time, money and energy finding the right practitioners & resources.</li>
        <li>Free membership.</li>
        <li>Confidential & Secure.</li>
        <li>Free trial consultation.</li>
        <li>Premium resources regularly updated.</li>
      </ul>
    );
    if (uType === 'practitioner') {
      SideBarContent = (
        <div id="practitioner-signup-banner" className="row mt-5 mb-4">
          {guides.map((guide, i) => (
            <div key={`guide${i}`} className="col-md-6 col-12 mb-4">
              <div className="guide">
                <h5>{guide.heading}</h5>
                <p>{guide.content}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }

    const signupView = (
      <div className="container-fluid" style={{ height: '100vh' }}>
        <div className="row h-100">
          <div className="col-lg-6 order-lg-first order-last signup-banner">
            <div className="row justify-content-center h-100 py-md-5 py-4">
              <div className="col-md-9 col-11 d-flex flex-column">
                <Link to="/">
                  <img
                    src="/icons/logo-white.svg"
                    className="d-lg-inline-block d-none mb-4"
                    width="128"
                    alt="Logo"
                  />
                </Link>
                {SideBarContent}
                <div className="mt-auto">
                  <GTCarousel />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row justify-content-center h-100 py-md-5 py-4">
              <div className="col-md-9 col-11 d-flex flex-column">
                <Link to="/">
                  <img src="/icons/logo.svg" className="d-lg-none mb-5" width="128" alt="Logo" />
                </Link>
                <div className="signup-text-box">
                  <h4 className="mb-0">Welcome to wellness.</h4>
                  <p>
                    Sign up today to get started. There is no payment or obligation upon
                    registration.
                  </p>
                </div>
                <div className="row py-3 pb-md-5 pb-4 position-relative oauth-container">
                  <div className="col-md-6 col-12 m-md-0 mb-3">
                    <SocialButton onSuccess={this.oAuthSuccessHandler} provider="facebook">
                      Signup with Facebook
                    </SocialButton>
                  </div>
                  <div className="col-md-6 col-12">
                    <SocialButton onSuccess={this.oAuthSuccessHandler} provider="google">
                      Signup with Google
                    </SocialButton>
                  </div>
                </div>
                <RegistrationForm onSubmit={this.createAccountHandler} changeUsername={this.changeUsername} displayUsername={displayUsername}/>
                <div className="auth-footer">
                  <span>
                    Already have an account ?
                    <Link className="ml-2 auth-footerlink" to="/signin">
                      Sign In
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const View = emailSent ? <EmailSent email={email} /> : signupView;
    return <>{isAuthenticated() ? <Redirect to="/" /> : View}</>;
  }
}

Signup.propTypes = {
  registerUser: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  saveSocialUser: PropTypes.func.isRequired,
  socialLogin: PropTypes.func.isRequired,
};

const mapStateToProp = state => {
  return {
    error: state.auth.error,
  };
};

export default connect(
  mapStateToProp,
  { registerUser, saveSocialUser, clearError, socialLogin, getTestimonials }
)(Signup);
