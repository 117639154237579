import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Singlefaq = ({ qa }) => {
  const [extend, setExtend] = useState(false);
  return (
    <div>
      <div className="pt-3">
        <div
          className="d-inline-flex qstn-answr"
          style={{ color: extend ? 'var(--primary-hover)' : '' }}
          onClick={() => setExtend(!extend)}
          onKeyDown={() => setExtend(!extend)}
          role="presentation"
        >
          <div>
            <svg
              className="mt-1"
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="14"
              viewBox="0 0 13 14"
            >
              {extend ? (
                <path fill="#D4AF37" fillRule="nonzero" d="M0 2.912V0h12.88v2.912z" />
              ) : (
                <path
                  fillRule="nonzero"
                  d="M4.928 13.216V8.064H0V5.152h4.928V0h3.024v5.152h4.928v2.912H7.952v5.152z"
                />
              )}{' '}
            </svg>
          </div>
          <h6 className="font-weight-bold pl-3 mb-0">{qa.question}</h6>
        </div>
        <div>
          {extend &&
            qa.answer.map(p => (
              <p key={p} className="pt-1">
                {p}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

Singlefaq.propTypes = {
  qa: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.array.isRequired,
  }).isRequired,
};

export default Singlefaq;
