import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../../store/actions/authActions';

class Home extends Component {

    logoutHandler = () => {
        this.props.logoutUser();
        this.props.history.push('/');
    }

    render() {
        return (
            <div className='container'>
                <h3>Home</h3>
                <button onClick={this.logoutHandler} >Logout</button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.currentUser
    }
}

export default connect(mapStateToProps, { logoutUser })(Home);