import React from 'react';
import PropTypes from 'prop-types';

const ListGuidelines = ({ data }) => {
  const guidelinesList = data.map(item => {
    return <li key={item.id}>{item.content}</li>;
  });
  return <ul>{guidelinesList}</ul>;
};
ListGuidelines.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default ListGuidelines;
