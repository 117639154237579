import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './FileUpload.css';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFileName: '',
    };
  }

  onChanges = event => {
    const {
      input: { onChange },
    } = this.props;
    if (event.target.files[0]) {
      onChange(event.target.files[0]);
      this.setState({ selectedFileName: event.target.files[0].name });
    }
  };

  render() {
    const { placeholder, accept } = this.props;
    const { selectedFileName } = this.state;
    return (
      <div className="file-upload d-flex align-items-center">
        <input
          type="file"
          accept={accept}
          className="custom-file-input input-file"
          onChange={this.onChanges}
        />
        <img src="/icons/upload.svg" className="p-2" alt="upload" />
        <p className="m-0 w-100 text-truncate">{selectedFileName || placeholder}</p>
        <button type="button" className="btn">
          Browse
        </button>
      </div>
    );
  }
}

FileUpload.propTypes = {
  placeholder: PropTypes.string,
  accept: PropTypes.string.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

FileUpload.defaultProps = {
  placeholder: '',
};

export default FileUpload;
