import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import './Location.css';

const Location = ({ onLocationSelect, value, meta, input, varient }) => {

    useEffect(() => {
        setAddress(value.address);
    }, [value])

    const [address, setAddress] = useState('');
    const [latLong, setLatLong ] = useState({ lat: '', lng: '' });

    const onChange = addr => {
        setAddress(addr);
    }

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setAddress(address);
                setLatLong(latLng);
                console.log('Success', latLng);
                onLocationSelect({
                    address,
                    coordinates: latLng
                })
            })
            .catch(error => console.error('Error', error));
    };

    return (
        <div name={input && input.name}>
            <PlacesAutocomplete
              value={address}
              onChange={onChange}
              onSelect={handleSelect}
            >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: ['location-search-input form-control', varient === 'material' ? ' gtm-form-control' : ' gt-form-control' ].join(' '),
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div className="p-2 text-center">
                <span className="spinner spinner-border spinner-border-sm"></span>
                </div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item-active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {meta && meta.error && meta.touched && (
        <span
          className={[
            'invalid-feedback d-block',
            varient === 'material' && 'gtm-invalid-feedback',
          ].join(' ')}
        >
          {meta.error}
        </span>
      )}
    </div>
    )
}

export default Location;
