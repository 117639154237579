/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getRecommendedFeedContents,
  setRecommendedFeedContents,
} from '../../../store/actions/contentAction';
import ContentCard from '../../shared/Cards/ContentCard/ContentCard';
import ContentSkelton from '../../shared/Cards/ContentCard/ContentSkelton';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';

// function Recommended({ recommended, getRecommendedFeedContents: getRecommended }) {
//   const [loading, setLoading] = useState(false);
//   const [processing, setProcessing] = useState(false);
//   const [hasMore, setHasMore] = useState(true);
//   const [page, setPage] = useState(1);

//   useEffect(() => {
//     fetchData();
//     document.addEventListener('scroll', scrollHandler);
//     return () => {
//       console.log('############ clen up called');
//       document.removeEventListener('scroll', scrollHandler);
//     };
//   }, [processing]);

//   const fetchData = () => {
//     getRecommended(page, more => {
//       setHasMore(more);
//       setPage(page + 1);
//       setProcessing(false);
//     });
//   };

//   const RecommendedContents = recommended.map(content => (
//     <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
//       <ContentCard content={content} />
//     </div>
//   ));

//   const scrollHandler = () => {
//     if (processing || loading || !hasMore) return;
//     if (
//       window.innerHeight + document.documentElement.scrollTop >=
//       document.documentElement.offsetHeight - 100
//     ) {
//       setProcessing(true);
//     }
//   };

//   return (
//     <div className="container my-4">
//       <h2 className="slider-title mb-3">Recommended For You</h2>
//       <div className="row">{RecommendedContents}</div>
//     </div>
//   );
// }

class Recommended extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasMore: true,
      page: 1,
    };
  }

  componentDidMount() {
    const { clearRecommended } = this.props;
    clearRecommended();
    this.fetchData();
    document.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  fetchData = () => {
    const { getRecommendedFeedContents: getRecommended } = this.props;
    const { page } = this.state;
    this.setState({ loading: true });
    getRecommended(page, more => {
      this.setState({
        loading: false,
        hasMore: more,
        page: page + 1,
      });
    });
  };

  scrollHandler = () => {
    const { loading, hasMore } = this.state;
    if (loading || !hasMore) return;
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      this.fetchData();
    }
  };
  goToDetails = id => {
    const { history } = this.props;
    history.push(`/content/${id}`);
  };

  render() {
    const { loading } = this.state;
    const { recommended } = this.props;

    const LoadSkeleton = (
      <div className="row">
        {[1, 2, 3, 54, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
          <div key={item} className="col-lg-3 col-md-4 col-sm-6 mb-3">
            <ContentSkelton key={item} />
          </div>
        ))}
      </div>
    );
    const RecommendedContents = recommended.map(content => (
      <div key={content._id} className="col-lg-3 col-md-4 col-sm-6 mb-3">
        <ContentCard key={content._id} content={content} onClick={() => this.goToDetails(content._id)} />
      </div>
    ));
    return (
      <>
        <Navbar />
        <div className="container my-4" style={{ minHeight: '80vh' }}>
          {loading && !recommended.length ? (
            <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
          ) : (
            <h2 className="slider-title mb-3">Recommended For You</h2>
          )}
          <div className="row">{RecommendedContents}</div>
          {loading && LoadSkeleton}
        </div>
        <Footer />
      </>
    );
  }
}

Recommended.propTypes = {
  recommended: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRecommendedFeedContents: PropTypes.func.isRequired,
  clearRecommended: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  recommended: state.content.recommendedFeeds,
});

const mapDispatchToProps = {
  getRecommendedFeedContents,
  clearRecommended: () => dispatch => dispatch(setRecommendedFeedContents([])),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Recommended);
