/* eslint-disable no-case-declarations */
import {
  SET_PROMOTIONS,
  SET_PROMOTION,
  ADD_PROMOTION,
  EDIT_PROMOTION,
  DELETE_PROMOTION,
  ADD_CARD,
  SET_ALL_CARDS,
  TOGGLE_ADD_CARD,
  ON_DELETE_CARD,
  SET_PRICE_PLANS,
  ON_UPDATE_CARD,
  SET_SHARED_RESULTS,
  SET_NEWRESULT_FIRST,
  SET_OLDRESULT_FIRST,
  UPDATE_SHARED_RESULT,
  SET_USER_PROFILES,
  SET_ORDERS_LIST,
  SET_SALES_LIST,
  SET_BUSINESS_INFO,
  CHANGE_BUSINESS_LOGO,
} from '../actions/types';

const INITIAL_STATE = {
  promotions: [],
  selectedPromotion: {},
  sharedResults: [],
  sharedWith: [],
  profile: null,
  cards: [],
  openAddCard: false,
  pricePlans: [],
  orders: [],
  sales: [],
  businessInfo: null,
};

const updateBusinessLogo = (state, logoUrl) => {
  const businessInfo = JSON.parse(JSON.stringify(state.businessInfo));
  businessInfo.business_logo_url = logoUrl;
  return businessInfo;
};
const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROMOTIONS:
      return { ...state, promotions: action.payload };
    case SET_PROMOTION:
      return { ...state, selectedPromotion: action.payload };
    case ADD_PROMOTION:
      const allPromo = [...state.promotions, action.payload];
      return { ...state, promotions: allPromo };
    case EDIT_PROMOTION:
      const updatedPromo = state.promotions.map(promo => {
        if (promo._id === action.payload._id) {
          return {
            ...promo,
            ...action.payload,
          };
        }
        return promo;
      });
      return { ...state, promotions: updatedPromo };
    case DELETE_PROMOTION:
      return {
        ...state,
        promotions: state.promotions.filter(promo => promo._id !== action.payload),
      };
    case SET_SHARED_RESULTS:
      return {
        ...state,
        sharedResults: action.payload.sharedByMe,
        sharedWith: action.payload.sharedWith,
      };
    case SET_NEWRESULT_FIRST:
      return { ...state, sharedResults: action.payload };
    case SET_OLDRESULT_FIRST:
      return { ...state, sharedResults: action.payload };
    case UPDATE_SHARED_RESULT:
      const updatedList = state.sharedResults.map(item => {
        if (item._id === action.payload._id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
      return { ...state, sharedResults: updatedList };
    case SET_USER_PROFILES:
      return {
        ...state,
        profile: action.payload,
      };
    case ADD_CARD:
      const cards = state.cards.slice();
      cards.push(action.payload);
      return {
        ...state,
        cards,
      };
    case ON_UPDATE_CARD:
      const updateCards = state.cards.slice();
      if (action.payload.card) {
        const updateIndex = updateCards.findIndex(card => card.id === action.payload.card.id);
        updateCards.splice(updateIndex, 1, action.payload.card);
        return {
          ...state,
          cards: updateCards,
        };
      }
      return {
        ...state,
      };
    case SET_ALL_CARDS:
      return {
        ...state,
        cards: action.payload,
      };
    case TOGGLE_ADD_CARD:
      return {
        ...state,
        openAddCard: action.payload,
      };
    case ON_DELETE_CARD:
      const allCards = state.cards.slice();
      allCards.splice(action.payload, 1);
      return {
        ...state,
        cards: allCards,
      };
    case SET_PRICE_PLANS:
      return {
        ...state,
        pricePlans: action.payload,
      };
    case SET_ORDERS_LIST:
      return {
        ...state,
        orders: action.payload,
      };
    case SET_SALES_LIST:
      return {
        ...state,
        sales: action.payload,
      };
    case SET_BUSINESS_INFO:
      return {
        ...state,
        businessInfo: action.payload,
      };
    case CHANGE_BUSINESS_LOGO:
      return {
        ...state,
        businessInfo: updateBusinessLogo(state, action.payload),
      };
    default:
      return state;
  }
};

export default settingsReducer;
