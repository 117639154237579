/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'redux-form';
import Switch from '../../../../common/Switch/Switch';
import PractitionerRegistrationHeader from './PractitionerRegistrationHeader';
import TimeAvailability from './TimeAvailability';

const PractitionerRegistration5 = () => {
  return (
    <div className="py-md-5 py-4 mt-md-5">
      <div className="d-flex">
        <PractitionerRegistrationHeader
          title="Business Hours"
          info="Select your general time for sessions."
        />
        <div className="d-flex align-items-center ml-auto">
          <span className="mr-3">Set Time Per Day</span>
          <Field
            name="p_set_time_per_day"
            component={prop => (
              <Switch
                id="2"
                checked={prop.input.value}
                onChange={event => prop.input.onChange(event)}
                {...prop}
              />
            )}
          />
        </div>
      </div>
      <div className="mb-5">
        <Field
          name="p_time_availability"
          component={prop => (
            <TimeAvailability
              selected={prop.input.value}
              onChangeTime={value => {
                prop.input.onChange(value);
              }}
              {...prop}
            />
          )}
        />
      </div>
      <div className="d-flex align-items-center mt-5">
        <span className="mr-4">Appointments Only</span>
        <Field
          name="p_appointments_only"
          component={props => (
            <Switch
              id="3"
              checked={props.input.value}
              onChange={event => props.input.onChange(event)}
              {...props}
            />
          )}
        />
      </div>
    </div>
  );
};

export default PractitionerRegistration5;
