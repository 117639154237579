import React from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import EmailInput from '../Practitioner/LinkBusiness/EmailInput/EmaiInput';

const InviteForm = ({ handleSubmit }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="emails"
        component={({ input }) => (
          <EmailInput value={input.value} onChange={input.onChange} varient="primary" />
        )}
      />
    </Form>
  );
};

InviteForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'invite-form',
})(InviteForm);
