import { SET_LINK_BUSINESS_PAGE_ACTIVE, SET_BUSINESSES } from '../actions/types';

const initialState = {
  pages: [
    {
      step: 1,
      status: 'progress',
    },
    {
      step: 2,
      status: 'waiting',
    },
    {
      step: 3,
      status: 'waiting',
    },
    {
      step: 4,
      status: 'waiting',
    },
  ],
  businesses: [],
};

const setPageActive = (state, step) => {
  return state.pages.map(page =>
    page.step === step ? { ...page, status: 'progress' } : { ...page, status: 'waiting' }
  );
};

const linkBusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LINK_BUSINESS_PAGE_ACTIVE:
      return {
        ...state,
        pages: setPageActive(state, action.payload),
      };
    case SET_BUSINESSES:
      return {
        ...state,
        businesses: action.payload,
      };
    default:
      return state;
  }
};

export default linkBusinessReducer;
