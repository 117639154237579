/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import Button from '../Button/Button';
import './AddCreditCard.css';
import { toggleAddCard } from '../../../store/actions/settingsActions';
import { addCreditCard } from '../../../store/actions/paymentActions';

class CardDetails extends Component {
  constructor() {
    super();
    this.state = {
      bankName: '',
      msg: '',
      bankError: false,
      cardError: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { clearPaymentForm } = this.props;
    if (clearPaymentForm !== prevProps.clearPaymentForm && clearPaymentForm) {
      this.clearForms();
    }
  }

  clearForms = () => {
    this.cardNumber.clear();
    this.cardExpiry.clear();
    this.cardCVV.clear();
    this.setState({ bankName: '' });
  };

  onSubmit = async () => {
    const { stripe, addCreditCard } = this.props;
    const { bankName } = this.state;
    try {
      if (!bankName.length) {
        this.setState({ msg: 'Cardholder name is required.', bankError: true });
        return false;
      }
      const { token } = await stripe.createToken({
        name: bankName,
      });
      if (token) {
        this.setState({ msg: '', cardError: false, bankError: false });
        addCreditCard(token, (err) => {
          if(err) {
            this.setState({ msg: err, cardError: true });
            return false;
          }
        });
      } else {
        this.setState({ msg: 'Invalid card details.', cardError: true });
        return false;
      }
    } catch (error) {
      this.setState({ msg: 'An error occured with payment.', cardError: true });
      return false;
    }
  };

  onCancel = () => {
    const { toggleAddCard } = this.props;
    this.clearForms();
    toggleAddCard(false);
  };

  render() {
    const { bankName, bankError, cardError, msg } = this.state;
    const { loading } = this.props;
    return (
      <div className="detail-container">
        <div className="row pt-5">
          <div className="col-md-6 col-12">
            <p className="card-label text-uppercase mb-0 mb-1">Enter Card no</p>
            <CardNumberElement
              onReady={c => (this.cardNumber = c)}
              onChange={() => {
                this.setState({
                  cardError: false,
                  msg: '',
                });
              }}
              placeholder="XXXX XXXX XXXX XXXX"
              className={`card-field ${cardError && 'border-danger'}`}
            />
          </div>
          <div className="col-md-3 col-6 pl-md-0 mt-md-0 mt-4">
            <p className="card-label text-uppercase mb-0 mb-1">Valid Thru</p>
            <CardExpiryElement
              onReady={c => (this.cardExpiry = c)}
              onChange={() => {
                this.setState({
                  cardError: false,
                  msg: '',
                });
              }}
              className={`card-field ${cardError && 'border-danger'}`}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-6 pl-0 mt-md-0 mt-4">
            <p className="card-label text-uppercase mb-0 mb-1">CVV</p>
            <CardCvcElement
              onReady={c => (this.cardCVV = c)}
              onChange={() => {
                this.setState({
                  cardError: false,
                  msg: '',
                });
              }}
              className={`card-field ${cardError && 'border-danger'}`}
            />
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-md-6 col-12">
            <p className="card-label text-uppercase mb-0 mb-1">Name on the Card</p>
            <input
              onChange={name => {
                this.setState({
                  bankName: name.target.value,
                  bankError: false,
                  msg: '',
                });
              }}
              value={bankName}
              className={`card-field p-2 w-100 ${bankError && 'border-danger'}`}
              placeholder="Your Name"
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-lg-3 col-md-5 col">
            <Button disabled={loading} onClick={this.onSubmit}>
              Save this card
            </Button>
          </div>
          <div className="col-md-4 col pl-md-4 d-flex align-items-center">
            <button type="submit" onClick={this.onCancel} className="btn btn-cancel pl-0">
              Cancel
            </button>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col">
            <p
              className={`card-label font-weight-normal pl-1 ${
                bankError || cardError ? 'text-danger' : 'text-body'
              }`}
            >
              {bankError || cardError
                ? msg
                : 'Your card details would be securely saved for faster payments. Your CVV will not be stored'}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

CardDetails.propTypes = {
  loading: PropTypes.bool.isRequired,
  addCreditCard: PropTypes.func.isRequired,
  toggleAddCard: PropTypes.func.isRequired,
  clearPaymentForm: PropTypes.bool.isRequired,
  stripe: PropTypes.shape({
    createToken: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  loading: state.app.loading,
  clearPaymentForm: state.payment.clearPaymentForm,
});

export default injectStripe(
  compose(
    connect(
      mapStateToProps,
      { addCreditCard, toggleAddCard }
    )
  )(CardDetails)
);
