/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { logoutUser } from '../../../store/actions/authActions';
import SearchBox from '../../pages/Landing/SearchBox';
import './Navbar.css';
import Publish from '../../publish/Publish';
import SessionRequest from '../../SessionRequests/SessionRequest';
import Notifications from '../../Notification/Notifications';
import UserType from '../../pages/Landing/UserType';
import ModalComponent from '../Modal/Modal';
import { setUserTypeModal } from '../../../store/actions/appActions';
import {recentMessage} from '../../../store/actions/messageAction';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
    };
  }

  componentDidMount(){
    const { recentMessage } = this.props;
    recentMessage('updatedtime');
  }

  nonloggedBar = () => (
    <>
      <div className="col-lg-5 d-lg-block d-none">
        <div className="d-flex justify-content-end">
          <Link className="nav-link" to="/">
            Home
          </Link>
          <Link className="nav-link" to="/signin?target=assessment">
            Free Assessment
          </Link>
          <Link className="nav-link" to="/signin">
            Sign in
          </Link>
          <button
            type="button"
            className="btn btn-signup pp"
            onClick={() => this.props.setUserTypeModal(true)}
          >
            Sign up
          </button>
        </div>
      </div>
    </>
  );

  popover = () => {
    const { logoutUser, history, user } = this.props;
    const ProfileImage = (user && user.u_image_url) || '/images/user.svg';
    return (
      <Popover id="navbar-user-popover" arrowProps="triangle">
        <div className="d-flex p-3" style={{ borderBottom: 'solid 1px #eaedf3' }}>
          <img
            className="rounded-circle"
            style={{ objectFit: 'cover' }}
            src={ProfileImage}
            height="38px"
            width="38px"
            alt=""
          />
          <div className="pl-1">
            <p className="mb-0 text-capitalize display2">
              {user.u_first_name} {user.u_last_name}
            </p>
            <p className="mb-0 display2 text-secondary">@{user.u_username}</p>
          </div>
        </div>

        <div className="d-flex justify-content-around flex-column popover-links">
          <Link to={`/${user.u_username}`}>View Member Profile </Link>
          {user.u_prac_profile_completion_status ? (
            <Link to={`/${user.u_prac_username}`}>View Practitioner Profile</Link>
          ) : (
            <Link to="/create-practitioner-profile">Create Practitioner Profile</Link>
          )}
          <Link to="/settings">Settings</Link>
          <div
            onClick={() => {
              logoutUser();
              history.push('/');
            }}
            role="presentation"
          >
            Logout
          </div>
        </div>
      </Popover>
    );
  };

  loggedBar = () => {

    const { user ,count} = this.props;
    const ProfileImage = (user && user.u_image_url) || '/images/user.svg';

    return (
      <>
        <div className="col-lg-5 d-flex">
          <div className="ml-lg-auto d-flex logged-bar-nav">
            {user.u_prac_profile_completion_status && (
              <div
                className="my-auto ml-auto order-1 order-lg-0 pt-lg-0 pt-2 pr-0"
                style={{ cursor: 'pointer' }}
              >
                <div className="publish float-right float-lg-left">
                  <Publish />
                </div>
              </div>
            )}
            <div className="d-flex align-items-center py-2">
              <Notifications />
              {user.u_prac_profile_completion_status && <SessionRequest />}
              <a href="/messages" style={{position:"relative"}}>
                {count>0 && (<span className="message-indicator" />)}
                <i className="icon-chat mr-4 text-secondary" />
              </a>
            </div>

            <OverlayTrigger
              trigger="click"
              placement="bottom-end"
              overlay={this.popover()}
              rootClose
            >
              <div className="d-lg-flex d-none justify-content-end p-0 text-center cursor-pointer">
                <svg
                  className="mt-lg-3 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="3"
                  viewBox="0 0 8 5"
                >
                  <path fill="#bfbfbf" fillRule="evenodd" d="M7.9 0L3.95 4.95 0 0z" />
                </svg>
                <img
                  className="rounded-circle"
                  style={{ objectFit: 'cover' }}
                  src={ProfileImage}
                  height="38px"
                  width="38px"
                  alt=""
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { isCollapsed } = this.state;
    const { history, logoutUser, user, show, setUserTypeModal } = this.props;
    const ProfileImage = (user && user.u_image_url) || '/images/user.svg';
    return (
      <>
        <ModalComponent title="Signup" show={show} size="lg" onHide={() => setUserTypeModal(false)}>
          <div className="row justify-content-center py-md-5">
            <div className="col-md-5 col-12 mb-3">
              <UserType
                type="practitioner"
                image="/images/practitioner-signup.svg"
                onClick={() => {
                  history.push('/signup?u_type=practitioner');
                  setUserTypeModal(false);
                }}
              />
            </div>
            <div className="col-md-5 col-12">
              <UserType
                type="member"
                image="/images/member-signup.svg"
                onClick={() => {
                  history.push('/signup?u_type=member');
                  setUserTypeModal(false);
                }}
              />
            </div>
          </div>
        </ModalComponent>
        <div
          className={
            isCollapsed
              ? 'navbar-profile-dropdown p-3 open border-bottom border-gt'
              : 'navbar-profile-dropdown p-3'
          }
        >
          {!user ? (
            <div className="d-flex py-2">
              <Link to="/">
                <img src="/images/guide-true.svg" alt="Logo" />
              </Link>
              <img
                src="/icons/close.svg"
                className="ml-auto"
                onClick={() => this.setState({ isCollapsed: false })}
                alt="Close"
                role="presentation"
              />
            </div>
          ) : (
            ''
          )}
          {!user ? (
            <ul>
              <li>
                {' '}
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/signin?target=assessment">Free Assessment</Link>
              </li>
              <li>
                <Link to="/signin">Sign in</Link>
              </li>
              <li>
                <Link to="/signup">Sign up</Link>
              </li>
            </ul>
          ) : (
            <>
              {' '}
              <div
                className="d-flex flex-column align-items-center p-3"
                style={{ borderBottom: 'solid 1px #eaedf3' }}
              >
                <img
                  src="/icons/close.svg"
                  className="ml-auto"
                  style={{ opacity: '0.3' }}
                  onClick={() => this.setState({ isCollapsed: false })}
                  alt="Close"
                  role="presentation"
                />
                <img
                  className="rounded-circle"
                  style={{ objectFit: 'cover' }}
                  src={ProfileImage}
                  height="85px"
                  width="85px"
                  alt=""
                />
                <div className="pl-1">
                  <p className="mb-0 text-capitalize" style={{ fontWeight: 'bold' }}>
                    {user.u_first_name} {user.u_last_name}
                  </p>
                  <p className="mb-0" style={{ color: '#9c9c9c' }}>
                    @{user.u_username}
                  </p>
                </div>
              </div>
              <ul>
                <li>
                  <Link to={`/${user.u_username}`}>View Member Profile </Link>
                </li>
                <li>
                  {user.u_prac_profile_completion_status ? (
                    <Link to={`/${user.u_prac_username}`}>View Practitioner Profile</Link>
                  ) : (
                    <Link to="/create-practitioner-profile">Create Practitioner Profile</Link>
                  )}
                </li>
                <li>
                  <Link to="/settings">Settings</Link>
                </li>
                <li>
                  <div
                    onClick={() => {
                      logoutUser();
                      history.push('/');
                    }}
                    role="presentation"
                  >
                    Logout
                  </div>
                </li>
              </ul>
            </>
          )}
        </div>
        <div className="navbar-shared">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 mb-lg-0 mb-2">
                <Link to="/">
                  <img src="/icons/logo.svg" width="80" alt="logo" />
                </Link>
                {/* <div className="line h-100 d-lg-block d-none ml-5" /> */}
                {!user ? (
                  <img
                    className="d-lg-none float-right"
                    onClick={() => this.setState({ isCollapsed: true })}
                    src="/icons/hamburgerb.svg"
                    alt="Hamburger"
                    style={{ marginLeft: '1rem' }}
                    role="presentation"
                  />
                ) : (
                  <img
                    className="rounded-circle d-lg-none float-right"
                    style={{ objectFit: 'cover' }}
                    src={ProfileImage}
                    height="30px"
                    width="30px"
                    alt=""
                    onClick={() => this.setState({ isCollapsed: true })}
                    role="presentation"
                  />
                )}
              </div>
              <div className="col-lg-5">
                <SearchBox border placeholder-text="Search" />
              </div>
              {user ? this.loggedBar() : this.nonloggedBar()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Navbar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    u_first_name: PropTypes.string,
    u_last_name: PropTypes.string,
    u_username: PropTypes.string,
    u_image_url: PropTypes.string,
    u_prac_username: PropTypes.string,
    u_prac_profile_completion_status: PropTypes.bool,
  }),
  show: PropTypes.bool,
  logoutUser: PropTypes.func.isRequired,
  setUserTypeModal: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
  user: null,
  show: false,
};

const mapStateToProps = state => ({
  user: state.auth.currentUser,
  show: state.app.userTypeModal,
  recent:state.message.recent,
  count:state.message.totalUnreadMessage
  
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      setUserTypeModal,
      logoutUser,
      recentMessage
    }
  )(Navbar)
);
