/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from 'react-slick';
import './SlidingCard.css';
import PropTypes from 'prop-types';
// import settings from './settings';

const SlidingCard = ({ heading, children, settings, cardNumber, ...rest }) => {
  const slider = React.createRef();
  return (
    <section className="mb-4">
      <div
        className="section-header d-flex justify-content-between align-items-center py-2 mb-3"
        style={{ borderBottom: 'solid 1px #eaedf3' }}
      >
        <h2 className="slider-title h2 m-0 text-truncate mr-5">{heading}</h2>
      </div>
      <div className="left-align-slick">
        <Slider className="row" ref={slider} {...settings} slidesToShow={cardNumber} {...rest}>
          {children}
        </Slider>
      </div>
    </section>
  );
};

SlidingCard.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  cardNumber: PropTypes.number,
  settings: PropTypes.shape({}).isRequired,
};

SlidingCard.defaultProps = {
  cardNumber: 4,
};

export default SlidingCard;
