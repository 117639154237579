import React from 'react';
import PropTypes from 'prop-types';

const UserType = ({ type, image, onClick }) => {
  return (
    <div className="signup-selection text-center p-3" role="presentation" onClick={onClick}>
      <img className="img-fluid py-md-4 py-3 mb-md-3" src={image} alt="Practitioner Signup" />
      <p className="m-0 text-secondary">Register As</p>
      <p className="m-0 font-weight-bold display1 text-capitalize">{type}</p>
    </div>
  );
};

UserType.propTypes = {
  type: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UserType;
