/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, socialLogin, saveSocialUser, clearError } from '../../../store/actions/authActions';
import SocialButton from '../../common/SocialButton/SocialButton';
import LoginForm from './LoginForm/LoginForm';
import { isAuthenticated } from '../../../auth.service';
import './Login.css';
import UserType from '../Landing/UserType';
import ModalComponent from '../../shared/Modal/Modal';
import { setUserTypeModal, setUserType } from '../../../store/actions/appActions';

class Login extends Component {
  componentDidMount() {
    if (isAuthenticated()) {
      const { history } = this.props;
      history.push('/');
    }
  }

  componentWillUnmount() {
    const { clearError } = this.props;
    clearError();
  }

  oAuthSuccessHandler = info => {
    const { history, saveSocialUser, socialLogin } = this.props;
    saveSocialUser(info);
    socialLogin(info, (err, user) => {
      if (err) {
        history.push('/signup/user/complete?action=signin');
        return;
      }
      const params = new URLSearchParams(history.location.search);
      // const businessId = params.get('business');
      const joinToken = params.get('token');
      if (joinToken) {
        const joinUrl = `/join-business${history.location.search}`;
        history.push(joinUrl);
      }
      if (params.get('target') === 'assessment') {
        history.push('/assessment');
        return;
      }
      if (user && user.u_type === 'practitioner' && !user.u_prac_profile_completion_status) {
        history.push('create-practitioner-profile');
        return;
      }
      history.push(`/user-feeds`);
    });
  };

  submitLoginFormHandler = values => {
    const { login, history } = this.props;
    login(values, (err, user) => {
      if (user) {
        const params = new URLSearchParams(history.location.search);
        const joinToken = params.get('token');
        if (joinToken) {
          const joinUrl = `/join-business${history.location.search}`;
          history.push(joinUrl);
          return;
        }
        if (params.get('target') === 'assessment') {
          history.push('/assessment');
          return;
        }
        if (params.get('target') === 'content') {
          history.push(`/content/${params.get('id')}`);
          return;
        }
        if (user.u_type === 'practitioner' && !user.u_prac_profile_completion_status) {
          history.push('create-practitioner-profile');
          return;
        }
        history.push(`/user-feeds`);
      }
    });
  };

  render() {
    const { error, history, show, setUserType, setUserTypeModal } = this.props;
    return (
      <>
        <div className="container-fluid" style={{ height: '100vh' }}>
          <div className="row h-100">
            <div className="col-lg-6 order-lg-first order-last signin-banner h-100 d-lg-block d-none">
              <div className="row justify-content-center h-100 py-md-5 py-4">
                <div className="col-md-9 col-11 d-flex flex-column">
                  <Link to="/">
                    <img src="/icons/logo-white.svg" width="128" alt="Logo" />
                  </Link>
                  <div className="mt-auto">
                    <h1 className="">Transform your life</h1>
                    <p className="" style={{opacity:0}}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                      nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 h-100">
              <div className="row justify-content-center align-items-center h-100 py-md-5 py-4">
                <div className="col-md-9 col-11">
                  <Link to="/">
                    <img src="/icons/logo.svg" className="d-lg-none mb-5" width="128" alt="Logo" />
                  </Link>
                  <div className="d-flex flex-column justify-content-center h-100">
                    <div className="signin-text-box">
                      <h2 className="m-0">Welcome to wellness.</h2>
                    </div>
                    <div className="row pt-3 pb-md-5 pb-4 position-relative oauth-container">
                      <div className="col-md-6 col-12 m-md-0 mb-3">
                        <SocialButton provider="facebook" onSuccess={this.oAuthSuccessHandler}>
                          Login with Facebook
                        </SocialButton>
                      </div>
                      <div className="col-md-6 col-12">
                        <SocialButton provider="google" onSuccess={this.oAuthSuccessHandler}>
                          Login with Google
                        </SocialButton>
                      </div>
                    </div>
                    <div className="w-100 pt-md-5 pt-4 position-relative">
                      {error && error.errorMsg && (
                        <span className="error-feedback d-flex align-items-center">
                          <img
                            className="mr-1"
                            src="/icons/cancel.svg"
                            height="16"
                            alt="error-icon"
                          />
                          {error.errorMsg}
                        </span>
                      )}
                      <LoginForm onSubmit={this.submitLoginFormHandler} />
                    </div>
                  </div>
                  <div className="auth-footer my-4">
                    <span className="mr-1">Dont have an Account ?</span>
                    <a
                      href="/"
                      role="button"
                      className="auth-footerlink"
                      onClick={e => {
                        e.preventDefault();
                        setUserTypeModal(true);
                      }}
                    >
                      Signup Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalComponent title="Signup" show={show} size="lg" onHide={() => setUserTypeModal(false)}>
          <div className="row justify-content-center py-md-5">
            <div className="col-md-5 col-12 mb-3">
              <UserType
                type="practitioner"
                image="/images/practitioner-signup.svg"
                onClick={() => {
                  history.push('/signup?u_type=practitioner');
                  setUserType('practitioner');
                  setUserTypeModal(false);
                }}
              />
            </div>
            <div className="col-md-5 col-12">
              <UserType
                type="member"
                image="/images/member-signup.svg"
                onClick={() => {
                  const params = new URLSearchParams(history.location.search);
                  if (params.get('target') === 'assessment') {
                    history.push('/signup?target=assessment');
                    return;
                  }
                  history.push('/signup?u_type=member');
                  setUserType('member');
                  setUserTypeModal(false);
                }}
              />
            </div>
          </div>
        </ModalComponent>
      </>
    );
  }
}

Login.propTypes = {
  error: PropTypes.shape({
    errorMsg: PropTypes.string,
  }),
  show: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  login: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  setUserType: PropTypes.func.isRequired,
  setUserTypeModal: PropTypes.func.isRequired,
  saveSocialUser: PropTypes.func.isRequired,
  socialLogin: PropTypes.func.isRequired,
};

Login.defaultProps = {
  error: null,
};

const mapStatetoProp = state => {
  return {
    error: state.auth.error,
    show: state.app.userTypeModal,
  };
};

export default connect(
  mapStatetoProp,
  { login, socialLogin, clearError, setUserType, saveSocialUser, setUserTypeModal }
)(Login);
