import React from 'react';
import './PractitionerLicenseCard.css';

function PractitionerLicenseCard({certifications=[], title, className}) {
    const icon = '/icons/certifcates-icon.png';
    const certifcates = certifications.map(certificate => 
        <div key={certificate._id} className='certificate p-3'>
            <p className='mb-1'>{certificate.institution}</p>
            <p className='m-0'>{certificate.year}</p>
        </div>
    )
    return (
        <div className={[className, 'practitioner-license-card'].join(' ')}>
            <div className='practitioner-license-card-header p-3'>
                <h6 className='d-inline-block'>{title}</h6>
                <img className='float-right ml-auto' src={icon} alt='certificate-icon'/>
            </div>
            <div className='practitioner-license-card-body p-3'>
                { certifcates }
            </div>
        </div>
    )
}

export default PractitionerLicenseCard
