/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PractitionerRegistrationHeader from './PractitionerRegistrationHeader';
// eslint-disable-next-line import/no-named-as-default
import MultiSearchDropDown from '../../../../common/MultiSearchDropDown/MultiSearchDropDown';

const PractitionerRegistration2 = ({ categories }) => (
  <div className="py-5 mt-5">
    <PractitionerRegistrationHeader
      title="What are your specialities ?"
      info="You can select from the dropdown menu, and enter your own."
    />
    <Field
      name="p_specialization"
      component={prop => (
        <MultiSearchDropDown
          placeholder="Select your specializations"
          options={categories}
          other
          onSelect={value => {
            prop.input.onChange(value);
          }}
          value={prop.input.value}
          {...prop}
        />
      )}
    />
  </div>
);

PractitionerRegistration2.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  return {
    categories: state.app.categories,
  };
};

export default connect(mapStateToProps)(PractitionerRegistration2);
