import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import moment from 'moment';
import GTCard from '../../../common/GTCard/GTCard';
import './ProgressCheckinCard.css';

const ProgressCheckinCard = ({ className, data, status, ownPerspective }) => {
  let ProgressCheckChart = (
    <div className="px-md-4 py-md-5 p-2">
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={value => moment(value).format('DD MMM YYYY')} />
          <YAxis tickFormatter={value => `${Math.round(value * 100)}%`} />
          <Tooltip
            formatter={(values, name) => {
              return [`${Math.round(values * 100)}%`, name.charAt(0).toUpperCase() + name.slice(1)];
            }}
            labelFormatter={label => moment(label).format('DD MMMM YYYY')}
          />
          <Legend
            verticalAlign="top"
            wrapperStyle={{ top: '-20px', textTransform: 'capitalize' }}
          />
          <Line type="monotone" dataKey="physical" stroke="#f17a79" />
          <Line type="monotone" dataKey="existential" stroke="#33d6f3" />
          <Line type="monotone" dataKey="financial" stroke="#7ed67f" />
          <Line type="monotone" dataKey="psychological" stroke="#ac80d4" />
          <Line type="monotone" dataKey="self actualization" stroke="#eea653" />
          <Line type="monotone" dataKey="social" stroke="#eee737" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
  if (status !== 'TAKEN') {
    ProgressCheckChart = (
      <div className="text-center py-5">
        <img src="/images/progress-checkin-empty.svg" className="mb-3" alt="No Progress Check-in" />
        <p className="font-medium mb-1">Track Your Progress</p>
        <p className="display2 text-secondary px-lg-5 px-3 mx-lg-5">Get a regularly updated graph of your personal journey. This helps to refine your recommended resources over time and celebrate your successes!</p>
        <Link to="/progress-check" className="btn gt-btn">
          Start Progress Check-In
        </Link>
      </div>
    );
  }
  return (
    <GTCard
      title="Progress Check-in"
      className={className}
      header={
        status === 'TAKEN' &&
        ownPerspective && (
          <Link to="/progress-check" className="gt-link text-primary ml-auto">
            Start New Progress Check-In
          </Link>
        )
      }
    >
      {ProgressCheckChart}
    </GTCard>
  );
};

ProgressCheckinCard.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};

ProgressCheckinCard.defaultProps = {
  className: '',
  data: [],
  status: '',
};

export default ProgressCheckinCard;
