/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './TextArea.css';

const TextArea = ({ input, meta, varient, info, ...rest }) => {
  return (
    <div>
      <textarea
        {...input}
        {...rest}
        className={[
          'form-control',
          meta && meta.error && meta.touched ? 'invalid' : '',
          varient === 'material' ? 'gtm-text-area-control' : 'gt-text-area-control',
        ].join(' ')}
      />
      {info && <span className="info-text d-block text-right mt-1">{info}</span>}
      {meta && meta.error && meta.touched && (
        <span
          className={[
            'invalid-feedback d-block',
            varient === 'material' && 'gtm-invalid-feedback',
          ].join(' ')}
        >
          {meta.error}
        </span>
      )}
    </div>
  );
};

TextArea.propTypes = {
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  varient: PropTypes.string,
  info: PropTypes.string,
};

TextArea.defaultProps = {
  input: null,
  meta: null,
  varient: '',
  info: '',
};

export default TextArea;
