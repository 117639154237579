/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import './UserProfile.css';
import UserCard from './UserCard/UserCard';
import ProgressCheckinCard from './ProgressCheckinCard/ProgressCheckinCard';
import ContactsCard from './ContactsCard/ContactsCard';
// import NotesCard from './NotesCard/NotesCard';
// import ResultOverview from '../assesment/AssessmentResult/ResultOverview';
import GTCard from '../../common/GTCard/GTCard';
import ResultCard from '../assesment/AssessmentResult/ResultCard';
import ContentTab from './ContentTab/ContentTab';
import { requestAssessmentResult } from '../../../store/actions/practitionerRegAction';

const UserProfile = props => {
  const {
    profile,
    bookmarkedPractitioners,
    currentUser,
    requestAssessmentResult,
    requestResultStatus,
  } = props;

  const requestForResult = () => {
    requestAssessmentResult(profile.c_user._id);
  };

  let ExploreResult = null;
  if (profile.assessment_status === 'TAKEN') {
    ExploreResult = (
      <div className="ml-auto" style={{ lineHeight: 1 }}>
        <button
          type="button"
          style={{ lineHeight: 1 }}
          onClick={() => {
            props.history.push(`/assessment-result/${profile.c_user.u_username}`);
          }}
          className="btn btn-link p-0"
        >
          Explore Result
        </button>
      </div>
    );
  }

  let AssessmentResultEmptyState = null;
  if (profile.ownPerspective) {
    AssessmentResultEmptyState = (
      <div className="text-center py-5">
        <img src="/images/assessment-not-taken.svg" className="mb-3" alt="Assessment not Taken" />
        <p className="font-medium mb-1">Get Customized Guidance</p>
        <p className="display2 text-secondary px-lg-5 px-3 mx-lg-5">
          Get matched with practitioners & wellness resources to streamline your personal growth.
        </p>
        {profile.assessment_status === 'NOT_COMPLETED' ? (
          <Link to="/assessment" className="btn gt-btn">
            Complete Your Assessment
          </Link>
        ) : (
          <Link to="/assessment" className="btn gt-btn">
            Start Your Assessment
          </Link>
        )}
      </div>
    );
  } else {
    AssessmentResultEmptyState = (
      <div className="text-center px-3 py-5">
        <p className="mb-4">You can Request for Sharing Assessment Result</p>
        <button type="button" className="btn gt-btn" onClick={requestForResult}>
          {requestResultStatus === 'requested'
            ? 'Cancel Assessment Share Request'
            : 'Request Assessment Result'}
        </button>
      </div>
    );
  }

  return (
    <>
      {/* <Navbar /> */}
      {profile && (
        <div id="user-profile">
          <div className="container py-3">
            <div className="row">
              <div className="col-lg-12 col-12">
                <UserCard user={profile.c_user} />
              </div>
              {profile.ownPerspective && (
                <div className="col-lg-3 order-lg-0 order-1">
                  <ContactsCard users={bookmarkedPractitioners} />
                </div>
              )}
              <div className="col">
                {(profile.ownPerspective ||
                  (currentUser && currentUser.u_prac_profile_completion_status)) && (
                  <GTCard title="Assessment" className="mt-3" header={ExploreResult}>
                    {profile.assessment_status === 'TAKEN' ? (
                      <ResultCard
                        categories={profile.assessmentResult.categories}
                        total={profile.assessmentResult.wholeness}
                        owner={!profile.ownPerspective ? profile.c_user.u_first_name : null}
                      />
                    ) : (
                      AssessmentResultEmptyState
                    )}
                  </GTCard>
                )}
                {((profile.assessment_status === 'TAKEN' && profile.ownPerspective) ||
                  (profile.progressCheckInStatus === 'TAKEN' &&
                    currentUser.u_prac_profile_completion_status)) && (
                  <ProgressCheckinCard
                    className="mt-3"
                    data={profile.progrees_check_in}
                    status={profile.progressCheckInStatus}
                    ownPerspective={profile.ownPerspective}
                  />
                )}
                {profile.ownPerspective && <ContentTab className="mt-3" title="Saved Contents" />}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

UserProfile.propTypes = {
  profile: PropTypes.shape({
    c_user: PropTypes.shape({
      u_first_name: PropTypes.string,
      _id: PropTypes.string,
    }).isRequired,
    assessment_status: PropTypes.string,
    assessmentResult: PropTypes.shape({
      wholeness: PropTypes.number,
      categories: PropTypes.array,
    }),
    ownPerspective: PropTypes.bool,
  }),
  bookmarkedPractitioners: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  currentUser: PropTypes.shape({
    u_type: PropTypes.string,
    u_prac_profile_completion_status: PropTypes.bool,
  }),
  requestAssessmentResult: PropTypes.func.isRequired,
  requestResultStatus: PropTypes.string,
};

UserProfile.defaultProps = {
  profile: null,
  currentUser: null,
  bookmarkedPractitioners: [],
  requestResultStatus: '',
};

const mapStateToProps = state => ({
  profile: state.auth.profile,
  currentUser: state.auth.currentUser,
  bookmarkedPractitioners: state.member.bookmarkedPractitioners,
  requestResultStatus: state.practitionerReg.requestResultStatus,
});

export default withRouter(
  connect(
    mapStateToProps,
    { requestAssessmentResult }
  )(UserProfile)
);
