/* eslint-disable no-underscore-dangle */
import { toast } from 'react-toastify';
import {
  SET_MODAL_ACTIVE,
  SET_CONTENT_FILE,
  UPLOAD_PROGRESS,
  CLEAR_CONTENT_FILE,
  SET_PUBLISH_PAGE,
  SET_CONTENT_CATEGORIES,
  PUBLISHED_CONTENT,
  SET_CONTENT,
  SET_EDIT_CONTENT,
  UPDATE_EDIT_CONTENT,
  UPDATE_PRACTITIONER_CONTENT_LIST,
} from './types';

import axios from '../../axios';
import { setLoader } from './appActions';

export const publishModal = toggle => {
  return {
    type: SET_MODAL_ACTIVE,
    payload: toggle,
  };
};
export const setContent = data => {
  return {
    type: SET_CONTENT,
    payload: data,
  };
};

export const clearContentFile = () => ({
  type: CLEAR_CONTENT_FILE,
  payload: null,
});

export const setPublishPage = page => ({
  type: SET_PUBLISH_PAGE,
  payload: page,
});

export const updateContentFileProgress = progress => ({
  type: UPLOAD_PROGRESS,
  payload: progress,
});

export const getCategoriesList = async dispatch => {
  try {
    const response = await axios.get('/api/meta/categories');
    const { categories } = response.data;
    const _categories = categories.map(category => {
      return {
        label: category.category_name,
        value: category._id,
      };
    });

    dispatch({
      type: SET_CONTENT_CATEGORIES,
      payload: _categories,
    });
  } catch (error) {}
};

export const uploadContent = (files, cb) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('content', files[0]);

    const response = await axios.post('/api/content/upload', formData, {
      onUploadProgress: event => {
        let percentComplete = event.loaded / event.total;
        percentComplete = parseInt(percentComplete * 100);
        if (percentComplete <= 98) dispatch(updateContentFileProgress(percentComplete));
      },
    });
    const { file } = response.data;
    dispatch({
      type: SET_CONTENT_FILE,
      payload: file,
    });
    dispatch(updateContentFileProgress(100));
    if (cb) cb(file);
  } catch (error) {
    console.log('Error', error);
    toast.error('Something went wrong! please try again');
    if (cb) cb(null);
  }
};

export const changeContentFile = file => dispatch => {
  dispatch({
    type: UPDATE_EDIT_CONTENT,
    payload: file,
  });
};

export const updateContent = (contentInfo, contentId, pStatus, callback) => async (
  dispatch,
  getState
) => {
  try {
    const {
      contentFile: { file },
      content,
    } = getState().publish;
    console.log('got', contentInfo);
    console.log('content===>', content);
    console.log('file ===>', file);
    console.log('thumbanil', contentInfo.thumbnail);
    const formData = new FormData();
    if (content) formData.append('article_content', content);
    if (file) formData.append('file_id', file._id);
    if (typeof contentInfo.thumbnail === 'object') {
      formData.append('thumbnail', contentInfo.thumbnail);
      delete contentInfo.thumbnail;
    }
    Object.keys(contentInfo).forEach(key => {
      if (contentInfo[key]) {
        formData.append(key, JSON.stringify(contentInfo[key]));
      }
    });
    formData.append('publish_type', JSON.stringify(pStatus));
    dispatch(setLoader(true));
    const response = await axios.put(`/api/content/${contentId}`, formData);
    dispatch(setLoader(false));
    dispatch({
      type: UPDATE_PRACTITIONER_CONTENT_LIST,
      payload: response.data.content,
    });
    callback(null);
  } catch (error) {
    callback(error);
    dispatch(setLoader(false));
    toast.error('Something went wrong');
  }
};

export const publishContent = contentInfo => async (dispatch, getState) => {
  const {
    contentFile: { file },
    content,
  } = getState().publish;
  try {
    const formData = new FormData();
    if (content) formData.append('article_content', content);
    if (file) formData.append('file_id', file._id);
    formData.append('thumbnail', contentInfo.thumbnail);
    delete contentInfo.thumbnail;
    Object.keys(contentInfo).forEach(key => {
      formData.append(key, JSON.stringify(contentInfo[key]));
    });
    formData.append('publish_type', JSON.stringify('PUBLISHED'));
    dispatch(setLoader(true));
    const response = await axios.post('/api/content/publish', formData);
    const _content = response.data.content;
    dispatch(setLoader(false));
    dispatch({
      type: PUBLISHED_CONTENT,
      payload: _content._id,
    });
    dispatch(setPublishPage(2));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const draftContent = (contentType, cb) => async (dispatch, getState) => {
  const {
    contentFile: { file },
    content,
  } = getState().publish;
  const { contentForm } = getState().form;
  const contentInfo = contentForm.values;
  try {
    const formData = new FormData();
    if (content) formData.append('article_content', content);
    if (file) formData.append('file_id', file._id);
    formData.append('thumbnail', contentInfo.thumbnail);
    formData.append('publish_type', JSON.stringify('DRAFT'));
    formData.append('content_type', JSON.stringify(contentType));
    delete contentInfo.thumbnail;
    Object.keys(contentInfo).forEach(key => {
      formData.append(key, JSON.stringify(contentInfo[key]));
    });
    dispatch(setLoader(true));
    const response = await axios.post('/api/content/publish', formData);
    dispatch(setLoader(false));
    dispatch(setPublishPage(2));
    toast.success('Content Saved as Draft');
    cb(null);
  } catch (error) {
    toast.error('Something went wrong, Please try again!');
    dispatch(setLoader(false));
    cb(null);
  }
};

export const getContentForEdit = (contentId, callback) => async dispatch => {
  try {
    const response = await axios.get(`/api/content/${contentId}?contentOnly=true&ownerOnly=true`);
    const { content } = response.data;
    dispatch({
      type: SET_EDIT_CONTENT,
      payload: content,
    });
    callback();
  } catch (error) {
    console.log('$$$$$$$', error);
    callback();
  }
};

export const clearEditContent = () => dispatch => {
  dispatch({
    type: SET_EDIT_CONTENT,
    payload: null,
  });
};
