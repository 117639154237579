/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RatingComponent from '../../../../common/Rating/Rating';
import {
  requestSessionOrCancelSession,
  bookmarkPractitioner,
  shareResult,
  ratePractitioner,
  reviewPractitioner,
} from '../../../../../store/actions/practitionerRegAction';
import './PractitionerProfileInfo.css';
import ModalComponent from '../../../../shared/Modal/Modal';
import TextArea from '../../../../common/TextArea/TextArea';
import Button from '../../../../common/Button/Button';
// import Button from '../../../../common/Button/Button';

class PractitionerProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReviewModal: false,
      review: '',
      rating: 0,
      showMoreSpecializations: false
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    if (profile && profile.review) {
      this.setState({
        review: profile.review.review,
        rating: profile.rating || 0,
      });
    }
  }

  bookmarkPractitioner = () => {
    const { bookmarkPractitioner, profile, currentUser, triggerLoginModal } = this.props;
    if (!currentUser) {
      triggerLoginModal(true);
      return;
    }
    bookmarkPractitioner(profile.p_user._id);
  };

  shareResult = () => {
    const { shareResult, profile } = this.props;
    shareResult(profile.p_user._id);
  };

  openReviewModal = () => {
    const { currentUser, triggerLoginModal } = this.props;
    if (!currentUser) {
      triggerLoginModal(true);
      return;
    }
    this.setState({ showReviewModal: true });
  };

  ratingHandler = rating => {
    const { ratePractitioner, profile } = this.props;
    this.setState({ rating });
    ratePractitioner(profile.p_user._id, rating);
  };

  reviewHandler = () => {
    const { reviewPractitioner, profile } = this.props;
    const { review, rating } = this.state;
    reviewPractitioner(profile.p_user._id, review, rating, () => {
      this.setState({ showReviewModal: false });
      window.location.reload();
    });
  };

  requestSession = () => {
    const { requestSessionOrCancelSession, currentUser, profile, triggerLoginModal } = this.props;
    requestSessionOrCancelSession(profile.p_user._id);
    if (!currentUser) {
      triggerLoginModal(true);
    }
  };

  // eslint-disable-next-line consistent-return
  render() {
    const { profile, sessionStatus, bookmarkStatus, shareStatus } = this.props;
    const { showReviewModal, review, rating, showMoreSpecializations } = this.state;

    const specializations = profile.p_specialization && profile.p_specialization
      .slice(0, 2)
      .map(specialization => <span key={specialization._id}>{specialization.category_name}</span>);

    let extraspecializations = null;
    if(profile.p_specialization.length > 2) {
      extraspecializations = profile.p_specialization && profile.p_specialization
      .slice(2)
      .map(specialization => <span key={specialization._id}>{specialization.category_name}</span>);
    }

    const ProfileImage = profile.p_user.u_image_url || '/images/user.svg';

    if (profile) {
      return (
        <>
          <div className="prac-profile-card">
            <div className="d-flex flex-column align-items-center prac_basic_info">
              <div className="prac_about_pic">
                <img src={ProfileImage} alt="" />
              </div>
              <div className="prac_about_info text-center mt-3">
                <p className="prac_name mb-0 text-truncate">
                  {profile.p_user.u_first_name} {profile.p_user.u_last_name || ''}
                </p>
                <div className="prac_category d-flex justify-content-center mx-1 row mb-0">
                  {specializations}
                </div>
                {profile.p_specialization.length > 2 ?
                  <div className="prac_category d-flex justify-content-center mx-1 row mb-0">
                    {showMoreSpecializations ? 
                      <>{extraspecializations}</>
                      :
                      <p className="cursor-pointer" onClick={() => this.setState({showMoreSpecializations: true})}
                      style={{color:'#d4af37'}}>
                        +{profile.p_specialization.length - 2} more</p>
                    }
                  </div>
                :<></>}
              </div>
            </div>

            {!profile.ownPerspective && (
              <div className="text-center px-3 mt-3">
                <p className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-white col-3"
                    onClick={this.bookmarkPractitioner}
                  >
                    <img
                      src={
                        bookmarkStatus ? '/icons/bookmark-fill.svg' : '/icons/bookmark-outline.svg'
                      }
                      height="17"
                      className="mb-1"
                      alt="bookmark"
                    />
                  </button>
                  <Link
                    className="btn btn-outline-white offset-1 col"
                    to={`/messages?id=${profile.p_user._id}`}
                  >
                    <img src="/icons/message.svg" className="mr-2 mb-1" alt="message" />
                    Message
                  </Link>
                </p>
                <p className="">
                  {sessionStatus === 'accepted' ? (
                    <button
                      type="button"
                      className="btn gt-btn w-100"
                      onClick={this.openReviewModal}
                    >
                      {profile && profile.review ? 'Edit Review' : 'Write Review'}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn gt-btn w-100 d-flex justify-content-around"
                      onClick={this.requestSession}
                      style={{fontSize: "0.9rem"}}
                    >
                      {sessionStatus === 'requested' ? 'Cancel Review Request' : 'Request to Write a Review'}
                    </button>
                  )}
                </p>
                {profile.assessment_status && profile.assessment_status === 'TAKEN' && (
                  <p>
                    <button
                      type="button"
                      onClick={this.shareResult}
                      className="btn btn-outline-white w-100"
                    >
                      {shareStatus ? 'Cancel Share My Result' : 'Share My Result'}
                    </button>
                  </p>
                )}
              </div>
            )}

            <div className="prac_info_2 col">
              <div className="prac_rating">
                <div className="py-3 prac_about_section">
                  <p className="mb-1">RATING</p>
                  <div className="d-flex justify-content-between">
                    <RatingComponent
                      height="20px"
                      width="20px"
                      initialRating={profile.avgRating}
                      readonly
                    />
                    <span className="display2 mt-1">{profile.reviewCount} Reviews</span>
                  </div>
                </div>
              </div>

              <div className="prac_about_me">
                <div className="py-3 prac_about_section">
                  <p className="mb-0">ABOUT ME</p>
                  <span>{profile.p_about}</span>
                </div>
              </div>

              <div className="prac_details">
                <div className="py-3 prac_about_section border-0">
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">CONTACT INFO</p>
                  </div>
                  <div className="prac_contact_info mt-3">
                   {!profile.p_hide_business_info && <div className="d-flex mb-3">
                      <span className="mr-3">
                        <img src="/icons/home.svg" alt="home" />
                      </span>
                      <div>{profile.p_address}</div>
                    </div>}

                    {!profile.p_hide_business_info && <div className="d-flex mb-3">
                      <span className="mr-3">
                        <img src="/icons/phone.svg" alt="phone" />
                      </span>
                      <div>{profile.p_phone_number}</div>
                    </div>}

                    <div className="d-flex">
                      <span className="mr-3">
                        <img src="/icons/rate_of.svg" alt="e-mail" />
                      </span>
                      <div>{profile.p_user.u_email}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalComponent
            show={showReviewModal}
            title="Review"
            onHide={() => this.setState({ showReviewModal: false })}
          >
            <div className="">
              <div className="text-center py-4">
                <RatingComponent
                  initialRating={rating}
                  height="33"
                  width="33"
                  onChange={this.ratingHandler}
                />
              </div>
              <label className="text-secondary display2">Review</label>
              <TextArea
                rows={10}
                placeholder="write review"
                value={review}
                onChange={event => this.setState({ review: event.target.value })}
              />
              <div className="mt-3 d-flex justify-content-end">
                <Button onClick={this.reviewHandler} disabled={!review}>
                  Add
                </Button>
              </div>
            </div>
          </ModalComponent>
        </>
      );
    }
  }
}

PractitionerProfileInfo.propTypes = {
  profile: PropTypes.shape({
    p_user: PropTypes.shape({
      u_first_name: PropTypes.string,
      u_last_name: PropTypes.string,
      u_image_url: PropTypes.string,
      u_email: PropTypes.string,
      _id: PropTypes.string,
    }),
    avgRating: PropTypes.number,
    p_about: PropTypes.string,
    p_address: PropTypes.string,
    p_phone_number: PropTypes.string,
    p_hide_business_info: PropTypes.bool,
    p_specialization: PropTypes.arrayOf(PropTypes.object),
    assessment_status: PropTypes.string,
    rating: PropTypes.number,
    reviewCount: PropTypes.number,
    review: PropTypes.shape({
      review: PropTypes.string,
    }),
    ownPerspective: PropTypes.bool,
  }).isRequired,
  currentUser: PropTypes.shape({}),
  bookmarkStatus: PropTypes.bool,
  shareStatus: PropTypes.bool,
  sessionStatus: PropTypes.string,
  requestSessionOrCancelSession: PropTypes.func.isRequired,
  bookmarkPractitioner: PropTypes.func.isRequired,
  shareResult: PropTypes.func.isRequired,
  reviewPractitioner: PropTypes.func.isRequired,
  ratePractitioner: PropTypes.func.isRequired,
  triggerLoginModal: PropTypes.func.isRequired,
};

PractitionerProfileInfo.defaultProps = {
  currentUser: null,
  bookmarkStatus: false,
  shareStatus: false,
  sessionStatus: '',
};

const mapStateToProps = state => {
  return {
    sessionStatus: state.practitionerReg.sessionStatus,
    bookmarkStatus: state.practitionerReg.bookmarkStatus,
    shareStatus: state.practitionerReg.shareStatus,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = {
  requestSessionOrCancelSession,
  bookmarkPractitioner,
  shareResult,
  reviewPractitioner,
  ratePractitioner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PractitionerProfileInfo);
