/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setContent } from '../../../store/actions/publishActions';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './WYSIWIGEditor.css';
import axios from '../../../axios';

class WYSIWIGEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { editorState } = this.props;
    if(editorState) {
      const contentState = convertFromRaw(JSON.parse(editorState));
      this.setState({
        editorState: EditorState.createWithContent(contentState)
      })
    }
  }

  onEditorStateChange = editorState => {
    const { dispatch } = this.props;
    this.setState({
      editorState,
    });
    const rawContent = convertToRaw(this.state.editorState.getCurrentContent());
    dispatch(setContent(JSON.stringify(rawContent)));
  };

  uploadImageCallBack = file => {
    return new Promise(async resolve => {
      const formData = new FormData();
      formData.append('image', file);
      const response = await axios.post('/api/content/upload-image', formData);
      const { imageUrl } = response.data;
      resolve({ data: { link: imageUrl } });
    }).catch(err => {
      return err;
    });
  };

  embedLinkCallback = (link) => {
    if (link.indexOf('youtube') >= 0) {
      link = link.replace('watch?v=', 'embed/');
      link = link.replace('/watch/', '/embed/');
      link = link.replace('youtu.be/', 'youtube.com/embed/');
    }
    return link;
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="wysiwig-editor">
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbarClassName="toolbar-box"
          wrapperClassName="wrapper h-100"
          editorClassName="editor-box pl-3 pr-3 "
          toolbar={{
            image: {
              className: 'gt-image-content',
              urlEnabled: true,
              uploadEnabled: true,
              uploadCallback: this.uploadImageCallBack,
              previewImage: true,
              // alignmentEnabled: false,
              defaultSize: undefined,
            },
            embedded: {
              className: 'gt-embedded-content',
              embedCallback: this.embedLinkCallback,
              defaultSize: undefined
            },
          }}
        />
      </div>
    );
  }
}

WYSIWIGEditor.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(WYSIWIGEditor);
