/* eslint-disable no-underscore-dangle */
import {
  SET_CONTENT_VIEW,
  SET_CONTENT_FEEDS,
  SET_CONTENT_LOADER,
  SET_COMMENT_LIST,
  ADD_COMMENT,
  SET_CONTENT_BOOKMARK_STATUS,
  INCREMENT_RATING_COUNT,
  SET_RECOMMENDED_FEED_CONTENT,
  SET_POPULAR_FEED_CONTENTS,
  SET_HOT_NEW_CONTENTS,
  SET_RECOMMENDED_FEED_PRACT,
} from '../actions/types';

const initialState = {
  content: null,
  feeds: [],
  comments: [],
  recommendedFeeds: [],
  popularFeeds: [],
  hotNew: [],
  recommendedPracFeeds: [],
  bookmarkStatus: false,
  contentLoader: false,
};

const addComment = (state, comment) => {
  const comments = state.comments.slice();
  const index = comments.findIndex(c => c._id === comment._id);
  if (index < 0) {
    comments.unshift(comment);
  }
  return comments;
};

const incrementRating = (state, rating) => {
  return { ...state.content, ratingCount: state.content.ratingCount + 1, my_rating: rating };
};

const contentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONTENT_VIEW:
      return {
        ...state,
        content: payload,
        contentLoader: false,
      };
    case SET_CONTENT_FEEDS:
      return {
        ...state,
        feeds: payload,
        content: null,
      };
    case SET_CONTENT_LOADER:
      return {
        ...state,
        contentLoader: payload,
      };
    case SET_COMMENT_LIST:
      return {
        ...state,
        comments: payload,
      };
    case ADD_COMMENT:
      return {
        ...state,
        comments: addComment(state, payload),
      };
    case SET_CONTENT_BOOKMARK_STATUS:
      return {
        ...state,
        bookmarkStatus: payload,
      };
    case INCREMENT_RATING_COUNT:
      return {
        ...state,
        content: incrementRating(state, payload),
      };
    case SET_RECOMMENDED_FEED_CONTENT:
      return {
        ...state,
        recommendedFeeds: payload,
      };
    case SET_POPULAR_FEED_CONTENTS:
      return {
        ...state,
        popularFeeds: payload,
      };
    case SET_HOT_NEW_CONTENTS:
      return {
        ...state,
        hotNew: payload,
      };
    case SET_RECOMMENDED_FEED_PRACT:
      return {
        ...state,
        recommendedPracFeeds: payload,
      };
    default:
      return state;
  }
};

export default contentReducer;
