import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';
import 'react-input-range/lib/css/index.css';
import './InputRangeSlider.css';

class InputRangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: props.maxValue,
      minValue: props.minValue,
      step: props.step || 1,
      value: props.value || { min: props.minValue + 5, max: props.maxValue - 5 },
    };
  }

  onChangeHandler = value => {
    this.setState({ value });
  };

  onChangeComplete = value => {
    const { meta, input, onChangeComplete, dispatch } = this.props;
    if (onChangeComplete) onChangeComplete(value);
    if (meta && input) dispatch(change(meta.form, input.name, value));
  };

  render() {
    const { value, maxValue, minValue, step } = this.state;
    const { formatLabel, varient } = this.props;
    return (
      <div className={varient}>
        <InputRange
          formatLabel={formatLabel}
          maxValue={maxValue}
          minValue={minValue}
          step={step}
          value={value}
          onChange={this.onChangeHandler}
          onChangeComplete={this.onChangeComplete}
        />
      </div>
    );
  }
}

InputRangeSlider.propTypes = {
  dispatch: PropTypes.func.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  value: PropTypes.shape({}),
  step: PropTypes.number,
  input: PropTypes.shape({
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    form: PropTypes.string,
  }),
  onChangeComplete: PropTypes.func,
  formatLabel: PropTypes.func.isRequired,
  varient: PropTypes.string,
};

InputRangeSlider.defaultProps = {
  value: { min: '', max: '' },
  step: 1,
  varient: '',
  input: {},
  meta: {},
  onChangeComplete: () => {},
};

export default connect()(InputRangeSlider);
