import React from 'react';
import { Field } from 'redux-form';
import './PractitionerProfileSettings.css';
import MultiSearchDropDown from '../../../common/MultiSearchDropDown/MultiSearchDropDown';

const typeOfServices = [
  { label: 'Individual', value: 'individual' },
  { label: 'Group', value: 'group' },
  { label: 'Parties', value: 'parties' },
  { label: 'Remote', value: 'remote' },
  { label: 'Retreats', value: 'retreats' },
  { label: 'Workshops', value: 'work-shops' },
];

const sessionTypes = [
  { label: 'In Office', value: 'in-office' },
  { label: 'Outcall', value: 'out-call' },
  { label: 'Video Chat', value: 'video-chat' },
  { label: 'Phone', value: 'phone' },
  { label: 'Text', value: 'text' },
];

const ProfessionalServices = () => {
  return (
    <div className="professional-services">
      <h1 className="professional-title">Professional Services</h1>
      <div className="row mt-3">
        <div className="col-md-6 col-12">
          <label className="sub-title">TYPES OF SERVICES</label>
          {/* <Field name="types_of_service" component={SelectInput}>
            <option value="">Type of Service</option>
            <option value="individual">Individual</option>
            <option value="group">Group</option>
            <option value="parties">Parties</option>
            <option value="remote">Remote</option>
            <option value="retreats">Retreats</option>
            <option value="work-shops">Workshops</option>
          </Field> */}
          <Field
            name="types_of_service"
            type="select-multi"
            component={prop => (
              <MultiSearchDropDown
                placeholder="Type of Service"
                options={typeOfServices}
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
                varient="primary"
                {...prop}
              />
            )}
          />
        </div>
        <div className="col-md-6 col-12">
          <label className="sub-title">SESSION TYPE</label>
          {/* <Field name="session_type" component={SelectInput}>
            <option value="">Session Type</option>
            <option value="in-office">In Office</option>
            <option value="out-call">Outcall</option>
            <option value="video-chat">Video Chat</option>
            <option value="phone">Phone</option>
            <option value="text">Text</option>
          </Field> */}
          <Field
            name="session_type"
            type="select-multi"
            component={prop => (
              <MultiSearchDropDown
                placeholder="Session Type"
                options={sessionTypes}
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
                varient="primary"
                {...prop}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfessionalServices;
