/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, Form } from 'redux-form';
import PractitionerRegistrationHeader from '../PractitionerRegistrationContainer/PractitinerRegistration/PractitionerRegistrationHeader';
import SearchDropDown from '../../../common/SearchDropDown/SearchDropDown';
import { setPageActive } from '../../../../store/actions/linkBusinessAction';
import { isEmpty } from '../../../../utils/validators';

const validator = values => {
  const errors = {};
  if (!isEmpty(values.business_id)) {
    errors.business_id = 'This field is required.';
  }
  return errors;
};

let LinkExistBuisness = ({ setPageActive, businesses, handleSubmit }) => {
  return (
    <div id="link-exist-business" className="py-5 mt-md-5">
      <PractitionerRegistrationHeader
        title="Link Business"
        info="Enter your business details to work as an organization."
      />
      <div className="row">
        <div className="col-md-8 mb-4">
          <Form onSubmit={handleSubmit}>
            <Field
              name="business_id"
              component={prop => (
                <SearchDropDown
                  onSelect={value => {
                    prop.input.onChange(String(value));
                  }}
                  value={prop.input.value}
                  placeholder="Choose one business from list"
                  options={businesses}
                  {...prop}
                />
              )}
            />
          </Form>
        </div>
        <div className="col d-flex align-items-center justify-content-center">
          <p className="or-text mr-md-0 mr-3">- OR -</p>
          <button
            type="button"
            className="btn btn-light ml-md-auto"
            onClick={() => setPageActive(2)}
          >
            Add new Business
          </button>
        </div>
      </div>
    </div>
  );
};

LinkExistBuisness = reduxForm({
  form: 'linkBusiness',
  validate: validator,
})(LinkExistBuisness);

const mapStateToProps = state => ({
  businesses: state.linkBusiness.businesses,
});

const mapDispatchToProps = {
  setPageActive,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkExistBuisness);
