/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

const PromotionCard = ({ item, deletePromotion, editPromotion }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <div className="promotion-card">
      <span className="promo-options" ref={target} onClick={() => setShow(!show)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            fill="#9c9c9c"
          />
        </svg>
      </span>
      <Overlay target={target.current} show={show} placement="bottom-end">
        {({ placement, scheduleUpdate, arrowProps, outOfBoundaries, show: _show, ...props }) => (
          <div {...props} className="d-flex flex-column promo-popover mr-2 mb-1">
            <button
              type="button"
              className="btn promo-popover-item-delete d-flex flex-row align-items-center"
              onClick={() => deletePromotion(item)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <path
                  fill="#ff0000"
                  fillRule="nonzero"
                  d="M7 16a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1zM17 4h-4V3a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v1H1a1 1 0 1 0 0 2h1v11a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V6h1a1 1 0 0 0 0-2zM7 3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1H7V3zm7 14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6h10v11zm-3-1a1 1 0 0 0 1-1V9a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1z"
                />
              </svg>
              Delete
            </button>
            <button
              type="button"
              className="btn promo-popover-item-edit"
              onClick={() => {
                setShow(!show);
                editPromotion(item);
              }}
              style={{ color: '#9c9c9c' }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <path
                  fill="#9C9C9C"
                  fillRule="nonzero"
                  d="M3.15 16.801h4.452c.28.002.547-.108.745-.304l7.267-7.275 2.982-2.918a1.05 1.05 0 0 0 0-1.49L14.144.31a1.05 1.05 0 0 0-1.491 0L9.692 3.281l-7.288 7.275a1.05 1.05 0 0 0-.304.745v4.45c0 .58.47 1.05 1.05 1.05zM13.398 2.536l2.972 2.97-1.491 1.491-2.972-2.97 1.491-1.491zM4.2 11.73l6.227-6.225 2.971 2.971-6.226 6.225H4.2v-2.97zm15.75 7.17H1.05a1.05 1.05 0 1 0 0 2.099h18.9a1.05 1.05 0 1 0 0-2.1z"
                />
              </svg>
              Edit
            </button>
          </div>
        )}
      </Overlay>
      
      <img src="/icons/percentage.svg" width="60px"/>
      <div className="row">
        <h3 className="promotion-card-description text-truncate">{item.promotion_title}</h3>
      </div>
      <span className="promotion-end-date">End Date -</span>
      <span className="promotion-end-date-container">
        {moment(item.promotion_end_date).format('DD MMM YYYY')}
      </span>
    </div>
  );
};

PromotionCard.propTypes = {
  item: PropTypes.shape({
    promotion_description: PropTypes.string,
    promotion_end_date: PropTypes.string,
    promotion_title: PropTypes.string,
  }).isRequired,
  deletePromotion: PropTypes.func.isRequired,
  editPromotion: PropTypes.func.isRequired,
};

export default PromotionCard;
