import React from 'react';
// import Button from '../../common/Button/Button';

const BlockedAccountsList = () => {
  const accounts = [
    {
      id: 1,
      name: 'Lela Holland',
      email: 'powlowski.cydney@yahoo.com',
      image: '',
      location: 'Lake Samir',
    },
    {
      id: 2,
      name: 'Adeline Nash',
      email: 'powlowski.cydney@yahoo.com',
      image: '',
      location: 'Lake Samir',
    },
    {
      id: 3,
      name: 'Lela Holland',
      email: 'powlowski.cydney@yahoo.com',
      image: '',
      location: 'Lake Samir',
    },
    {
      id: 4,
      name: 'Roxie Nelson',
      email: 'powlowski.cydney@yahoo.com',
      image: '',
      location: 'Lake Samir',
    },
    {
      id: 5,
      name: 'Lela Holland',
      email: 'powlowski.cydney@yahoo.com',
      image: '',
      location: 'Lake Samir',
    },
  ];
  const blockedAccountsList = accounts.map(profile => {
    return (
      <tr key={profile.id}>
        <td>
          <div className="d-flex flex-row">
            <img
              src="/images/user.svg"
              className="practitioner-profile-image"
              alt="prac_profile"
            />
            <div className="d-flex flex-column ml-2">
              <span className="practitioner-name">{profile.name}</span>
              <span className="practitioner-email">{profile.email}</span>
            </div>
          </div>
        </td>
        <td>{profile.location}</td>
        <td>
          <button
            type="button"
            className="btn outline"
            style={{ color: '#d4af37', fontSize: '0.75rem' }}
          >
            Unblock
          </button>
        </td>
      </tr>
    );
  });
  return blockedAccountsList;
};
class BlockedAccounts extends React.Component {
  componentDidMount = () => {};

  render() {
    return (
      <div id="blocked-accounts">
        <h1 className="blocked-accounts-title">Blocked Accounts</h1>
        <p className="blocked-accounts-subtitle">Manage the accounts whom you blocked.</p>
        <div className="table-responsive-md">
          <table className="blocked-accounts-table table table-borderless">
            <thead>
              <tr>
                <th scope="col" className="practitioner-table-head">
                  Name
                </th>
                <th scope="col" className="practitioner-table-head">
                  Location
                </th>
              </tr>
            </thead>
            <tbody>
              <BlockedAccountsList />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default BlockedAccounts;
