import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './UserComments.css';

const UserComments = ({ comment }) => {
  return (
    <div className="user-comments pt-3">
      <div className="d-flex">
        <img
          className="avatar"
          src={comment.commented_by.u_image_url || '/images/user.svg'}
          alt="user"
        />
        <div className="ml-3">
          <div className="d-flex">
            <h6 className="mb-0" style={{ fontWeight: 600 }}>
              {comment.commented_by.u_first_name} {comment.commented_by.u_last_name || ''}
            </h6>
            <span className="time pl-2 ">{moment(comment.commented_at).fromNow()}</span>
          </div>
          <div className="pt-1">
            <p className="comment-text mb-0">{comment.comment}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

UserComments.propTypes = {
  comment: PropTypes.shape({
    comment: PropTypes.string,
    commented_at: PropTypes.string,
    commented_by: PropTypes.shape({
      u_first_name: PropTypes.string,
      u_last_name: PropTypes.string,
      u_image_url: PropTypes.string,
    }),
  }).isRequired,
};

export default UserComments;
