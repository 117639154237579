import { toast } from 'react-toastify';
import { SET_SEARCH_QUERY, SET_SEARCH_TYPE, SET_SEARCH_RESULTS, SET_LOADER, SET_CATEGORIES_LIST } from './types';
import axios from '../../axios';

export const getSpecialitiesList = () => (dispatch) => {
  dispatch(setLoader(true));
  axios
    .get('/api/meta/filter_data')
    .then(res => {
      dispatch(setLoader(false));
      // console.log('resssssssss------->', res.data);
      let categories = res.data;
      if (res.data.status) {
        dispatch({
          type: SET_CATEGORIES_LIST,
          payload: categories,
        });
      }
      // console.log('categoriezzzzzzz------->', categories);
    })
    .catch(err => {
      toast.error('Something went wrong');
    });
};

export const setSearchQuery = query => dispatch => {
  dispatch({
    type: SET_SEARCH_QUERY,
    payload: query,
  });
};

export const setSearchType = type => dispatch => {
  dispatch({
    type: SET_SEARCH_TYPE,
    payload: type,
  });
};

export const clearSearchResult = () => dispatch => {
  dispatch({
    type: SET_SEARCH_RESULTS,
    payload: { results: [] },
  });
};

export const onSearch = (type, query, page = 0, filterlist, cb) => (dispatch, getState) => {
  dispatch(setLoader(true));
  // console.log('filterlist----------------------------->', filterlist);
  // console.log('type----------------------------->', type);
  // console.log('query----------------------------->', query);
  // console.log('page----------------------------->', page);
  let URL = `/api/search?type=${type}&query=${query}&page=${page}`
  // if(filterlist.contentAccessType){
  //   URL = `/api/search?type=${type}&query=${query}&page=${page}&filterlist=${filterlist}`
  // }
  axios
    .post(URL, null, {params: 
      {
        contentAccessType: filterlist.contentAccessType ? filterlist.contentAccessType : null,
        contentType: filterlist.contentType ? filterlist.contentType : null,
        categories: filterlist.categories ? filterlist.categories : null,
        specialization: filterlist.specialization ? filterlist.specialization : null,
        serviceType: filterlist.serviceType ? filterlist.serviceType : null,
        sexOrientation: filterlist.sexOrientation ? filterlist.sexOrientation : null,
        sessionType: filterlist.sessionType ? filterlist.sessionType : null,
        culturalHeritages: filterlist.culturalHeritages ? filterlist.culturalHeritages : null,
        spiritualOrientations: filterlist.spiritualOrientations ? filterlist.spiritualOrientations : null,
        gender: filterlist.gender ? filterlist.gender : null,
        minAge: filterlist.minAge ? filterlist.minAge : null,
        maxAge: filterlist.maxAge ? filterlist.maxAge : null,
        minExp: filterlist.minExp ? filterlist.minExp : null,
        maxExp: filterlist.maxExp ? filterlist.maxExp : null,
        lat: filterlist.lat ? filterlist.lat : null,
        long: filterlist.long ? filterlist.long : null,
        orderBy: filterlist.orderBy ? filterlist.orderBy : null,
      }})
    .then(res => {
      dispatch(setLoader(false));
      const { results } = getState().search;
      // console.log('actionresults----------------------------->', res.data.results);
      const updatedResult = page > 0 ? [...results, ...res.data.results] : res.data.results;
      if (res.data.status) {
        dispatch({
          type: SET_SEARCH_RESULTS,
          payload: { results: updatedResult },
        });
      }
      cb(null, res.data.hasMore);
    })
    .catch(err => {
      toast.error('Something went wrong');
      cb(err);
    });
};

export const setLoader = loaderState => {
  return {
    type: SET_LOADER,
    payload: loaderState,
  };
};
