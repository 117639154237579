/* eslint-disable camelcase */
import React from 'react';
import './ContentCard.css';
import PropTypes from 'prop-types';

const ContentCard = ({ content, onClick }) => {
  const {
    content_type,
    content_title,
    content_thumbnail_url,
    content_access_type,
    content_description,
    content_owner: { u_first_name, u_last_name } = {},
    rating,
  } = content;

  const icons = {
    article: '/icons/article_ico.svg',
    video: '/icons/video_ico.svg',
    audio: '/icons/audio_ico.svg',
  };
  const icon = icons[content_type];
  const thumbnail = content_thumbnail_url || '/images/thumbnail-placeholder.jpg';

  return (
    <div className="card article-card" role="presentation" onClick={onClick}>
      {content_access_type !== 'free' && (
        <img className="premium" src="/icons/premium-feed.svg" height="32" alt="premium" />
      )}
      <img className="type-icon" src={icon} alt="article" />
      <div className="card-img-top article-image">
        <img className="card-img-top" src={thumbnail} alt="Article" />
      </div>
      <div className="card-body d-flex flex-column">
        <div className="d-flex align-items-start">
          <h6 className="article-heading">{content_title}</h6>
          {/* <img className="ml-auto" src="/icons/bookmark.svg" alt="" style={{ marginTop: '6px' }} /> */}
        </div>
        <p className="article-content">{content_description}</p>
        <div className="d-flex article-footer mt-auto">
          <span className="text-capitalize text-truncate">
            {u_first_name} {u_last_name || ''}
          </span>
          {/* <span>{moment(content_created_at).format('DD MMM YYYY') }</span> */}
          <div className="ml-auto">
            <img
              className="d-inline-block"
              src="/icons/star.svg"
              alt="Rating"
              style={{ margin: '0 4px 3px 0' }}
            />
            <span>{(rating && rating.toFixed(1)) || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

ContentCard.propTypes = {
  content: PropTypes.shape({
    content_type: PropTypes.string.isRequired,
    content_access_type: PropTypes.string.isRequired,
    content_title: PropTypes.string.isRequired,
    content_description: PropTypes.string.isRequired,
    content_thumbnail_url: PropTypes.string,
    content_owner: PropTypes.shape({}).isRequired,
    rating: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ContentCard;
