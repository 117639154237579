/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, formValueSelector } from 'redux-form';
// import PractitionerRegistrationFooter from '../PractitionerRegistrationContainer/PractitinerRegistration/PractitionerRegistrationFooter'
import LinkBusinessForm from './LinkBusinessForm';
import InviteEmployees from './InviteEmployees';
import LinkBusinessComplete from './LinkBusinessComplete';
import {
  setPageActive,
  getBusinesses,
  linkBusiness,
  addBusiness,
  invitePeople,
} from '../../../../store/actions/linkBusinessAction';
import './LinkBusiness.css';
import LinkExistBuisness from './LinkExistBuisness';
import { getCountries } from '../../../../store/actions/appActions';

class LinkBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      businessId: '',
    };
  }

  componentWillUnmount() {
    const { setPageActive } = this.props;
    setPageActive(1);
  }

  getStep = () => this.props.pages.find(page => page.status === 'progress').step;

  renderActivePage = () => {
    const page = this.props.pages.find(page => page.status === 'progress');
    if (!page) return null;
    switch (page.step) {
      case 1:
        return <LinkExistBuisness onSubmit={this.linkBusinessHandler} />;
      case 2:
        return <LinkBusinessForm onSubmit={this.addBusinessHandler} />;
      case 3:
        return <InviteEmployees onSubmit={this.invitePeoples} />;
      case 4:
        return <LinkBusinessComplete />;
      default:
        break;
    }
  };

  componentDidMount = () => {
    const { getBusinesses, getCountries } = this.props;
    getBusinesses();
    getCountries();
  };

  addBusinessHandler = values => {
    const { addBusiness } = this.props;
    this.setState({ loading: true });
    addBusiness(values, (err, business) => {
      this.setState({ loading: false, businessId: business._id });
    });
  };

  linkBusinessHandler = value => {
    const { linkBusiness } = this.props;
    this.setState({ loading: true });
    linkBusiness(value.business_id, () => {
      // setPageActive(4);
      this.setState({ loading: false });
    });
  };

  invitePeoples = values => {
    const { businessId } = this.state;
    const { invitePeople } = this.props;
    if (businessId && values.emails && values.emails.length) {
      this.setState({ loading: true });
      invitePeople(values.emails, businessId, err => {
        this.setState({ loading: false });
      });
    }
  };

  render() {
    const { remoteSubmit, setPageActive, emails } = this.props;
    const { loading } = this.state;

    return (
      <div id="practitioner-registration-container" className="link-practitioner-container">
        <div className="container h-100">{this.renderActivePage()}</div>
        {this.getStep() <= 4 && (
          <div className="prac-reg-footer d-flex align-items-center">
            <div className="container" style={{ zIndex: 100 }}>
              <div className="d-flex">
                {this.getStep() === 1 && (
                  <button
                    type="button"
                    className="btn gt-btn ml-auto"
                    disabled={loading}
                    onClick={() => {
                      remoteSubmit('linkBusiness');
                    }}
                  >
                    {loading ? (
                      <span>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        />
                        Linking...
                      </span>
                    ) : (
                      'Link Business'
                    )}
                  </button>
                )}
                {this.getStep() === 2 && (
                  <button
                    type="button"
                    disabled={loading}
                    className="btn gt-btn ml-auto"
                    onClick={() => {
                      remoteSubmit('businessLinkForm');
                    }}
                  >
                    {loading ? (
                      <span>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        />
                        Adding...
                      </span>
                    ) : (
                      'Add Business'
                    )}
                  </button>
                )}
                {this.getStep() === 3 && (
                  <div className="ml-auto">
                    <button type="button" className="btn text-white font-medium mr-4"
                    onClick={()=>{
                      setPageActive(4);
                    }}>
                      Skip
                    </button>
                    <button
                      type="button"
                      className="btn gt-btn"
                      disabled={loading || !emails || !emails.length }
                      onClick={() => {
                        remoteSubmit('invite-form');
                      }}
                    >
                      {loading ? (
                        <span>
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"
                          />
                          Inviting...
                        </span>
                      ) : (
                        'Invite All'
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const selector = formValueSelector('invite-form');
  return {
    pages: state.linkBusiness.pages,
    emails: selector(state,'emails')
  };
};

const mapDispatchToProps = {
  getBusinesses,
  linkBusiness,
  addBusiness,
  invitePeople,
  setPageActive,
  getCountries,
  remoteSubmit: formName => dispatch => dispatch(submit(formName)),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkBusiness);
