/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Switch from '../../../common/Switch/Switch';
import './PractitionerProfileSettings.css';
import TimeAvailability from '../../Practitioner/PractitionerRegistrationContainer/PractitinerRegistration/TimeAvailability';

const TimeAvailabilitySettings = () => {
  return (
    <div className="professional-services" style={{ backgroundColor: '' }}>
      <div className="d-flex flex-md-row justify-content-between">
        <h1 className="professional-title">Time Availability</h1>
        <div className="d-flex justify-content-center">
          <Field
            name="p_set_time_per_day"
            component={props => (
              <Switch
                id="2"
                checked={props.input.value}
                onChange={event => props.input.onChange(event)}
                {...props}
                classType="dark-switch"
              />
            )}
          />
          <span className="set-time">Set Time Per Day</span>
        </div>
      </div>
      <p className="time-description">Select your general time for sessions.</p>
      <div className="mt-5">
        <Field
          name="time_availability"
          varient="primary"
          component={prop => (
            <TimeAvailability
              selected={prop.input.value}
              onChangeTime={value => {
                prop.input.onChange(value);
              }}
              {...prop}
            />
          )}
        />
      </div>
    </div>
  );
};

TimeAvailabilitySettings.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
};
TimeAvailabilitySettings.defaultProps = {
  input: {},
};
export default TimeAvailabilitySettings;
