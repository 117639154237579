import React from 'react';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import * as informations from './conflict-data/conflict-data.json';

import './ResolvingConflict.css';

const { cureInfo } = informations.default;
const { tipsInfo } = informations.default;

const renderCureInfo = () => {
  const infoList = cureInfo.map(information => {
    return <li key={information.id}>{information.info}</li>;
  });
  return <ul>{infoList}</ul>;
};

const renderTips = () => {
  const tipsList = tipsInfo.map(tip => {
    return <li key={tip.id}>{tip.tip}</li>;
  });
  return <ul>{tipsList}</ul>;
};

const ResolvingConflict = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row" id="resolving-conflict">
          <div className="col-md-8 col-12 ">
            <h1 className="title">RESOLVING CONFLICT WITH ANOTHER MEMBER</h1>
            <div className="instructions-container">
              <p className="instructions">
                The GuideTrue community prides itself on being a welcoming and supportive
                environment for all people. The diverse mix of backgrounds, cultures, and
                perspectives makes GuideTrue a vibrant and interesting place to find resources for
                self-actualization. Most people do the right thing and enjoy GuideTrue. These tips
                are to help us all support one another and resolve little conflicts if they arise.
              </p>
            </div>
            <div>
              <h1 className="sub-title">PREVENTION IS BETTER THAN CURE</h1>
              {renderCureInfo()}
            </div>
            <div>
              <h1 className="sub-title">TIPS FOR RESOLVING CONFLICT ONLINE</h1>
              {renderTips()}
            </div>
            <div>
              <p className="instructions">
                Most people want to do the right thing. Many differences can be settled by those
                involved with a few deep breaths and a little extra diplomacy. Behavior that
                continues to escalate can be reported for review using the links provided around the
                site.
              </p>
            </div>
            <div>
              <p className="instructions">
                GuideTrue does not currently offer a block function, but we do offer support with
                member conflict by contacting us with details relevant to the dispute.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '3rem' }}>
        <Footer />
      </div>
    </div>
  );
};
export default ResolvingConflict;
