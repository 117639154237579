import axios from '../../axios';
import {
  SET_NOTIFICATIONS_LIST,
  UPDATE_NOTIFICATION_LIST,
  UPDATE_NOTIFICATION_STATUS,
  MARK_AS_READ,
} from './types';
import { setLoader } from './appActions';
import { setCurrentUser } from './authActions';

export const updateNotification = notification => dispatch => {
  dispatch({
    type: UPDATE_NOTIFICATION_LIST,
    payload: notification,
  });
};

export const markAllAsRead = () => async dispatch => {
  try {
    const response = await axios.put('/api/user/notifications/read_all');
    const { status } = response.data;
    if (status) {
      dispatch({
        type: UPDATE_NOTIFICATION_STATUS,
        payload: 'read',
      });
    }
  } catch (error) {
    console.log('[Error While notification mark all as read]', error);
  }
};

export const markAllAsSeen = () => async dispatch => {
  try {
    const response = await axios.put('/api/user/notifications/seen_all');
    const { status } = response.data;
    if (status) {
      dispatch({
        type: UPDATE_NOTIFICATION_STATUS,
        payload: 'seen',
      });
    }
  } catch (error) {
    console.log('[Error While notification mark all as seen]', error);
  }
};

export const markAsRead = notificationId => async dispatch => {
  try {
    const response = await axios.put(`/api/user/notifications/read/${notificationId}`);
    const { status } = response.data;
    if (status) {
      dispatch({
        type: MARK_AS_READ,
        payload: notificationId,
      });
    }
  } catch (error) {
    console.log('[Error While notification mark as read]', error);
  }
};

export const getNotifications = () => async dispatch => {
  try {
    const response = await axios.get('/api/user/notifications');
    const { notifications } = response.data;
    dispatch({
      type: SET_NOTIFICATIONS_LIST,
      payload: notifications,
    });
  } catch (error) {
    console.log('Errror...', error);
  }
};

export const updateNotificationSettings = params => async dispatch => {
  dispatch(setLoader(true));
  try {
    const response = await axios.put('/api/user/update_notification_settings', params);
    const { user } = response.data;
    localStorage.setItem('user', JSON.stringify(user));
    dispatch(setCurrentUser(user));
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
  }
};
