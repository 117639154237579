/* eslint-disable no-case-declarations */
import {
  SET_SEARCH_QUERY,
  SET_SEARCH_TYPE,
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
  SET_CATEGORIES_LIST
} from '../actions/types';

const initialState = {
  type: 'practitioner',
  query: '',
  results: [],
  categories: []
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CATEGORIES_LIST:
      return {
        ...state,
        categories: payload,
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        query: payload,
      };
    case SET_SEARCH_TYPE:
      return {
        ...state,
        type: payload,
      };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        results: [],
      };
    case SET_SEARCH_RESULTS:
      const { results } = payload;
      return {
        ...state,
        results,
      };
    default:
      return state;
  }
};
export default searchReducer;
