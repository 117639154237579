/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { reduxForm, Field, Form } from 'redux-form';
import PractitionerRegistrationHeader from '../PractitionerRegistrationContainer/PractitinerRegistration/PractitionerRegistrationHeader';
import EmailInput from './EmailInput/EmaiInput';

let InviteEmployees = ({ handleSubmit }) => (
  <div id="invite-employees-page" className="mt-lg-5 pt-5">
    <PractitionerRegistrationHeader
      title="Invite other members"
      info="By entering the email address of your employees, we will send an invitation link to their email."
    />
    <Form onSubmit={handleSubmit}>
      <Field
        name="emails"
        component={({ input }) => <EmailInput value={input.value} onChange={input.onChange} />}
      />
    </Form>
  </div>
);

InviteEmployees = reduxForm({
  form: 'invite-form',
})(InviteEmployees);

export default InviteEmployees;
