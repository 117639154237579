/* eslint-disable no-underscore-dangle */
import {
  SET_PRACTITIONER_PROFILE,
  SET_PAGE_ACTIVE,
  SET_SESSION_STATUS,
  SET_PRAC_BOOKMARK_STATUS,
  SET_SHARE_RESULT_STATUS,
  SET_SESSION_REQUESTS,
  UPDATE_SESSION_REQUESTS,
  SET_PRACTITIONER_CONTENT_LIST,
  SET_USER_REVIEW_LIST,
  UPDATE_USER_REVIEW_LIST,
  SET_REQUEST_RESULT_STATUS,
  DELETE_CONTENT,
  CHANGE_CONTENT_PUBLISH_STATUS,
  UPDATE_PRACTITIONER_CONTENT_LIST,
} from '../actions/types';

const initialState = {
  profile: null,
  sessionStatus: '', // For Member Perspective Practitioner Profile view
  bookmarkStatus: false, // For Member Perspective Practitioner Profile view
  shareStatus: false, // For Member Perspective Practitioner Profile view
  requestResultStatus: '',
  sessionRequests: [],
  contents: [],
  reviews: [],
  pages: [
    {
      step: 0,
      status: 'progress',
    },
    {
      step: 1,
      status: 'waiting',
    },
    {
      step: 2,
      status: 'waiting',
    },
    {
      step: 3,
      status: 'waiting',
    },
    {
      step: 4,
      status: 'waiting',
    },
    {
      step: 5,
      status: 'waiting',
    },
    {
      step: 6,
      status: 'waiting',
    },
    {
      step: 7,
      status: 'waiting',
    },
  ],
};

const setPageActive = (state, step) => {
  return state.pages.map(page =>
    page.step === step ? { ...page, status: 'progress' } : { ...page, status: 'waiting' }
  );
};
const updateSessionRequestList = (state, session) => {
  const sessionRequests = state.sessionRequests.slice();
  const idx = sessionRequests.findIndex(sr => sr._id === session._id);
  if (idx > -1) {
    sessionRequests[idx] = session;
  } else {
    sessionRequests.unshift(session);
  }
  return sessionRequests;
};

const updateReviewList = (state, review) => {
  const reviews = state.reviews.slice();
  const idx = reviews.findIndex(rev => rev._id === review._id);
  if (idx > -1) {
    reviews[idx].review = review.review;
    reviews[idx].rating = review.rating;
  } else {
    reviews.unshift(review);
  }
  return reviews;
};

const changeStatus = (state, payload) => {
  const contents = JSON.parse(JSON.stringify(state.contents));
  const index = contents.findIndex(content => content._id === payload.contentId);
  if (index > -1) {
    contents[index].content_publish_status = payload.status;
  }
  return contents;
};

const updatePracContentList = (state, content) => {
  const contents = state.contents.slice();
  const idx = contents.findIndex(cont => cont._id === content._id);
  if (idx > -1) {
    contents[idx] = content;
  } else {
    contents.unshift(content);
  }
  return contents;
};

// const addNewContent = () => {
//   const contents = state.contents.slice();
//   if(contents.length) {
//     contents[0].content_owner._id === contents[0].content_owner._id
//   }
// }

const practitionerRegReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRACTITIONER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_PAGE_ACTIVE:
      return {
        ...state,
        pages: setPageActive(state, action.payload),
      };
    case SET_SESSION_STATUS:
      return {
        ...state,
        sessionStatus: action.payload,
      };
    case SET_SESSION_REQUESTS:
      return {
        ...state,
        sessionRequests: action.payload,
      };
    case UPDATE_SESSION_REQUESTS:
      return {
        ...state,
        sessionRequests: updateSessionRequestList(state, action.payload),
      };
    case SET_PRACTITIONER_CONTENT_LIST:
      return {
        ...state,
        contents: action.payload,
      };
    case UPDATE_PRACTITIONER_CONTENT_LIST:
      return {
        ...state,
        contents: updatePracContentList(state, action.payload),
      };
    case DELETE_CONTENT:
      return {
        ...state,
        contents: state.contents.filter(content => content._id !== action.payload),
      };
    case CHANGE_CONTENT_PUBLISH_STATUS:
      return {
        ...state,
        contents: changeStatus(state, action.payload),
      };
    case SET_USER_REVIEW_LIST:
      return {
        ...state,
        reviews: action.payload,
      };
    case SET_PRAC_BOOKMARK_STATUS:
      return {
        ...state,
        bookmarkStatus: action.payload,
      };
    case UPDATE_USER_REVIEW_LIST:
      return {
        ...state,
        reviews: updateReviewList(state, action.payload),
      };
    case SET_SHARE_RESULT_STATUS:
      return {
        ...state,
        shareStatus: action.payload,
      };
    case SET_REQUEST_RESULT_STATUS:
      return {
        ...state,
        requestResultStatus: action.payload,
      };
    default:
      return state;
  }
};

export default practitionerRegReducer;
