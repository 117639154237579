/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Report.css';
import { reportContent } from '../../../store/actions/contentAction';

const catagories = [
  'Sexual Content',
  'Violent or repulsive content',
  'Hateful or abusive content',
  'Harmful dangerous acts',
  'Mislabeled',
  'Child abuse',
  'Not relevant to GuideTrue',
];

class Report extends Component {
  others = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isTyping: false,
      otherOption: '',
      reason: '',
      reported: false,
    };
  }

  addOtherOptionHandler = event => {
    if (event.target.value) {
      this.setState({ otherOption: event.target.value, isTyping: false });
    } else {
      this.setState({ isTyping: false });
    }
  };

  handleRadioSelection = event => {
    this.setState({ otherOption: '', reason: event.target.value });
  };

  onReport = () => {
    const { reason, otherOption } = this.state;
    const { reportContent, contentId } = this.props;

    let reportReason = reason;
    if (otherOption) {
      reportReason = otherOption;
    }
    if (reportReason.trim()) {
      reportContent(contentId, reportReason, err => {
        if (!err) {
          this.setState({ reported: true });
        }
      });
    }
  };

  render() {
    const { isTyping, otherOption, reported, reason } = this.state;
    const { onCancel, title } = this.props;
    return (
      <div className="report-content pt-2">
        {!reported ? (
          <div>
            <h6 className="mb-0">{title}</h6>
            <div className="pt-3 pl-4 row">
              {catagories.map(category => (
                <div className="col-6 d-inline-flex" key={category}>
                  <label className="radio-container">
                    {category}
                    <input
                      type="radio"
                      name="radio"
                      value={category}
                      checked={reason === category}
                      onChange={this.handleRadioSelection}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              ))}
              <div className="col-6 d-inline-flex">
                {!isTyping ? (
                  <label
                    className="radio-container "
                    onClick={() => {
                      this.setState({ isTyping: true });
                    }}
                  >
                    <span>{otherOption || 'Other (Enter reason)'}</span>
                    <input
                      type="radio"
                      name="radio"
                      value={otherOption}
                      checked={otherOption}
                      onChange={this.handleRadioSelection}
                    />
                    <span className="checkmark" />
                  </label>
                ) : (
                  <input
                    autoFocus
                    className="form-control"
                    type="text"
                    onBlur={this.addOtherOptionHandler}
                  />
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button type="button" className="btn" onClick={onCancel}>
                CANCEL
              </button>
              <button type="button" className="btn btn-next" onClick={this.onReport}>
                REPORT
              </button>
            </div>
          </div>
        ) : (
          <div className="p-4 text-center row complete-report">
            <div className="col-3" />
            <div className="col-6">
              <img src="/images/completed.svg" alt="reported" />
              <h6 className="pt-4 font-weight-bold">Thanks for reporting</h6>
              <p>
                If we find this content to be in violation of our community guidelines, we will
                remove it.
              </p>
            </div>
            <div className="col-3" />
          </div>
        )}
      </div>
    );
  }
}

Report.propTypes = {
  reportContent: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default connect(
  null,
  { reportContent }
)(Report);
