import { toast } from 'react-toastify';
import axios from '../../axios';
import { SET_TRENDING_LIST, SET_PREMIUM_LIST, SET_TOP_CONTRIBUTERS, SET_FOCUS } from './types';

const getCategoryDetailes = (focusSlug, cb) => async dispatch => {
  try {
    const response = await axios.get(`/api/content/category/${focusSlug}`);
    const { trending, premium, contributers, focus } = response.data;
    dispatch({
      type: SET_TRENDING_LIST,
      payload: trending,
    });
    dispatch({
      type: SET_PREMIUM_LIST,
      payload: premium,
    });
    dispatch({
      type: SET_TOP_CONTRIBUTERS,
      payload: contributers,
    });
    dispatch({
      type: SET_FOCUS,
      payload: focus,
    });
    cb();
  } catch (error) {
    cb();
    toast.error('Somthing went wrong, Try again!');
  }
};
export default getCategoryDetailes;
