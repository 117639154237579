import React from 'react';
import { Carousel } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import './PractitionerUserOffers.css';

const PractitionerUserOffers = ({ promotions }) => {
  const getDaysLeft = endDate => moment(endDate).fromNow();
  const carouselItems = promotions.map((promotion, i) => (
    <Carousel.Item key={i}>
      <div className="prac_carousel d-flex justify-content-between">
        <div className="d-flex flex-column h-100 justify-content-between">
          <div>
            <h4 className="mb-0">{promotion.promotion_title}</h4>
            <p>Free your mind.</p>
          </div>
          <div className="offer_days_left d-flex">
            <img src="/icons/clock.svg" alt="clock_placeholder" />
            <p className="mb-0 ml-2">Ends {getDaysLeft(promotion.promotion_end_date)}</p>
          </div>
        </div>
        <div className="offer_image_div">
          <img src="/icons/percentage_large.svg" alt="percentage" />
          <p>EXPLORE</p>
        </div>
      </div>
    </Carousel.Item>
  ));
  return (
    <div className="prac_user_offers mb-3">
      <Carousel>{carouselItems}</Carousel>
    </div>
  );
};

PractitionerUserOffers.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.object),
};

PractitionerUserOffers.defaultProps = {
  promotions: [],
};

export default PractitionerUserOffers;
