/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MultiSearchDropDown.css';

export class MultiSearchDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalOptions: props.options,
      options: props.options,
      filteredOptions: props.options,
      selectedItems: [],
      activeIndex: 0,
      isOpen: false,
      value: '',
    };

    this.wrapperRef = React.createRef();
    this.inputElement = React.createRef();
    this.inputCopySpan = React.createRef();
    this.dropdown = React.createRef();
  }

  componentDidMount() {
    const { placeholder, value, other } = this.props;
    const { options } = this.state;
    if (placeholder) {
      this.inputElement.current.style.width = `${`${placeholder.length * 12}px`}`;
    }
    if (value && Array.isArray(value) && options.length) {
      // const items = options.filter(opt => value.includes(opt.value));
      const items = value.map(val => {
        const item = options.find(opt => opt.value === val);
        if (item) {
          return item;
        }
        return { label: val, value: val };
      });
      this.setState({ selectedItems: items }, () => {
        this.update();
      });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
      // const { input } = this.props;
      // if (input) input.onBlur();
    }
  };

  onChange = event => {
    const { options, selectedItems } = this.state;
    const { placeholder } = this.props;
    // eslint-disable-next-line prefer-destructuring
    const value = event.target.value;
    const filteredOptions = options.filter(opt =>
      opt.label.toLowerCase().includes(value.toLowerCase())
    );

    // filteredOptions = filteredOptions.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
    if (value.length === 0) {
      if (selectedItems.length === 0) {
        this.inputElement.current.style.width = `${`${placeholder.length * 12}px`}`;
      } else {
        this.inputElement.current.style.width = '10px';
      }
    } else {
      this.inputCopySpan.current.innerText = value;
      this.inputElement.current.style.width = `${this.inputCopySpan.current.clientWidth + 4}px`;
    }

    this.setState({
      activeIndex: 0,
      isOpen: true,
      value,
      filteredOptions,
    });
  };

  update = () => {
    const { originalOptions, selectedItems } = this.state;
    const updatedOptions = originalOptions.filter(
      opt => !(selectedItems.findIndex(o => o.value === opt.value) >= 0)
    );
    this.setState({
      filteredOptions: updatedOptions,
      options: updatedOptions,
    });
    
    this.inputCopySpan.current.innerText = '';
    this.inputElement.current.style.width = '4px';
    if (selectedItems.length === 0) {
      this.inputCopySpan.current.innerText = this.props.placeholder;
      this.inputElement.current.style.width = `${this.inputCopySpan.current.clientWidth + 4}px`;
    }
  };

  onRemoveSelectedItem = option => {
    const { selectedItems } = this.state;
    const { placeholder, onSelect } = this.props;

    const items = selectedItems.filter(opt => opt.value !== option.value);
    if (items.length === 0) {      
      this.inputElement.current.style.width = `${`${placeholder.length * 12}px`}`;
    }
    if (onSelect) {
      const values = items.map(item => item.value);
      onSelect(values);
    }
    this.setState(
      {
        selectedItems: items,
      },
      () => {
        this.update();
      }
    );
  };

  onClick = option => {
    const { selectedItems } = this.state;
    const { onSelect } = this.props;

    selectedItems.push(option);
    // const updatedOptions = options.filter(item => item.value !== option.value);
    if (onSelect) {
      const values = selectedItems.map(item => item.value);
      onSelect(values);
    }

    this.setState(
      {
        activeIndex: 0,
        // isOpen: false,
        value: '',
        selectedItems,
        // filteredOptions: updatedOptions,
        // options: updatedOptions,
      },
      () => {
        this.update();
      }
    );
  };

  onKeyDown = event => {
    const { filteredOptions, activeIndex, selectedItems, value, isOpen } = this.state;
    const { onSelect, placeholder, other } = this.props;

    if (event.keyCode === 13) {
      event.preventDefault();
      if (other && filteredOptions.length === 0) {
        selectedItems.push({ label: value, value });
        if (onSelect) {
          const values = selectedItems.map(item => item.value);
          onSelect(values);
        }
        this.setState({
          selectedItems,
          value: '',
          isOpen: false,
        });
        this.inputElement.current.style.width = '4px';
      }

      if (!isOpen || filteredOptions.length === 0) return;
      selectedItems.push(filteredOptions[activeIndex]);
      if (onSelect) {
        const values = selectedItems.map(item => item.value);
        onSelect(values);
      }

      this.setState(
        {
          activeIndex: 0,
          isOpen: false,
          value: '',
          selectedItems,
          // filteredOptions: updatedOptions,
          // options: updatedOptions,
        },
        () => {
          this.update();
        }
      );
    } else if (event.keyCode === 8) {
      // Backspace
      if (selectedItems.length === 0 || value) {
        return;
      }

      selectedItems.pop();
      this.update();
      if (selectedItems.length === 0) {
        this.inputElement.current.style.width = `${`${placeholder.length * 12}px`}`;
      }

      if (onSelect) {
        const values = selectedItems.map(item => item.value);
        onSelect(values);
      }
    } else if (event.keyCode === 40) {
      // Down Arrow
      if (activeIndex + 1 === filteredOptions.length) {
        return;
      }
      this.setState({ activeIndex: activeIndex + 1 });
    } else if (event.keyCode === 38) {
      // Up Arrow
      if (activeIndex === 0) {
        return;
      }
      this.setState({ activeIndex: activeIndex - 1 });
    }

    if (!this.dropdown.current) return;

    const listItems = this.dropdown.current.childNodes;
    if (listItems.length) {
      this.dropdown.current.scrollTop = 0;
      // eslint-disable-next-line react/destructuring-assignment
      const activeElement = listItems[this.state.activeIndex + 1];
      if (activeElement) {
        this.dropdown.current.scrollTop =
          activeElement.offsetTop - (this.dropdown.current.clientHeight - 34.38);
      }
    }
  };

  render() {
    const { value, selectedItems, filteredOptions, activeIndex, isOpen } = this.state;
    const { placeholder, meta, varient, other, input } = this.props;

    let listItems = filteredOptions.map((opt, i) => (
      <li
        key={opt.value}
        className={[i === activeIndex ? 'active' : '']}
        onClick={() => this.onClick(opt)}
        role="presentation"
      >
        {opt.label}
      </li>
    ));

    if (listItems.length === 0) {
      listItems = (
        <li style={{ backgroundColor: '#dee2e6', textTransform: 'unset' }} role="presentation">
          No items in the list
          {other && <span className="float-right ml-auto">Press enter/return to add</span>}
        </li>
      );
    }    
    return (
      <div ref={this.wrapperRef} className="position-relative">
        <div
          className={[
            'form-control search-multi-dropdown-control d-flex',
            varient && varient === 'primary' ? 'gt-form-control-primary' : '',
          ].join(' ')}
          name={input && input.name}
          onClick={() => {
            this.inputElement.current.focus();
            this.setState({ isOpen: true });
          }}
          role="presentation"
        >
          <div className="d-flex flex-wrap" style={{ maxWidth: '100%' }}>
            {selectedItems.map((item, i) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="selected-item"
                onClick={() => this.onRemoveSelectedItem(item)}
                role="presentation"
              >
                {item.label}
                <img src="/icons/close.svg" alt="close" />
              </span>
            ))}
            <input
              ref={this.inputElement}
              className="search-multi-dropdown-input"
              placeholder={selectedItems.length === 0 ? placeholder : ''}
              value={value}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
            />
            <span
              ref={this.inputCopySpan}
              className="position-absolute"
              style={{ left: '-100%', opacity: '0', zIndex: '-22222', whiteSpace: 'pre' }}
            />
          </div>
          {/* <img className={isOpen ? 'ml-auto rotate': 'ml-auto'} src='/images/down.svg' alt='down-arrow' /> */}
        </div>
        {meta && meta.error && meta.touched && (
          <span
            className={['invalid-feedback d-block', !varient && 'gtm-invalid-feedback'].join(' ')}
          >
            {meta.error}
          </span>
        )}
        {isOpen && (
          <ul
            ref={this.dropdown}
            className={['search-multi-dropdown-box', isOpen ? 'opened' : ''].join(' ')}
          >
            {listItems}
          </ul>
        )}
      </div>
    );
  }
}

MultiSearchDropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  other: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onSelect: PropTypes.func.isRequired,
  varient: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired,
  }),
};

MultiSearchDropDown.defaultProps = {
  other: false,
  varient: '',
  meta: null,
};

export default MultiSearchDropDown;
