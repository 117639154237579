/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';
import {
  isEmailValid,
  isEmpty,
  isNumeric,
  isObject,
  isValidDOB,
  isValidUsername,
  isName,
} from '../../../../utils/validators';
import GTDatePicker from '../../../common/DatePicker/DatePicker';
import './RegistrationForm.css';
import SelectInput from '../../../common/SelectInput/SelectInput';

const validator = values => {
  const errors = {};
  if (!isEmpty(values.u_first_name)) {
    errors.u_first_name = 'This field is required';
  } else if (!isName(values.u_first_name)) {
    errors.u_first_name = 'Invalid name';
  }
  if (values.u_last_name && !isName(values.u_last_name)) {
    errors.u_last_name = 'Invalid name';
  }
  if (!isEmpty(values.u_gender)) {
    errors.u_gender = 'Select Gender';
  }
  if (!isObject(values.u_dob)) {
    errors.u_dob = 'This field is required';
  } else if (!isValidDOB(values.u_dob)) {
    errors.u_dob = 'You must be at least 16 years old.';
  }
  if (!isEmpty(values.u_email)) {
    errors.u_email = 'This field is required';
  } else if (!isEmailValid(values.u_email)) {
    errors.u_email = 'Enter a valid email address';
  }
  if (isEmpty(values.u_zipcode)) {
    if (!isNumeric(values.u_zipcode)) errors.u_zipcode = 'This should be number';
  }
  if (!isEmpty(values.u_username)) {
    errors.u_username = 'This field is required';
  } else if (!isValidUsername(values.u_username)) {
      let format = /[!@#$%^&*()_+\-=\ \[\]{};':"\\|,.<>\/?]+/;
      if(format.test(values.u_username)){
        errors.u_username = 'No special characters or space allowed';
      }
      // else if(values.u_username.match(/ /g) && (values.u_username.match(/ /g)).length){
      //   errors.u_username = 'No space allowed';
      // }
      else{
        errors.u_username = 'Invalid username';
      }
  }
  if (!isEmpty(values.u_password)) {
    errors.u_password = 'This field is required';
  }
  if (!isEmpty(values.u_cpassword)) {
    errors.u_cpassword = 'This field is required';
  } else if (values.u_password !== values.u_cpassword) {
    errors.u_cpassword = 'Password does not match';
  }
  if (!values.isAgreed) {
    errors.isAgreed = 'Agree to terms and conditions';
  }
  return errors;
};

let RegistrationForm = ({ handleSubmit, onSubmit, changeUsername, displayUsername, submitting, _error }) => (
  <form className="pt-md-5 pt-4 mb-3 signup-form" onSubmit={handleSubmit(onSubmit)}>
    {_error && _error.errorMsg && (
      <div className="position-relative">
        <span className="error-feedback d-flex align-items-center">
          <img className="mr-1" src="/images/error.png" alt="error-icon" />
          {_error.errorMsg}
        </span>
      </div>
    )}
    <div className="row">
      <div className="col-6 mb-3">
        <Field name="u_first_name" placeholder="First name" type="text" component={Input} />
      </div>
      <div className="col-6 mb-3">
        <Field name="u_last_name" placeholder="Last name" type="text" component={Input} />
      </div>
      <div className="col-6 mb-3">
        <Field name="u_gender" placeholder="Gender" component={SelectInput}>
          <option value="" disabled>
            Select Gender
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="non-binary">Non-Binary</option>
          <option value="other">Other</option>
          <option value="prefer not to answer">Prefer not to answer</option>
        </Field>
      </div>
      <div className="col-6 mb-3">
        <Field
          name="u_dob"
          component={props => (
            <GTDatePicker
              {...props}
              // selected={new Date()}
              minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
              dateFormat="dd MMM yyyy"
              className="form-control gt-form-control"
              placeholderText="Date of Birth"
            />
          )}
        />
      </div>
      <div className="col-12 mb-3">
        <Field name="u_email" placeholder="Email" type="text" component={Input} />
      </div>
      <div className="col-12 mb-3">
        <Field name="u_username" placeholder="Username" type="text" component={Input} onChange={changeUsername}/>
        {/* <p className="mb-0">Guidetrue URL is: <span>guidetrue.com/{displayUsername}</span></p> */}
      </div>
      <div className="col-12 mb-3">
        <Field name="u_password" type="password" placeholder="Password" component={Input} />
      </div>
      <div className="col-12 mb-3">
        <Field
          name="u_cpassword"
          type="password"
          placeholder="Confirm Password"
          component={Input}
        />
      </div>
      <div className="col-12 mb-3">
        <Field
          name="isAgreed"
          type="checkbox"
          label={
            <span className="checkbox-text">
              I hereby agree all the
              <Link to="/termsofservice" target="_blank" className="checkbox-text-tc ml-1">
                Terms and conditions.
              </Link>
            </span>
          }
          component={Input}
        />
      </div>
    </div>
    <div className="mt-3">
      <Button type="submit" disabled={submitting}>
        Create Account
      </Button>
    </div>
  </form>
);

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  changeUsername: PropTypes.func.isRequired,
  displayUsername: PropTypes.func.isRequired,
  _error: PropTypes.shape({
    errorMsg: PropTypes.string,
  }),
};

RegistrationForm.defaultProps = {
  _error: null,
};

RegistrationForm = reduxForm({
  form: 'registrationForm',
  validate: validator,
})(RegistrationForm);

const mapStateToProps = state => {
  return {
    _error: state.auth.error,
  };
};

export default connect(mapStateToProps)(RegistrationForm);
