import React, { Component } from 'react';
import Rating from 'react-rating';
import PropTypes from 'prop-types';
import './Rating.css';

class RatingComponent extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     rating: props.initialRating || 0,
  //   };
  // }

  changeRating = rate => {
    // const { rating } = this.state;
    // if (rate === rating) {
    //   // eslint-disable-next-line no-param-reassign
    //   rate = 0;
    // }
    // this.setState({ rating: rate });
  };

  render() {
    // const { rating } = this.state;
    // const { } = this.props;
    const { width, height, readonly, initialRating, onChange } = this.props;
    return (
      <div>
        <Rating
          initialRating={initialRating || 0}
          readonly={readonly}
          onClick={onChange}
          emptySymbol={
            <svg
              className="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width={width}
              height={height}
              viewBox="0 0 9 8"
            >
              <defs>
                <path
                  id="a"
                  d="M8.985 3.026a.445.445 0 0 0-.387-.28l-2.557-.347L4.895.236A.453.453 0 0 0 4.491 0a.453.453 0 0 0-.404.236L2.94 2.395l-2.557.35a.442.442 0 0 0-.364.285.398.398 0 0 0 .112.419l1.856 1.673-.45 2.376a.405.405 0 0 0 .18.418.478.478 0 0 0 .472.03l2.301-1.117 2.292 1.12a.472.472 0 0 0 .472-.03.405.405 0 0 0 .18-.417l-.45-2.376 1.856-1.673a.401.401 0 0 0 .144-.427z"
                />
              </defs>
              <use fill="#d8d8d8" fillRule="nonzero" xlinkHref="#a" />
            </svg>
          }
          fullSymbol={
            <svg
              className="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width={width}
              height={height}
              viewBox="0 0 9 8"
            >
              <defs>
                <path
                  id="a"
                  d="M8.985 3.026a.445.445 0 0 0-.387-.28l-2.557-.347L4.895.236A.453.453 0 0 0 4.491 0a.453.453 0 0 0-.404.236L2.94 2.395l-2.557.35a.442.442 0 0 0-.364.285.398.398 0 0 0 .112.419l1.856 1.673-.45 2.376a.405.405 0 0 0 .18.418.478.478 0 0 0 .472.03l2.301-1.117 2.292 1.12a.472.472 0 0 0 .472-.03.405.405 0 0 0 .18-.417l-.45-2.376 1.856-1.673a.401.401 0 0 0 .144-.427z"
                />
              </defs>
              <use fill="#d4af37" fillRule="nonzero" xlinkHref="#a" />
            </svg>
          }
        />
      </div>
    );
  }
}

RatingComponent.propTypes = {
  initialRating: PropTypes.number,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
};

RatingComponent.defaultProps = {
  initialRating: 0,
  readonly: false,
  onChange: () => {},
};

export default RatingComponent;
