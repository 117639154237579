import { isEmpty, isValidURL } from '../../../../utils/validators';

const validators = values => {
  const errors = {};
  if (!isEmpty(values.business_name)) {
    errors.business_name = 'This field is required.';
  }
  if (!isEmpty(values.business_phone_number)) {
    errors.business_phone_number = 'This field is required.';
  }
  if (!isEmpty(values.business_address_line1)) {
    errors.business_address_line1 = 'This field is required.';
  }
  if (isEmpty(values.business_website)) {
    if (!isValidURL(values.business_website)) {
      errors.business_website = 'please enter a valid url.';
    }
  }
  // if (!isEmpty(values.business_country)) {
  //   errors.business_country = 'This field is required.';
  // }
  // if (!isEmpty(values.business_state)) {
  //   errors.business_state = 'This field is required.';
  // }
  // if (!isEmpty(values.business_city)) {
  //   errors.business_city = 'This field is required.';
  // }
  if(!values.business_location) {
    errors.business_location = 'This field is required.';
  }

  return errors;
};

export default validators;
