/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import './ContentSection.css';
import ModalComponent from '../../../../../shared/Modal/Modal';
import { deleteContent, changeStatus } from '../../../../../../store/actions/contentAction';
import EditContent from '../../../../../publish/EditContent/EditContent';
import { getContentForEdit } from '../../../../../../store/actions/publishActions';

const ContentSection = ({
  content,
  onClick,
  owner,
  deleteContent,
  changeStatus,
  getContentForEdit,
}) => {
  const content_type_icons = {
    video: '/icons/video_ico.svg',
    audio: '/icons/audio_ico.svg',
    article: '/icons/article_ico.svg',
  };

  const [showModal, setModal] = useState(false);
  const [showPublishModal, setPublishModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);

  const togglePopup = event => {
    event.stopPropagation();
    setOpen(!open);
  };

  const deleteContentHandler = () => {
    deleteContent(content._id, () => {
      setModal(false);
    });
  };

  const editContent = event => {
    event.preventDefault();
    event.stopPropagation();
    setEditModalShow(true);
    setOpen(false);
    getContentForEdit(content._id, () => {});
  };

  const changeContentStatus = event => {
    event.stopPropagation();
    setOpen(false);
    const status = content.content_publish_status === 'PUBLISHED' ? 'UNPUBLISHED' : 'PUBLISHED';
    changeStatus(content._id, status);
    setPublishModal(false);
  };
  const DeleteConfirmationModal = (
    <ModalComponent
      size="small"
      onHide={() => setModal(false)}
      show={showModal}
      title="Delete Content"
      noHeader
    >
      <div className="d-flex flex-column p-2">
        <>
          <div className="d-flex align-items-center">
            <img src="/icons/delete.svg" alt="delete" />

            <div className="d-flex flex-column align-items-baseline pl-3 ml-2">
              <h5 className="text-center font-weight-bold mb-0">Are you sure ?</h5>
              <p className="text-black-50 mb-0">
                This content will be permanently removed from your profile
              </p>
            </div>
          </div>
          <div className="d-flex pt-4 justify-content-end">
            <button
              type="submit"
              onClick={() => setModal(false)}
              className="btn p-2 btn-outline-white px-4 font-weight-normal"
            >
              No
            </button>
            <button
              type="submit"
              onClick={deleteContentHandler}
              className="btn ml-3  btn-signup p-2 px-4 font-weight-normal"
            >
              Yes
            </button>
          </div>
        </>
      </div>
    </ModalComponent>
  );

  const UnpublishModal = (
    <ModalComponent
      size="small"
      onHide={() => setPublishModal(false)}
      show={showPublishModal}
      title="Delete Content"
      noHeader
    >
      <div className="d-flex flex-column p-2">
        <>
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-baseline pl-3 ml-2">
              <h5 className="text-center font-weight-bold mb-0">
                Do you want to publish this content ?
              </h5>
            </div>
          </div>
          <div className="d-flex pt-4 justify-content-end">
            <button
              type="submit"
              onClick={() => setPublishModal(false)}
              className="btn p-2 btn-outline-white px-4 font-weight-normal"
            >
              No
            </button>
            <button
              type="submit"
              onClick={changeContentStatus}
              className="btn ml-3  btn-signup p-2 px-4 font-weight-normal"
            >
              Yes
            </button>
          </div>
        </>
      </div>
    </ModalComponent>
  );

  const onClickHandler = () => {
    if (content.content_publish_status === 'DRAFT') {
      setEditModalShow(true);
      setOpen(false);
      getContentForEdit(content._id, () => {});
      return false;
    }

    if (content.content_publish_status === 'UNPUBLISHED') {
      setPublishModal(true);
      setOpen(false);
      return false;
    }
    onClick();
  };
  return (
    <>
      <div
        role="presentation"
        className="content_section cursor-pointer d-flex"
        onClick={onClickHandler}
      >
        <div className="position-relative">
          {content && content.content_access_type !== 'free' && (
            <img className="premium" src="/icons/premium-feed.svg" height="18" alt="premium" />
          )}
          <img
            src={content_type_icons[content.content_type]}
            className="content-type-icon"
            alt="content-type"
          />
          <img
            src={content.content_thumbnail_url || '/images/thumbnail-placeholder.jpg'}
            className="content_icon"
            alt="content-section"
          />
        </div>
        <div className="content_section_desc pl-3 d-flex flex-column w-100 position-relative">
          <div className="d-flex justify-content-between align-items-start">
            <h5 className="text-vertical-truncate line-2">{content.content_title}</h5>
            {owner && (
              <button className="btn py-0 pr-0" onClick={togglePopup} type="button">
                <img src="/icons/more.svg" alt="more" className="more-icon" />
              </button>
            )}
          </div>
          {open && (
            <div className="position-absolute border border-gt content-popover rounded">
              <button
                type="button"
                className="btn text-secondary btn-sm btn-block p-0 text-left"
                onClick={event => {
                  event.stopPropagation();
                  setModal(true);
                  setOpen(false);
                }}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn text-secondary btn-sm btn-block mt-0 p-0 text-left"
                onClick={editContent}
              >
                Edit
              </button>
              {((content && content.content_publish_status === 'PUBLISHED') ||
                (content && content.content_publish_status === 'UNPUBLISHED')) && (
                <button
                  type="button"
                  className="btn text-secondary btn-sm btn-block mt-0 p-0 text-left"
                  onClick={changeContentStatus}
                >
                  {content && content.content_publish_status === 'PUBLISHED'
                    ? 'Unpublish'
                    : 'Publish'}
                </button>
              )}
            </div>
          )}
          <p className="mb-0 line-md-3 line-2">{content.content_description}</p>
          <div className="content_section_footer d-flex justify-content-between p-0 mt-auto">
            <div className="d-flex justify-content-between w-100">
              <div className="content_rating">
                <i className="icon-star text-secondary display2" />
                <span>{(content.rating && content.rating.toFixed(1)) || 0}</span>
                <span className="content_footer_date text-secondary m-0">
                  {moment(content.content_created_at).format('DD MMM YYYY')}
                </span>
              </div>
              {content && content.content_publish_status === 'DRAFT' && (
                <p className="text-secondary display2 m-0 font-italic">Saved as Draft</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {DeleteConfirmationModal}
      {UnpublishModal}
      {
        <EditContent
          show={editModalShow}
          contentId={content._id}
          onHide={() => setEditModalShow(false)}
        />
      }
    </>
  );
};

ContentSection.propTypes = {
  content: PropTypes.shape({
    _id: PropTypes.string,
    content_type: PropTypes.string,
    content_thumbnail_url: PropTypes.string,
    content_title: PropTypes.string,
    content_description: PropTypes.string,
    content_publish_status: PropTypes.string,
    rating: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
  deleteContent: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  owner: PropTypes.bool,
};

ContentSection.defaultProps = {
  onClick: () => {},
  owner: false,
};

const mapDispatchToProps = {
  deleteContent,
  changeStatus,
  getContentForEdit,
};

export default connect(
  null,
  mapDispatchToProps
)(ContentSection);
