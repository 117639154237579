/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import './UserSettings.css';
import AddCreditCard from '../../common/AddCard/AddCreditCard';
import { getAllCreditCards } from '../../../store/actions/settingsActions';
import CreditCardDetails from '../../common/CreditCardDetails/CreditCardDetails';

class PaymentInfo extends React.Component {
  componentDidMount() {
    const { getAllCreditCards } = this.props;
    getAllCreditCards();
  }

  loadSkelton = () => {
    const { cards, creditCardLoader } = this.props;
    if (creditCardLoader) {
      if (cards.length) {
        return cards.map(card => (
          <div key={card.id} className="col pb-3">
            <div className="member-skelton shine w-100" />
          </div>
        ));
      }
      return (
        <div className="col pb-3">
          <div className="member-skelton shine w-100" />
        </div>
      );
    }
    return null;
  };

  render() {
    const { cards, creditCardLoader } = this.props;
    return (
      <div id="payment-info-settings">
        <h1 className="saved-cards">Saved Cards</h1>
        <p className="cards-description">Manage your Debit/Credit card for easier transactions.</p>
        {this.loadSkelton()}
        {!creditCardLoader &&
          cards.map((card, index) => (
            <CreditCardDetails settings index={index} key={card.id} card={card} />
          ))}
        {cards.length <= 9 ? (
          <AddCreditCard />
        ) : (
          <p className="text-secondary font-italic mt-4">
            Maximum cards added. Remove unused/expired card to add a new card
          </p>
        )}
      </div>
    );
  }
}

PaymentInfo.propTypes = {
  getAllCreditCards: PropTypes.func.isRequired,
  creditCardLoader: PropTypes.bool.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  cards: state.settings.cards,
  openAddCard: state.settings.openAddCard,
  creditCardLoader: state.payment.creditCardLoader,
});

export default compose(
  connect(
    mapStateToProps,
    { getAllCreditCards }
  )
)(PaymentInfo);
