import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="container load">
      <div className="row vh-100 justify-content-center">
        <div className="col-10 col-md-7 d-flex flex-column align-items-center justify-content-center ">
          <img src="/images/not-found.svg" alt="not found" width="100%" />
          <h3 className="text-uppercase pt-5 text-center font-weight-bold">page not found</h3>
          <p className="text-black-50 text-center pt-1">
            The page you are looking for doesn't exist, or an error occurred.
            <div>Go back to explore lots of other great stuff!</div>
          </p>
          <Link to="/" className="btn gt-btn mt-5">
            Back to home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
