/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './PractitionerReview.css';
import ReviewCard from './ReviewCard/ReviewCard';

const PractitionerReview = ({ reviews }) => {
  let ReviewsList = reviews.map(review => <ReviewCard key={review._id} review={review} />);
  if (ReviewsList.length === 0) {
    ReviewsList = (
      <div className="px-3 py-5 text-center">
        <p className="mb-0 font-medium">No Reviews Yet.</p>
      </div>
    );
  }
  return (
    <div className="prac_review mb-4">
      <div className="prac_review_header border-bottom border-gt">
        <h6 className="p-0 m-0">User Reviews</h6>
      </div>
      <div className="card_block">{ReviewsList}</div>
      {/* <div className="review_load border-top py-2">load more</div> */}
    </div>
  );
};

PractitionerReview.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  reviews: state.practitionerReg.reviews,
});

export default connect(mapStateToProps)(PractitionerReview);
