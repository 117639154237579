/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectionCard from '../shared/Cards/SelectionCard/SelectionCard';
import ModalComponent from '../shared/Modal/Modal';
import {
  clearContentFile,
  setPublishPage,
  getCategoriesList,
  setContent,
} from '../../store/actions/publishActions';
import './Publish.css';
import PublishContentForm from './PublishContentForm';
import ContentPublished from './ContentPublished';

const selectionTypes = [
  {
    img_url: '/images/article.svg',
    type: 'Article',
    description: 'Make your writings robust by uploading images or embedded media in our editor.',
  },
  {
    img_url: '/images/Audio.svg',
    type: 'Audio',
    description: 'Mp3, Wav, aac files are supported for uploading the audio contents.',
  },
  {
    img_url: '/images/video.svg',
    type: 'Video',
    description: 'AVI, MPG, MP4, MOV files are supported for uploading the video contents.',
  },
];

class Publish extends Component {
  constructor(...args) {
    super(...args);
    this.attachRef = target => this.setState({ target });
    this.state = {
      publishType: 'none',
      showTooltip: false,
      modalOpen: false,
    };
  }

  componentDidMount() {
    const { getCategoriesList, dispatch } = this.props;
    getCategoriesList(dispatch);
  }

  renderSelectionCard = () => {
    const selectionTypess = selectionTypes.map(cardtype => (
      <div
        key={cardtype.type}
        className="col p-3"
        onClick={() => this.onChooseContentType(cardtype.type)}
        role="presentation"
      >
        <SelectionCard selectionType={cardtype} />
      </div>
    ));
    return selectionTypess;
  };

  // eslint-disable-next-line consistent-return
  activePage = () => {
    const { pages } = this.props;
    const page = pages.find(page => page.status === 'progress');
    const { publishType } = this.state;
    switch (page.step) {
      case 0:
        return (
          <div>
            <div className="text-center p-3">
              <div className="d-flex justify-content-center">
                <p className="head mb-0">Publish Your Content</p>
              </div>
              <span className="description">
                You'll need to choose whether your content is Free, Premium, or Exclusive.
                <br />
                Find out about your choices here!
              </span>

              <OverlayTrigger
                trigger={['click']}
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-content-info" pos>
                    <div className="p-2">
                      <p className="text-left mb-2">
                        <strong>Free Content</strong> is any type of media that can be accessed
                        freely by anybody, anywhere, anytime. These generally include articles,
                        podcasts, informal video talks, etc.)
                      </p>
                      <p className="text-left mb-2">
                        <strong>Premium Content</strong> is typically inexpensive and relatively
                        quick (special worksheets, special guided meditations, etc.). It can be
                        accessed by purchasing access individually, and also through a Premium
                        Account. Despite having an inexpensive price tag, its wide accessibility
                        increases its chances of being seen and highly rated by all Premium Users,
                        thereby potentially earning you more money and more exposure.
                      </p>
                      <p className="text-left mb-2">
                        <strong>Exclusive Content</strong> is typically more expensive and in-depth
                        (e-books, webinars, video classes, etc.). It can only be accessed when a
                        user purchases it individually. These items are not accessible freely to
                        Premium Users.
                      </p>
                    </div>
                  </Tooltip>
                }
                rootClose>
                <button
                  type="button"
                  className="btn info-btn-publish"
                  style={{ padding: 0, lineHeight: 1 }}
                >
                  <img className="ml-2 mb-1 rotate" height="16" src="/icons/info.svg" alt="info" />
                </button>
              </OverlayTrigger>
            </div>
            <div className="row" style={{ padding: '1rem 1.5rem 0 1.5rem' }}>
              {this.renderSelectionCard()}
            </div>
            <div className="footer text-center pb-2">
              <p className="mb-0">
                By proceeding, you acknowledge your content contains no copyrighted material,
                plagiarism, and that you have the exclusive right to publish this content in
                accordance to the <span><Link to="/community-and-content-guidelines" target="_blank">
                Community and Content Guidelines</Link></span>.
              </p>
            </div>
          </div>
        );
      case 1:
        return (
          <PublishContentForm
            type={publishType}
            onCancel={this.backHandler}
            clearState={this.clearState}
          />
        );
      case 2:
        return <ContentPublished clearState={this.clearState} />;
      default:
        break;
    }
  };

  onHideModal = () => {
    const { dispatch } = this.props;
    this.setState({ modalOpen: false });
    this.setState({ publishType: 'none' });
    dispatch(clearContentFile());
    dispatch(setPublishPage(0));
  };

  onChooseContentType = type => {
    const { dispatch } = this.props;
    this.setState({ publishType: type });
    dispatch(setContent(''));
    dispatch(setPublishPage(1));
  };

  backHandler = () => {
    const { dispatch } = this.props;
    this.setState({ publishType: 'none' });
    dispatch(clearContentFile());
    dispatch(setPublishPage(0));
  };

  clearState = () => {
    const { dispatch } = this.props;
    this.setState({ publishType: 'none', modalOpen: false });
    dispatch(clearContentFile());
    dispatch(setPublishPage(0));
  };

  render() {
    const { publishType, modalOpen } = this.state;
    let title;
    if (publishType === 'none') {
      title = 'Publish';
    } else {
      title = (
        <div className="d-flex">
          <div className="pr-4">
            <img
              className="mb-1"
              src="/icons/back-arrow.svg"
              alt="arrow"
              style={{ cursor: 'pointer' }}
              onClick={this.backHandler}
              role="presentation"
            />
          </div>
          <div className="mt-2 mb-2" style={{ width: '2px', background: '#eaedf3' }} />
          <span className="pl-4">{publishType}</span>
        </div>
      );
    }

    return (
      <div>
        <button
          type="button"
          className="btn btn-sm gt-btn mr-lg-5"
          onClick={() => this.setState({ modalOpen: !modalOpen })}
        >
          Publish Content
        </button>
        <ModalComponent
          className="publish-modal"
          show={modalOpen}
          title={title}
          onHide={this.onHideModal}
        >
          {this.activePage()}
        </ModalComponent>
      </div>
    );
  }
}

Publish.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getCategoriesList: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pages: state.publish.pages,
});

const mapDispatchToProps = dispatch => ({
  getCategoriesList,
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Publish);
