/* eslint-disable no-case-declarations */
import {
  SET_ASSESSMENT_QUESTIONS,
  SET_ASSESSMENT_ALL_ANSWERS,
  SET_ASSESSMENT_ANSWERS,
  SET_ASSESSMENT_PROGESS,
  SET_ASSESSMENT_USER,
  SET_ASSESSMENT_PAGE_PROGRESS,
  SET_ASSESSEMENT_RESULT,
  SET_ASSESSMENT_ERROR,
  ANSWERING,
  SET_ASSESSMENT_PAGE_ACTIVE,
  SET_PROGRESS_CHECK_ACTIVE,
  SET_PROGRESS_CHECK_ANSWER,
  GET_PROGRESS_CHECK_QUESTIONS,
  SET_PROGRESS_CHECK_LAST_DATE,
  SET_RECOMMENDED_CONTENT,
  SET_RECOMMENDED_PRACT,
} from '../actions/types';

const initialState = {
  questions: [],
  answers: [],
  assessment_user: {},
  questioned: '',
  progress: 0,
  isAnswering: false,
  result: null,
  error: null,
  pages: [
    {
      step: 1,
      status: 'progress',
    },
    {
      step: 2,
      status: 'waiting',
    },
    {
      step: 3,
      status: 'waiting',
    },
    {
      step: 4,
      status: 'waiting',
    },
    {
      step: 5,
      status: 'waiting',
    },
  ],
  pageProgress: {
    Welcome: 'progress',
    UserBasicForm: 'waiting',
    UserUpdateForm: 'waiting',
    Intrest: 'waiting',
    Questions: 'waiting',
  },
  progressCheckPage: {
    status: 'welcome',
  },
  progressCheckQuestions: [],
  lastCheckIn: null,
  recommendedContents: [],
  recommendedPract: [],
};

const setPageActive = (state, step) => {
  return state.pages.map(page =>
    page.step === step ? { ...page, status: 'progress' } : { ...page, status: 'waiting' }
  );
};

const setAnswer = (state, ans) => {
  const answers = state.answers.slice();
  const idx = answers.findIndex(a => a.question_id === ans.question_id);
  if (idx > -1) {
    answers[idx] = ans;
  } else {
    answers.push(ans);
  }
  return answers;
};

const calculateProgress = state => {
  if (!state.questions.length || !state.answers.length) return 0;
  return ((state.answers.length * 100) / state.questions.length).toFixed(0);
};

const assesmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSESSMENT_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    case SET_ASSESSMENT_ALL_ANSWERS:
      return {
        ...state,
        answers: action.payload,
      };
    case SET_ASSESSMENT_PAGE_ACTIVE:
      return {
        ...state,
        pages: setPageActive(state, action.payload),
      };
    case SET_ASSESSMENT_ANSWERS:
      return {
        ...state,
        questioned: action.payload.question_id,
        isAnswering: false,
        answers: setAnswer(state, action.payload),
      };
    case SET_ASSESSMENT_PROGESS:
      return {
        ...state,
        progress: calculateProgress(state),
      };
    case SET_ASSESSMENT_USER:
      return {
        ...state,
        assessment_user: action.payload,
      };
    case SET_ASSESSMENT_PAGE_PROGRESS:
      return {
        ...state,
        pageProgress: action.payload,
      };
    case SET_ASSESSMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ANSWERING:
      return {
        ...state,
        isAnswering: action.payload,
      };
    case SET_ASSESSEMENT_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    case SET_PROGRESS_CHECK_ACTIVE:
      return {
        ...state,
        progressCheckPage: action.payload,
      };
    case SET_PROGRESS_CHECK_ANSWER:
      const { index, selected } = action.payload;
      const progressCheckQuestions = state.progressCheckQuestions.slice();
      progressCheckQuestions[index].answer = selected;
      return {
        ...state,
        progressCheckQuestions,
      };
    case GET_PROGRESS_CHECK_QUESTIONS:
      return {
        ...state,
        progressCheckQuestions: action.payload,
      };
    case SET_PROGRESS_CHECK_LAST_DATE:
      return {
        ...state,
        lastCheckIn: action.payload,
      };
    case SET_RECOMMENDED_CONTENT:
      return {
        ...state,
        recommendedContents: action.payload,
      };
    case SET_RECOMMENDED_PRACT:
      return {
        ...state,
        recommendedPract: action.payload,
      };
    default:
      return state;
  }
};

export default assesmentReducer;
