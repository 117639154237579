/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import * as metaData from '../../../data/meta.json';
import SelectInput from '../../common/SelectInput/SelectInput';
// eslint-disable-next-line import/no-named-as-default
import MultiSearchDropDown from '../../common/MultiSearchDropDown/MultiSearchDropDown';
import { getMemberIntrestList } from '../../../store/actions/appActions.js';

const MemberProfileSettings = ({ categories, getMemberIntrestList }) => {
  useEffect(() => {
    getMemberIntrestList();
  }, []);
  return (
    <div className="professional-services">
      <div className="row">
        <div className="col-md-6 mb-4">
          <label className="sub-title">RELATIONSHIP STATUS</label>
          <Field name="c_relationship_status" component={SelectInput}>
            <option value="">Relationship Status</option>
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="divorced">Divorced</option>
            <option value="other">Other</option>
          </Field>
        </div>
        <div className="col-md-6 mb-4">
          <label className="sub-title">SEXUAL ORIENTATION</label>
          <Field name="c_sexual_orientation" component={SelectInput}>
            <option value="">Sexual Orientation</option>
            {metaData.default.sexualOrientation.map(so => (
              <option key={so} value={so.toLowerCase()}>
                {so}
              </option>
            ))}
          </Field>
        </div>
        <div className="col-12 mb-4">
          <label className="sub-title">AREA OF INTEREST</label>
          <Field
            name="c_intrests"
            component={prop => (
              <MultiSearchDropDown
                placeholder="Select your area of interest"
                options={categories}
                varient="primary"
                other
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

MemberProfileSettings.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  getMemberIntrestList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  categories: state.app.interest_list,
});

export default connect(
  mapStateToProps,
  { getMemberIntrestList }
)(MemberProfileSettings);
