import React from 'react';
import './PageLoader.css';
import PropTypes from 'prop-types';

const PageLoader = ({ className }) => (
  <div className={['lds-ripple', className].join(' ')}>
    <div />
    <div />
  </div>
);

PageLoader.propTypes = {
  className: PropTypes.string.isRequired,
};
export default PageLoader;
