/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import './ContentTab.css';
import ContentSection from '../../Practitioner/PractitionerProfile/PractitionerContent/ContentSection/ContentSection';

const ContentTab = ({ className, title, purchased, bookmarked, history }) => {
  const viewContent = contendId => history.push(`/content/${contendId}`);

  let PurchasedContentList = purchased.map(content => (
    <ContentSection
      key={content._id}
      content={content.purchased_content_id}
      onClick={() => viewContent(content.purchased_content_id._id)}
    />
  ));
  if (PurchasedContentList.length === 0) {
    PurchasedContentList = (
      <div className="text-center py-5">
        <img className="mb-3" src="/images/no-saved.png" alt="No Purchased" />
        <p className="font-medium mb-1">No Purchased Items Yet</p>
        <p className="display2 text-secondary">
          Explore the regularly updated library of resources! Find articles, audio, and video to
          enjoy!
        </p>
        <Link to="/user-feeds" className="btn gt-btn">
          Find Resources
        </Link>
      </div>
    );
  }

  let BookmarkedContentList = bookmarked.map(content => (
    <ContentSection
      key={content._id}
      content={content.saved_content}
      onClick={() => viewContent(content.saved_content._id)}
    />
  ));
  if (BookmarkedContentList.length === 0) {
    BookmarkedContentList = (
      <div className="text-center py-5">
        <img className="mb-3" src="/images/bookmark-empty.svg" alt="No Bookmarks" />
        <p className="font-medium mb-1">No Saved Items Yet</p>
        <p className="display2 text-secondary">
          Explore the regularly updated library of resources! Find articles, audio, and video to
          enjoy!
        </p>
        <Link to="/user-feeds" className="btn gt-btn">
          Find Resources
        </Link>
      </div>
    );
  }

  return (
    <div className={['content-tab-card', className].join(' ')}>
      <h3 className="title pt-3 pl-3">{title}</h3>
      <Tabs defaultActiveKey="bookmarked" id="content-tab">
        <Tab eventKey="bookmarked" title="Bookmarked">
          <div className="p-3 saved-content-tab">{BookmarkedContentList}</div>
        </Tab>
        <Tab eventKey="purchased" title="Purchased">
          <div className="p-3 saved-content-tab">{PurchasedContentList}</div>
        </Tab>
      </Tabs>
    </div>
  );
};

ContentTab.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  purchased: PropTypes.arrayOf(PropTypes.object),
  bookmarked: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
ContentTab.defaultProps = {
  className: '',
  purchased: [],
  bookmarked: [],
};

const mapStateToProps = state => ({
  purchased: state.member.purchasedContents,
  bookmarked: state.member.bookmarkedContents,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContentTab)
);
