/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from '../../../common/Input/Input';
// import TextArea from '../../../common/TextArea/TextArea';
import GTDatePicker from '../../../common/DatePicker/DatePicker';
import './PractitionerProfileSettings.css';
import { getAllMetaData } from '../../../../store/actions/appActions';
import { deleteUserAccount } from '../../../../store/actions/settingsActions';
import SelectInput from '../../../common/SelectInput/SelectInput';
import ModalComponent from '../../../shared/Modal/Modal';
import { logoutUser } from '../../../../store/actions/authActions';
// import MultiSearchDropDown from '../../../common/MultiSearchDropDown/MultiSearchDropDown';

class PractitionerProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    const { getAllMetaData } = this.props;
    getAllMetaData();
  }

  deleteAccountHandler = () => {
    const { deleteUserAccount, logoutUser, history } = this.props;
    deleteUserAccount({ deleteAccount: 0 }, err => {
      if (!err) {
        this.setState({ show: false });
        logoutUser();
        history.push('/');
      }
    });
  };

  render() {
    const { show } = this.state;

    const DeleteConfirmationModal = (
      <ModalComponent
        size="small"
        onHide={() => this.setState({ show: false })}
        show={show}
        title="Delete Content"
        noHeader
      >
        <div className="d-flex flex-column p-2">
          <>
            <div className="d-flex align-items-center">
              <img src="/icons/delete.svg" alt="delete" />
              <div className="d-flex flex-column align-items-baseline pl-3 ml-2">
                <h5 className="text-center font-weight-bold mb-0">Are you sure ?</h5>
                <p className="text-black-50 mb-0">This action will be permanently remove account</p>
              </div>
            </div>
            <div className="d-flex pt-4 justify-content-end">
              <button
                type="submit"
                onClick={() => this.setState({ show: false })}
                className="btn p-2 btn-outline-white px-4 font-weight-normal"
              >
                No
              </button>
              <button
                type="submit"
                onClick={this.deleteAccountHandler}
                className="btn ml-3  btn-signup p-2 px-4 font-weight-normal"
              >
                Yes
              </button>
            </div>
          </>
        </div>
      </ModalComponent>
    );
    return (
      <div className="mt-3">
        {DeleteConfirmationModal}
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <label className="sub-title">FIRST NAME</label>
            <Field name="u_first_name" placeholder="First Name" component={Input} />
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label className="sub-title">LAST NAME</label>
            <Field name="u_last_name" placeholder="Last Name" component={Input} />
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label className="sub-title">GENDER</label>
            <Field name="u_gender" placeholder="Gender" component={SelectInput}>
              <option value="" disabled>
                Select Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="non-binary">Non-Binary</option>
              <option value="other">Other</option>
            </Field>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label className="sub-title">BIRTH DAY</label>
            <Field
              name="u_dob"
              component={props => (
                <GTDatePicker
                  {...props}
                  // selected={props.input.value}
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                  dateFormat="dd MMM yyyy"
                  className="form-control gt-form-control"
                  placeholderText="Date of Birth"
                />
              )}
            />
          </div>
          <button
            type="button"
            onClick={() => this.setState({ show: true })}
            className="btn outline delete-account-button ml-3"
          >
            <img src="/icons/deleteIcon.svg" className="type-icon" alt="deleteIcon" />
            <span className="delete-button-title">DELETE MY ACCOUNT</span>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.app.categories,
    // specialities: state.app,
  };
};

const mapDispatchToProps = {
  getAllMetaData,
  deleteUserAccount,
  logoutUser,
};

PractitionerProfileForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getAllMetaData: PropTypes.func.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PractitionerProfileForm)
);
