/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */

import React from 'react';
import './FaceEmoji.css';
import PropTypes from 'prop-types';

const FaceEmoji = ({ text, src, showTick }) => {
  return (
    <div>
      <div className="face-container p-3 mx-auto">
        {showTick && <img className="tick" src="/icons/emoji-tick.svg" alt="tick" />}
        {src.length ? (
          <img src={src} alt={text} className="face-dimension" />
        ) : (
          <div className="face-dimension" />
        )}
      </div>
      <p className="text-center font-weight-light mt-1 face-content">{text}</p>
    </div>
  );
};

FaceEmoji.propTypes = {
  text: PropTypes.number,
  src: PropTypes.string,
  showTick: PropTypes.bool,
};
FaceEmoji.defaultProps = {
  text: 0,
  src: '',
  showTick: false,
};

export default FaceEmoji;
