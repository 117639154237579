/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './MatInput.css';

const MatInput = ({ input, meta, ...rest }) => {
  return (
    <div>
      <input {...input} {...rest} className="form-control gtm-input" />
      {meta.error && meta.touched && (
        <span className="invalid-feedback gtm-invalid-feedback d-block">{meta.error}</span>
      )}
    </div>
  );
};

MatInput.propTypes = {
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};
MatInput.defaultProps = {
  input: null,
  meta: null,
};

export default MatInput;
