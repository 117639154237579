/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import Input from '../../common/Input/Input';
import LinkedAccounts from '../PractitionerSettings/LinkedAccounts';
import { getProfile, isUsernameAvailable } from '../../../store/actions/authActions';
import { changePassword } from '../../../store/actions/settingsActions';
import './UserSettings.css';
import UsernameUpdateForm from './UsernameUpdateForm';

const validators = values => {
  const errors = {};
  if (!values.current_password) {
    errors.current_password = 'This field is required';
  }
  if (!values.new_password) {
    errors.new_password = 'This field is required';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'This field is required';
  } else if (values.new_password !== values.confirm_password) {
    errors.confirm_password = 'Password does not match';
  }
  return errors;
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updatePassword = values => {
    const { current_password, confirm_password } = values;
    const { changePassword } = this.props;
    changePassword(current_password, confirm_password);
  };

  render() {
    const { handleSubmit, user } = this.props;
    return (
      <div>
        <div className="user-info">
          <div className="row">
            <div className="col-md-6 col-12">
              <UsernameUpdateForm />
            </div>
          </div>
        </div>
        {user && !user.socialAuthenticated && (
          <div className="general-settings">
            <form onSubmit={handleSubmit(this.updatePassword)}>
              <div className="row">
                <div className="col-md-6 col-12">
                  <h1 className="change-password">Change Password</h1>
                  <div className="mb-3">
                    <label className="subtitle">Current Password</label>
                    <Field
                      name="current_password"
                      type="password"
                      placeholder="Current password"
                      component={Input}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="subtitle">New Password</label>
                    <Field
                      name="new_password"
                      type="password"
                      placeholder="New password"
                      component={Input}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="subtitle">Confirm Password</label>
                    <Field
                      name="confirm_password"
                      type="password"
                      placeholder="Confirm password"
                      component={Input}
                    />
                  </div>
                  <button type="submit" className="btn gt-btn mt-4">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        {user && user.u_prac_profile_completion_status && <LinkedAccounts />}
      </div>
    );
  }
}

const mapDispatchToProps = {
  changePassword,
  getProfile,
  isUsernameAvailable,
};

const mapStateToProps = state => {
  const user = state.auth.currentUser;
  return {
    user,
    passwordUpdated: state.auth.passwordUpdated,
    profile: state.auth.profile,
  };
};

ChangePassword.propTypes = {
  user: PropTypes.shape({
    socialAuthenticated: PropTypes.bool,
    u_prac_profile_completion_status: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func,
  changePassword: PropTypes.func.isRequired,
};

ChangePassword.defaultProps = {
  user: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'changepassword',
    enableReinitialize: true,
    validate: validators,
  })(ChangePassword)
);
