/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import InputMask from 'react-input-mask';
import { injectStripe } from 'react-stripe-elements';
import Button from '../Button/Button';
import './AddCreditCard.css';
import { updateCard } from '../../../store/actions/paymentActions';

class UpdateCardDetails extends Component {
  constructor() {
    super();
    this.state = {
      holdersName: '',
      expiryDate: '',
      msg: '',
      nameError: false,
      dateError: false,
    };
  }

  componentDidMount = () => {
    const { card } = this.props;
    if (card.exp_month.toString().length === 1) {
      card.exp_month = `0${card.exp_month.toString()}`;
    }
    card.exp_month = card.exp_month.toString();
    const expiry = `${card.exp_month}/${card.exp_year.toString().slice(2)}`;
    this.setState({ holdersName: card.name, expiryDate: expiry });
  };

  onSubmit = async () => {
    const { updateCard, card } = this.props;
    const { holdersName, expiryDate } = this.state;
    try {
      const year = new Date()
        .getFullYear()
        .toString()
        .slice(-2);
      const expiry = expiryDate.split('/');
      if (!holdersName.length) {
        this.setState({ msg: 'Cardholder name is required.', nameError: true });
        return false;
      }
      if (parseInt(expiry[0], 10) < 13 && parseInt(expiry[1], 10) >= parseInt(year, 10)) {
        const expiryMonth = parseInt(expiry[0], 10);
        const expiryYear = 2000 + parseInt(expiry[1], 10);
        updateCard(card, expiryMonth, expiryYear, holdersName);
        return false;
      }
      this.setState({ msg: 'Invalid Expiry Date', dateError: true });
      return true;
    } catch (error) {
      this.setState({ msg: 'An error occured with payment.', dateError: true });
      return false;
    }
  };

  onChangeText = e => {
    const word = e.target.value;
    if (e.target.id === 'expiryDate') {
      this.setState({ dateError: false });
    } else {
      this.setState({ nameError: false });
    }
    this.setState({ [e.target.id]: word });
  };

  render() {
    const { nameError, dateError, msg, holdersName, expiryDate } = this.state;
    const { loading, card } = this.props;
    return (
      <div className="detail-container">
        <div className="row pt-5">
          <div className="col-md-6 col-12">
            <p className="card-label text-uppercase mb-0 mb-1">Enter card no</p>
            <input
              disabled
              value={`XXXX XXXX XXXX ${card.last4}`}
              className="card-field p-2 w-100 "
              placeholder="ABC bank"
            />
          </div>
          <div className="col-md-3 col-6 pl-md-0 mt-md-0 mt-4">
            <p className="card-label text-uppercase mb-0 mb-1">Valid Thru</p>
            <InputMask
              id="expiryDate"
              className={`card-field p-2 w-100 ${dateError && 'border-danger'}`}
              mask="99/99"
              maskplaceholder="m/yy"
              value={expiryDate}
              onChange={this.onChangeText}
            />
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-md-6 col-12">
            <p className="card-label text-uppercase mb-0 mb-1">Name on the Card</p>
            <input
              id="holdersName"
              onChange={this.onChangeText}
              onKeyDown={this.onChangeText}
              value={holdersName}
              className={`card-field p-2 w-100 ${nameError && 'border-danger'}`}
              placeholder="Your Name"
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-lg-3 col-md-5 col">
            <Button disabled={loading} onClick={this.onSubmit}>
              Update this card
            </Button>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col">
            <p
              className={`card-label font-weight-normal pl-1 ${
                nameError || dateError ? 'text-danger' : 'text-body'
              }`}
            >
              {nameError || dateError
                ? msg
                : 'Your card details would be securely saved for faster payments. Your CVV will not be stored'}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

UpdateCardDetails.propTypes = {
  loading: PropTypes.bool.isRequired,
  card: PropTypes.shape({
    last4: PropTypes.string,
    exp_month: PropTypes.number,
    exp_year: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  updateCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: state.app.loading,
  clearPaymentForm: state.payment.clearPaymentForm,
});

export default injectStripe(
  compose(
    connect(
      mapStateToProps,
      { updateCard }
    )
  )(UpdateCardDetails)
);
