import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { isAuthenticated } from './services/auth.service';

const isAuthenticated = (onlyPractitioner = false) => {
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  if(onlyPractitioner){
    return token && user && user.u_type === 'practitioner';
  }
    return !!token
  
};

const PrivateRoute = ({ component: Component, onlyPractitioner, ...rest }) => {
  // const token = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated(onlyPractitioner) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
