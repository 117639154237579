import styled from 'styled-components';

export const Container = styled.div`
  background: #f2f4f8;
  `;

export const Title = styled.h2`
  display: ${props => (props.active ? 'none' : 'block')};
  padding-top: 0.5rem;
  font-size: 1.75rem;
  font-weight: bold;
`;

export const AddUser = styled.button`
  display: ${props => (props.active ? 'none' : 'block')};
`;

export const Main = styled.main`
  height: calc(100vh - 64px);
`;

export const Users = styled.div`
  display: ${props => (props.active ? 'none' : 'block')};
  height: ${props => (props.active && '79vh' )};
  border-right: solid 0.06rem #eaedf3;
`;

export const Chat = styled.div`
  flex: 70%;
`;

export const InputBox = styled.div`
  background: #ffffff;
  /* padding: 1.25rem; */
  height: 5.4375rem;
  border-top: solid 0.06rem #eaedf3;
`;

export const ChatBox = styled.div`
  height: 68vh;
  padding-top : 0.75rem;
`;

export const Button = styled.button`
  margin-right: 1.5rem;
  width: 3.0625rem;
  height: 2.5rem;
  border-radius: 0.1875rem;
  border: 1px solid #fff;
  background-color: #d4af37;
  &:focus {
    outline: 0;
  }
`;

export const Input = styled.textarea`
  /* padding-top: 0.625rem; */
  resize: none;
  border: 0;
  outline: 0px !important;
  box-shadow: none !important;
`;

export const InputSearch = styled.input`
  border-radius: 0.1875rem;
  /* margin: 0.625rem; */
  /* padding: 0.625rem; */
  outline: 0 none;
  /* border: 0; */
  box-shadow: none; /* You may want to include this as bootstrap applies these styles too */

  border: 0.0625rem solid #eaedf3 !important;
`;

export const ModalBody = styled.div`
  width: 300px !important;
  background: red;
`;

export const Name = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #d4af37;
  &:hover {
    text-decoration: underline #d4af37;
  }
`;