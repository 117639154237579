/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './ReviewCard.css';
import RatingComponent from '../../../../../common/Rating/Rating';

const ReviewCard = ({ review }) => {
  const {
    reviewed_by: { u_first_name, u_last_name = '', u_image_url },
    review_created_at,
    rating,
  } = review;
  const userImage = u_image_url || '/images/user.svg';
  return (
    <div className="prac_review_card">
      <div className="d-flex p-3 justify-content-between">
        <div className="review_card_left d-flex">
          <div className="reviewer_icon">
            <img src={userImage} alt="reviewer_icon" />
          </div>
          <div className="reviewer_details ml-2">
            <h6 className="mb-0 text-capitalize">
              {u_first_name} {u_last_name || ''}
            </h6>
            <div className="reviewer_details_bottom d-flex">
              <p className="published_date">{moment(review_created_at).fromNow()}</p>
            </div>
          </div>
        </div>
        <div className="review_card_left">
          <RatingComponent height="11px" width="11px" initialRating={rating} readonly />
        </div>
      </div>
      <div className="prac_review_body">
        <p className="mx-3 pb-3 prac_review_content">{review.review}</p>
      </div>
    </div>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.shape({
    reviewed_by: PropTypes.shape({
      u_first_name: PropTypes.string,
      u_last_name: PropTypes.string,
      u_image_url: PropTypes.string,
    }),
    review_created_at: PropTypes.string,
    rating: PropTypes.number,
  }).isRequired,
};

export default ReviewCard;
