/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Talktext, Talkbubble, MeAvatar, ChatBubble,Texttime } from './style';
import moment from 'moment';

const Me = ({ message, u_image_url, time }) => {
  const profileImage = u_image_url || '/images/user.svg';
  return (
    <>
      <ChatBubble>
        <div className="col">
          <div className="row  align-items-center justify-content-end">
            <Talkbubble me>
              <Talktext>{message}</Talktext>
            </Talkbubble>
            <div>
              <MeAvatar src={profileImage} className="rounded-circle" />
              <Texttime me>{moment(new Date(time)).format('hh:mm A')} </Texttime>
            </div>
          </div>
        </div>
      </ChatBubble>
    </>
  );
};
Me.propTypes = {
  message: PropTypes.string.isRequired,
  u_image_url: PropTypes.string,
};
Me.defaultProps = {
  u_image_url: '',
};
export default Me;
