/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SearchDropDown from '../../../../common/SearchDropDown/SearchDropDown';
import MatInput from '../../../../common/MatInput/MatInput';
import Input from '../../../../common/Input/Input';

const BestOwedInstitutions = ({ fields, universities, meta, varient, input }) => {
  if (!fields.length) fields.push({});
  const InputControl = varient === 'primary' ? Input : MatInput;

  const normalizeYears= value => {
    let onlyNums = value.replace(/[^0-9]/, '')
    return onlyNums.slice(0,4)
  }
  universities = universities.filter(university => university.label!==undefined);
  
  return (
    <div className="mt-3" name={fields && fields.name}>
      <div className="row">
        {fields.map((institution, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <div className="col-md-7 col-12 mb-3">
              <Field
                name={`${institution}.institution`}
                component={prop => (
                  <SearchDropDown
                    value={prop.input.value}
                    onSelect={value => prop.input.onChange(value)}
                    placeholder="Select Institution"
                    options={universities}
                    varient={varient}
                    other
                    {...prop}
                  />
                )}
              />
            </div>
            <div className="col-md-3 col-12 mb-3">
              <Field
                name={`${institution}.year`}
                placeholder="Issued Year"
                normalize={normalizeYears}
                component={InputControl}
              />
            </div>
            {fields.length > 1 && (
              <div className="col-md-2 mb-4 d-flex">
                <button
                  type="button"
                  className="btn btn-sm mt-3 btn-white w-100 cert-del-btn"
                  onClick={event => {
                    event.preventDefault();
                    fields.remove(index);
                  }}
                >               
                    <i
                      className={[
                        'icon-trash',
                        varient === 'primary' ? 'text-secondary' : 'text-light',
                      ].join(' ')}
                      style={{ fontSize: '1rem' }}
                    />
                </button>
              </div>
            )}
          </Fragment>
        ))}
      </div>
      <button
        className={['btn mt-3', varient === 'primary' ? 'gt-btn' : 'btn-light'].join(' ')}
        type="button"
        onClick={event => {
          event.preventDefault();
          fields.push({});
        }}
      >
        Add
      </button>
    </div>
  );
};

BestOwedInstitutions.propTypes = {
  fields: PropTypes.arrayOf({
    remove: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  universities: PropTypes.arrayOf(PropTypes.object).isRequired,
  varient: PropTypes.string,
};

BestOwedInstitutions.defaultProps = {
  varient: '',
};

const mapStateToProp = state => {
  return {
    universities: state.app.universities,
  };
};

export default connect(mapStateToProp)(BestOwedInstitutions);
