/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './ProgressCheckQuestionForm.css';
import { animateScroll as scroller } from 'react-scroll';
import PropTypes from 'prop-types';
import {
  startAssessment,
  setProgressCheckAnswer,
  setProgressCheckPage,
  calculateProgressCheckResults,
} from '../../../../store/actions/assessmentActions';
import FaceEmoji from '../../../common/FaceEmoji/FaceEmoji';

const faces = [
  { value: 0, src: '/images/Poor.png' },
  { value: 1, src: '' },
  { value: 2, src: '/images/Fair.png' },
  { value: 3, src: '' },
  { value: 4, src: '/images/no-change.png' },
  { value: 5, src: '' },
  { value: 6, src: '/images/Good.png' },
  { value: 7, src: '' },
  { value: 8, src: '/images/very-good.png' },
  { value: 9, src: '' },
  { value: 10, src: '/images/Excellent.png' },
];

class ProgressCheckQuestionForm extends Component {
  constructor(props) {
    super(props);
    this.formElem = React.createRef();
  }

  componentDidMount() {
    const { questions } = this.props;
    if (
      this.formElem.current &&
      this.formElem.current.childNodes &&
      this.formElem.current.childNodes.length
    ) {
      Array.from(this.formElem.current.childNodes)[0].classList.add('active');
    }
    const questionSet = questions.filter(question => question.answer === false);
    if (questionSet.length) this.gotoQuestion(questionSet[0]._id);
  }

  nextHandler = () => {
    const nodes = Array.from(this.formElem.current.childNodes).slice();
    const nodeIndex = nodes.findIndex(node => node.classList.contains('active'));
    if (nodeIndex > -1 && nodeIndex + 1 < nodes.length) {
      const nextNode = nodes[nodeIndex + 1];
      scroller.scrollTo(nextNode.offsetTop - 200, {
        duration: 300,
        delay: 0,
        smooth: true,
        containerId: 'ContainerScroll',
      });
    }
  };

  backHandler = () => {
    const nodes = Array.from(this.formElem.current.childNodes);
    const nodeIndex = nodes.findIndex(node => node.classList.contains('active'));
    if (nodeIndex > -1 && nodeIndex - 1 > 0) {
      const prevNode = nodes[nodeIndex - 1];
      scroller.scrollTo(prevNode.offsetTop - 200, {
        duration: 300,
        delay: 0,
        smooth: true,
        containerId: 'ContainerScroll',
      });
    }
  };

  gotoQuestion = questionId => {
    const nodes = Array.from(this.formElem.current.childNodes);
    const question = nodes.find(node => node.id === questionId);
    scroller.scrollTo(question.offsetTop - 200, {
      duration: 1000,
      delay: 500,
      smooth: true,
      containerId: 'ContainerScroll',
    });
  };

  getRemainingAnswer = () => {
    const { questions } = this.props;
    const questionLength = questions.filter(question => question.answer === false).length;
    return questionLength;
  };

  getProgress = () => {
    const { questions, setProgressCheckPage } = this.props;
    const questionLength = questions.filter(question => question.answer !== false).length;
    const progress = ((questionLength / questions.length) * 100).toFixed(0);
    if (parseInt(progress, 10) === 100) {
      setTimeout(() => {
        setProgressCheckPage({ status: 'success' });
      }, 600);
    }
    return progress;
  };

  scrollHandler = () => {
    const nodes = Array.from(this.formElem.current.childNodes);
    nodes.forEach(qNode => {
      const etop = qNode.offsetTop;
      const diff = etop - this.formElem.current.scrollTop;
      if (diff > 100 && diff < 450) {
        nodes.forEach(q => q.classList.remove('active'));
        qNode.classList.add('active');
      }
    });
  };

  selectAnswer = (questionIndex, faceValue) => {
    const { questions, setProgressCheckAnswer } = this.props;
    const nodes = Array.from(this.formElem.current.childNodes);
    setProgressCheckAnswer(questionIndex, faceValue, questions[questionIndex], questionIndex);
    if (questionIndex + 1 < questions.length) {
      const nextNode = nodes[questionIndex + 1];
      setTimeout(() => {
        scroller.scrollTo(nextNode.offsetTop - 200, {
          duration: 300,
          delay: 0,
          smooth: true,
          containerId: 'ContainerScroll',
        });
      }, 800);
    }
  };

  getEmojiStyle = (selected, face) => {
    if (selected === face) {
      return 'face-emoji-active';
    }
    if (selected) {
      return 'face-emoji-inactive';
    }
    return null;
  };

  render() {
    const { questions, loading, isAnswering } = this.props;
    return (
      <>
        {!loading && (
          <form
            ref={this.formElem}
            className="position-relative progress-check-in-form"
            id="ContainerScroll"
            onScroll={this.scrollHandler}
          >
            {questions.map((qstn, index) => (
              <div id={qstn._id} key={qstn._id} className="question-list-item">
                <div>
                  <h5>
                    {index + 1}. Do you feel your{' '}
                    <span className="font-weight-bold">{qstn.subcategory_name}</span> has gotten
                    better or worse?
                  </h5>
                </div>
                <div className="row no-gutters pt-3">
                  {faces.map(face => (
                    <div
                      key={face.value}
                      onClick={() => this.selectAnswer(index, face.value)}
                      className={`face-emoji col p-0 ${this.getEmojiStyle(
                        qstn.answer,
                        face.value
                      )}`}
                    >
                      <FaceEmoji
                        showTick={qstn.answer === face.value}
                        text={face.value}
                        src={face.src}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </form>
        )}
        <div className="assessment-footer progress-check-in-footer">
          <div className="container position-relative h-100">
            <div className="row h-100 align-items-center d-lg-flex d-none">
              <div className="col-lg-8 d-flex align-items-center">
                <div className="position-relative w-75">
                  <p className="mb-1">{this.getProgress()}% Completed</p>
                  <ProgressBar now={this.getProgress()} />
                  {isAnswering && (
                    <div className="position-absolute" style={{ right: 0, bottom: '0.5rem' }}>
                      <Spinner animation="border" variant="light" size="sm" />
                    </div>
                  )}
                </div>
                <div className="ml-auto px-3">
                  <button type="button" onClick={this.backHandler} className="nav-arrow-btn mr-3">
                    Back
                  </button>
                  <button type="button" onClick={this.nextHandler} className="nav-arrow-btn">
                    Next
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ml-auto float-right">
                  {this.getRemainingAnswer() > 0 ? (
                    <span className="mr-3">{`${this.getRemainingAnswer()} Questions Pending`}</span>
                  ) : (
                    <span className="mr-3">Finished</span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-lg-none d-flex align-items-center h-100">
              <span>
                {questions.length - this.getRemainingAnswer()}/{questions.length},
              </span>
              <span className="ml-3">{this.getProgress()}%</span>
              <div className="ml-auto">
                <button
                  type="button"
                  onClick={this.backHandler}
                  className="nav-arrow-btn btn-sm p-2 mr-2"
                >
                  <i className="icon-arrow-up" />
                </button>
                <button
                  type="button"
                  onClick={this.nextHandler}
                  className="nav-arrow-btn btn-sm p-2 mr-2"
                >
                  <i className="icon-arrow-down" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ProgressCheckQuestionForm.propTypes = {
  questions: PropTypes.shape({
    map: PropTypes.func,
    filter: PropTypes.func,
    length: PropTypes.func,
  }).isRequired,
  setProgressCheckAnswer: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isAnswering: PropTypes.bool.isRequired,
  setProgressCheckPage: PropTypes.func.isRequired,
  calculateProgressCheckResults: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    questions: state.assessment.progressCheckQuestions,
    answers: state.assessment.answers,
    progress: state.assessment.progress,
    isAnswering: state.assessment.isAnswering,
    loading: state.app.loading,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { startAssessment, setProgressCheckAnswer, setProgressCheckPage, calculateProgressCheckResults }
  )(ProgressCheckQuestionForm)
);
