/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import Switch from '../../../../common/Switch/Switch';
import InputRange from '../../../../common/InputRangeSlider/InputRangeSlider';
import RadioSelect from '../../../../common/RadioSelect/RadioSelect';
import PractitionerRegistrationHeader from './PractitionerRegistrationHeader';
import MultiSearchDropDown from '../../../../common/MultiSearchDropDown/MultiSearchDropDown';

const PractitionerRegistration6 = ({
  p_accept_insurance,
  p_session_price_type,
  insurance_types,
}) => (
  <div id="practitioner-reg-6" className="py-md-5 py-4 mt-md-5">
    <PractitionerRegistrationHeader
      title="Session Price"
      info="Choose your general price range for your sessions"
    />
    <div className="row">
      <div className="col-12 mb-4">
        <Field
          name="p_session_price_type"
          component={props => <RadioSelect options={['hourly', 'session']} {...props} />}
        />
      </div>
      {p_session_price_type && (
        <div className="col-12">
          <div className="">
            <p className="title-message">Set your price for Session</p>
            <div className="my-5">
              <Field
                name="p_session_price"
                component={prop => (
                  <InputRange
                    value={prop.input.value || { min: 50, max: 100 }}
                    minValue={0}
                    maxValue={500}
                    step={5}
                    {...prop}
                    formatLabel={value => `$${value}`}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
      <div className="col-md-8 col-12 mt-5">
        <div className="mb-3">
          <span className="mr-4">Accept Insurance</span>
          <Field
            name="p_accept_insurance"
            component={prop => (
              <Switch
                id={4}
                checked={prop.input.value}
                onChange={event => prop.input.onChange(event)}
                {...prop}
              />
            )}
          />
        </div>
        {p_accept_insurance && (
          <Field
            name="p_accepted_insurances"
            placeholder="Insurance Type"
            component={prop => (
              <MultiSearchDropDown
                options={insurance_types}
                {...prop}
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
              />
            )}
          />
        )}
      </div>
    </div>
  </div>
);

PractitionerRegistration6.propTypes = {
  p_accept_insurance: PropTypes.bool,
  p_session_price_type: PropTypes.string,
  insurance_types: PropTypes.arrayOf(PropTypes.object),
};

PractitionerRegistration6.defaultProps = {
  insurance_types: [],
  p_accept_insurance: false,
  p_session_price_type: '',
};

const mapStateToProps = state => {
  const selector = formValueSelector('practitionerProfileForm');
  const { p_accept_insurance, p_session_price_type } = selector(
    state,
    'p_accept_insurance',
    'p_session_price_type'
  );
  return {
    p_accept_insurance,
    p_session_price_type,
    insurance_types: state.app.insurance_types,
  };
};

export default connect(mapStateToProps)(PractitionerRegistration6);
