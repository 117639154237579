import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProgressCheckQuestions } from '../../../../store/actions/assessmentActions';
import ProgressCheckWelcome from '../ProgressCheckWelcome/ProgressCheckWelcome';
import ProgressCheckQuestionForm from '../ProgressCheckQuestion/ProgressCheckQuestionForm';
import './ProgressCheckContainer.css';
import ProgressCheckSuccess from '../ProgressCheckSuccess/ProgressCheckSuccess';

class ProgressCheckContainer extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  componentDidMount = () => {
    const { getProgressCheckQuestions } = this.props;
    getProgressCheckQuestions();
  };

  onLoad = () => {
    this.container.current.style.backgroundColor = '#00000030';
    this.container.current.style.backgroundImage = 'url(/images/progressCheck.jpg)';
  };

  render() {
    const { progressCheckPage } = this.props;
    return (
      <div ref={this.container} className="assesment-welcome-container">
        <img src="/images/Assessment_background.jpg" className="d-none" onLoad={this.onLoad} alt="test" />
        <div className="container h-100">
          <div className="row h-100 justify-content-center">
            {progressCheckPage.status === 'welcome' && <ProgressCheckWelcome />}
            {progressCheckPage.status === 'questions' && (
              <div className="col-10 d-flex flex-column h-100">
                <ProgressCheckQuestionForm />
              </div>
            )}
            {progressCheckPage.status === 'success' && <ProgressCheckSuccess />}
          </div>
        </div>
      </div>
    );
  }
}

ProgressCheckContainer.propTypes = {
  progressCheckPage: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    progressCheckPage: state.assessment.progressCheckPage,
  };
};

export default connect(
  mapStateToProps,
  { getProgressCheckQuestions }
)(ProgressCheckContainer);
