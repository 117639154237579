/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import './RadioSelect.css';

class RadioSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options || [],
      other: props.other || false,
      otherValue: props.otherValue,
      //   selected: props.input.value || props.selected || null,
      isTyping: false,
    };
  }

  componentDidMount() {
    const { options } = this.state;
    const { input } = this.props;
    if (input.value && !options.includes(input.value)) {
      this.setState({ otherValue: input.value });
    }
  }

  addOtherOptionHandler = event => {
    const { meta, input, dispatch } = this.props;
    this.setState({ otherValue: event.target.value, isTyping: false });
    if (meta && input) dispatch(change(meta.form, input.name, event.target.value));
  };

  render() {
    const { input, meta, varient } = this.props;
    const { other, isTyping, otherValue, options } = this.state;
    const radioClassname = [
      varient && varient === 'content-form' ? 'gtm-radio-option mr-2' : 'gt-radio-option mr-3',
    ].join(' ');
    return (
      <>
        <div className="d-flex flex-wrap">
          {options.map((option, i) => (
            <div key={option} className={radioClassname}>
              <input
                {...input}
                id={`${i}-${option}`}
                type="radio"
                className="d-none"
                value={option}
                onChange={e => {
                  this.setState({ otherValue: '' });
                  if (input) input.onChange(e);
                }}
                checked={input.value === option}
              />
              <label htmlFor={`${i}-${option}`} className="">
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </label>
            </div>
          ))}
          {other && (
            <div className={radioClassname}>
              <label className={[!isTyping && otherValue ? 'selected' : ''].join(' ')}>
                {!isTyping ? (
                  <span onClick={() => this.setState({ isTyping: true })} role="presentation">
                    {otherValue || 'Other'}
                  </span>
                ) : (
                  <input
                    type="text"
                    onBlur={this.addOtherOptionHandler}
                    onKeyUp={e => {
                      if (e.which === 13) {
                        e.preventDefault();
                        this.addOtherOptionHandler(e);
                      }
                    }}
                    className="other-text-field"
                  />
                )}
              </label>
            </div>
          )}
        </div>
        {meta && meta.error && meta.touched && (
          <span className="invalid-feedback gtm-invalid-feedback d-block">{meta.error}</span>
        )}
      </>
    );
  }
}

RadioSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  other: PropTypes.bool,
  otherValue: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    form: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  varient: PropTypes.string,
};

RadioSelect.defaultProps = {
  options: [],
  other: false,
  varient: '',
  otherValue: '',
};

export default connect()(RadioSelect);
