/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Switch from '../../common/Switch/Switch';
import { updateAssessmentResultStatus } from '../../../store/actions/settingsActions';

const SharedAssessmentResults = ({ result, updateAssessmentResultStatus }) => {
  const imageUrl = result.shared_with.u_image_url || '/images/user.svg';
  return (
    <tr key={result._id}>
      <td>
        <div className="d-flex flex-row">
          <Link to={`/${result.shared_with.u_prac_username}`}>
            <img src={imageUrl} alt="prac-profile" className="shared-practitioner-image" />
          </Link>
          <div className="d-flex flex-column ml-2">
            <span className="practitioner-name">
              {result.shared_with.u_first_name} {result.shared_with.u_last_name || ''}
            </span>
            <span className="shared-prac-category">
              {result.shared_with.specialization &&
                result.shared_with.specialization.map(item => <span key={item}>{item}</span>)}
            </span>
          </div>
        </div>
      </td>

      <td className="">{moment(result.shared_at).format('DD MMM YYYY')}</td>
      <td className="d-flex flex-row">
        <>
          <Switch
            onChange={() => {
              updateAssessmentResultStatus(result);
            }}
            id={result._id}
            checked={result.share_status === 'SHARED'}
            classType="dark-switch"
          />
          <span className="ml-2 mt-1 shared-status">
            {result.share_status === 'SHARED' ? 'SHARED' : 'PRIVATE'}
          </span>
        </>
      </td>
    </tr>
  );
};
const mapDispatchToProps = {
  updateAssessmentResultStatus,
};

SharedAssessmentResults.propTypes = {
  result: PropTypes.shape({
    _id: PropTypes.string,
    share_status: PropTypes.string,
    shared_at: PropTypes.string,
    shared_with: PropTypes.shape({
      _id: PropTypes.string,
      u_image_url: PropTypes.string,
      u_first_name: PropTypes.string,
      u_last_name: PropTypes.string,
      u_prac_username: PropTypes.string,
      specialization: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  updateAssessmentResultStatus: PropTypes.func.isRequired,
};
SharedAssessmentResults.defaultProps = {
  result: '',
};
export default connect(
  null,
  mapDispatchToProps
)(SharedAssessmentResults);
