/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Tabs, Tab } from 'react-bootstrap';
import Navbar from '../../../shared/Navbar/Navbar';
import Footer from '../../../shared/Footer/Footer';
import ResultCard from './ResultCard';
import {
  getAssessmentResult,
  getRecommendedContents,
  clearRecommended,
  fetchRecommended,
  getRecommendedPract,
} from '../../../../store/actions/assessmentActions';
import settings from '../../../shared/SlidingCard/settings';
import './AssessmentResult.css';
// import SlidingCard from '../../../shared/SlidingCard/SlidingCard';
// import ContentCard from '../../../shared/Cards/ContentCard/ContentCard';
// import PractitionerCard from '../../../shared/Cards/PractitionerCard/PractitionerCard';
import AssessmentResultStatusChart from './AssessmentResultStatusChart';
import SlidingCard from '../../../shared/SlidingCard/SlidingCard';
import ContentCard from '../../../shared/Cards/ContentCard/ContentCard';
import ContentSkelton from '../../../shared/Cards/ContentCard/ContentSkelton';
import PractitionerCard from '../../../shared/Cards/PractitionerCard/PractitionerCard';
import PractitionerSkeleton from '../../../shared/Cards/PractitionerCard/PractitionerSkeleton';

class AssessmentResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeCategoryId: '',
      recommendedLoading: false,
      moreContents: true,
      contentsPage: 1,
      loadingMore: '',
      practPage: 1,
      morePrac: true,
    };
  }

  componentDidMount() {
    const { getAssessmentResult, clearRecommended, match, history } = this.props;
    const { username } = match.params;
    this.setState({ loading: true });
    getAssessmentResult(username, (err, catId) => {
      this.setState({ loading: false });
      if (err && err.ERR_CODE === 'NOTFOUND') {
        history.push('/not-found');
        return;
      }
      if (catId) {
        this.setState({ activeCategoryId: catId });
        clearRecommended();
        this.fetchRecommended(catId);
      }
    });
  }

  fetchRecommended = categoryId => {
    const { fetchRecommended: fetchRecommendedContentAndPrac } = this.props;
    this.setState({ recommendedLoading: true });
    const location = JSON.parse(localStorage.getItem('location'));    
    fetchRecommendedContentAndPrac(categoryId, location, (err, hasMore, morePrac) => {
      this.setState({ recommendedLoading: false });
      if (!err) {
        this.setState({ moreContents: hasMore, morePrac });
      }
    });
  };

  loadMoreContents = () => {
    const { contentsPage: page, activeCategoryId } = this.state;
    this.setState({ loadingMore: 'content', contentsPage: page + 1 });
    const { getRecommendedContents } = this.props;
    getRecommendedContents(activeCategoryId, page + 1, (err, hasMore) => {
      this.setState({ moreContents: hasMore, loadingMore: '' });
    });
  };

  loadMorePrac = () => {
    const { practPage: page, activeCategoryId } = this.state;
    this.setState({ loadingMore: 'prac', practPage: page + 1 });
    const { getRecommendedPract } = this.props;
    const location = JSON.parse(localStorage.getItem('location'));    
    getRecommendedPract(activeCategoryId, page + 1, location, (err, hasMore) => {
      this.setState({ morePrac: hasMore, loadingMore: '' });
    });
  };

  onContentClick = content_id => this.props.history.push(`/content/${content_id}`);

  categoryChangeHandler = cat => {
    const { clearRecommended } = this.props;
    this.setState({
      activeCategoryId: cat._id,
      contentsPage: 1,
    });
    clearRecommended();
    this.fetchRecommended(cat._id);
  };

  render() {
    const { result, match, user, recommended, recommendedPract, history } = this.props;
    const { username } = match.params;
    const { loading, recommendedLoading, moreContents, morePrac, loadingMore } = this.state;
    let owner = null;

    const RecommendedContents = recommended.map(content => (
      <ContentCard
        key={content._id}
        content={content}
        onClick={() => this.onContentClick(content._id)}
      />
    ));

    const RecommendedPract = recommendedPract.map(practitioner => (
      <PractitionerCard
        onClick={username => history.push(`/${username}`)}
        key={practitioner._id}
        practitioner={practitioner}
      />
    ));

    if (result && result.user && user && username !== user.u_username)
      owner = `${result.user.u_first_name} ${result.user.u_last_name || ''}`;
    let Answers = null;
    if (result) {
      const answers = lodash.orderBy(result.answers, ['question_id.question_order'], ['asc']);
      Answers = answers.map(answer => (
        <div key={answer._id} className="mb-4">
          <div className="d-flex">
            <span className="mr-2 font-bold">{answer.question_id.question_order}.</span>
            <div>
              <p className="mb-2 font-bold">{answer.question_id.question}</p>
              <p className="text-capitalize mb-1">Answer: {answer.assessment_answer}</p>
              {answer.additional_detailes && (
                <p>
                  <u className="d-block font-medium mb-1">Note:</u>
                  {answer.additional_detailes}
                </p>
              )}
            </div>
          </div>
        </div>
      ));
    }

    let RecommendedContentSection = null;
    if (recommendedLoading) {
      RecommendedContentSection = (
        <div className="bg-white rounded mt-3 px-3 py-2">
          <section className="skeleton-section mb-4">
            <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
            <div className="row">
              {[1, 2, 3, 4].map(ele => (
                <div key={`pop-${ele}`} className="col-lg-3 col-md-4 col-6">
                  <ContentSkelton />
                </div>
              ))}
            </div>
          </section>
        </div>
      );
    } else {
      RecommendedContentSection =  (RecommendedContents.length > 0) && (
        <div className="bg-white rounded overflow-hidden mt-3 px-3 py-2">
          <SlidingCard settings={settings} cardNumber={4} heading="Recommended For You">
            {RecommendedContents}
            {moreContents && (
              <div className="show-more-slide">
                <button
                  type="button"
                  className="btn btn-link m-auto"
                  onClick={this.loadMoreContents}
                >
                  {loadingMore === 'content' ? (
                    <span className="spinner-border text-primary" />
                  ) : (
                    'Show more'
                  )}
                </button>
              </div>
            )}
          </SlidingCard>
        </div>
      );
    }
    let RecommendedPractitionerSection = null;
    if (recommendedLoading) {
      RecommendedPractitionerSection = (
        <div className="bg-white rounded mt-3 px-3 py-2">
          <section className="mb-4 overflow-hidden" style={{ height: '396px' }}>
            <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
            <div className="row">
              {[1, 2, 3, 4].map(ele => (
                <div key={`prac-${ele}`} className="col-lg-3 col-md-4 col-6">
                  <PractitionerSkeleton />
                </div>
              ))}
            </div>
          </section>
        </div>
      );
    } else {
      RecommendedPractitionerSection = (RecommendedPract.length > 0) && (
        <div className="bg-white rounded overflow-hidden mt-3 px-3 py-2 mt-4">
          <SlidingCard settings={settings} cardNumber={4} heading="Recommended Practitioners">
            {RecommendedPract}
            {morePrac && (RecommendedPract.length > 15) && (
              <div className="show-more-slide">
                <button type="button" className="btn btn-link m-auto" onClick={this.loadMorePrac}>
                  {loadingMore === 'prac' ? (
                    <span className="spinner-border text-primary" />
                  ) : (
                    'Show more'
                  )}
                </button>
              </div>
            )}
          </SlidingCard>
        </div>
      );
    }

    return (
      <>
        <Navbar />
        {loading && (
          <div className="d-flex" style={{ height: 'calc(100vh - 3rem)' }}>
            <div className="spinner-grow text-primary m-auto" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {!loading && result && (
          <div id="assessment-result">
            <div className="container py-3">
              <h2 className="">Assessment Results</h2>
              <AssessmentResultStatusChart progress={result.wholeness} />
              <div className="row">
                <div className="col-lg-12">
                  <div className={['content-tab-card assessment-result-tab'].join(' ')}>
                    <Tabs defaultActiveKey="details" id="content-tab">
                      <Tab eventKey="details" title="Details">
                        <ResultCard
                          categories={result.categories}
                          total={result.wholeness}
                          owner={owner}
                          onChange={this.categoryChangeHandler}
                        />
                      </Tab>
                      <Tab eventKey="answers" title="Answers">
                        <div className="p-3">{Answers}</div>
                      </Tab>
                    </Tabs>
                  </div>
                  {user && username === user.u_username && RecommendedContentSection}
                  {user && username === user.u_username && RecommendedPractitionerSection}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}

AssessmentResult.propTypes = {
  result: PropTypes.shape({
    wholeness: PropTypes.number.isRequired,
    categories: PropTypes.arrayOf(),
  }),
  recommended: PropTypes.arrayOf(PropTypes.object),
  getAssessmentResult: PropTypes.func.isRequired,
  getRecommendedContents: PropTypes.func.isRequired,
  clearRecommended: PropTypes.func.isRequired,
};
AssessmentResult.defaultProps = {
  result: null,
  recommended: [],
};

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
    result: state.assessment.result,
    recommended: state.assessment.recommendedContents,
    recommendedPract: state.assessment.recommendedPract,
  };
};

export default connect(
  mapStateToProps,
  {
    getAssessmentResult,
    getRecommendedContents,
    clearRecommended,
    fetchRecommended,
    getRecommendedPract,
  }
)(AssessmentResult);
