/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { shareResult } from '../../store/actions/practitionerRegAction';
import {
  approveOrRejectBusiness,
  approveBusinessInvitation,
} from '../../store/actions/linkBusinessAction';

function NotificationItem({ notification, ...props }) {
  const {
    sender: { u_first_name, u_last_name, u_image_url, u_username, u_prac_username, _id: userId },
    reciever,
    created_at,
    notification_type,
  } = notification;

  const avatarClickHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    if (
      notification_type === 'SESSION_ACCEPT' ||
      notification_type === 'NEW_COMMENT' ||
      notification_type === 'NEW_CONTENT' ||
      notification_type === 'ASSESSMENT_RESULT_REQUEST'
    ) {
      props.history.push(`/${u_prac_username}`);
      return false;
    }
    props.history.push(`/${u_username}`);
  };

  const senderImage = u_image_url || '/images/user.svg';
  const classNames = [
    'notification-item cursor-pointer d-flex p-3',
    !reciever[0].read_status && 'read',
  ];

  const { onClick, approveOrRejectBusiness, approveBusinessInvitation } = props;

  const approveHandler = e => {
    e.stopPropagation();
    setLoading('JOIN-APPROVE');
    approveOrRejectBusiness(
      notification.business_ref.linked_business_id._id,
      userId,
      'APPROVED',
      notification._id,
      () => {
        setLoading('');
      }
    );
  };
  const rejectHandler = e => {
    e.stopPropagation();
    setLoading('JOIN-REJECT');
    approveOrRejectBusiness(
      notification.business_ref.linked_business_id._id,
      userId,
      'REJECTED',
      notification._id,
      () => {
        setLoading('');
      }
    );
  };

  const [loading, setLoading] = useState('');

  const approveInvitation = e => {
    e.stopPropagation();
    setLoading('INV-APPROVE');
    approveBusinessInvitation(notification.business_ref._id, 'APPROVED', notification._id, () => {
      setLoading('');
    });
  };

  const rejectInvitation = e => {
    e.stopPropagation();
    setLoading('INV-REJECT');
    approveBusinessInvitation(notification.business_ref._id, 'REJECTED', notification._id, () => {
      setLoading('');
    });
  };

  return (
    <div className={classNames.join(' ')} role="presentation" onClick={() => onClick(notification)}>
      <div
        className="profile-image cursor-pointer"
        role="presentation"
        onClick={avatarClickHandler}
        style={{ backgroundImage: `url(${senderImage})` }}
      />
      {notification_type === 'SESSION_ACCEPT' && (
        <div className="ml-2">
          <p className="message mb-1">
            <span className="sender text-capitalize">{`${u_first_name} ${u_last_name ||
              ''} `}</span>
            has approved your request
          </p>
          <p className="date mb-0">{moment(new Date(created_at)).fromNow()}</p>
        </div>
      )}
      {notification_type === 'SHARED_RESULT' && (
        <div className="ml-2">
          <p className="message mb-1">
            <span className="sender text-capitalize">{`${u_first_name} ${u_last_name ||
              ''} `}</span>
            shared result with you.
          </p>
          <p className="date mb-0">{moment(new Date(created_at)).fromNow()}</p>
        </div>
      )}
      {notification_type === 'ADD_REVIEW' && (
        <div className="ml-2">
          <p className="message mb-1">
            <span className="sender text-capitalize">{`${u_first_name} ${u_last_name ||
              ''} `}</span>
            has made a review.
          </p>
          <p className="date mb-0">{moment(new Date(created_at)).fromNow()}</p>
        </div>
      )}
      {notification_type === 'NEW_CONTENT' && (
        <div className="ml-2">
          <p className="message mb-1">
            <span className="sender text-capitalize">{`${u_first_name} ${u_last_name ||
              ''} `}</span>
            has published new content.
          </p>
          <p className="date mb-0">{moment(new Date(created_at)).fromNow()}</p>
        </div>
      )}
      {notification_type === 'NEW_COMMENT' && (
        <div className="ml-2">
          <p className="message mb-1">
            <span className="sender text-capitalize">{`${u_first_name} ${u_last_name ||
              ''} `}</span>
            commented on{' '}
            <span className="text-capitalize font-weight-bold text-dark">
              "{notification.reference.content_title}".
            </span>
          </p>
          <p className="date mb-0">{moment(new Date(created_at)).fromNow()}</p>
        </div>
      )}
      {notification_type === 'ASSESSMENT_RESULT_REQUEST' && (
        <div className="ml-2">
          <p className="message mb-1">
            <span className="sender text-capitalize">{`${u_first_name} ${u_last_name ||
              ''} `}</span>
            has requested for your personal assessment result.
            {/* <button
              type="button"
              className="btn btn-sm btn-link p-0 d-block text-capitalize"
              onClick={event => shareAssessmentResult(event, _id)}
            >
              {shareStatus ? 'cancel sharing result' : 'share your result'}
            </button> */}
          </p>
          <p className="date mb-0">{moment(new Date(created_at)).fromNow()}</p>
        </div>
      )}
      {notification_type === 'BUSINESS_JOIN_REQUEST' && (
        <div className="ml-2">
          <p className="message mb-1">
            <span className="sender text-capitalize">
              {u_first_name} {u_last_name || ''}
            </span>{' '}
            is interested in joining your business{' '}
            <span className="text-capitalize font-medium text-dark">
              "{notification.business_ref && notification.business_ref.linked_business_id && notification.business_ref.linked_business_id.business_name}"
            </span>
            {notification.business_ref.admin_approve_status === 'APPROVED' && (
              <span className="approve-status bg-success rounded display2 text-white px-3 py-1 ml-2">
                Approved
              </span>
            )}
            {notification.business_ref.admin_approve_status === 'REJECTED' && (
              <span className="approve-status bg-secondary rounded display2 text-white px-3 py-1 ml-2">
                Rejected
              </span>
            )}
          </p>
          <div className="d-flex align-items-center">
            <p className="date mb-0">{moment(new Date(created_at)).fromNow()}</p>
            {notification.business_ref.admin_approve_status === 'PENDING' && (
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-sm btn-primary font-medium display2 px-3 ml-2"
                  disabled={loading === 'JOIN-APPROVE'}
                  onClick={approveHandler}
                >
                  {loading === 'JOIN-APPROVE' ? (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                      Approving...
                    </span>
                  ) : (
                    'Approve'
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary display2 px-3 ml-2"
                  disabled={loading === 'JOIN-REJECT'}
                  onClick={rejectHandler}
                >
                  {loading === 'JOIN-REJECT' ? (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                      Rejecting...
                    </span>
                  ) : (
                    'Reject'
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {notification_type === 'BUSINESS_INVITATION_REQUEST' && (
        <div className="ml-2">
          <p className="message mb-1">
            <span className="sender text-capitalize">
              {u_first_name} {u_last_name || ''}
            </span>{' '}
            has invited{' '}
            <span className="font-medium text-dark">
              {notification.business_ref.linked_business_member_id
                ? notification.business_ref.linked_business_member_id.u_first_name
                : notification.business_ref.invitation_email}
            </span>{' '}
            to join your business{' '}
            <span className="text-capitalize font-medium text-dark">
              "{notification.business_ref.linked_business_id.business_name}"
            </span>
            {notification.business_ref.admin_approve_status === 'APPROVED' && (
              <span className="approve-status bg-success rounded display2 text-white px-3 py-1 ml-2">
                Approved
              </span>
            )}
            {notification.business_ref.admin_approve_status === 'REJECTED' && (
              <span className="approve-status bg-secondary rounded display2 text-white px-3 py-1 ml-2">
                Rejected
              </span>
            )}
          </p>
          <div className="d-flex align-items-center">
            <p className="date mb-0">{moment(new Date(created_at)).fromNow()}</p>
            {notification.business_ref.admin_approve_status === 'PENDING' && (
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-sm btn-primary font-medium display2 px-3 ml-2"
                  disabled={loading === 'INV-APPROVE'}
                  onClick={approveInvitation}
                >
                  {loading === 'INV-APPROVE' ? (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                      Approving...
                    </span>
                  ) : (
                    'Approve'
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary display2 px-3 ml-2"
                  disabled={loading === 'INV-REJECT'}
                  onClick={rejectInvitation}
                >
                  {loading === 'INV-REJECT' ? (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                      Rejecting...
                    </span>
                  ) : (
                    'Reject'
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    sender: PropTypes.shape({
      _id: PropTypes.string,
      u_first_name: PropTypes.string,
      u_last_name: PropTypes.string,
      u_image_url: PropTypes.string,
      u_username: PropTypes.string,
      u_prac_username: PropTypes.string,
    }),
    reciever: PropTypes.arrayOf(PropTypes.object),
    created_at: PropTypes.string,
    notification_type: PropTypes.string,
  }).isRequired,
  shareStatus: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  shareResult: PropTypes.func.isRequired,
};

NotificationItem.defaultProps = {
  shareStatus: false,
};

const mapStateToProps = state => ({
  shareStatus: state.practitionerReg.shareStatus,
});

export default withRouter(
  connect(
    mapStateToProps,
    { shareResult, approveOrRejectBusiness, approveBusinessInvitation }
  )(NotificationItem)
);
