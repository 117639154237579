import {
  isEmpty,
  isNumeric,
  isValidURL,
  isValidYear,
  isValidUsername,
  isValidPhoneNumber
} from '../../../../../utils/validators';

// const validateProfessionalService = (values={}) => {
//     const errors = {};
//     if(!isEmpty(values.service_type)){
//         errors.service_type = 'This field is required.'
//     }
//     if(!isEmpty(values.session_type)){
//         errors.session_type = 'This field is required.'
//     }
//     return errors;
// }

// const validateProfessionalBacground = (values={}) => {
//     const errors = {};
//     if(!isEmpty(values.professional_background_type)){
//         errors.professional_background_type = 'This field is required.'
//     }
//     if(values.professional_background_type === 'licensed' || values.professional_background_type === 'certified') {
//         if(!values.certificates || values.certificates.length === 0) {
//             errors.certificates = 'Please choose institution and issued year.'
//         }
//     }

//     return errors;
// }

const validators = values => {
  const errors = {};
  if (!isEmpty(values.u_prac_username)) {
    errors.u_prac_username = 'This field is required';
  } else if (!isValidUsername(values.u_prac_username)) {
    errors.u_prac_username = 'Invalid username';
  }
  if (!isEmpty(values.p_about)) {
    errors.p_about = 'This field is required';
  }
  if (!isEmpty(values.p_business_name)) {
    errors.p_business_name = 'This field is required';
  }
  if (!isEmpty(values.p_phone_number)) {
    errors.p_phone_number = 'This field is required.';
  } else if (!isValidPhoneNumber(values.p_phone_number)) {
    errors.p_phone_number = 'Invalid phone number.';
  }
  if (!isEmpty(values.p_address)) {
    errors.p_address = 'This field is required.';
  }
  if (isEmpty(values.p_website)) {
    if (!isValidURL(values.p_website)) {
      errors.p_website = 'please enter a valid url.';
    }
  }
  if (!isEmpty(values.p_country)) {
    errors.p_country = 'Choose from list.';
  }
  if (!isEmpty(values.p_state)) {
    errors.p_state = 'Choose from list.';
  }
  if (!isEmpty(values.p_city)) {
    errors.p_city = 'Choose from list.';
  }
  if (!isEmpty(values.p_experience)) {
    errors.p_experience = 'This field is required.';
  } else if (!isNumeric(values.p_experience)) {
    errors.p_experience = 'Should be Number.';
  }
  if(!values.location) {
    errors.location = 'This field is required.';
  }
  // else {
  //   if(!values.location.address) {
  //     errors.location = 'This field is required.';
  //   }
  // }

  if (!values.p_specialization) {
    errors.p_specialization = 'Select your specialization.';
  } else if (values.p_specialization.length === 0) {
    errors.p_specialization = 'Select your specialization.';
  }

  // errors.p_professional_service = validateProfessionalService(values.p_professional_service);
  if (!values.p_service_type) {
    errors.p_service_type = 'This field is required.';
  } else if (values.p_service_type.length === 0) {
    errors.p_service_type = 'This field is required.';
  }
  if (!values.p_session_type) {
    errors.p_session_type = 'This field is required.';
  } else if (values.p_session_type.length === 0) {
    errors.p_session_type = 'This field is required.';
  }

  // errors.p_professional_background = validateProfessionalBacground(values.p_professional_background);
  // if (!isEmpty(values.p_professional_type)) {
  //   errors.p_professional_type = 'This field is required.';
  // }
  if (values.p_licensed === true) {
    if (!values.p_licenses || values.p_licenses.length === 0) {
      errors.p_licenses = 'Please choose institution and issued year.';
    } else {
      const institutionArrayError = [];
      values.p_licenses.forEach((institution, index) => {
        const institutionErrors = {};
        if (!institution || !institution.institution) {
          institutionErrors.institution = 'Select Institution';
          institutionArrayError[index] = institutionErrors;
        }
        if (!institution || !institution.year) {
          institutionErrors.year = 'Choose year';
          institutionArrayError[index] = institutionErrors;
        } else if (!institution || !isValidYear(institution.year)) {
          institutionErrors.year = 'Invalid Year';
          institutionArrayError[index] = institutionErrors;
        }
      });
      if (institutionArrayError.length) {
        errors.p_licenses = institutionArrayError;
      }
    }
  }

  if (values.p_certified === true) {
    if (!values.p_certifications || values.p_certifications.length === 0) {
      errors.p_certifications = 'Please choose institution and issued year.';
    } else {
      const institutionArrayError = [];
      values.p_certifications.forEach((institution, index) => {
        const institutionErrors = {};
        if (!institution || !institution.institution) {
          institutionErrors.institution = 'Select Institution';
          institutionArrayError[index] = institutionErrors;
        }
        if (!institution || !institution.year) {
          institutionErrors.year = 'Choose year';
          institutionArrayError[index] = institutionErrors;
        } else if (!institution || !isValidYear(institution.year)) {
          institutionErrors.year = 'Invalid Year';
          institutionArrayError[index] = institutionErrors;
        }
      });
      if (institutionArrayError.length) {
        errors.p_certifications = institutionArrayError;
      }
    }
  }

  if (!values.p_time_availability || values.p_time_availability.length === 0) {
    errors.p_time_availability = 'Please choose your business hour';
  }

  if (!isEmpty(values.p_session_price_type)) {
    errors.p_session_price_type = 'This field is required.';
  }

  if (values.p_accept_insurance) {
    if (!values.p_accepted_insurances || values.p_accepted_insurances.length === 0) {
      errors.p_accepted_insurances = 'This field is required.';
    }
  }

  return errors;
};

export default validators;
