/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import InputRange from '../../../../common/InputRangeSlider/InputRangeSlider';

const options = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

class TimeAvailability extends Component {
  constructor(props) {
    super(props);
    this.inputElem = React.createRef();
    this.state = {
      selected: [],
      selectedDayAndTime: props.selected || [],
      selectedTime: { min: 9, max: 18 },
      isSelecting: false,
    };
  }

  componentDidMount() {
    const { selectedDayAndTime } = this.state;
    const { manual } = this.props;

    if (selectedDayAndTime.length && !manual) {
      const selected = selectedDayAndTime.map(d => d.day);
      this.setState({ selected, isSelecting: true, selectedTime: selectedDayAndTime[0].time });
    }
  }

  componentDidUpdate(prevProp) {
    const { manual, onChangeTime } = this.props;
    console.log('#############################', manual, '----->', prevProp.manual);
    if (manual !== prevProp.manual) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selected: [], selectedDayAndTime: [], isSelecting: false });
      onChangeTime([]);
    }
  }

  changeHandler = event => {
    const { selectedDayAndTime, selectedTime } = this.state;
    let { selected } = this.state;
    const { manual, onChangeTime } = this.props;
    if (manual) {
      selected = [event.target.value];
    } else {
      if (event.target.checked) {
        selected.push(event.target.value);
        selectedDayAndTime.push({ day: event.target.value, time: selectedTime });
      } else {
        const index = selected.indexOf(event.target.value);
        selectedDayAndTime.splice(index, 1);
        selected.splice(index, 1);
      }
      onChangeTime([...selectedDayAndTime]);
    }
    this.setState({
      selected: [...selected],
      selectedDayAndTime: [...selectedDayAndTime],
      isSelecting: true,
    });
  };

  getSelectedTime = day => {
    const { selectedDayAndTime } = this.state;
    day = selectedDayAndTime.find(d => d.day === day);
    const start = this.formateTime(day.time.min);
    const to = this.formateTime(day.time.max);
    return `${start} - ${to}`;
  };

  // eslint-disable-next-line react/destructuring-assignment
  isDayChoosed = day => this.state.selectedDayAndTime.findIndex(d => d.day === day) >= 0;

  formateTime = time => {
    if (time === 24) {
      return '12:00 AM';
    }
    const temp = time.toString().split('.');
    if (temp[0].length === 1) temp[0] = `0${temp[0]}`;
    // eslint-disable-next-line no-unused-expressions
    temp[1] ? (temp[1] = 60 * Number(`.${temp[1]}`)) : (temp[1] = '00');
    time = temp.join(':');
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  };

  setTimeManually = event => {
    event.preventDefault();
    event.stopPropagation();
    const { selectedTime, selectedDayAndTime, selected } = this.state;
    const { onChangeTime } = this.props;
    const index = selectedDayAndTime.findIndex(d => d.day === selected[0]);
    if (index >= 0) {
      selectedDayAndTime[index] = { day: selected[0], time: selectedTime };
    } else {
      selectedDayAndTime.push({ day: selected[0], time: selectedTime });
    }
    this.setState({ selectedDayAndTime: [...selectedDayAndTime], isSelecting: false });
    onChangeTime([...selectedDayAndTime]);
    this.clearInput();
  };

  clearInput = () => {
    Array.from(document.querySelectorAll("input[name='days']")).forEach(inp => {
      inp.checked = false;
    });
  };

  onChangeComplete = value => {
    const { manual } = this.props;
    this.setState({ selectedTime: value });
    if (!manual) {
      const { selectedDayAndTime, selectedTime } = this.state;
      this.setState({
        selectedDayAndTime: selectedDayAndTime.map(day => {
          day.time = selectedTime;
          return day;
        }),
      });
    }
  };

  onCancel = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isSelecting: false });
    this.inputElem.current.value = null;
    this.clearInput();
  };

  render() {
    const { selected, selectedTime, isSelecting, selectedDayAndTime } = this.state;
    const { meta, manual, varient } = this.props;
    console.log('manual value--->', manual);
    return (
      <div id="time-availability" className={varient}>
        {meta && meta.error && meta.touched && (
          <span className="invalid-feedback gtm-invalid-feedback d-block mb-2">{meta.error}</span>
        )}
        <div className="d-flex flex-wrap">
          {options.map((option, i) => (
            <div key={i} className="position-relative day mr-3">
              {selectedDayAndTime.length > 0 && this.isDayChoosed(option) && (
                <span className="day-choosed">{this.getSelectedTime(option)}</span>
              )}
              <input
                id={`${i}-${option}`}
                name="days"
                onChange={this.changeHandler}
                ref={this.inputElem}
                type={manual ? 'radio' : 'checkbox'}
                className="d-none"
                value={option}
                checked={selected.includes(option)}
              />
              <label htmlFor={`${i}-${option}`} className="">
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </label>
            </div>
          ))}
        </div>
        {selected.length > 0 && isSelecting && (
          <div className="slider-container mt-3">
            <p className="title-message d-flex flex-wrap">
              Set your business hour for -
              {selected.map((day, i) => (
                <span className="text-capitalize" key={i}>
                  {day}
                </span>
              ))}
            </p>
            <div className="my-5">
              <InputRange
                minValue={0}
                maxValue={24}
                value={selectedTime}
                onChangeComplete={this.onChangeComplete}
                formatLabel={this.formateTime}
                step={0.25}
                varient={varient}
              />
            </div>
            {manual && (
              <div>
                <button type="button" className="btn btn-light" onClick={this.setTimeManually}>
                  Set Time
                </button>
                <button type="button" className="btn btn-light ml-3" onClick={this.onCancel}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

TimeAvailability.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  selected: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.string]),
  manual: PropTypes.bool,
  onChangeTime: PropTypes.func.isRequired,
  varient: PropTypes.string,
};

TimeAvailability.defaultProps = {
  meta: {},
  manual: false,
  selected: [],
  varient: '',
};

const mapStateToProps = state => {
  let selector = formValueSelector('practitionerProfileForm');
  if (state.form.memberprofilesettings) {
    selector = formValueSelector('memberprofilesettings');
  }
  // eslint-disable-next-line camelcase
  const p_set_time_per_day = selector(state, 'p_set_time_per_day');
  return {
    manual: p_set_time_per_day,
  };
};

export default connect(mapStateToProps)(TimeAvailability);
