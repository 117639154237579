import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import './KeywordInput.css';

class KeywordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      keywords: [],
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (value && value.length) {
      this.setState({ keywords: value });
    }
  }

  onChange = event => this.setState({ keyword: event.target.value });

  onEnter = event => {
    const { onChange } = this.props;
    event.stopPropagation();
    const { keywords, keyword } = this.state;
    if (event.which === 13 && keyword.trim()) {
      // on Enter Press
      event.preventDefault();
      keywords.push(keyword);
      this.setState({ keywords, keyword: '' });
      if (onChange) {
        onChange(keywords);
      }
    }
  };

  onRemoveEmail = keyword => {
    const { keywords } = this.state;
    const { onChange } = this.props;
    const updateKeywords = keywords.filter(kw => kw !== keyword);
    if (onChange) {
      onChange(updateKeywords);
    }
    this.setState({ keywords: updateKeywords });
  };

  render() {
    const { keywords, keyword } = this.state;
    return (
      <div id="keyword-inputs">
        <div className="position-relative mb-3 keyword-input-wrapper">
          <input
            className="form-control gt-form-control"
            value={keyword}
            onChange={this.onChange}
            onKeyDown={this.onEnter}
            placeholder="Enter keywords"
          />
        </div>
        <div className="d-flex flex-wrap selected-keywords">
          {keywords.map((key, i) => (
            <span key={`keyword-${i}`}>
              {key}
              <img src="/icons/close.svg" onClick={() => this.onRemoveEmail(key)} alt="close" />
            </span>
          ))}
        </div>
      </div>
    );
  }
}
export default KeywordInput;
