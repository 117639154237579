/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
// import { MD5 } from 'crypto-js';
import moment from 'moment';
import { Name, Time, Container, Section, Avatar } from './style';

const User = ({
  _id,
  u_first_name,
  u_last_name,
  active,
  onSelect,
  updatedtime,
  room,
  tag,
  u_username,
  count,
  u_image_url,
  activeRoom,
}) => {
  const id = tag === 'invite' ? _id : room;
  const profileImage = u_image_url || '/images/user.svg';
  return (
    <Container active={active} onClick={() => onSelect(id, tag === 'invite')}>
      <Section>
        <div className="d-flex">
          <Avatar src={profileImage} className="rounded-circle float-left" />
          <div className="ml-3">
            <Name className="name">{`${u_first_name || ''}  ${u_last_name || ''}`}</Name>
            {tag === 'invite' && `@${u_username}`}
            {tag === 'recent' && count !== 0 && room !== activeRoom && (
              <span className="badge badge-primary">{count} new {count === 1 ? 'message' : 'messages' }</span>
            )}
            {tag === 'recent' && count === 0 && (
              <Time>{moment(updatedtime).format('Do MMM YYYY LT')} </Time>
            )}
          </div>
        </div>
      </Section>
    </Container>
  );
};

User.propTypes = {
  _id: PropTypes.string.isRequired,
  u_first_name: PropTypes.string,
  u_last_name: PropTypes.string,
  u_image_url: PropTypes.string,
  updatedtime: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  room: PropTypes.string,
  tag: PropTypes.string.isRequired,
  u_username: PropTypes.string,
  count: PropTypes.number,
  activeRoom: PropTypes.string.isRequired,
};
User.defaultProps = {
  u_first_name: '',
  u_last_name: '',
  u_image_url: '',
  room: '',
  updatedtime: '',
  u_username: '',
  count: 0,
};

export default User;
