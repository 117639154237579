import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './RadialChart.css';

const DEFAULT_COLOR = '#040404';
class RadialChart extends Component {
  state = {};

  componentDidMount() {
    // For initial animation
    setTimeout(() => {
      this.setState({ setStrokeLength: true });
    });
  }

  render() {
    const { setStrokeLength } = this.state;
    const { className, radius, progress, strokeWidth, dimension, color, title } = this.props;

    const circleRadius = Math.min(radius, 85);
    const circumference = 2 * 3.14 * circleRadius;
    const strokeLength = setStrokeLength ? (circumference / 100) * progress : 0;
    return (
      <div
        className={classNames('radial-chart', className, {
          'no-progress': strokeLength === 0,
        })}
      >
        <svg viewBox="0 0 160 160" width={dimension} height={dimension}>
          <circle
            className="radial-chart-total"
            stroke={color}
            strokeWidth={strokeWidth}
            fill="none"
            cx="80"
            cy="80"
            r={circleRadius}
          />
          <circle
            className="radial-chart-progress"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeDasharray={`${strokeLength},${circumference}`}
            strokeLinecap="round"
            fill="none"
            cx="80"
            cy="80"
            r={circleRadius}
          />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            stroke="#919191"
            fontSize="20px"
            strokeWidth="1px"
            dy=".3em"
          >
            {`${progress  }%`}
          </text>
        </svg>
        <p className="radial-chart-title">{title}</p>
      </div>
    );
  }
}
RadialChart.defaultProps = {
  radius: 80,
  progress: 50,
  strokeWidth: 10,
  dimension: '100%',
  color: DEFAULT_COLOR,
  title: '',
};
RadialChart.propTypes = {
  className: PropTypes.string,
  radius: PropTypes.number,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
  progress: PropTypes.number,
  title: PropTypes.string,
  // dimension: PropTypes.number
};
export default RadialChart;
