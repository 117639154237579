import {
  SET_TRENDING_LIST,
  SET_PREMIUM_LIST,
  SET_TOP_CONTRIBUTERS,
  SET_FOCUS,
} from '../actions/types';

const initialState = {
  trending: [],
  premium: [],
  contributers: [],
  focus: null,
};

const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TRENDING_LIST:
      return {
        ...state,
        trending: payload,
      };
    case SET_PREMIUM_LIST:
      return {
        ...state,
        premium: payload,
      };
    case SET_TOP_CONTRIBUTERS:
      return {
        ...state,
        contributers: payload,
      };
    case SET_FOCUS:
      return {
        ...state,
        focus: payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
