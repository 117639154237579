import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './Modal.css';

class ModalComponent extends Component {
  onHide = () => {
    const { onHide } = this.props;
    if (onHide) onHide();
  };

  render() {
    const { size, show, children, title, className, noHeader } = this.props;
    return (
      <>
        <Modal show={show} size={size} className={className}>
          {!noHeader && (
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
              <svg
                onClick={this.onHide}
                className="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 11 11"
                opacity=".5"
              >
                <g
                  fill="none"
                  fillRule="evenodd"
                  stroke="#979797"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#979797"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  >
                    <path d="M1.5 1.5L10 10M9.5 1.5L1 10" />
                  </g>
                </g>
              </svg>
            </Modal.Header>
          )}
          <Modal.Body>{children}</Modal.Body>
        </Modal>
      </>
    );
  }
}

ModalComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  size: PropTypes.string,
  className: PropTypes.string,
  noHeader: PropTypes.bool,
};

ModalComponent.defaultProps = {
  title: '',
  className: '',
  size: 'lg',
  noHeader: false,
};

export default ModalComponent;
