/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';

import Input from '../../common/Input/Input';
import { isEmpty, isEmailValid, isValidUsername } from '../../../utils/validators';
import { isUsernameAvailable, socialSignup, clearError } from '../../../store/actions/authActions';
import './ChooseUsername.css';

const validation = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'This field is required';
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Enter a valid email address';
  }
  if (!isEmpty(values.username)) {
    errors.username = 'Please Choose an username';
  } else if (!isValidUsername(values.username)) {
    errors.username = 'Invalid username';
  }
  if (!values.isAgreed) {
    errors.isAgreed = 'Please accept terms and conditions';
  }
  return errors;
};

let ChooseUsername = props => {
  const [available, setAvailability] = useState('');

  const checkAvailability = event => {
    const username = event.target.value;
    if (!isValidUsername(username)) setAvailability('');
    if (isValidUsername(username)) {
      props.isUsernameAvailable(username, err => {
        if (err) {
          setAvailability('error');
        } else {
          setAvailability('success');
        }
      });
    }
  };

  const onSubmit = values => {
    Object.assign(values, props.socialUser);
    const params = new URLSearchParams(props.history.location.search);
    values.u_type = params.get('u_type');
    props.socialSignup(values, (err, user) => {
      if (user && user.u_type === 'practitioner' && !user.u_prac_profile_completion_status) {
        props.history.push('/create-practitioner-profile');
        return;
      }
      props.history.push(`/user-feeds`);
    });
  };

  const { handleSubmit, submitting, invalid, socialUser, _error, clearError } = props;

  useEffect(() => {
    return () => {
      clearError();
    };
  }, []);

  if (!socialUser) {
    return <Redirect to="/signup" />;
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-5 col-md-6 col-10">
          <div className="py-5">
            <div className="text-center mb-4">
              <img src="/icons/logo.svg" className="mb-5 w-50" alt="Logo" />
              <h2 className=".h2">Pick your Username</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {_error && _error.errorMsg && (
                <span className="error-feedback d-flex align-items-center">
                  <img className="mr-1" src="/icons/cancel.svg" height="16" alt="error-icon" />
                  {_error.errorMsg}
                </span>
              )}
              <div className="mb-2">
                <label className="text-left text-secondary">Email</label>
                <Field
                  name="email"
                  type="text"
                  icon="icon-mail"
                  placeholder="Email"
                  component={Input}
                  disabled
                />
              </div>
              <label id="username" className="text-left text-secondary">
                Username
              </label>
              <div className="position-relative">
                {available === 'success' && (
                  <i className="icon-check_circle text-success validation-badge" />
                )}
                {available === 'error' && (
                  <i className="icon-times-circle text-danger validation-badge" />
                )}
                <Field
                  name="username"
                  for="username"
                  icon="icon-user"
                  onChange={checkAvailability}
                  placeholder="Username"
                  component={Input}
                />
              </div>
              <div className="mt-3">
                <Field
                  name="isAgreed"
                  type="checkbox"
                  component={Input}
                  label={
                    <span className="checkbox-text ml-1">
                      I hereby agree all the
                      <Link to="/termsofservice" target="_blank" className="checkbox-text-tc ml-1">
                        Terms and conditions.
                      </Link>
                    </span>
                  }
                />
              </div>

              <button
                type="submit"
                className="btn gt-btn w-100 mt-4"
                disabled={submitting || invalid || available === 'error'}
              >
                Signup
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ChooseUsername.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isUsernameAvailable: PropTypes.func.isRequired,
  socialUser: PropTypes.shape({}).isRequired,
  socialSignup: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

ChooseUsername = reduxForm({
  form: 'usernameForm',
  validate: validation,
  enableReinitialize: true,
})(ChooseUsername);

const mapStateToProps = state => ({
  socialUser: state.auth.socialUser,
  _error: state.auth.error,
  initialValues: {
    email: state.auth.socialUser && state.auth.socialUser.email,
  },
});

export default connect(
  mapStateToProps,
  { isUsernameAvailable, socialSignup, clearError }
)(ChooseUsername);
