/* eslint-disable no-plusplus */
export const isEmailValid = value => {
  const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return pattern.test(value.toLowerCase());
};

export const isNumeric = value => {
  const pattern = /^\d+$/;
  return pattern.test(value);
};

export const isEmpty = value => value && (typeof value === 'string' && value.trim() !== '');
// value === undefined ||
// value === null ||
// (typeof value === 'object' && Object.keys(value).length === 0) ||
// (typeof value === 'string' && value.trim().length === 0);
export const isObject = value => typeof value === 'object' && Object.keys(value).length === 0;

export const minLength = (value, length) => value.length >= length;

export const maxLength = (value, length) => value.length <= length;

export const isUrlValid = value => {
  const regexp = /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/;
  return value.match(regexp) && value.match(regexp)[2];
};

export const isValidURL = value => {
  const regexp = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  return regexp.test(value);
};

export const isValidVideoURL = value => {
  if(value.match(/youtube.com\/watch/g) || value.match(/youtu.be\//g) || value.match(/vimeo/g)){
    return true
  } else { return false }
}

// export const isVideoURL = value => {
//   const regexp = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
//   return regexp.test(value);
// }

export const isValidDOB = dob => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age >= 16;
};

export const isValidYear = year => {
  const number = /^[0-9]+$/;
  const currentYear = new Date().getFullYear();
  return (
    number.test(Number(year)) &&
    year.toString().length === 4 &&
    (year > 1920 && year <= currentYear)
  );
};

export const isValidUsername = username => {
  const regx = /^[a-z0-9_-]{3,16}$/gim;
  return regx.test(username);
};

export const isName = name => {
  const regx = /^[a-zA-Z]+[a-zA-Z0-9]+$/gim;
  return regx.test(name);
};

export const isTwitterUrl = url => {
  const regx = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/(\w+)$/i;
  return regx.test(url);
};

export const isFacebookUrl = url => {
  const regx = /http(?:s)?:\/\/(?:www\.)?facebook.com\/(\w+)$/i;
  return regx.test(url);
};

export const isInstagramUrl = url => {
  const regx = /http(?:s)?:\/\/(?:www\.)?instagram\.com\/[a-z\d-_]{1,255}\s*$/i;
  return regx.test(url);
};

export const isLinkedInUrl = url => {
  const regx = /http(?:s)?:\/\/(?:www\.)?linkedin\.com\/in\/[A-z0-9_-]+$/i;
  return regx.test(url);
};

export const isValidPhoneNumber = url => {
  const regx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i;
  return regx.test(url);
};