import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function Focus({ focus }) {
  return (
    <Link to={`/category/${focus.focus}`}>
      <div className="focus-item">
        <img src={focus.focus_image_url} className="" alt="" />
        <p className="text-capitalize">{focus.focus_display_name}</p>
      </div>
    </Link>
  );
}

Focus.propTypes = {
  focus: PropTypes.shape({
    focus: PropTypes.string,
    focus_image_url: PropTypes.string,
    focus_display_name: PropTypes.string,
  }).isRequired,
};

export default Focus;
