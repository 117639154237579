/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Tab, Tabs } from 'react-bootstrap';
import Navbar from '../../shared/Navbar/Navbar';
import AddCreditCard from '../../common/AddCard/AddCreditCard';
import { getPlanDetails } from '../../../store/actions/appActions';
import { getAllCreditCards } from '../../../store/actions/settingsActions';
import './PaymentCheckout.css';
import CreditCardDetails from '../../common/CreditCardDetails/CreditCardDetails';
import Footer from '../../shared/Footer/Footer';
import ModalComponent from '../../shared/Modal/Modal';
import { onPayment } from '../../../store/actions/paymentActions';
import PageLoader from '../../shared/PageLoader/PageLoader';
import Button from '../../common/Button/Button';

class PaymentCheckout extends Component {
  constructor() {
    super();
    this.state = {
      openModal: false,
    };
  }

  componentDidMount() {
    const { getPlanDetails, match, getAllCreditCards, premiumUser, history } = this.props;
    const { planId } = match.params;
    const params = history.location.pathname.split('=');
    if (params[1]) {
      this.params = params[1];
    } else {
      this.params = new URLSearchParams(history.location.search).get('contentID');
    }
    if (premiumUser) {
      history.push('/settings/membership');
    }
    getAllCreditCards();
    getPlanDetails(planId);
  }

  onChooseCard = () => {
    const { onPayment, paymentPricePlan } = this.props;

    this.setState({ openModal: true });
    onPayment(paymentPricePlan.id);
  };

  onHide = () => {
    const { paymentMessage, history } = this.props;
    if (paymentMessage.status === 'success') {
      if (this.params) {
        history.push(`/content/${this.params}`);
        return false;
      }
      history.push('/settings/membership');
    }
    return false;
  };

  loadSkelton = () => {
    const { cards, creditCardLoader } = this.props;
    if (creditCardLoader) {
      if (cards.length) {
        return cards.map(card => (
          <div key={card.id} className="col-12 pb-3">
            <div className="card-container p-4 shine mt-3 h-100 w-100" />
          </div>
        ));
      }
      return (
        <div className="col-12 pb-3">
          <div className="card-container p-4 shine mt-3 h-100 w-100" />
        </div>
      );
    }
    return null;
  };

  render() {
    const { loading, paymentPricePlan, cards, paymentMessage, creditCardLoader } = this.props;
    const { openModal } = this.state;
    return (
      <div style={{ backgroundColor: 'rgb(242, 244, 248)' }}>
        <Navbar />
        <div className="container mt-5 checkout-container">
          <div className="row">
            <h4 className="font-weight-bold">Make Payment</h4>
          </div>
          <div className="row">
            <div className="col-12">
              {!loading ? (
                <div className="price-plan-detail mt-2 p-3 d-flex justify-content-between">
                  <div>
                    <span className="d-flex align-items-center">
                      <h2 className="font-weight-bold text-uppercase mb-1">
                        ${paymentPricePlan && (paymentPricePlan.amount / 100).toFixed(2)}
                      </h2>
                      <p className="font-weight-bold mb-0 ml-2">
                        {paymentPricePlan && paymentPricePlan.nickname}
                      </p>
                    </span>
                    <p className="plan-description mb-1">
                      {paymentPricePlan &&
                        paymentPricePlan.metadata &&
                        paymentPricePlan.metadata.description}
                    </p>
                  </div>
                  <img src="/icons/premium-member.svg" alt="premium" />
                </div>
              ) : (
                <div className="w-100 shine p-5" />
              )}
            </div>
          </div>
          <div className="row pt-md-3 pt-0 mt-3 checkout">
            <div className="col-12 pb-5">
              <Tabs
                className="payment-tab"
                defaultActiveKey="creditCard"
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="creditCard" title="Credit card">
                  <div className="row checkout-details mx-0 p-md-4 pb-5">
                    {!creditCardLoader
                      ? cards.map((card, index) => (
                          <div key={card.id} className="col-12 pb-md-0 pb-2">
                            <CreditCardDetails index={index} key={card.id} card={card} />
                          </div>
                        ))
                      : this.loadSkelton()}
                    <div className="col-12">
                      {cards.length <= 9 ? (
                        <AddCreditCard />
                      ) : (
                        <p className="text-secondary font-italic mt-4">
                          Maximum cards added. Remove unused/expired card to add a new card
                        </p>
                      )}
                    </div>
                    <div className="col-12 d-flex justify-content-end pt-3">
                      <Button onClick={this.onChooseCard} disabled={!cards.length}>
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="paypal" title="Paypal">
                  <h1>Paypal</h1>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <ModalComponent onHide={this.onHide} show={openModal} title="Payment">
          <div className="d-flex flex-column justify-content-center align-items-center p-5">
            {paymentMessage.status === 'success' ? (
              <img className="success-pop" src="/images/feedSucces.svg" alt="success" />
            ) : (
              <PageLoader className="checkout" />
            )}
            <h4 className="font-weight-bold pt-4">{paymentMessage.head}</h4>
            <p className="text-black-50 text-center">{paymentMessage.subHead}</p>
          </div>
        </ModalComponent>
        <Footer />
      </div>
    );
  }
}

PaymentCheckout.propTypes = {
  getPlanDetails: PropTypes.func.isRequired,
  getAllCreditCards: PropTypes.func.isRequired,
  onPayment: PropTypes.func.isRequired,
  paymentPricePlan: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    nickname: PropTypes.string,
    metadata: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  creditCardLoader: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  paymentMessage: PropTypes.shape({
    status: PropTypes.string,
    head: PropTypes.string,
    subHead: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      planId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
  premiumUser: PropTypes.bool.isRequired,
};

PaymentCheckout.defaultProps = {
  paymentPricePlan: null,
};

const mapStateToProps = state => ({
  loading: state.app.loading,
  paymentPricePlan: state.app.paymentPricePlan,
  cards: state.settings.cards,
  paymentMessage: state.payment.paymentMessage,
  creditCardLoader: state.payment.creditCardLoader,
  premiumUser: state.payment.premiumUser,
});

export default compose(
  connect(
    mapStateToProps,
    { getPlanDetails, getAllCreditCards, onPayment }
  )
)(PaymentCheckout);
