import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './UserSettings.css';
import { getOrderHistory } from '../../../store/actions/settingsActions';

class OrderHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    // Get all premium and exclusive purchases.
    const { getOrderHistory } = this.props;
    this.setState({ loading: true });
    getOrderHistory(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { orders } = this.props;
    const { loading } = this.state;
    const Orders = orders.map(order => (
      <tr key={order._id}>
        <td>
          <Link to={`/content/${order.purchased_content_id._id}`} className="text-dark">
            {order.purchased_content_id.content_title}
          </Link>
        </td>
        <td>
          {order.purchased_content_owner.u_first_name}{' '}
          {order.purchased_content_owner.u_last_name || ''}
        </td>
        <td>{moment(order.created_at).format('DD MMM YYYY')}</td>
      </tr>
    ));

    let OrdersTable = (
      <div className="table-responsive-md order-history-list">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Content Name</th>
              <th>Published By</th>
              <th>Order Date</th>
            </tr>
          </thead>
          <tbody>{Orders}</tbody>
        </table>
      </div>
    );
    if (Orders.length === 0) {
      OrdersTable = (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '30vh' }}
        >
          <p className="font-medium">No Orders History</p>
        </div>
      );
    }

    return (
      <div id="order-history">
        <h1 className="order-history-title">Order History</h1>
        <p className="order-history-subtitle">All your Premium & Exclusive purchases.</p>
        {!loading && OrdersTable}
        {loading && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '30vh' }}
          >
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.settings.orders,
});

export default connect(
  mapStateToProps,
  { getOrderHistory }
)(OrderHistory);
