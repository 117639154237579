/* eslint-disable no-underscore-dangle */
import { toast } from 'react-toastify';
import axios from '../../axios';
import {
  SET_ASSESSMENT_QUESTIONS,
  SET_ASSESSMENT_ANSWERS,
  SET_ASSESSMENT_PROGESS,
  SET_CURRENT_USER,
  SET_LOADER,
  SET_ASSESSMENT_ERROR,
  ANSWERING,
  SET_ASSESSEMENT_RESULT,
  SET_ASSESSMENT_ALL_ANSWERS,
  SET_ASSESSMENT_PAGE_ACTIVE,
  SET_PROGRESS_CHECK_ACTIVE,
  SET_PROGRESS_CHECK_ANSWER,
  GET_PROGRESS_CHECK_QUESTIONS,
  SET_PROGRESS_CHECK_LAST_DATE,
  SET_RECOMMENDED_CONTENT,
  SET_RECOMMENDED_PRACT,
} from './types';
import { setCurrentUser } from './authActions';

export const setQuestionsAnswer = answer => {
  return {
    type: SET_ASSESSMENT_ANSWERS,
    payload: answer,
  };
};

export const setPage = step => {
  return {
    type: SET_ASSESSMENT_PAGE_ACTIVE,
    payload: step,
  };
};

export const setAnswering = isAnswering => {
  return {
    type: ANSWERING,
    payload: isAnswering,
  };
};

export const setAssessmentProgress = () => {
  return {
    type: SET_ASSESSMENT_PROGESS,
  };
};

export const setLoader = loaderState => {
  return {
    type: SET_LOADER,
    payload: loaderState,
  };
};

export const setAssessmentError = error => {
  return {
    type: SET_ASSESSMENT_ERROR,
    payload: error,
  };
};

export const setAssessmentAnswer = (answer, callback) => dispatch => {
  dispatch(setAnswering(true));
  axios
    .post('/api/assessment/answer', answer)
    .then(response => {
      dispatch(setAnswering(false));
      if (response.data.status) {
        dispatch(setQuestionsAnswer(response.data.answer));
        dispatch(setAssessmentProgress());
      }
      callback();
    })
    .catch(() => {
      dispatch(setAnswering(false));
    });
};

export const startAssessment = cb => async dispatch => {
  dispatch(setLoader(true));
  const requests = [axios.get('/api/assessment/questions'), axios.get('/api/assessment/answers')];
  try {
    const [response1, response2] = await axios.all(requests);
    dispatch({
      type: SET_ASSESSMENT_QUESTIONS,
      payload: response1.data.questions,
    });
    dispatch({
      type: SET_ASSESSMENT_ALL_ANSWERS,
      payload: response2.data.answers,
    });
    dispatch(setLoader(false));
    cb();
    if (response2.data.answers.length) {
      dispatch(setAssessmentProgress());
    }
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getAssessmentQuestions = () => dispatch => {
  const token = localStorage.getItem('token');
  dispatch(setLoader(true));
  axios
    .get('/api/assessment/questions')
    .then(response => {
      dispatch(setLoader(false));
      dispatch({
        type: SET_ASSESSMENT_QUESTIONS,
        payload: response.data.questions,
      });
      if (token) {
        dispatch(setPage(5));
      } else {
        dispatch(setPage(2));
      }
    })
    .catch(error => {
      dispatch(setLoader(false));
    });
};

export const getAssessmentAnswers = () => async dispatch => {
  try {
    const response = await axios.get('/api/assessment/answers');
    dispatch({
      type: SET_ASSESSMENT_ALL_ANSWERS,
      payload: response.data.answers,
    });
  } catch (error) {
    console.log(error);
  }
};

// not using now
export const setAssessmentUser = assessmentUser => async dispatch => {
  try {
    const response = await axios.post('/api/user', assessmentUser);
    localStorage.clear();
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    dispatch({
      type: SET_CURRENT_USER,
      payload: response.data.user,
    });
    if (response.data.user.newUser) {
      dispatch(setPage(3));
    } else {
      dispatch(setPage(5));
    }
  } catch (error) {
    dispatch(
      setAssessmentError({
        errorCode: 401,
        errorMsg: 'Login Failed.',
      })
    );
  }
};

export const updateUser = userInfo => async dispatch => {
  try {
    dispatch(setLoader(true));
    const response = await axios.put('/api/user', userInfo);
    const { user } = response.data;
    dispatch(setLoader(false));
    localStorage.setItem('user', JSON.stringify(user));
    dispatch(setCurrentUser(user));
    dispatch(setPage(5));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getAssessmentResult = (username, cb) => dispatch => {
  axios
    .get(`/api/assessment/result/${username}`)
    .then(response => {
      if (response.data.status) {
        dispatch({
          type: SET_ASSESSEMENT_RESULT,
          payload: response.data.result,
        });
      }
      if (
        response.data.result &&
        response.data.result.categories &&
        response.data.result.categories.length
      ) {
        cb(null, response.data.result.categories[0]._id);
        return;
      }
      cb(null);
    })
    .catch(err => {
      if (err && err.response && err.response.status === 400) {
        cb({ ERR_CODE: 'NOTFOUND' });
        return;
      }
      cb(err);
    });
};

export const setAssessmentRecommendedContent = contents => ({
  type: SET_RECOMMENDED_CONTENT,
  payload: contents,
});

export const setAssessmentRecommendedPrac = practitioners => ({
  type: SET_RECOMMENDED_PRACT,
  payload: practitioners,
});

export const getRecommendedContents = (categoryId, page, cb) => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `/api/content/assessment-result-recommended/${categoryId}?page=${page}`
    );
    const { contents, hasMore } = response.data;
    const { recommendedContents } = getState().assessment;
    const updated = [...recommendedContents, ...contents];
    dispatch(setAssessmentRecommendedContent(updated));
    cb(null, hasMore);
  } catch (error) {
    cb(error);
    toast.error('Something went wrong');
  }
};

export const fetchRecommended = (categoryId, loc, cb) => async dispatch => {
  try {
    const location = {};
    if(loc) {
      location.lat = loc.lat;
      location.lng = loc.lng;
    }
    const req0 = axios.get(`/api/content/assessment-result-recommended/${categoryId}`);
    const req1 = axios.get(`/api/practitioner/assessment-result-recommended/${categoryId}?lat=${location.lat}&lng=${location.lng}`);
    const [res0, res1] = await axios.all([req0, req1]);

    const { contents, hasMore } = res0.data;
    const { practitioners, hasMore: morePrac } = res1.data;
    dispatch(setAssessmentRecommendedContent(contents));
    dispatch(setAssessmentRecommendedPrac(practitioners));
    cb(null, hasMore, morePrac);
  } catch (error) {
    console.log('error', error);
    cb(error);
    toast.error('Something went wrong');
  }
};

export const getRecommendedPract = (categoryId, page, loc, cb) => async (dispatch, getState) => {
  try {
    const location = {};
    if(loc) {
      location.lat = loc.lat;
      location.lng = loc.lng;
    }
    const response = await axios.get(
      `/api/practitioner/assessment-result-recommended/${categoryId}?page=${page}&lat=${location.lat}&lng=${location.lng}`
    );
    const { practitioners, hasMore } = response.data;
    const { recommendedPract } = getState().assessment;
    const updated = [...recommendedPract, ...practitioners];
    dispatch(setAssessmentRecommendedPrac(updated));
    cb(null, hasMore);
  } catch (error) {
    cb(error);
    toast.error('Something went wrong');
  }
};

export const clearRecommended = () => dispatch => {
  dispatch(setAssessmentRecommendedContent([]));
};

export const getProgressCheckQuestions = () => dispatch => {
  dispatch(setLoader(true));
  axios.get('api/progress-check/questions').then(res => {
    const { data } = res;
    if (data.status) {
      dispatch({
        type: SET_PROGRESS_CHECK_LAST_DATE,
        payload: res.data.lastCheckIn,
      });
      dispatch(setLoader(false));
      if (!data.incomplete || data.answers.length === 0) {
        data.questions = data.questions.map(question => ({
          ...question,
          answer: false,
          answer_id: data.answer_id,
        }));
        dispatch({
          type: GET_PROGRESS_CHECK_QUESTIONS,
          payload: data.questions,
        });
      } else {
        data.questions = data.questions.map(question => {
          const answerIndex = data.answers.findIndex(
            answer => answer.sub_category_id === question._id
          );
          if (answerIndex > -1) {
            return {
              ...question,
              answer: data.answers[answerIndex].answer,
              answer_id: data.answer_id,
            };
          }
          return {
            ...question,
            answer: false,
            answer_id: data.answer_id,
          };
        });
        dispatch({
          type: GET_PROGRESS_CHECK_QUESTIONS,
          payload: data.questions,
        });
      }
    }
  });
};

export const setProgressCheckPage = active => dispatch => {
  dispatch({
    type: SET_PROGRESS_CHECK_ACTIVE,
    payload: active,
  });
};

export const setProgressCheckAnswer = (questionIndex, face, question, index) => dispatch => {
  const params = {
    answer: face,
    answer_id: question.answer_id,
    category_id: question._id,
    index,
  };
  dispatch({
    type: SET_PROGRESS_CHECK_ANSWER,
    payload: { index: questionIndex, selected: face },
  });
  dispatch(setAnswering(true));
  axios.post('/api/progress-check/answer', params).then(res => {
    if (res.data.status) {
      dispatch(setAnswering(false));
    }
  });
};

export const calculateProgressCheckResults = (question, cb) => async dispatch => {
  try {
    const params = {
      answer_id: question.answer_id,
    };
    dispatch(setLoader(true));
    await axios.post('/api/progress-check/results', params);
    dispatch(setLoader(false));
    cb(null);
  } catch (error) {
    dispatch(setLoader(false));
    cb(error);
    toast.error('Somthing Went wrong');
  }
};

export const getProgressCheckResults = () => dispatch => {
  axios.get('/api/progress-check/').then(res => {
    console.log('res', res);
  });
};
