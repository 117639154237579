import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssessmentWelcome from './AssesmentWelcome/AssesmentWelcome';
import { updateUser } from '../../../../store/actions/assessmentActions';
import './AssesmentContainer.css';
import AssessmentQuestionForm from '../AssessmentQuestionForm/AssessmentQuestionForm';
import { isAuthenticated } from '../../../../auth.service';
import MemberCreateProfileForm from './MemberCreateProfileForm';

class AssesmentContainer extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  getActiveStep = () => this.props.pages.find(page => page.status === 'progress').step;

  submitClientProfile = values => {
    this.props.updateUser(values);
  };

  onLoad = () => {
    this.container.current.style.backgroundColor = '#00000080';
    this.container.current.style.backgroundImage = 'url(/images/Assessment_background.jpg)';
  };

  componentDidMount() {
    if (!isAuthenticated()) {
      this.props.history.push('/signin');
    }
  }

  render() {
    return (
      <div ref={this.container} className="assesment-welcome-container">
        <img src="/images/Assessment_background.jpg" className="d-none" onLoad={this.onLoad} alt="test" />
        <div className="container h-100">
          <div className="row h-100">
            {this.getActiveStep() === 1 && <AssessmentWelcome />}
            {this.getActiveStep() === 2 && <MemberCreateProfileForm />}
            {this.getActiveStep() === 5 && (
              <div className="col-12 d-flex flex-column h-100">
                <AssessmentQuestionForm />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pages: state.assessment.pages,
  };
};

export default connect(
  mapStateToProps,
  { updateUser }
)(AssesmentContainer);
