import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './PriceCard.css';

const PriceCard = ({ plan, contentId }) => {
  return (
    <div className="pricing-card d-flex flex-column justify-content-around p-3 text-center">
      <h2 className="font-weight-bold" style={{ fontSize: '1.75rem' }}>
        {plan.nickname}
      </h2>
      <p className="price mb-0">${(plan.amount / 100).toFixed(2)}</p>
      <p className="mb-0">({plan.metadata && plan.metadata.description})</p>
      <Link
        to={
          contentId
            ? { pathname: `/payment-checkout/${plan.id}?contentID=${contentId}` }
            : { pathname: `/payment-checkout/${plan.id}` }
        }
      >
        <button type="submit" className="btn price-btn">
          Choose Plan
        </button>
      </Link>
    </div>
  );
};
PriceCard.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string,
    nickname: PropTypes.string,
    amount: PropTypes.number,
    metadata: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  contentId: PropTypes.string,
};

PriceCard.defaultProps = {
  plan: null,
  contentId: undefined,
};

export default PriceCard;
