/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, reduxForm, Field, formValueSelector, change, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import ModalComponent from '../../shared/Modal/Modal';
import Input from '../../common/Input/Input';
import TextArea from '../../common/TextArea/TextArea';
import WYSIWIGEditor from '../../common/WYSIWIGEditor/WYSIWIGEditor';
import {
  getContentForEdit,
  uploadContent,
  changeContentFile,
  updateContent,
  clearContentFile,
  clearEditContent,
} from '../../../store/actions/publishActions';
import RadioSelect from '../../common/RadioSelect/RadioSelect';
import KeywordInput from '../../common/KeywordInput/KeywordInput';
import { MultiSearchDropDown } from '../../common/MultiSearchDropDown/MultiSearchDropDown';
import FileUpload from '../../common/FileUpload/FileUpload';
import './EditContent.css';
import DragNDrop from '../../common/DragNDrop/DragNDrop';
import { createSoundCloudUrl } from '../../../utils/helper';
import { isEmpty, isUrlValid } from '../../../utils/validators';

const validator = values => {
  const errors = {};
  if (!isEmpty(values.content_title)) {
    errors.content_title = 'This Field is required';
  }
  if (!values.content_categories) {
    errors.content_categories = 'This field is required';
  } else if (values.content_categories && !values.content_categories.length) {
    errors.content_categories = 'This field is required';
  }
  // if (!values.content_keywords) {
  //   errors.content_keywords = 'This field is required.';
  // } else if (values.content_keywords && !values.content_keywords.length) {
  //   errors.content_keywords = 'This field is required.';
  // }
  if (!isEmpty(values.content_description)) {
    errors.content_description = 'This field is required.';
  }
  if (!isEmpty(values.content_access_type)) {
    errors.content_access_type = 'This field is required.';
  }
  if (values.content_access_type !== 'free') {
    if (!values.content_price) {
      errors.content_price = 'This field is required.';
    } else if (+values.content_price === 0) {
      errors.content_price = 'Invalid price';
    } else if (isNaN(values.content_price)) {
      errors.content_price = 'Invalid price';
    }
  }
  // if (!isObject(values.thumbnail)) {
  //   errors.thumbnail = 'This field is required';
  // }

  if (isEmpty(values.audio_url)) {
    if (!isUrlValid(values.audio_url)) {
      errors.audio_url = 'Not Valid url';
    }
  }
  return errors;
};

const normalizePrice = value => {
  let onlyNums = value.replace(/[^0-9.]/, '')
  if (/\./.test(onlyNums)) {
    let number = onlyNums.split('.');
    onlyNums = `${number[0]}.${number[1].slice(0,2)}`
  }
  return onlyNums
}

class EditContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.fileUpload = React.createRef();
    this.contentFileUploader = React.createRef();
    this.thumbnailImage = React.createRef();
  }

  contentChangeHandler = event => {
    const { uploadContent, changeContentFile } = this.props;
    uploadContent(event.target.files, file => {
      if (file) {
        changeContentFile(file);
      }
    });
  };

  changeFile = event => {
    event.preventDefault();
    this.contentFileUploader.current.click();
  };

  saveChangesHandler = values => {
    const { updateContent, contentId } = this.props;
    updateContent(values, contentId, 'PUBLISHED', err => {
      this.onHideModal();
    });
  };

  saveAsDraft = event => {
    const { disableDraft, updateContent, formValues, contentId } = this.props;
    event.preventDefault();
    event.stopPropagation();
    if (!disableDraft) {
      updateContent(formValues, contentId, 'DRAFT', () => {
        this.onHideModal();
      });
    }
  };

  changeThumbnail = event => {
    const { dispatch } = this.props;
    dispatch(change('edit-content-form', 'thumbnail', event.target.files[0]));
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        this.thumbnailImage.current.src = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onHideModal = () => {
    const { dispatch, clearContentFile, clearEditContent, onHide } = this.props;
    dispatch(clearContentFile());
    clearEditContent();
    if (onHide) onHide();
  };

  render() {
    const {
      editContent,
      categories,
      content_access_type,
      content_type,
      audio_url,
      video_url,
      audio_type,
      video_type,
      contentFile,
      submitting,
      handleSubmit,
      uploadContent,
      show,
      invalid,
      disableDraft,
      changeContentFile,
    } = this.props;
    const contentType = ['free', 'premium', 'exclusive'];
    const acceptType = content_type === 'video' ? 'video/mp4,video/x-m4v,video/*' : 'audio/*';
    const thumbnail =
      (editContent && editContent.content_thumbnail_url) || '/images/thumbnail-placeholder.jpg';
    let PreviewContent = null;

    const isDisabled = () => {
      const { formValues } = this.props;
      return (
        submitting ||
        invalid ||
        (!editContent.content_file_id && content_type !== 'article' && !audio_url && !video_url) 
        // || (!editContent.content_thumbnail_url && !formValues.thumbnail)
      );
    };

    if (
      content_type === 'video' &&
      editContent &&
      editContent.content_file_id &&
      editContent.content_file_id.file_url
    ) {
      PreviewContent = (
        <div className="col-12 mb-3">
          <div className="publish-vedio-wrapper">
            <video controls id="bgvid" src={editContent.content_file_id.file_url} />
          </div>
          <button
            className="btn btn-sm btn-link gt-link float-right ml-auto"
            type="button"
            onClick={this.changeFile}
          >
            Change video
          </button>
        </div>
      );
    }
    if (
      content_type === 'audio' &&
      !audio_url &&
      editContent &&
      editContent.content_file_id &&
      editContent.content_file_id.file_url
    )
      PreviewContent = (
        <div className="col-12 mb-3">
          <audio src={editContent.content_file_id.file_url} controls style={{ width: '100%' }} />
          <button
            className="btn btn-sm btn-link gt-link float-right ml-auto"
            type="button"
            onClick={this.changeFile}
          >
            Change audio
          </button>
        </div>
      );

      const changeVideoUrl = (url) => {
        const urlType = url.match(/youtube/g) ? 'youtube' : url.match(/vimeo/g) ? 'vimeo' : '';
        let videoUrl;
        if (urlType === 'youtube') {
          videoUrl = url.replace("watch?v=", "embed/");
        } else if (urlType === 'vimeo') {
          const vimeo = url.split('/');
          const vimeoID = vimeo[vimeo.length - 1];
          videoUrl = "https://player.vimeo.com/video/" + vimeoID
        }
        return videoUrl
      }

    return (
      <div>
        <ModalComponent
          className="publish-modal"
          title="Edit Content"
          show={show}
          onHide={this.onHideModal}
        >
          <div>
            {editContent && (
              <Form
                onSubmit={handleSubmit(this.saveChangesHandler)}
                className="content-form publish-content-form-wrapper"
              >
                <div className="row">
                  <div className="col-12 mb-3">
                    <h6>TITLE</h6>
                    <Field name="content_title" placeholder="Title" type="text" component={Input} />
                  </div>
                  <div className="col-12 mb-3">
                    <h6>DESCRIPTION</h6>
                    <Field name="content_description" component={TextArea} />
                  </div>
                  {content_type === 'article' && (
                    <div className="col-12 mb-3">
                      <h6>CONTENT</h6>
                      <WYSIWIGEditor editorState={editContent.article_content} />
                    </div>
                  )}

                  {content_type === 'audio' && (
                    <>
                      <div className="col-12 mb-3">
                        <Field
                          name="audio_type"
                          component={props => (
                            <RadioSelect
                              {...props}
                              options={['upload file', 'sound cloud url']}
                              other={false}
                              varient="content-form"
                            />
                          )}
                        />
                      </div>

                      {audio_type === 'sound cloud url' && (
                        <div className="col-12 mb-3">
                          <h6>Insert from url</h6>
                          <Field
                            name="audio_url"
                            placeholder="Insert your soundcloud url here."
                            type="text"
                            component={Input}
                          />
                        </div>
                      )}
                      {content_type === 'audio' && audio_url && audio_type === 'sound cloud url' && (
                        <div className="col-12 mb-3">
                          <iframe
                            width="100%"
                            height="124"
                            scrolling="no"
                            title="audio"
                            frameBorder="no"
                            allow="autoplay"
                            src={createSoundCloudUrl(audio_url)}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {content_type === 'video' && (
                    <>
                      <div className="col-12 mb-3">
                        <Field
                          name="video_type"
                          component={props => (
                            <RadioSelect
                              {...props}
                              options={['upload file', 'video url']}
                              other={false}
                              varient="content-form"
                            />
                          )}
                        />
                      </div>

                      {video_type === 'video url' && (
                        <div className="col-12 mb-3">
                          <h6>Insert from url</h6>
                          <Field
                            name="video_url"
                            placeholder="Insert your video url here."
                            type="text"
                            component={Input}
                          />
                        </div>
                      )}
                      {content_type === 'video' && video_type === 'video url' && video_url && (
                        <div className="col-12 mb-3">
                          <iframe src={changeVideoUrl(video_url)}
                            frameBorder='0'
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                            title='video'
                            style={{height: 300, width: '100%'}}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {!(editContent.content_file_id && editContent.content_file_id.file_url) &&
                  !contentFile.uploading &&
                  (content_type === 'video' && video_type === 'upload file' ||
                    (content_type === 'audio' && audio_type === 'upload file')) ? (
                    <div className="col-12 mb-3">
                      <DragNDrop
                        accept={acceptType}
                        onFileChoose={files =>
                          uploadContent(files, file => {
                            if (file) {
                              changeContentFile(file);
                            }
                          })
                        }
                      >
                        <div className="h-100 d-flex flex-column justify-content-center align-items-center dragzone">
                          <img src="/icons/cloud-upload.svg" alt="audio" />
                          <h5 className="mb-0">Drag & drop</h5>
                          <p>
                            Your File, <span style={{ color: '#d4af37' }}>or Browse</span>
                          </p>
                        </div>
                      </DragNDrop>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept={acceptType}
                        ref={this.contentFileUploader}
                        className="d-none"
                        onChange={this.contentChangeHandler}
                      />
                      {PreviewContent}
                    </>
                  )}
                  {contentFile.uploading && (
                    <div className="col-12 mb-3">
                      <ProgressBar
                        striped
                        label={`Uploading ${contentFile.progress}%`}
                        animated
                        now={contentFile.progress}
                        srOnly={false}
                      />
                    </div>
                  )}
                  <div className="col-12 mb-3">
                    <h6>CATEGORIES</h6>
                    <Field
                      name="content_categories"
                      component={prop => (
                        <MultiSearchDropDown
                          varient="primary"
                          options={categories}
                          placeholder="Categories"
                          onSelect={value => {
                            prop.input.onChange(value);
                          }}
                          value={prop.input.value}
                          {...prop}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <h6>SPECIAL KEYWORDS</h6>
                    <Field
                      name="content_keywords"
                      component={prop => (
                        <KeywordInput
                          value={prop.input.value}
                          onChange={value => {
                            prop.input.onChange(value);
                          }}
                          {...prop}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <h6>THUMBNAIL</h6>
                    <div className="d-none">
                      <Field
                        name="thumbnail"
                        type="file"
                        accept="image/*"
                        placeholder="choose your thumbnail image"
                        component={FileUpload}
                      />
                    </div>
                    <div className="change-thumbnail">
                      <input
                        ref={this.fileUpload}
                        type="file"
                        className="d-none"
                        onChange={this.changeThumbnail}
                      />
                      <div className="file-change d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          className="btn rounded-circle p-2 bg-white"
                          onClick={event => {
                            event.preventDefault();
                            this.fileUpload.current.click();
                          }}
                        >
                          <img src="/icons/edit.svg" alt="edit" />
                        </button>
                      </div>
                      <img className="thumbnail" ref={this.thumbnailImage} src={thumbnail} alt="" />
                    </div>
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <h6>CHOOSE CONTENT TYPE</h6>
                    <Field
                      name="content_access_type"
                      component={props => (
                        <RadioSelect
                          {...props}
                          options={contentType}
                          other={false}
                          varient="content-form"
                        />
                      )}
                    />
                  </div>
                  {(content_access_type === 'exclusive' || content_access_type === 'premium') && (
                    <div className="col-6 col-md-4 mb-3">
                      <h6>AMOUNT</h6>
                      <Field name="content_price" placeholder="$" component={Input} normalize={normalizePrice}/>
                    </div>
                  )}

                  <div className="col-12 px-2">
                    <div className="border-top d-flex pt-3">
                      <button
                        type="button"
                        onClick={this.saveAsDraft}
                        className="btn btn-outline-primary"
                        disabled={disableDraft}
                      >
                        Save as Draft
                      </button>
                      <div className="ml-auto">
                        <button type="button" className="btn btn-cancel" onClick={this.onHideModal}>
                          Cancel
                        </button>
                        <button type="submit" className="btn gt-btn" disabled={isDisabled()}>
                          {!submitting && <span>Save Changes</span>}
                          {submitting && (
                            <span>
                              <span className="spinner-border spinner-border-sm mr-2" />
                              Saving...
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
            {!editContent && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '50vh' }}
              >
                <div className="spinner-grow text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </ModalComponent>
      </div>
    );
  }
}

EditContent.propTypes = {
  show: PropTypes.bool.isRequired,
};

EditContent = reduxForm({
  form: 'edit-content-form',
  enableReinitialize: true,
  validate: validator,
})(EditContent);

const mapStateToProps = state => {
  const editContent = state.publish.editContent;

  const selector = formValueSelector('edit-content-form');
  const audio_url = selector(state, 'audio_url');
  const video_url = selector(state, 'video_url');
  const content_access_type = selector(state, 'content_access_type');
  const audio_type = selector(state, 'audio_type');
  const video_type = selector(state, 'video_type');
  const initialValues = {};

  if (editContent) {
    initialValues.content_title = editContent.content_title;
    initialValues.content_description = editContent.content_description;
    initialValues.content_categories = editContent.content_categories.map(cat => cat._id);
    initialValues.content_keywords = editContent.content_keywords;
    initialValues.content_access_type = editContent.content_access_type;
    initialValues.content_price = editContent.content_price;
    initialValues.audio_type = 'upload file';
    initialValues.video_type = 'upload file';
    if (editContent.content_type === 'audio' && editContent.content_soundcloud_url) {
      initialValues.audio_type = 'sound cloud url';
      initialValues.audio_url = editContent.content_soundcloud_url;
    }
    if (editContent.content_type === 'video' && editContent.content_video_url) {
      initialValues.video_type = 'video url';
      initialValues.video_url = editContent.content_video_url;
    }
  }
  const content_title = selector(state, 'content_title');
  const content_description = selector(state, 'content_description');
  let disableDraft = true;
  if (content_title && content_description && content_title.trim() && content_description.trim()) {
    disableDraft = false;
  }

  return {
    initialValues,
    editContent,
    categories: state.publish.categories,
    audio_url,
    video_url,
    content_access_type,
    audio_type,
    video_type,
    content_type: editContent && editContent.content_type,
    contentFile: state.publish.contentFile,
    disableDraft,
    formValues: getFormValues('edit-content-form')(state),
  };
};

const mapDispatchToProps = {
  getContentForEdit,
  updateContent,
  uploadContent,
  changeContentFile,
  clearContentFile,
  clearEditContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContent);
