/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Landing from './components/pages/Landing/Landing';
import Login from './components/pages/Login/Login';
import Signup from './components/pages/Signup/Signup';
import PrivateRoute from './PrivateRoute';
import Home from './components/pages/Home/Home';
import Assessment from './components/pages/assesment/AssesmentContainer/AssesmentContainer';
import ProgressCheck from './components/pages/progressCheck/ProgressCheckContainer/ProgressCheckContainer';
import Loader from './components/common/Loader/Loader';
// import PractitionerHome from './components/pages/Practitioner/PractionerHome/PractitionerHome';
import PractitionerRegistrationContainer from './components/pages/Practitioner/PractitionerRegistrationContainer/PractitionerRegistrationContainer';
import CategoryView from './components/pages/CategoryView/CategoryView';
import PractitionerProfile from './components/pages/Practitioner/PractitionerProfile/PractitionerProfile';
import AssessmentResult from './components/pages/assesment/AssessmentResult/AssessmentResult';
import UserProfile from './components/pages/UserProfile/UserProfile';
import ViewArticle from './components/pages/ViewArticles/ViewArticle';
import LinkBusiness from './components/pages/Practitioner/LinkBusiness/LinkBusiness';
import VerifyEmail from './components/pages/VerifyEmail/VerifyEmail';
// import MemberCreateProfile from './components/pages/Member/MemberCreateProfile/MemberCreateProfile';
import FaqComponent from './components/pages/FAQ/Faq';
import Profile from './components/pages/Profile/Profile';
import TermsOfService from './components/pages/TermsOfService/TermsOfService';
import ResolvingConflict from './components/pages/Resolving-conflict/ResolvingConflict';
import CommunityAndContentGuidelines from './components/pages/Community-and-content-guidelines/CommunityAndContentGuidelines';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import SearchResults from './components/pages/SearchResults/SearchResults';
import UserSettings from './components/pages/UserSettings/UserSettings';
import ChooseUsername from './components/pages/Username/ChooseUsername';
import ForgotPassword from './components/pages/ForgotPassword/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword/ResetPassword';
import { setCurrentUser } from './store/actions/authActions';
import Messages from './components/pages/Messages/Messages';
import './socket.client';
import NotFound from './components/common/NotFound/NotFound';
import UserFeeds from './components/pages/UserFeeds/UserFeeds';
import PaymentCheckout from './components/pages/PaymentCheckout/PaymentCheckout';
import { recentMessage } from './store/actions/messageAction';
import JoinBusiness from './components/pages/JoinBusiness/JoinBusiness';
import Recommended from './components/pages/Recommended/Recommended';
import Popular from './components/pages/Popular/Poular';
import HotNew from './components/pages/HotNew/HotNew';
import RecommendedPrac from './components/pages/RecommendedPrac/RecommendedPrac';
import { setUserLocation } from './store/actions/appActions';

class App extends Component {
  componentDidMount() {
    const { setCurrentUser, recentMessage, setUserLocation } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    setCurrentUser(user);
    recentMessage('updatedtime');
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos)=> {
        localStorage.setItem('location', JSON.stringify({
          lat:pos.coords.latitude,
          lng: pos.coords.longitude
        }));
        setUserLocation(pos.coords.latitude, pos.coords.longitude);
      })
    }
  }

  render() {
    const { loading } = this.props;
    return (
      <>
        {loading && <Loader />}
        {
          <Router>
            <Switch>
              <Route path="/" component={Landing} exact />
              <Route path="/signin" component={Login} />
              <Route path="/signup" component={Signup} exact />
              <Route path="/forgot-password" component={ForgotPassword} exact />
              <Route path="/reset-password/:token" component={ResetPassword} exact />
              <Route path="/signup/user/complete" component={ChooseUsername} exact />
              {/* <Route path="/signup/practitioner" exact component={PractitionerHome} />
              <Route path="/member/create_profile" component={MemberCreateProfile} exact /> */}
              <PrivateRoute
                path="/create-practitioner-profile"
                component={PractitionerRegistrationContainer}
              />
              <PrivateRoute path="/link-business" component={LinkBusiness} />
              <Route path="/assessment" exact component={Assessment} />
              <PrivateRoute path="/progress-check" exact component={ProgressCheck} />
              <Route path="/search" component={SearchResults} />
              <PrivateRoute
                path="/assessment-result/:username"
                exact
                component={AssessmentResult}
              />
              <PrivateRoute path="/practitioner/profile" component={PractitionerProfile} />
              <PrivateRoute path="/user/profile/" component={UserProfile} exact />
              <Route path="/category/:name" component={CategoryView} />
              <Route path="/FAQ" exact component={FaqComponent} />
              <Route path="/content/:content_id" exact component={ViewArticle} />
              <PrivateRoute path="/home" component={Home} />
              <Route path="/verify_email/:token" component={VerifyEmail} />
              <Route path="/join-business" component={JoinBusiness} />
              <Route path="/termsofservice" component={TermsOfService} exact />
              <Route
                path="/resolving-conflict-with-another-member"
                component={ResolvingConflict}
                exact
              />
              <Route
                path="/community-and-content-guidelines"
                component={CommunityAndContentGuidelines}
                exact
              />
              <PrivateRoute path="/messages" component={Messages} exact />
              <PrivateRoute path="/user-feeds" component={UserFeeds} exact />
              <PrivateRoute path="/recommended-for-you" component={Recommended} exact />
              <PrivateRoute path="/popular" component={Popular} exact />
              <PrivateRoute path="/hot-and-new" component={HotNew} exact />
              <PrivateRoute path="/recommended-practitioners" component={RecommendedPrac} exact />
              <Route path="/privacy-policy" component={PrivacyPolicy} exact />
              <PrivateRoute path="/settings" component={UserSettings} />
              <PrivateRoute path="/payment-checkout/:planId" component={PaymentCheckout} />
              <Route path="/not-found" component={NotFound} />
              <Route path="/:username" component={Profile} exact />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        }
        {
          <ToastContainer
            position="top-right"
            autoClose={6000}
            hideProgressBar
            closeOnClick
            pauseOnHover
            draggable
          />
        }
      </>
    );
  }
}

const mapSateToProps = state => {
  return {
    loading: state.app.loading,
  };
};

App.propTypes = {
  loading: PropTypes.bool.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  setUserLocation: PropTypes.func.isRequired,
};

export default connect(
  mapSateToProps,
  { setCurrentUser, recentMessage, setUserLocation }
)(App);
