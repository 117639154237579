/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import lastUpdatedTime from '../common/TimeAgo';
import { acceptOrRejectSessionRequest } from '../../store/actions/practitionerRegAction';

function SessionRequestItem({ sessionRequest, acceptOrRejectSessionRequest }) {
  const {
    session_requested_by: { u_first_name, u_last_name, u_image_url, u_username, _id },
    session_requested_at,
    session_request_status,
  } = sessionRequest;
  const senderImage = u_image_url || '/images/user.svg';
  const acceptRejectHandler = (action, member_id = _id) =>
    acceptOrRejectSessionRequest({ action, member_id });

  return (
    <div className="session-request-item p-3 d-flex align-items-center new-item">
      <Link to={`/${u_username}`}>
        <div className="profile-image" style={{ backgroundImage: `url(${senderImage})` }} />
      </Link>
      <div className="ml-2">
        <p className="request-by mb-1">{`${u_first_name} ${u_last_name || ''}`}</p>
        <p className="date mb-0">{moment(session_requested_at).fromNow()}</p>
      </div>
      <div className="ml-auto">
        {session_request_status === 'requested' ? (
          <>
            <button
              type="button"
              className="btn gt-btn gt-btn-sm mr-3"
              onClick={() => acceptRejectHandler('accepted')}
            >
              Accept
            </button>
            <button
              type="button"
              className="btn gt-btn-white gt-btn-sm"
              onClick={() => acceptRejectHandler('rejected')}
            >
              Reject
            </button>
          </>
        ) : (
          <button type="button" className="btn gt-btn-sm accepted">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
              <path
                fill="#876d16"
                fillRule="nonzero"
                d="M2.114 4.9a1.257 1.257 0 0 0-1.751 0 1.194 1.194 0 0 0 0 1.715l3.095 3.03c.49.48 1.288.472 1.768-.018l7.429-7.575a1.194 1.194 0 0 0-.036-1.714 1.257 1.257 0 0 0-1.75.035L4.315 7.056 2.114 4.9z"
              />
            </svg>
            {session_request_status === 'accepted' ? 'Accepted' : 'Rejected'}
          </button>
        )}
      </div>
    </div>
  );
}

SessionRequestItem.propTypes = {
  sessionRequest: PropTypes.shape({
    session_requested_by: PropTypes.shape({
      u_first_name: PropTypes.string,
      u_last_name: PropTypes.string,
      u_image_url: PropTypes.string,
      u_username: PropTypes.string,
      _id: PropTypes.string,
    }),
    session_request_status: PropTypes.string,
    session_requested_at: PropTypes.string,
  }).isRequired,
  acceptOrRejectSessionRequest: PropTypes.func,
};

SessionRequestItem.defaultProps = {
  acceptOrRejectSessionRequest: () => {},
};

export default connect(
  null,
  { acceptOrRejectSessionRequest }
)(SessionRequestItem);
