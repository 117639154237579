/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './UserFeeds.css';
import Navbar from '../../shared/Navbar/Navbar';
import { getFeedContents } from '../../../store/actions/contentAction';
// import FeedTypeTwo from './FeedTypeTwo';
import Footer from '../../shared/Footer/Footer';
import Focus from '../Landing/Focus';
import SlidingCard from '../../shared/SlidingCard/SlidingCard';
import categorySliderSettings from '../Landing/categorySliderSettings';
import { logoutUser } from '../../../store/actions/authActions';
import ContentCard from '../../shared/Cards/ContentCard/ContentCard';
import LandingSkeleton from '../Landing/LandingSkeleton';
import PractitionerCard from '../../shared/Cards/PractitionerCard/PractitionerCard';
import settings from '../../shared/SlidingCard/settings';

class UserFeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  goToDetails = id => {
    const { history } = this.props;
    history.push(`/content/${id}`);
  };

  componentDidMount = async () => {
    const { getFeedContents, logoutUser } = this.props;
    this.setState({ loading: true });
    getFeedContents(err => {
      this.setState({ loading: false });
      if (err && err.errorCode === 401) {
        logoutUser();
      }
    });
  };

  render() {
    const { focuses, recommended, popular, hotNew, recommendedPrac, history } = this.props;
    const { loading } = this.state;
    const Focuses = focuses.map(focus => <Focus key={focus._id} focus={focus} />);

    const RecommendedContents = recommended.map(content => (
      <ContentCard content={content} onClick={() => this.goToDetails(content._id)} />
    ));
    const PopularContents = popular.map(content => (
      <ContentCard content={content} onClick={() => this.goToDetails(content._id)} />
    ));
    const HotNewContents = hotNew.map(content => (
      <ContentCard content={content} onClick={() => this.goToDetails(content._id)} />
    ));
    const RecommendedPrac = recommendedPrac.map(prac => (
      <PractitionerCard onClick={username => history.push(`/${username}`)} practitioner={prac} />
    ));

    return (
      <>
        <Navbar />
        <div className="container my-4" style={{ minHeight: '80vh' }}>
          {loading ? (
            <LandingSkeleton />
          ) : (
            <>
              <section className="overflow-hidden mb-4" id="focuses-section">
                <SlidingCard
                  settings={categorySliderSettings}
                  cardNumber={5.5}
                  heading="Choose Your Focus"
                >
                  {Focuses}
                </SlidingCard>
              </section>
              {RecommendedContents.length > 0 &&
              <section className="mb-4 position-relative">
                <Link to="/recommended-for-you" className="btn btn-link top-right pr-0">
                  View all
                </Link>
                <SlidingCard
                  settings={settings}
                  cardNumber={4}
                  arrows={false}
                  heading="Recommended For You"
                >
                  {RecommendedContents}
                </SlidingCard>
              </section>
              }
              {
                PopularContents.length > 0 &&
                <section className="mb-4 position-relative">
                  <Link to="/popular" className="btn btn-link top-right pr-0">
                    View all
                  </Link>
                  <SlidingCard
                    settings={settings}
                    cardNumber={4}
                    arrows={false}
                    heading="Popular"
                  >
                    {PopularContents}
                  </SlidingCard>
                </section>
              }
              {
                HotNewContents.length > 0 &&
                <section className="mb-4 position-relative">
                  <Link to="/hot-and-new" className="btn btn-link top-right pr-0">
                    View all
                  </Link>
                  <SlidingCard
                    settings={settings}
                    cardNumber={4}
                    arrows={false}
                    heading="Hot & New"
                  >
                    {HotNewContents}
                  </SlidingCard>
                </section>
              }
              {
                RecommendedPrac.length > 0 &&
                <section className="mb-4 position-relative">
                  <Link to="/recommended-practitioners" className="btn btn-link top-right pr-0">
                    View all
                  </Link>
                  <SlidingCard
                    settings={settings}
                    cardNumber={4}
                    arrows={false}
                    heading="Recommended Practitioners"
                  >
                    {RecommendedPrac}
                  </SlidingCard>
                </section>
              }
            </>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

UserFeeds.propTypes = {
  focuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFeedContents: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    focuses: state.app.focuses,
    recommended: state.content.recommendedFeeds,
    popular: state.content.popularFeeds,
    hotNew: state.content.hotNew,
    content: state.content.content,
    recommendedPrac: state.content.recommendedPracFeeds,
  };
};
export default connect(
  mapStateToProps,
  { getFeedContents, logoutUser }
)(UserFeeds);
