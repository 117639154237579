/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import User from './User';
import { List } from './style';

const UsersList = ({ users, active, onSelect, recent, tag }) => {
  let recentUsers = recent || [];
  if (users.length) {
    recentUsers = users;
  }
  console.log('recentUsers :', recentUsers);
  return (
    <List>
      {recentUsers.map(user => {
        return (
          <User
            key={user._id}
            {...user}
            tag={tag}
            active={tag === 'recent' && user.room === active}
            activeRoom={active}
            onSelect={onSelect}
          />
        );
      })}
    </List>
  );
};

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  recent: PropTypes.arrayOf(PropTypes.object),
  active: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  tag: PropTypes.string.isRequired,
};
UsersList.defaultProps = {
  active: '',
  recent: [],
};
export default UsersList;
