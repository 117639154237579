/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import RadioSelect from '../../../common/RadioSelect/RadioSelect';
import BestOwedInstitutions from '../../Practitioner/PractitionerRegistrationContainer/PractitinerRegistration/BestOwedInstitutions';
import './PractitionerProfileSettings.css';
import Input from '../../../common/Input/Input';

const ProfessionalBackground = ({ p_licensed, p_certified }) => {
  return (
    <div className="professional-services">
      <h1 className="professional-title mb-3">Professional Background</h1>
      <div className="mb-4 pb-2">
        <Field
          name="p_licensed"
          type="checkbox"
          component={Input}
          label={
            <p className="mb-0 ml-2" style={{ lineHeight: 1 }}>
              I am a licensed practitioner
            </p>
          }
        />
        {p_licensed && (
          <div className="p-3 ml-4">
            <FieldArray varient="primary" name="p_licenses" component={BestOwedInstitutions} />
          </div>
        )}
      </div>
      <div className="mb-4 pb-2">
        <Field
          name="p_certified"
          type="checkbox"
          component={Input}
          label={
            <p className="mb-0 ml-2" style={{ lineHeight: 1 }}>
              I am a certified practitioner
            </p>
          }
        />
        {p_certified && (
          <div className="p-3 ml-4">
            <FieldArray
              varient="primary"
              name="p_certifications"
              component={BestOwedInstitutions}
            />
          </div>
        )}
      </div>
      <div className="mb-4 pb-2">
        <Field
          name="p_self_taught"
          type="checkbox"
          varient="material"
          component={Input}
          label={
            <p className="mb-0 ml-2" style={{ lineHeight: 1 }}>
              I am a self taught practitioner
            </p>
          }
        />
      </div>
    </div>
  );
};

ProfessionalBackground.propTypes = {
  p_licensed: PropTypes.bool,
  p_certified: PropTypes.bool,
};

ProfessionalBackground.defaultProps = {
  p_licensed: false,
  p_certified: false,
};

const mapStateToProps = state => {
  // const selector = formValueSelector('memberprofilesettings');
  // const pb_type = selector(state, 'professional_type');
  const selector = formValueSelector('memberprofilesettings');
  const p_licensed = selector(state, 'p_licensed');
  const p_certified = selector(state, 'p_certified');
  return {
    p_licensed,
    p_certified,
  };
};

export default connect(mapStateToProps)(ProfessionalBackground);
