// import { SET_MODAL_ACTIVE, SET_CONTENT_FILE } from '../actions/types';
import {
  SET_CONTENT,
  SET_CONTENT_CATEGORIES,
  SET_CONTENT_FILE,
  UPLOAD_PROGRESS,
  CLEAR_CONTENT_FILE,
  SET_PUBLISH_PAGE,
  PUBLISHED_CONTENT,
  SET_EDIT_CONTENT,
  UPDATE_EDIT_CONTENT,
} from '../actions/types';

const initialState = {
  showModal: false,
  publishedContentId: '',
  content: '',
  categories: [],
  contentFile: {
    uploading: false,
    progress: 0,
    file: null,
  },
  pages: [
    { step: 0, status: 'progress' },
    { step: 1, status: 'waiting' },
    { step: 2, status: 'waiting' },
  ],
  editContent: null,
};

const updateContentFileProgress = (state, progress) => {
  const contentFile = {
    ...state.contentFile,
    progress,
  };
  progress === 100 ? (contentFile.uploading = false) : (contentFile.uploading = true);

  return contentFile;
};

const setPageActive = (state, step) => {
  return state.pages.map(page =>
    page.step === step ? { ...page, status: 'progress' } : { ...page, status: 'waiting' }
  );
};

const editContent = (state, file) => {
  const content = JSON.parse(JSON.stringify(state.editContent));
  content.content_file_id = file;
  return content;
};

const publishReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      };
    case SET_CONTENT_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case UPLOAD_PROGRESS:
      return {
        ...state,
        contentFile: updateContentFileProgress(state, action.payload),
      };
    case SET_CONTENT_FILE:
      return {
        ...state,
        contentFile: { ...state.contentFile, file: action.payload },
      };
    case CLEAR_CONTENT_FILE:
      return {
        ...state,
        contentFile: {
          uploading: false,
          progress: 0,
          file: null,
        },
      };
    case SET_PUBLISH_PAGE:
      return {
        ...state,
        pages: setPageActive(state, action.payload),
      };
    case PUBLISHED_CONTENT: {
      return {
        ...state,
        publishedContentId: action.payload,
      };
    }
    case SET_EDIT_CONTENT: {
      return {
        ...state,
        editContent: action.payload,
      };
    }
    case UPDATE_EDIT_CONTENT: {
      return {
        ...state,
        editContent: editContent(state, action.payload),
      };
    }
    default:
      return state;
  }
};

export default publishReducer;
