/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Navbar from '../../shared/Navbar/Navbar';
import {
  Container,
  Title,
  Main,
  Users,
  InputBox,
  Button,
  Input,
  InputSearch,
  ChatBox,
  Name
} from './style';
import {
  setActiveRoom,
  resetActiveRoom,
  searchUser,
  recentMessage,
  searchRecent,
  updateMessage,
  setRead,
  setUnreadMessageCount
  // getMessages,
} from '../../../store/actions/messageAction';
import ChatWindow from '../../Messages/ChatWindow';
import UsersList from '../../Messages/UsersList';
import ModalComponent from '../../shared/Modal/Modal';
import { messageNameSpace } from '../../../socket.client';

class Messages extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      message: '',
      typing: { typing: false, himRoom: '' },
    };
    this.chatWindow = React.createRef();
    this.messageBox = React.createRef();
  }

  componentDidMount() {
    const { messages, recentMessage } = this.props;
    console.log('calling :', this.props);
    recentMessage('updatedtime');
    if (messages.length > 0) {
      this.scrollToBottom();
    }
    this.mounted = true;
  }
  componentWillUnmount(){
    const {resetActiveRoom} = this.props;
    this.mounted = false;
    resetActiveRoom();
  }

  componentDidUpdate(prevProps) {
    const { currentUserId, recent, room, messages, location, setRead } = this.props;
    if (currentUserId !== prevProps.currentUserId && currentUserId) {
      if (!this.socket) {
        this.initSocket();
      }
    }

    if (currentUserId && !this.socket) {
      this.initSocket();
      const { location } = this.props;
      if (location.search) {
        const id = location.search.match(/\?id=(.*)/)[1];
        this.invite(id, true);
      }
    }

    // if (recent.length && !room && this.socket && !location.search) {
    //setActiveRoom(recent[0].room);
    // }

    if (messages.length !== prevProps.messages.length) {
      this.scrollToBottom();
    }

    if (room !== prevProps.room) {
      this.scrollToBottom();
      // if (this.socket && recent.length) {
      if (this.socket) {
        this.socket.emit('room', room);
        this.socket.emit('read', { room, id: currentUserId });
        setRead(room);
      }
    }
  }

  scrollToBottom = () => {
    const { room } = this.props;
    if (this.messageBox && this.messageBox.current && room) {
      this.messageBox.current.focus();
    }

    if (this.chatWindow && this.chatWindow.current) {
      this.chatWindow.current.scrollIntoView({ block: 'end' });
    }
  };

  initSocket = () => {
    const { currentUserId, recentMessage, updateMessage, room, setRead } = this.props;
    this.socket = messageNameSpace;
    if (this.socket.connected) {
      return false;
    }



    this.socket.on(currentUserId, data => {
      if (data.message === 'invite') {
        recentMessage();
      }

      if (data.message === 'initial_message' && room === data.room) {
        this.socket.emit('read', { room: data.room, id: currentUserId });
      }
    });

    this.socket.on('message', data => {
      const { room } = this.props;
      recentMessage();
      if (data.room === room && this.mounted) {
        this.socket.emit('read', { room: data.room, id: currentUserId });
        // console.log(data)
        updateMessage(data);
      }
    });

    this.socket.on('typing', data => {
      const { typing, room } = data;
      this.setState({
        typing: { typing, himRoom: room },
      });
    });

    if (room) {
      this.socket.emit('room', room);
      this.socket.emit('read', { room, id: currentUserId });
      setRead(room);
    }
  };

  invite = (id, activateRoom = false) => {
    const { currentUserId, setActiveRoom, recent } = this.props;
    const found = recent.find(user => user._id === id);
    if (found) {
      // if already invited user
      setActiveRoom(found.room);
      return false;
    }

    const room = `${currentUserId}:${id}`;

    if (this.socket) {
      this.socket.emit('invite', {
        message: 'invite',
        room,
        from: currentUserId,
        to: id,
      });
      if (activateRoom) {
        setActiveRoom(room);
      }
    }
    this.setState({ show: false }, () => {
      if (!room) {
        return false;
      }
      setTimeout(() => {
        this.messageBox.current && this.messageBox.current.focus();
      }, 500);
    });
  };

  showChat = room => {
    const { setActiveRoom, room: prevRoom, currentUserId, setRead, recent, totalUnreadMessage, setUnreadMessageCount } = this.props;
    let index = recent.findIndex(user => user.room === room);
    setUnreadMessageCount(totalUnreadMessage - recent[index].count)
    if (this.socket) {
      this.socket.emit('room', room);
      this.socket.emit('read', { room, id: currentUserId });
    }

    setRead(room);
    if (room === prevRoom) {
      this.messageBox.current.focus();
      return false;
    }
    setActiveRoom(room);
    this.setState({ message: '' });
  };

  sendMessage = () => {
    const { message } = this.state;
    const { currentUserId, room, updateMessage } = this.props;
    if (!message.replace(/\s/g, '').length || !room) return;
    if (this.socket) this.socket.emit('message', { message, room, from: currentUserId });

    updateMessage({ message, room, from: currentUserId });
    this.messageBox.current.focus();
    this.setState({ message: '' }, () => {
      this.onTyping(false);
    });
  };

  onTyping = bool => {
    const { currentUserId, room } = this.props;
    if (this.socket) this.socket.emit('typing', { typing: bool, room, userId: currentUserId });
  };

  render() {
    const {
      user,
      resetActiveRoom,
      searchUser,
      searchResult,
      searchRecent,
      recent,
      room,
      messages,
      currentUserId,
      searchRecentKey,
      recentFilter,
      him,
      loading,
      loadChat
    } = this.props;
    const { show, message, typing } = this.state;
    return (
      <>
        <Navbar />
        <Container>
          <Main className="container">
            <>
              <div className="row">
                <div className="col-md-4 m-1 col-12">
                  <div className="d-flex d-lg-none align-items-center ">
                    {(room && him) ? (
                      <>
                        <span className="icon-back-arrow text pr-2" onClick={() => {
                          resetActiveRoom()
                        }} />
                        <Title className="col-10">{him && him.u_first_name}</Title>
                      </>
                    ) : <Title className="col-10">Messages</Title>}
                  </div>
                  <div className="row align-items-center d-none d-lg-block">
                    <Title active={false} className="col-10">
                      Messages
                    </Title>
                  </div>
                </div>
              </div>

              <div className="bg-white">
                <div className="row no-gutters">
                  {!loading &&
                    <>
                      <Users active={room} className={`col-lg-4  overflow-auto d-lg-block ${room && 'd-none'}`} >
                        <div style={{ top: 0 }} className="p-3 bg-white position-sticky d-flex justify-content-around mr-1">
                          {recent.length > 0 && (
                            <InputSearch
                              value={searchRecentKey}
                              onChange={event => {
                                searchRecent(event.target.value);
                              }}
                              className="w-100 px-3 py-2 mr-2"
                              type="text"
                              placeholder="search"
                            />)}
                          <button className="btn w-50 " style={{ borderRadius: " 0.1875rem", backgroundColor: '#d4af37' }}
                            onClick={() => this.setState({ show: true })}>
                            <img src='/icons/add-user.svg' alt="Add user" />
                          </button>
                        </div>
                        {recent.length < 0 ? <div className="p-5 text-center" style={{ color: "#9c9c9c" }}>
                          <h5>Please select a user to start chatting</h5></div> : (<UsersList
                            tag="recent"
                            users={recentFilter}
                            active={room}
                            onSelect={this.showChat}
                          />)}
                      </Users>
                      <div className={`col-lg-8 d-lg-block ${room ? 'd-block' : 'd-none'}`}>
                        {
                          room && recent.length > 0 ? (
                            <>
                              <div className="pt-3 pb-2 pl-4 d-flex" style={{ borderBottom: '1px solid #eaedf3' }}>
                                <Link to={`/${him.u_username}`} >
                                  <img src={him.u_image_url ? him.u_image_url : '/images/user.svg'} className="rounded-circle" 
                                  style={{ height: 40, width: 40, objectFit: 'cover' }} />
                                </Link>
                                <div className="ml-2">
                                  <Link to={`/${him.u_username}`} >
                                    <Name className="m-0">{him.u_first_name} {him.u_last_name}</Name>
                                  </Link>
                                  {him.updatedtime && <p className="m-0" style={{ color: '#9c9c9c', fontSize: '0.75rem' }}>{moment(him.updatedtime).format('Do MMM YYYY LT')}</p> }
                                </div>
                              </div>
                              <ChatBox className="overflow-auto">
                                {
                                loadChat ?
                                 <div className="align-items-center d-flex h-100 justify-content-center">
                                    <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div> 
                                 </div> : <ChatWindow
                                  currentUserId={currentUserId}
                                  messages={messages}
                                  imageMe={user && user.u_image_url}
                                  imageHim={him && him.u_image_url}
                                />
                                }  
                                <div ref={this.chatWindow} />
                              </ChatBox>
                              <p className="pl-2 text-secondary font-weight-light">
                                {typing.typing && typing.himRoom === room
                                  ? `${him.u_first_name} is typing...`
                                  : ''}
                              </p>
                              <InputBox className="overflow-auto">
                                <div className="d-flex align-items-center h-100">
                                  <Input
                                    placeholder="Type a message..."
                                    ref={this.messageBox}
                                    rows="1"
                                    cols="1"
                                    onKeyUp={e => {
                                      if (e.target.value.length >= 1) {
                                        this.onTyping(true);
                                        return false;
                                      }
                                      this.onTyping(false);
                                    }}
                                    onKeyDown={e => {
                                      if (e.keyCode === 13 && !e.shiftKey) {
                                        this.sendMessage();
                                        e.preventDefault();
                                      }
                                    }}
                                    className="form-control"
                                    value={message}
                                    onChange={e => this.setState({ message: e.target.value })}
                                    style={{height:48}}
                                  />
                                  <Button onClick={this.sendMessage}>
                                    <span className="icon-message text-white" />
                                  </Button>
                                </div>
                              </InputBox></>
                          ) : (
                              <div style={{ height: '79vh' }} className="d-flex flex-column justify-content-center text-center">
                                <img src='/images/inbox.svg' alt="inbox" />
                                <div className="pt-4">
                                  <h6 className="font-weight-bold">Choose a person to start chatting </h6>
                                  <span style={{ color: '#9c9c9c' }}>choose one from existing or start new</span>
                                </div>
                              </div>
                            )
                        }
                      </div>
                    </>
                  }
                </div>
              </div>
            </>
          </Main>
        </Container>
        <ModalComponent
          title="Start a conversation"
          show={show}
          size=""
          onHide={() => {
            const { room } = this.props;
            this.setState({ show: false }, () => {
              if (!room) {
                return false;
              }
              setTimeout(() => {
                this.messageBox.current && this.messageBox.current.focus();
              }, 500);
            });
          }}
        >
          <div className="row">
            <Users active={false} className="col overflow-auto">
              <div className="p-3">
                <InputSearch
                  autofocus
                  onChange={event => {
                    searchUser(event.target.value);
                  }}
                  className="w-100 px-3 py-2"
                  type="text"
                  placeholder="Type the name of a person to start chatting"
                />
              </div>
              <UsersList
                tag="invite"
                users={searchResult}
                active={room}
                onSelect={this.invite}
              />
            </Users>
          </div>
        </ModalComponent>
      </>
    );
  }
}

Messages.propTypes = {
  setActiveRoom: PropTypes.func.isRequired,
  resetActiveRoom: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
  searchRecent: PropTypes.func.isRequired,
  user: PropTypes.shape({
    u_first_name: PropTypes.string,
  }),
  searchResult: PropTypes.arrayOf(PropTypes.object).isRequired,
  recent: PropTypes.arrayOf(PropTypes.object).isRequired,
  recentMessage: PropTypes.func.isRequired,
  currentUserId: PropTypes.string,
  room: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.object),
  searchRecentKey: PropTypes.string,
  recentFilter: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateMessage: PropTypes.func.isRequired,
  setRead: PropTypes.func.isRequired,
};
Messages.defaultProps = {
  user: null,
  currentUserId: null,
  room: null,
  messages: [],
  searchRecentKey: '',
};

const mapStateToProps = ({ message, auth, app }) => {
  return {
    user: auth.currentUser,
    searchResult: message.searchResult,
    recent: message.recent,
    room: message.room,
    currentUserId: auth.currentUser && auth.currentUser._id,
    messages: message.messages,
    searchRecentKey: message.searchRecentKey,
    totalUnreadMessage: message.totalUnreadMessage,
    loading: app.loading,
    loadChat:message.loadChat,
    recentFilter: message.recent.filter(user => {
      return user.u_first_name.toLowerCase().indexOf(message.searchRecentKey.toLowerCase()) !== -1;
    }),
    him: message.recent.find(user => {
      return user.room === message.room;
    }),
  };
};

export default connect(
  mapStateToProps,
  {
    setActiveRoom,
    resetActiveRoom,
    searchUser,
    recentMessage,
    searchRecent,
    updateMessage,
    setRead,
    setUnreadMessageCount
  }
)(Messages);
