import React from 'react';
import PropTypes from 'prop-types';
import './ContentListCard.css';

const ContentListCard = ({ content, onClick }) => {
  const contentIcons = {
    article: '/icons/article_ico.svg',
    video: '/icons/video_ico.svg',
    audio: '/icons/audio_ico.svg',
  };
  return (
    <div className="content-list-card cursor-pointer" role="presentation" onClick={onClick}>
      <img className="type-icon" src={contentIcons[content.content_type]} alt="article" />
      <div className="d-flex">
        <img className="card-content-img" src={content.content_thumbnail_url || '/images/thumbnail-placeholder.jpg'} alt="Article" />
        <div className="ml-2 d-flex flex-column">
          <h6 className="content-heading mb-3">{content.content_title}</h6>
          <div className="mt-auto">
            <p className="mb-0" style={{ fontSize: '0.75rem' }}>
              {content.content_owner.u_first_name} {content.content_owner.u_last_name || ''}
            </p>
            <p className="mb-0 text-secondary display2">{content.content_views} views</p>
          </div>
        </div>
      </div>
    </div>
  );
};

ContentListCard.propTypes = {
  content: PropTypes.shape({
    content_type: PropTypes.string,
    content_thumbnail_url: PropTypes.string,
    content_title: PropTypes.string,
    content_views: PropTypes.number,
    content_owner: PropTypes.shape({
      u_first_name: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ContentListCard;
