/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, Field, formValueSelector } from 'redux-form';
import { ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import ContentForm from './ContentForm';
import WYSIWIGEditor from '../common/WYSIWIGEditor/WYSIWIGEditor';
import Input from '../common/Input/Input';
import TextArea from '../common/TextArea/TextArea';
import FileUpload from '../common/FileUpload/FileUpload';
import RadioSelect from '../common/RadioSelect/RadioSelect';
import { MultiSearchDropDown } from '../common/MultiSearchDropDown/MultiSearchDropDown';

import { isEmpty, isUrlValid, isValidURL, isValidVideoURL, isObject } from '../../utils/validators';
import DragNDrop from '../common/DragNDrop/DragNDrop';
import { publishContent, uploadContent, draftContent } from '../../store/actions/publishActions';
import { createSoundCloudUrl } from '../../utils/helper';
import KeywordInput from '../common/KeywordInput/KeywordInput';

const validator = values => {
  const errors = {};
  if (!isEmpty(values.content_title)) {
    errors.content_title = 'This Field is required';
  }
  if (!values.content_categories) {
    errors.content_categories = 'This field is required';
  } else if (values.content_categories && !values.content_categories.length) {
    errors.content_categories = 'This field is required';
  }
  // if (!values.content_keywords) {
  //   errors.content_keywords = 'This field is required.';
  // } else if (values.content_keywords && !values.content_keywords.length) {
  //   errors.content_keywords = 'This field is required.';
  // }
  if (!isEmpty(values.content_description)) {
    errors.content_description = 'This field is required.';
  }
  if (!isEmpty(values.content_access_type)) {
    errors.content_access_type = 'This field is required.';
  }
  if (values.content_access_type !== 'free') {
    if (!isEmpty(values.content_price)) {
      errors.content_price = 'This field is required.';
    } else if (+values.content_price === 0) {
      errors.content_price = 'Invalid price';
    } else if (isNaN(values.content_price)) {
      errors.content_price = 'Invalid price';
    }
  }
  // if (!isObject(values.thumbnail)) {
  //   errors.thumbnail = 'This field is required';
  // }

  if (isEmpty(values.audio_url)) {
    if (!isUrlValid(values.audio_url)) {
      errors.audio_url = 'Not Valid url';
    }
  }

  if (isEmpty(values.video_url)) {
    if ( !values.video_url.match(/youtube.com\/watch/g) && !values.video_url.match(/youtu.be\//g) && !values.video_url.match(/vimeo.com/g)) {
      errors.video_url = 'Not Valid url';
    }
  }
  return errors;
};

let PublishContentForm = props => {
  const {
    type,
    handleSubmit,
    audio_url,
    video_url,
    audio_type,
    video_type,
    contentFile,
    submitting,
    invalid,
    onCancel,
    content_access_type,
    categories,
    disableDraft,
  } = props;
  let PreviewContent = null;

  const changeFile = event => {
    event.preventDefault();
    contentFileUploader.current.click();
  };

  const contentChangeHandler = event => {
    props.uploadContent(event.target.files);
  };

  const normalizePrice = value => {
    let onlyNums = value.replace(/[^0-9.]/, '')
    if (/\./.test(onlyNums)) {
      let number = onlyNums.split('.');
      onlyNums = `${number[0]}.${number[1].slice(0,2)}`
    }
    return onlyNums
  }
  
  if (type === 'Video' && contentFile.progress === 100 && contentFile.file)
    PreviewContent = (
      <div className="col-12 mb-3">
        <div className="publish-vedio-wrapper">
          <video controls id="bgvid">
            <source src={contentFile.file.file_url} />
            Your browser does not support the video tag.
          </video>
        </div>
        <button
          className="btn btn-sm btn-link gt-link float-right ml-auto"
          type="button"
          onClick={changeFile}
        >
          Change video
        </button>
      </div>
    );
  if (type === 'Audio' && !audio_url && contentFile.file)
    PreviewContent = (
      <div className="col-12 mb-3">
        {/* <audio controls style={{ width: '100%' }}>
          <source src={contentFile.file.file_url} type="audio/ogg" />
        </audio> */}
        <audio controls style={{ width: '100%' }} src={contentFile.file.file_url} type="audio/ogg"></audio>
        <button
          className="btn btn-sm btn-link gt-link float-right ml-auto"
          type="button"
          onClick={changeFile}
        >
          Change audio
        </button>
      </div>
    );
  const contentFileUploader = React.createRef();

  const acceptType = type === 'Video' ? 'video/mp4,video/x-m4v,video/*' : 'audio/*';

  const publishContent = values => props.publishContent(values);

  const draftContentHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!disableDraft) {
      props.draftContent(type.toLowerCase(), () => {
        props.clearState();
      });
    }
  };

  const changeVideoUrl = (url) => {
    const urlType = url.match(/youtube/g) || url.match(/youtu.be/g) ? 'youtube' : url.match(/vimeo/g) ? 'vimeo' : '';
    let videoUrl;
    if (urlType === 'youtube') {
      if(url.match(/youtube/g)){
        videoUrl = url.replace("watch?v=", "embed/");
        if(videoUrl.includes('&')) { videoUrl = videoUrl.substring(0,videoUrl.indexOf('&')) }
      }
      if(url.match(/youtu.be/g)){ videoUrl = url.replace("youtu.be", "youtube.com/embed/"); }
    } else if (urlType === 'vimeo') {
      const vimeo = url.split('/');
      const vimeoID = vimeo[vimeo.length - 1];
      videoUrl = "https://player.vimeo.com/video/" + vimeoID
    }
    return videoUrl
  }

  const isDisabled = () => {
    return submitting || invalid || (!contentFile.file && type !== 'Article' && !audio_url && !video_url);
  };
  const contentType = ['free', 'premium', 'exclusive'];

  return (
    <Form onSubmit={handleSubmit(publishContent)}>
      <div className="publish-article publish-content-form-wrapper">
        <div className="d-flex justify-content-center">
          <p className="head mb-0">{`Ready to publish your ${type.toLowerCase()}?`}</p>
        </div>
        <div className="p-2">
          <div className="content-form">
            <div className="row">
              <div className="col-12 mb-3">
                <h6>TITLE</h6>
                <Field name="content_title" placeholder="Title" type="text" component={Input} />
              </div>
              <div className="col-12 mb-3">
                <h6>DESCRIPTION</h6>
                <Field name="content_description" component={TextArea} />
              </div>

              {type === 'Article' && (
                <div className="col-12 mb-3">
                  <h6>CONTENT</h6>
                  <WYSIWIGEditor />
                </div>
              )}
              {type === 'Audio' && (
                <>
                  <div className="col-12 mb-3">
                    <Field
                      name="audio_type"
                      component={props => (
                        <RadioSelect
                          {...props}
                          options={['upload file', 'sound cloud url']}
                          other={false}
                          varient="content-form"
                        />
                      )}
                    />
                  </div>

                  {audio_type === 'sound cloud url' && (
                    <div className="col-12 mb-3">
                      <h6>Insert from url</h6>
                      <Field
                        name="audio_url"
                        placeholder="Insert your soundcloud url here."
                        type="text"
                        component={Input}
                      />
                    </div>
                  )}
                  {type === 'Audio' && audio_url && isUrlValid(audio_url) && (
                    <div className="col-12 mb-3">
                      <iframe
                        width="100%"
                        height="124"
                        scrolling="no"
                        title="audio"
                        frameBorder="no"
                        allow="autoplay"
                        src={createSoundCloudUrl(audio_url)}
                      />
                    </div>
                  )}
                </>
              )}

              {type === 'Video' && (
                <>
                  <div className="col-12 mb-3">
                    <Field
                      name="video_type"
                      component={props => (
                        <RadioSelect
                          {...props}
                          options={['upload file', 'video url']}
                          other={false}
                          varient="content-form"
                        />
                      )}
                    />
                  </div>

                  {video_type === 'video url' && (
                    <div className="col-12 mb-3">
                      <h6>Insert from url</h6>
                      <Field
                        name="video_url"
                        placeholder="Insert your video url here."
                        type="text"
                        component={Input}
                      />
                    </div>
                  )}
                  {type === 'Video' && video_type === 'video url' && video_url && isValidVideoURL(video_url) && (
                    <div className="col-12 mb-3">
                      <iframe src={changeVideoUrl(video_url)}
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                        title='video'
                        style={{height: 300, width: '100%'}}
                      />
                    </div>
                  )}
                </>
              )}
              {!contentFile.file &&
              !contentFile.uploading &&
              ((type === 'Video' && video_type === 'upload file') || (type === 'Audio' && audio_type === 'upload file')) ? (
                <div className="col-12 mb-3">
                  <DragNDrop accept={acceptType} onFileChoose={files => props.uploadContent(files)}>
                    <div className="h-100 d-flex flex-column justify-content-center align-items-center dragzone">
                      <img src="/icons/cloud-upload.svg" alt="audio" />
                      <h5 className="mb-0">Drag & drop</h5>
                      <p>
                        Your File, <span style={{ color: '#d4af37' }}>or Browse</span>
                      </p>
                    </div>
                  </DragNDrop>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    accept={acceptType}
                    ref={contentFileUploader}
                    className="d-none"
                    onChange={contentChangeHandler}
                  />
                  {PreviewContent}
                </>
              )}
              {contentFile.uploading && (
                <div className="col-12 mb-3">
                  <ProgressBar
                    striped
                    label={`Uploading ${contentFile.progress}%`}
                    animated
                    now={contentFile.progress}
                    srOnly={false}
                  />
                </div>
              )}
              <div className="col-12 mb-3">
                <h6>CATEGORIES</h6>
                <Field
                  name="content_categories"
                  component={prop => (
                    <MultiSearchDropDown
                      varient="primary"
                      options={categories}
                      placeholder="Categories"
                      onSelect={value => {
                        prop.input.onChange(value);
                      }}
                      value={prop.input.value}
                      {...prop}
                    />
                  )}
                />
              </div>
              <div className="col-12 mb-3">
                <h6>SPECIAL KEYWORDS</h6>
                <Field
                  name="content_keywords"
                  component={prop => (
                    <KeywordInput
                      value={prop.input.value}
                      onChange={value => {
                        prop.input.onChange(value);
                      }}
                      {...prop}
                    />
                  )}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <h6>THUMBNAIL</h6>
                <Field
                  name="thumbnail"
                  type="file"
                  accept="image/*"
                  placeholder="choose your thumbnail image"
                  component={FileUpload}
                />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <div className="position-relative d-flex">
                  <h6>CHOOSE CONTENT TYPE</h6>
                  <OverlayTrigger
                    trigger={['click']}
                    overlay={
                      <Tooltip id="tooltip-content-info">
                        <div className="p-2">
                          <p className="text-left mb-2">
                            <strong>Free Content</strong> is any type of media that can be accessed
                            freely by anybody, anywhere, anytime. These generally include articles,
                            podcasts, informal video talks, etc.)
                          </p>
                          <p className="text-left mb-2">
                            <strong>Premium Content</strong> is typically inexpensive and relatively
                            quick (special worksheets, special guided meditations, etc.). It can be
                            accessed by purchasing access individually, and also through a Premium
                            Account. Despite having an inexpensive price tag, its wide accessibility
                            increases its chances of being seen and highly rated by all Premium
                            Users, thereby potentially earning you more money and more exposure.
                          </p>
                          <p className="text-left mb-2">
                            <strong>Exclusive Content</strong> is typically more expensive and
                            in-depth (e-books, webinars, video classes, etc.). It can only be
                            accessed when a user purchases it individually. These items are not
                            accessible freely to Premium Users.
                          </p>
                        </div>
                      </Tooltip>
                    }
                    rootClose>
                    <button
                      type="button"
                      className="btn info-btn-publish"
                      style={{ padding: 0, lineHeight: 1 }}
                    >
                      <img
                        className="ml-2 mb-2 rotate"
                        height="16"
                        src="/icons/info.svg"
                        alt="info"
                      />
                    </button>
                  </OverlayTrigger>
                </div>
                <div className="d-flex">
                  <Field
                    name="content_access_type"
                    component={props => (
                      <RadioSelect
                        {...props}
                        options={contentType}
                        other={false}
                        varient="content-form"
                      />
                    )}
                  />
                </div>
              </div>
              {(content_access_type === 'exclusive' || content_access_type === 'premium') && (
                <div className="col-6 col-md-2 mb-3">
                  <h6>AMOUNT</h6>
                  <Field name="content_price"  normalize={normalizePrice} placeholder="$" component={Input} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 px-2">
          <div className="border-top d-flex pt-3">
            <button
              type="button"
              onClick={draftContentHandler}
              className="btn btn-outline-primary"
              disabled={disableDraft}
            >
              Save as Draft
            </button>
            <div className="ml-auto">
              <button type="button" className="btn btn-cancel" onClick={onCancel}>
                Cancel
              </button>
              <button type="submit" className="btn gt-btn" disabled={isDisabled()}>
                {!submitting && <span>Publish</span>}
                {submitting && (
                  <span>
                    <span className="spinner-border spinner-border-sm mr-2" />
                    Publishing...
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

PublishContentForm.propTypes = {
  type: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  audio_url: PropTypes.string,
  video_url: PropTypes.string,
  audio_type: PropTypes.string,
  video_type: PropTypes.string,
  contentFile: PropTypes.shape({
    file: PropTypes.shape({ file_url: PropTypes.string }),
    progress: PropTypes.number,
    uploading: PropTypes.bool,
  }),
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  publishContent: PropTypes.func.isRequired,
  draftContent: PropTypes.func.isRequired,
  uploadContent: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  content_access_type: PropTypes.string,
  disableDraft: PropTypes.bool,
};
PublishContentForm.defaultProps = {
  handleSubmit: () => {},
  audio_url: '',
  video_url: '',
  audio_type: '',
  video_type: '',
  contentFile: {},
  categories: [],
  content_access_type: '',
  disableDraft: true,
};

PublishContentForm = reduxForm({
  form: 'contentForm',
  validate: validator,
  initialValues: {
    audio_type: 'upload file',
    video_type: 'upload file',
    content_access_type: 'free',
  },
})(PublishContentForm);

const mapStateToProps = state => {
  const selector = formValueSelector('contentForm');
  const audio_url = selector(state, 'audio_url');
  const video_url = selector(state, 'video_url');
  const content_access_type = selector(state, 'content_access_type');
  const audio_type = selector(state, 'audio_type');
  const video_type = selector(state, 'video_type');
  const content_title = selector(state, 'content_title');
  const content_description = selector(state, 'content_description');
  let disableDraft = true;
  if (content_title && content_description && content_title.trim() && content_description.trim()) {
    disableDraft = false;
  }
  return {
    audio_url,
    video_url,
    audio_type,
    video_type,
    content: state.publish.content,
    contentFile: state.publish.contentFile,
    content_access_type,
    categories: state.publish.categories,
    disableDraft,
  };
};
const mapDispatchToProp = {
  uploadContent,
  publishContent,
  draftContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProp
)(PublishContentForm);
