import {
  SET_MEMBER_PAGE_ACTIVE,
  SET_BOOKMARKED_PRAC_LIST,
  SET_BOOKMARKED_CONTENT_LIST,
  SET_PURCHASED_CONTENT_LIST,
} from '../actions/types';

const initialState = {
  pages: [
    {
      step: 1,
      status: 'progress',
    },
    {
      step: 2,
      status: 'waiting',
    },
  ],
  bookmarkedPractitioners: [],
  bookmarkedContents: [],
  purchasedContents: [],
};

const setPageActive = (state, step) => {
  return state.pages.map(page =>
    page.step === step ? { ...page, status: 'progress' } : { ...page, status: 'waiting' }
  );
};

const memberReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MEMBER_PAGE_ACTIVE:
      return {
        ...state,
        pages: setPageActive(state, payload),
      };
    case SET_BOOKMARKED_PRAC_LIST:
      return {
        ...state,
        bookmarkedPractitioners: payload,
      };
    case SET_BOOKMARKED_CONTENT_LIST:
      return {
        ...state,
        bookmarkedContents: payload,
      };
    case SET_PURCHASED_CONTENT_LIST:
      return {
        ...state,
        purchasedContents: payload,
      };
    default:
      return state;
  }
};

export default memberReducer;
