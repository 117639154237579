/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setRecommendedFeedPrac,
  getRecommendedFeedPrac,
} from '../../../store/actions/contentAction';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import PractitionerSkeleton from '../../shared/Cards/PractitionerCard/PractitionerSkeleton';
import PractitionerCard from '../../shared/Cards/PractitionerCard/PractitionerCard';

class RecommendedPrac extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasMore: true,
      page: 1,
    };
  }

  componentDidMount() {
    const { clearRecommended } = this.props;
    clearRecommended();
    this.fetchData();
    document.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  fetchData = () => {
    const { getRecommendedFeedPrac: getRecommended } = this.props;
    const { page } = this.state;
    this.setState({ loading: true });
    getRecommended(page, more => {
      this.setState({
        loading: false,
        hasMore: more,
        page: page + 1,
      });
    });
  };

  scrollHandler = () => {
    const { loading, hasMore } = this.state;
    if (loading || !hasMore) return;
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      this.fetchData();
    }
  };

  render() {
    const { loading } = this.state;
    const { recommended, history } = this.props;

    const LoadSkeleton = (
      <div className="row">
        {[1, 2, 3, 54, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
          <div key={item} className="col-lg-3 col-md-4 col-sm-6 mb-3">
            <PractitionerSkeleton key={item} />
          </div>
        ))}
      </div>
    );
    const RecommendedPractioners = recommended.map(prac => (
      <div key={prac._id} className="col-lg-3 col-md-4 col-sm-6 mb-3">
        <PractitionerCard onClick={username => history.push(`/${username}`)} practitioner={prac} />
      </div>
    ));
    return (
      <>
        <Navbar />
        <div className="container my-4" style={{ minHeight: '80vh' }}>
          {loading && !recommended.length ? (
            <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
          ) : (
            <h2 className="slider-title mb-3">Recommended Practitioners</h2>
          )}
          <div className="row">{RecommendedPractioners}</div>
          {loading && LoadSkeleton}
        </div>
        <Footer />
      </>
    );
  }
}

RecommendedPrac.propTypes = {
  recommended: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRecommendedFeedPrac: PropTypes.func.isRequired,
  clearRecommended: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  recommended: state.content.recommendedPracFeeds,
});

const mapDispatchToProps = {
  getRecommendedFeedPrac,
  clearRecommended: () => dispatch => dispatch(setRecommendedFeedPrac([])),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedPrac);
