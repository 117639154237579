/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './TermsOfService.css';

const TermsList = ({ onClick, active }) => {
  const allTerms = [
    { term: 'Introduction', link: 'introduction' },
    { term: 'Legal Agreement', link: 'legal-agreement' },
    { term: 'Eligibilty', link: 'eligibilty' },
    { term: 'Amendments', link: 'amendments' },
    { term: 'Our service', link: 'our-service' },
    { term: 'Members', link: 'members' },
    { term: 'Passwords', link: 'passwords' },
    { term: 'Adding Content on GuideTrue', link: 'adding-content-on-guideTrue' },
    {
      term: 'Offering Your Content and Services for Sale',
      link: 'offering-your-content-and-services-for-sale',
    },
    { term: 'Purchasing a Product on GuideTrue', link: 'purchasing-a-product-on-guideTrue' },
    { term: 'Getting Paid for Your Content', link: 'getting-paid-for-your-content' },
    { term: 'GuideTrue May Cancel Orders', link: 'guideTrue-may-cancel-orders' },
    { term: 'Delivery', link: 'delivery' },
    { term: 'Damaged Product', link: 'damaged-product' },
    {
      term: 'Reporting Inappropriate Content to GuideTrue',
      link: 'reporting-inappropriate-content-to-guideTrue',
    },
    { term: 'Specific Warnings', link: 'specific-warnings' },
    {
      term: 'Intellectual Property Rights and License',
      link: 'intellectual-property-rights-and-license',
    },
    { term: 'Linked Websites', link: 'linked-websites' },
    { term: 'Disclaimer', link: 'disclaimer' },
    { term: 'Indemnity', link: 'indemnity' },
    { term: 'Privacy Policy', link: 'privacy-policy' },
    { term: 'Security of Information', link: 'security-of-information' },
    { term: 'Termination of Access', link: 'termination-of-access' },
    { term: 'Dispute Resolution', link: 'dispute-resolution' },
    { term: 'General', link: 'general' },
    { term: 'Services Agreement', link: 'services-agreement' },
  ];
  const termList = allTerms.map(item => {
    return (
      <li
        className={active === item.link ? 'list-item-active' : 'list-item'}
        onClick={() => onClick(item.term)}
        key={item.term}
      >
        <a href={`/termsofservice#${item.link}`}>{item.term}</a>
      </li>
    );
  });
  return <ul className="list-unstyled">{termList}</ul>;
};

TermsList.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
};
export default TermsList;
