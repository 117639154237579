/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */

import React from 'react';
import './Button.css';
import PropTypes from 'prop-types';

const Button = ({ type = 'button', onClick, children, varient, ...rest }) => {
  const className = ['btn gt-btn', varient || ''];
  return (
    <button type={type} {...rest} className={className.join(' ')} onClick={onClick}>
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  varient: PropTypes.string,
};
Button.defaultProps = {
  type: 'button',
  varient: '',
  onClick: () => {},
};

export default Button;
