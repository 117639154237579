/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, submit, formValueSelector } from 'redux-form';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
// import SearchDropDown from '../../common/SearchDropDown/SearchDropDown';
// import SelectInput from '../../common/SelectInput/SelectInput';
import './PractitionerSettings.css';
import { getAllMetaData } from '../../../store/actions/appActions';
import {
  getBusinessDetailes,
  updateBusinessInfo,
  updateBusinessLogo,
  removeBusinessLogo,
} from '../../../store/actions/settingsActions';
import ModalComponent from '../../shared/Modal/Modal';
import InviteForm from './InviteForm';
import { invitePeople } from '../../../store/actions/linkBusinessAction';
import validators from '../Practitioner/LinkBusiness/businessFormValidator';
import Location from '../../common/Location/Location';

const PractitionerTimeList = ({ list }) => {
  const practitionerList = list.map(practitioner => {
    const imgUrl = practitioner.u_image_url || '/images/user.svg';
    return (
      <tr key={practitioner._id}>
        <td>
          <div className="d-flex flex-row">
            <Link to={`/${practitioner.u_prac_username}`}>
              <img src={imgUrl} className="practitioner-profile-image" alt="prac_profile" />
            </Link>
            <div className="d-flex flex-column ml-2">
              <span className="practitioner-name">
                {practitioner.u_first_name} {practitioner.u_last_name || ''}
              </span>
              <span className="practitioner-email">{practitioner.u_email}</span>
            </div>
          </div>
        </td>
        <td className="text-capitalize">{practitioner.business_role}</td>
        <td>{practitioner.location}</td>
        <td>
          <div
            className="d-flex justify-content-center"
            style={{
              paddingTop: '0.3rem',
              paddingBottom: '0.3rem',
              backgroundColor: '#f5f6ff',
              borderRadius: '0.5rem',
            }}
          >
            <span style={{ color: '#4392f1', fontWeight: '600' }}>
              {(practitioner.rating && practitioner.rating.toFixed(1)) || 0}
            </span>
          </div>
        </td>
        <td className="practitioner-date-added">
          {moment(practitioner.business_accepted_at).format('DD MMM YYYY')}
        </td>
      </tr>
    );
  });
  return practitionerList;
};

class BusinessSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inviteModalShow: false,
      inviting: false,
    };
    this.fileUpload = React.createRef();
  }

  componentDidMount() {
    const { getBusinessDetailes } = this.props;
    this.setState({ loading: true });
    getBusinessDetailes(resp => {
      this.setState({ loading: false });
    });
  }

  saveChangesHandler = values => {
    const { updateBusinessInfo, business } = this.props;
    return updateBusinessInfo(values, business._id);
  };

  uploadBusinessLogoHandler = e => {
    const { updateBusinessLogo, business } = this.props;
    updateBusinessLogo(business._id, e.target.files[0]);
  };

  removeBusinessLogo = () => {
    const { removeBusinessLogo, business } = this.props;
    removeBusinessLogo(business._id);
  };

  invitePeoples = values => {
    const { invitePeople, business } = this.props;
    if (values.emails && values.emails.length) {
      this.setState({ inviting: true });
      invitePeople(values.emails, business._id, err => {
        this.setState({ inviting: false, inviteModalShow: false });
      });
    }
  };

  render() {
    const {
      business,
      handleSubmit,
      remoteSubmit,
      submitting,
      user,
      emails
    } = this.props;
    const { loading, inviteModalShow, inviting } = this.state;

    console.log('business Info', business);

    if (loading) {
      return (
        <div className="d-flex p-5 text-center bg-light rounded" style={{ height: '50vh' }}>
          <span className="spinner-grow text-primary m-auto" />
        </div>
      );
    }

    if (!loading && !business) {
      return (
        <div className="d-flex p-5 text-center bg-light rounded" style={{ height: '50vh' }}>
          <p className="m-auto">You are not part of any business yet.</p>
        </div>
      );
    }

    return (
      <div id="practitioner-business-settings">
        {user && business && user._id === business.business_created_by && (
          <div className="practitioner-details mb-3">
            <div className="d-flex flex-md-row flex-column align-items-center">
              <img
                src={business.business_logo_url || '/images/business-empty.svg'}
                className="profile-image mr-md-4 mr-0 mb-md-0 mb-3"
                style={{ objectFit: 'contain', height: '120px', width: '120px' }}
                alt="prac_profile"
              />
              <input
                ref={this.fileUpload}
                type="file"
                accept="image/*"
                className="d-none"
                onChange={this.uploadBusinessLogoHandler}
              />
              <div className="text-md-left text-center">
                <button
                  type="button"
                  className="btn gt-btn"
                  onClick={() => this.fileUpload.current.click()}
                >
                  Upload Picture
                </button>
                <button
                  type="button"
                  className="btn delete-picture mt-md-0 mt-2"
                  onClick={this.removeBusinessLogo}
                >
                  Delete Picture
                </button>
              </div>
            </div>
            <form className="profile-form" onSubmit={handleSubmit(this.saveChangesHandler)}>
              <div className="row">
                <div className="col-md-6 col-12 mb-3">
                  <span className="form-sub-title">NAME OF BUSINESS</span>
                  <Field name="business_name" placeholder="Name Of Business" component={Input} />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <span className="form-sub-title">PHONE</span>
                  <Field name="business_phone_number" placeholder="Phone" component={Input} />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <span className="form-sub-title">ADDRESS LINE 1</span>
                  <Field
                    name="business_address_line1"
                    placeholder="Address Line 1"
                    component={Input}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <span className="form-sub-title">ZIP CODE</span>
                  <Field name="business_zip_code" placeholder="Zip Code" component={Input} />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <span className="form-sub-title">WEBSITE</span>
                  <Field
                    name="business_website"
                    placeholder="http://www.example.com"
                    component={Input}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <span className="form-sub-title">LOCATION</span>
                  <Field
                    name="business_location"
                    component={({ input }) => 
                      <Location onLocationSelect={(loc)=> input.onChange(loc)} value={input.value} />
                    }
                  />
                </div>
              </div>
              <div className="d-flex mt-4">
                <button
                  type="button"
                  className="btn outline delete-account-button"
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  {/* <img src="/icons/deleteIcon.svg" className="type-icon" alt="deleteIcon" />
                  <span className="delete-button-title">Delete Business Profile</span> */}
                </button>
                <button type="submit" className="btn gt-btn ml-auto" disabled={submitting}>
                  {submitting ? (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                      Saving...
                    </span>
                  ) : (
                    'Save Changes'
                  )}
                </button>
              </div>
            </form>
          </div>
        )}

        {business && (
          <div className="practitioner-time-details">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="practitioner-time-title">Members</h1>
              <Button onClick={() => this.setState({ inviteModalShow: true })}>
                <span>+Add Employee</span>
              </Button>
            </div>
            <p className="practitioner-time-subtitle">
              Add and manage the employees in your business.
            </p>
            {business.members && business.members.length > 0 && (
              <div className="table-responsive-md">
                <table className="practitioner-time-table table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col" className="practitioner-table-head">
                        Member Name
                      </th>
                      <th scope="col" className="practitioner-table-head">
                        Account Type
                      </th>
                      <th scope="col" className="practitioner-table-head">
                        Location
                      </th>
                      <th scope="col" className="practitioner-table-head">
                        Rating
                      </th>
                      <th scope="col" className="practitioner-table-head">
                        Date Added
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <PractitionerTimeList list={business.members} />
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

        <ModalComponent
          title="Invite people"
          show={inviteModalShow}
          onHide={() => this.setState({ inviteModalShow: false })}
        >
          <div className="p-4">
            <p className="">
              By entering the email address of your employees, we will send  invitation link to
              their email.
            </p>
            <InviteForm onSubmit={this.invitePeoples} />
            <button
              type="button"
              className="btn gt-btn mt-4 float-right ml-auto mb-3"
              disabled={!emails || !emails.length}
              onClick={() => remoteSubmit('invite-form')}
            >
              {inviting ? (
                <span>
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  />
                  Inviting...
                </span>
              ) : (
                'Invite'
              )}
            </button>
          </div>
        </ModalComponent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const business = state.settings.businessInfo;
  const selector = formValueSelector('invite-form');
  const initialValues = {};
  if (business) {
    const coordinates = {
      lat: business.business_location_cordinates.coordinates[1],
      lng: business.business_location_cordinates.coordinates[0]
    };
    initialValues.business_name = business.business_name;
    initialValues.business_phone_number = business.business_phone_number;
    initialValues.business_address_line1 = business.business_address_line1;
    initialValues.business_country = business.business_country;
    initialValues.business_state = business.business_state;
    initialValues.business_city = business.business_city;
    initialValues.business_zip_code = business.business_zip_code;
    initialValues.business_website = business.business_website;
    initialValues.business_location = {
      address: business.business_location_address,
      coordinates,
    }
  }
  return {
    business,
    initialValues,
    user: state.auth.currentUser,
    emails: selector(state,'emails')
  };
};

const mapDispatchToProps = {
  getAllMetaData,
  getBusinessDetailes,
  updateBusinessInfo,
  updateBusinessLogo,
  invitePeople,
  removeBusinessLogo,
  remoteSubmit: formName => dispatch => dispatch(submit(formName)),
};

BusinessSettings.propTypes = {
  getBusinessDetailes: PropTypes.func.isRequired,
  updateBusinessInfo: PropTypes.func.isRequired,
  updateBusinessLogo: PropTypes.func.isRequired,
  invitePeople: PropTypes.func.isRequired,
};

const BusinessSettingsForm = reduxForm({
  form: 'edit-business-form',
  validate: validators,
  enableReinitialize: true,
})(BusinessSettings);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BusinessSettingsForm)
);
