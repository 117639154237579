import React from 'react';
import PropTypes from 'prop-types';

const PractitionerRegistrationHeader = ({ title, info }) => (
  <div className="pract-reg-header">
    <h3>{title}</h3>
    <p>{info}</p>
  </div>
);

PractitionerRegistrationHeader.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default PractitionerRegistrationHeader;
