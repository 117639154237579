/* eslint-disable no-underscore-dangle */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ContactsCard.css';

let Avatar = ({ className, user, history }) => {
  const profileImage = user.u_image_url || '/images/user.svg';
  return (
    <div
      role="presentation"
      className={`${className} avatarr d-flex`}
      onClick={() => history.push(`/${user.u_prac_username}`)}
    >
      <img src={profileImage} className="avatar" alt="avatar" />
      <div className="ml-3">
        <p className="name text-capitalize">
          {user.u_first_name} {user.u_last_name || ''}
        </p>
        <p className="specialisation text-capitalize">
          {user.specialization.map(spec => (
            <span key={spec}>{spec}</span>
          ))}
        </p>
      </div>
    </div>
  );
};

Avatar.propTypes = {
  user: PropTypes.shape({
    u_first_name: PropTypes.string.isRequired,
    u_username: PropTypes.string,
    u_last_name: PropTypes.string,
    u_image_url: PropTypes.string,
    u_prac_username: PropTypes.string,
    specialization: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
};

Avatar = withRouter(Avatar);

const ContactsCard = ({ users }) => {
  let bookmarkedList = users.map(user => <Avatar key={user._id} className="mb-3" user={user} />);
  if (users && users.length === 0) {
    bookmarkedList = (
      <div className="text-center py-5 px-2">
        <img className="mb-1" src="/images/bookmarked-prac-empty.svg" alt="No Bookmarked" />
        <p className="font-medium mb-1">Start browsing & bookmarking!</p>
        <p className="display2 text-secondary">
          Keep track of your favorite practitioners and businesses here!
        </p>
        <Link className="btn gt-btn text-truncate btn-browse-pract" to="/search?type=practitioner">
          Browse Practitioners
        </Link>
      </div>
    );
  }
  return (
    <div className="contacts-card mt-3">
      <div className="contacts-card-header border-bottom border-gt p-3">
        <h6>Bookmarked Practitioners</h6>
      </div>
      <div className="contacts-card-body p-3">{bookmarkedList}</div>
      {/* <div className="contacts-card-footer border-top px-3 py-1">
      <div className="text-center">
        <button type="button" className="btn primary-link-btn">
          VIEW ALL
        </button>
      </div>
    </div> */}
    </div>
  );
};

ContactsCard.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ContactsCard;
