import React from 'react';

const PractitionerSkeleton = () => {
  return (
    <div className="card practitioner-skeleton-card p-3 text-center">
      <div className="d-block img-placeholder-small img-fluid shine rounded-circle mb-2 mx-auto" />
      <p className="line-placeholder header-placeholder-h1 shine" />
      <div className="text-center my-4">
        <p className="d-block line-placeholder shine w-75 mx-auto" />
        <p className="d-block line-placeholder shine w-100 mx-auto" />
        <p className="d-block line-placeholder shine w-100 mx-auto" />
        <p className="d-block line-placeholder shine w-50 mx-auto" />
      </div>
      <div className="d-flex flex-wrap justify-content-center">
        <p className="line-placeholder shine mx-2 mb-1" />
        <p className="line-placeholder shine mx-2 mb-1" />
        <p className="line-placeholder shine mx-2 mb-1" />
      </div>
    </div>
  );
};

export default PractitionerSkeleton;
