/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './SelectInput.css';

const SelectInput = ({ children, input, varient, meta, ...rest }) => {
  const varients = {
    material: 'gtm-form-control',
    primary: 'gt-primary',
  };
  return (
    <div>
      <select
        className={[
          'custom-select',
          varients[varient || 'primary'],
          meta.error && meta.touched ? 'invalid' : '',
        ].join(' ')}
        datavalue={input.value || ''}
        {...input}
        {...rest}
      >
        {children}
      </select>
      {meta.error && meta.touched && (
        <span
          className={[
            'invalid-feedback d-block',
            varient === 'material' ? 'gtm-invalid-feedback' : '',
          ].join(' ')}
        >
          {meta.error}
        </span>
      )}
    </div>
  );
};

SelectInput.propTypes = {
  // children: PropTypes.arrayOf()
  input: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  varient: PropTypes.string,
};

SelectInput.defaultProps = {
  // children: [],
  varient: '',
};

export default SelectInput;
