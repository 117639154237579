import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import { socialLogin } from '../../../store/actions/authActions';
import { GOOGLE_APP_ID, FACEBOOK_APP_ID } from '../../../config';
import './SocialButton.css';

const SocialButton = ({ provider, children, onSuccess }) => {
  const responseFacebook = response => {
    const { id, name, email, accessToken } = response;
    const socialUser = {
      id,
      name,
      email,
      accessToken,
      provider: 'facebook',
      profile_url: response.picture.data.url,
    };
    onSuccess(socialUser);
  };

  const responseGoogle = response => {
    const { googleId, name, email, imageUrl } = response.profileObj;
    const socialUser = {
      provider: 'google',
      accessToken: response.accessToken,
      id: googleId,
      name,
      email,
      profile_url: imageUrl,
    };
    console.log('$$$$$$$$$$$$$', socialUser);

    onSuccess(socialUser);
  };

  const errorResponseGoogle = response => {
    console.log('Google OAuth Error', response);
  };

  return provider === 'google' ? (
    <GoogleLogin
      clientId={GOOGLE_APP_ID}
      onSuccess={responseGoogle}
      onFailure={errorResponseGoogle}
      scope="profile"
      render={renderProps => (
        <button type="button" onClick={renderProps.onClick} className="btn social-btn google w-100">
          {children}
        </button>
      )}
      cookiePolicy="single_host_origin"
    />
  ) : (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      fields="name,email,picture"
      scope="public_profile,email"
      disableMobileRedirect
      render={renderProps => (
        <button
          type="button"
          onClick={renderProps.onClick}
          className="btn social-btn facebook w-100"
        >
          {children}
        </button>
      )}
      callback={responseFacebook}
    />
  );
};

SocialButton.propTypes = {
  provider: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  // history: PropTypes.func.isRequired,
  // socialLogin: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    null,
    { socialLogin }
  )(SocialButton)
);
