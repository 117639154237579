import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../../../common/Button/Button';
import './ProgressCheckWelcome.css';
import { setProgressCheckPage } from '../../../../store/actions/assessmentActions';

const ProgressCheckWelcome = ({ setProgressCheckPage, lastCheckIn }) => {
  return (
    <div className="d-flex h-100 welcome-container">
      <div className="py-3 my-auto" id="progresscheck-welcome-page">
        <h1>
          <span className="welcome-combined">WELCOME TO YOUR</span>
          PROGRESS CHECK-IN!
        </h1>
        <div id="progresscheck-content" className="text-center container-fluid">
          <h3 className="mb-4 text-uppercase">Why is this so important ?</h3>
          <div className="row d-flex justify-content-center">
            <div className="col-md-7 col-12 mb-4">
              <p className="text-white-50">
                This will provide you with your personal progress chart to visualize your growth. It
                will also help us anticipate your needs and support you as best as possible.
              </p>
              {lastCheckIn && (
                <p className="font-weight-bold">
                  Rate the overall change you've experienced since your last check in on
                  <span className="mx-1">{moment(lastCheckIn).format('DD MMM YYYY')}</span>. (0=Much
                  Worse, 5=No Change, 10= Great Improvement)
                </p>
              )}
            </div>
          </div>
          <div className="mt-1">
            <Button
              onClick={() => {
                setProgressCheckPage({ status: 'questions' });
              }}
              varient="large"
            >
              Start Check-In
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ProgressCheckWelcome.propTypes = {
  setProgressCheckPage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  lastCheckIn: state.assessment.lastCheckIn,
});

export default connect(
  mapStateToProps,
  { setProgressCheckPage }
)(ProgressCheckWelcome);
