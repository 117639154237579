/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setSearchQuery, setSearchType } from '../../../store/actions/searchAction';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.dropDown = React.createRef();
  }

  componentDidMount = () => document.addEventListener('mousedown', this.handleClickOutside);

  componentWillUnmount = () => document.removeEventListener('mousedown', this.handleClickOutside);

  onSelect = value => {
    const { setSearchType, history, query, location } = this.props;
    setSearchType(value);
    this.setState({ isOpen: false });
    if (location.pathname === '/search') {
      history.push({
        pathname: '/search',
        search: `?type=${value}&query=${query}`,
      });
    }
  };

  search = event => {
    const { selected, query, history } = this.props;
    if (!event.key || event.key === 'Enter') {
      history.push({
        pathname: '/search',
        search: `?type=${selected}&query=${query}`,
      });
    }
  };

  handleClickOutside = event => {
    if (this.dropDown.current && !this.dropDown.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const { isOpen } = this.state;
    const { placeholder, border, selected, setSearchQuery, query } = this.props;

    return (
      <div className="navbar-search-box position-relative w-100">
        <svg
          className="cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          onClick={this.search}
        >
          <path
            fill="#9C9C9C"
            fillRule="nonzero"
            d="M24.649 22.892l-4.635-4.6c3.736-4.662 3.18-11.432-1.267-15.42a11.237 11.237 0 0 0-15.454.424C-.929 7.52-1.114 14.31 2.87 18.76a11.237 11.237 0 0 0 15.408 1.268l4.597 4.601a1.249 1.249 0 0 0 1.774 0 1.25 1.25 0 0 0 0-1.738zm-13.38-2.863c-4.83 0-8.744-3.918-8.744-8.751 0-4.833 3.915-8.751 8.744-8.751 4.83 0 8.745 3.918 8.745 8.751a8.755 8.755 0 0 1-2.561 6.188 8.741 8.741 0 0 1-6.184 2.563z"
          />
        </svg>
        <div
          className={[
            'navbar-search d-flex align-items-center px-3',
            border && 'nav-bordered',
          ].join(' ')}
        >
          <div
            className="search-drop-down d-flex position-relative"
            onClick={() => this.setState({ isOpen: !isOpen })}
            role="presentation"
          >
            <div className="d-flex align-items-center drop">
              <span className="text-capitalize">{selected}</span>
              <img className="ml-auto mr-2" src="/icons/arrow.svg" alt="" />
            </div>
            <div className="position-absolute dropdown" ref={this.dropDown}>
              {isOpen && (
                <ul className="">
                  <li role="presentation" onClick={() => this.onSelect('practitioner')}>
                    Practitioner
                  </li>
                  <li role="presentation" onClick={() => this.onSelect('content')}>
                    Content
                  </li>
                </ul>
              )}
            </div>
          </div>
          <input
            className="form-control"
            value={query}
            placeholder={placeholder}
            onKeyPress={this.search}
            onChange={evt => setSearchQuery(evt.target.value)}
          />
        </div>
      </div>
    );
  }
}

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  border: PropTypes.bool,
  setSearchQuery: PropTypes.func.isRequired,
  setSearchType: PropTypes.func.isRequired,
  selected: PropTypes.string,
  query: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

SearchBox.defaultProps = {
  placeholder: '',
  border: false,
  selected: 'practitioner',
  query: '',
};

const mapStateToProps = state => {
  return {
    query: state.search.query,
    selected: state.search.type,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { setSearchType, setSearchQuery }
  )(SearchBox)
);
