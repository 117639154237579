/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { MultiSearchDropDown } from '../../../common/MultiSearchDropDown/MultiSearchDropDown';
import Switch from '../../../common/Switch/Switch';

const AcceptInsurance = ({ insurance_types, accept_insurance }) => {
  return (
    <div className="professional-services">
      <div className="d-flex justify-content-between">
        <h1 className="professional-title">Accept Insurance</h1>
        <Field
          name="accept_insurance"
          component={prop => (
            <Switch
              id="insurance"
              checked={prop.input.value}
              onChange={event => prop.input.onChange(event)}
              {...prop}
              classType="dark-switch"
            />
          )}
        />
      </div>
      {accept_insurance && (
        <div>
          <span className="subtitle">ACCEPTED INSURANCE</span>
          <Field
            name="accepted_insurances"
            component={prop => (
              <MultiSearchDropDown
                placeholder="Accepted Insurances"
                options={insurance_types}
                varient="primary"
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
                {...prop}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const selector = formValueSelector('memberprofilesettings');
  const accept_insurance = selector(state, 'accept_insurance');
  return {
    accept_insurance,
    insurance_types: state.app.insurance_types,
  };
};

export default connect(mapStateToProps)(AcceptInsurance);
