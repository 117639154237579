/* eslint-disable no-param-reassign */
import {
  SET_NOTIFICATIONS_LIST,
  UPDATE_NOTIFICATION_LIST,
  UPDATE_NOTIFICATION_STATUS,
  MARK_AS_READ,
  SET_ADMIN_APPROVE_STATUS,
} from '../actions/types';

const initialState = {
  notifications: [],
};

const updateNotificationList = (state, notification) => {
  const notifications = state.notifications.slice();
  const index = notifications.findIndex(not => not._id === notification._id);
  if (index < 0) {
    notifications.unshift(notification);
  }
  return notifications;
};

const markAsRead = (state, notificationId) => {
  const notifications = JSON.parse(JSON.stringify(state.notifications));
  const index = notifications.findIndex(noti => noti._id === notificationId);
  if (index > -1) {
    notifications[index].reciever[0].read_status = true;
  }
  return notifications;
};

const updateAdminApproveStatus = (state, notificationId, status) => {
  const notifications = JSON.parse(JSON.stringify(state.notifications));
  const index = notifications.findIndex(noti => noti._id === notificationId);
  if (index > -1) {
    notifications[index].business_ref.admin_approve_status = status;
  }
  return notifications;
};

const markAllAsReadOrSeen = (state, statusType) => {
  let notifications = JSON.parse(JSON.stringify(state.notifications));
  notifications = notifications.map(noti => {
    if (statusType === 'seen') {
      noti.reciever[0].seen_status = true;
    }
    if (statusType === 'read') {
      noti.reciever[0].read_status = true;
    }
    return noti;
  });
  return notifications;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATIONS_LIST:
      return {
        ...state,
        notifications: payload,
      };
    case UPDATE_NOTIFICATION_LIST:
      return {
        ...state,
        notifications: updateNotificationList(state, payload),
      };
    case UPDATE_NOTIFICATION_STATUS:
      return {
        ...state,
        notifications: markAllAsReadOrSeen(state, payload),
      };
    case MARK_AS_READ:
      return {
        ...state,
        notifications: markAsRead(state, payload),
      };
    case SET_ADMIN_APPROVE_STATUS:
      return {
        ...state,
        notifications: updateAdminApproveStatus(state, payload.id, payload.status),
      };
    default:
      return state;
  }
};
