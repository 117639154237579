import React from 'react';
import ContentSkelton from '../../shared/Cards/ContentCard/ContentSkelton';
import PractitionerSkeleton from '../../shared/Cards/PractitionerCard/PractitionerSkeleton';

function CategoryViewSkeleton() {
  return (
    <>
      <section className="skeleton-section mb-4">
        <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
        <div className="row">
          {[1, 2, 3, 4].map(ele => (
            <div key={`pop-${ele}`} className="col-lg-3 col-md-4 col-6">
              <ContentSkelton />
            </div>
          ))}
        </div>
      </section>
      <section className="skeleton-section mb-4">
        <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
        <div className="row">
          {[1, 2, 3, 4].map(ele => (
            <div key={`rec-${ele}`} className="col-lg-3 col-md-4 col-6">
              <ContentSkelton />
            </div>
          ))}
        </div>
      </section>
      <section className="mb-4 overflow-hidden" style={{ height: '396px' }}>
        <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
        <div className="row">
          {[1, 2, 3, 4].map(ele => (
            <div key={`prac-${ele}`} className="col-lg-3 col-md-4 col-6">
              <PractitionerSkeleton />
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default CategoryViewSkeleton;
