/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './PractitionerContent.css';
import { Tabs, Tab } from 'react-bootstrap';
import ContentSection from './ContentSection/ContentSection';
import { getContents } from '../../../../../store/actions/practitionerRegAction';

class PractitionerContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 'all',
      status: '',
    };
  }

  componentDidMount() {
    // const { getContents } = this.props;
    // getContents();
  }

  viewContent = content_id => this.props.history.push(`/content/${content_id}`);

  renderContents = filter => {
    let contentsList = [];
    const { contents, ownProfile } = this.props;
    let _contents = contents;
    const { status } = this.state;
    if (status) {
      _contents = contents.filter(content => content.content_publish_status === status);
    }
    if (filter === 'all') {
      contentsList = _contents.map(cont => (
        <ContentSection
          key={cont._id}
          content={cont}
          owner={ownProfile}
          onClick={() => this.viewContent(cont._id)}
        />
      ));
    }
    if (filter === 'article' || filter === 'video' || filter === 'audio') {
      contentsList = _contents
        .filter(c => c.content_type === filter)
        .map(cont => (
          <ContentSection
            key={cont._id}
            content={cont}
            onClick={() => this.viewContent(cont._id)}
          />
        ));
    }
    if (filter === 'premium' || filter === 'exclusive') {
      contentsList = _contents
        .filter(c => c.content_access_type === filter)
        .map(cont => (
          <ContentSection
            key={cont._id}
            content={cont}
            onClick={() => this.viewContent(cont._id)}
          />
        ));
    }
    return contentsList;
  };

  onChangeFilter = event => this.setState({ status: event.target.value });

  onTabChange = key => this.setState({ filter: key });

  render() {
    const { title, className, ownProfile } = this.props;
    const { filter } = this.state;
    let contentList = this.renderContents(filter);
    if (contentList.length === 0) {
      contentList = (
        <div className="px-3 py-5 text-center">
          <p className="mb-0 font-medium">No Contents Yet.</p>
        </div>
      );
    } else {
      contentList = <div className="content-tab-container p-3">{contentList}</div>;
    }

    return (
      <div className={['content-tab-card', className].join(' ')}>
        <div className="d-flex justify-content-between pt-3 px-3">
          <h3 className="title">{title}</h3>
          {ownProfile && (
            <div>
              <select
                className="custom-select display2 border-gt py-0"
                onChange={this.onChangeFilter}
              >
                <option value="">All</option>
                <option value="PUBLISHED">Published</option>
                <option value="UNPUBLISHED">Unpublished</option>
                <option value="DRAFT">Draft</option>
              </select>
            </div>
          )}
        </div>
        <Tabs defaultActiveKey="all" id="content-tab" onSelect={this.onTabChange}>
          <Tab eventKey="all" title="All">
            {contentList}
          </Tab>
          <Tab eventKey="article" title="Articles">
            {contentList}
          </Tab>
          <Tab eventKey="audio" title="Audio">
            {contentList}
          </Tab>
          <Tab eventKey="video" title="Videos">
            {contentList}
          </Tab>
          {ownProfile && (
            <Tab eventKey="premium" title="Premium">
              {contentList}
            </Tab>
          )}
          {ownProfile && (
            <Tab eventKey="exclusive" title="Exclusive">
              {contentList}
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

PractitionerContent.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  ownProfile: PropTypes.bool,
};

PractitionerContent.defaultProps = {
  className: '',
  contents: [],
  ownProfile: false,
};

const mapStateToProps = state => {
  return {
    contents: state.practitionerReg.contents,
  };
};

const mapDispatchToProps = {
  getContents,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PractitionerContent)
);
