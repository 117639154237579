import { toast } from 'react-toastify';
import { SET_LINK_BUSINESS_PAGE_ACTIVE, SET_BUSINESSES, SET_ADMIN_APPROVE_STATUS } from './types';
import axios from '../../axios';

export const setPage = step => {
  return {
    type: SET_LINK_BUSINESS_PAGE_ACTIVE,
    payload: step,
  };
};

export const setPageActive = step => dispatch => dispatch(setPage(step));

export const getBusinesses = () => async dispatch => {
  try {
    const response = await axios.get('/api/practitioner/get_businesses');
    if (response.status === 200) {
      const businesses = response.data.businesses.map(business => {
        return {
          label: business.business_name,
          value: business._id,
        };
      });
      dispatch({
        type: SET_BUSINESSES,
        payload: businesses,
      });
    }
  } catch (error) {
    console.log('###########', error);
  }
};

export const linkBusiness = (businessId, cb) => async dispatch => {
  try {
    await axios.post('/api/practitioner/link_business', {
      business_id: businessId,
    });
    dispatch(setPage(4));
    cb();
  } catch (error) {
    cb();
    if (error && error.response && error.response.status === 400) {
      toast.error(error.response.data.message);
      return;
    }
    toast.error('Something went wrong!');
  }
};

export const addBusiness = (businessInfo, cb) => async dispatch => {
  try {
    const response = await axios.post('/api/practitioner/add_business', businessInfo);
    dispatch(setPage(3));
    const { business } = response.data;
    cb(null, business);
  } catch (error) {
    cb();
    if (error && error.response && error.response.status === 400) {
      toast.error(error.response.data.message);
      return;
    }
    toast.error('Something went wrong!');
  }
};

export const approveOrRejectBusiness = (businessId, userId, action, nId, cb) => async dispatch => {
  try {
    const data = {
      business_id: businessId,
      user_id: userId,
      action,
    };
    await axios.post('/api/practitioner/business-approve-or-reject', data);
    dispatch({
      type: SET_ADMIN_APPROVE_STATUS,
      payload: {
        id: nId,
        status: action,
      },
    });
    cb();
    // cb();
  } catch (error) {
    // cb();
    toast.error('Something went wrong!');
  }
};

export const approveBusinessInvitation = (businessLinkId, action, nId, cb) => async dispatch => {
  const data = { businessLinkId, action };
  try {
    await axios.post('/api/practitioner/approve-business-invitation', data);
    dispatch({
      type: SET_ADMIN_APPROVE_STATUS,
      payload: {
        id: nId,
        status: action,
      },
    });
    cb();
  } catch (error) {
    cb();
    toast.error('Something went wrong!');
  }
};

export const acceptBusinessInvitation = (businessId, token, cb) => async dispatch => {
  const data = {
    businessId,
    token,
  };
  try {
    await axios.post('/api/practitioner/accept-business', data);
    cb(null);
  } catch (error) {
    if (error && error.response && error.response.status === 400) {
      cb({ errCode: 400, errMsg: error.response.data.message });
      return;
    }
    toast.error('Something went wrong!');
  }
};

export const invitePeople = (emails, businessId, cb) => async dispatch => {
  const data = {
    business_id: businessId,
    emails,
  };
  try {
    const response = await axios.post('/api/practitioner/invite-to-business', data);
    cb(null);
    dispatch(setPage(4));
  } catch (error) {
    cb(error);
    toast.error('Error');
  }
};
