/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import moment from 'moment';
import './UserSettings.css';
import PriceCard from '../../common/PriceCard/PriceCard';
import { getMembershipDetails, getAllCreditCards } from '../../../store/actions/settingsActions';
import { cancelSubscription } from '../../../store/actions/paymentActions';
import CreditCardDetails from '../../common/CreditCardDetails/CreditCardDetails';
import ModalComponent from '../../shared/Modal/Modal';

const planDescription = [
  {
    id: 1,
    head: 'Unlimited Access!',
    subhead: 'Enjoy ALL Premium content and exclusive events! Cancel anytime.',
  },
  {
    id: 2,
    head: 'Discount Prices',
    subhead: 'Save money on Exclusive content, special offers, and special events!',
  },
  {
    id: 3,
    head: 'Priority Experience!',
    subhead: 'Advanced access to retreats, workshops, webinars, and new features to come!',
  },
];

class Membership extends React.Component {
  constructor() {
    super();
    this.state = {
      showConfirmation: false,
    };
  }

  componentDidMount() {
    const { getMembershipDetails, getAllCreditCards } = this.props;
    getMembershipDetails();
    getAllCreditCards();
  }

  componentDidUpdate(prevProps) {
    const { premiumUser, getMembershipDetails } = this.props;
    if (prevProps.premiumUser !== premiumUser && !premiumUser) {
      getMembershipDetails();
    }
  }

  premiumUser = () => {
    const { membershipDetails, cards } = this.props;
    return (
      <>
        <div className="row flex-column px-4">
          <div className="col">
            <h5 className="font-weight-bold">Current Plan</h5>
            <div className="membership-plan mt-3 p-3 d-flex justify-content-between">
              <div>
                <p className="font-weight-bold text-uppercase mb-1">
                  {membershipDetails && membershipDetails.plan_name}
                </p>
                <p className="plan-description mb-1">
                  Next Renewel : {membershipDetails && membershipDetails.next_renewal}
                </p>
              </div>
              <img src="/icons/premium-member.svg" alt="premium" />
            </div>
          </div>
          <div className="col">
            {cards.length && <CreditCardDetails membership card={cards[0]} />}
          </div>
        </div>
        <div className="row px-4 pt-3">
          <div className="col px-0 mx-3 pb-3 d-flex align-items-center cancel-border">
            <img
              onClick={() => this.setState({ showConfirmation: true })}
              onKeyDown={() => this.setState({ showConfirmation: true })}
              className="subscription-close"
              src="/icons/cancel-subsciption.svg"
              alt="close"
            />
            <p className="text-black-50 mb-0 ml-1 text-uppercase">Cancel Subscription</p>
          </div>
        </div>
        <div className="row px-4 pt-5">
          <div className="col-md-10 col-12">
            <h5 className="font-weight-bold">Billing History</h5>
            <table className="table table-borderless mt-4">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Invoice Number</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {membershipDetails.invoices &&
                  membershipDetails.invoices.map(invoice => (
                    <tr>
                      <td>{moment.unix(invoice.created).format('DD MMM YYYY')}</td>
                      <td>{invoice.number}</td>
                      <td>${(invoice.amount_paid / 100).toFixed(2)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  nonPremiumUser = () => {
    const { pricePlans, loading } = this.props;
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-md-8 col-11 d-flex flex-column justify-content-center">
            <img className="type-icon" src="/icons/star-premium.svg" alt="star-premium" />
            <h1 className="membership-title">
              Looks like you're missing out on GuideTrue Premium!
            </h1>
            <p className="membership-subtitle">
              Choose a Premium Plan to take your growth to the next level!
            </p>
          </div>
        </div>
        <div className="row justify-content-center px-5 pt-5">
          {planDescription.map(description => (
            <div key={description.id} className="col-md-4 col-12 pb-3 text-center">
              <img className="pb-2 plan-tick-icon" src="/icons/green-tick.svg" alt="tick" />
              <p className="mb-0">{description.head}</p>
              <p className="text-black-50 plan-description">{description.subhead}</p>
            </div>
          ))}
        </div>
        <div className="row justify-content-center px-5 pt-5">
          {!loading &&
            pricePlans.map(plan => (
              <div key={plan.id} className="col pb-3">
                <PriceCard plan={plan} />
              </div>
            ))}
          {loading &&
            [1, 2, 3].map(skelton => (
              <div key={skelton} className="col pb-3">
                <div className="price-card-skelton shine w-100" />
              </div>
            ))}
        </div>
      </>
    );
  };

  onConfimation = () => {
    const { cancelSubscription } = this.props;
    this.setState({ showConfirmation: false });
    cancelSubscription();
  };

  render() {
    const { premiumUser, paymentLoader, creditCardLoader } = this.props;
    const { showConfirmation } = this.state;
    return (
      <div id="membership-settings">
        {!(paymentLoader || creditCardLoader) &&
          (premiumUser ? this.premiumUser() : this.nonPremiumUser())}
        {(paymentLoader || creditCardLoader) &&
          [1, 2, 3, 4].map(skelton => (
            <div key={skelton} className="col pb-3">
              <div className="member-skelton shine w-100" />
            </div>
          ))}
        <ModalComponent
          size="small"
          onHide={() => this.setState({ showConfirmation: false })}
          show={showConfirmation}
          title="Cancel Subscription"
          noHeader
        >
          <div className="d-flex flex-column p-2">
            <div className="d-flex align-items-center">
              <img src="/icons/cancel-sub.svg" alt="cancel" style={{ width: '4rem' }} />
              <div className="d-flex flex-column align-items-baseline pl-3 ml-2">
                <h5 className="text-center font-weight-bold mb-0">Cancel Subscription</h5>
                <p className="text-black-50 mb-0">
                  Are you sure you want to cancel the subscription?
                </p>
              </div>
            </div>
            <div className="d-flex pt-4 justify-content-end">
              <button
                type="submit"
                onClick={() => this.setState({ showConfirmation: false })}
                className="btn p-2 btn-outline-white px-4 font-weight-normal"
              >
                No
              </button>
              <button
                type="submit"
                onClick={() => this.onConfimation()}
                className="btn ml-3  btn-signup p-2 px-4 font-weight-normal"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    );
  }
}

Membership.propTypes = {
  getMembershipDetails: PropTypes.func.isRequired,
  getAllCreditCards: PropTypes.func.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  premiumUser: PropTypes.bool.isRequired,
  membershipDetails: PropTypes.shape({
    plan_name: PropTypes.string,
    next_renewal: PropTypes.string,
    invoices: PropTypes.arrayOf(PropTypes.object),
  }),
  pricePlans: PropTypes.arrayOf(PropTypes.object),
  cards: PropTypes.arrayOf(PropTypes.object),
  paymentLoader: PropTypes.bool,
  loading: PropTypes.bool,
  creditCardLoader: PropTypes.bool,
};

Membership.defaultProps = {
  membershipDetails: null,
  pricePlans: [],
  cards: [],
  paymentLoader: false,
  loading: false,
  creditCardLoader: false,
};

const mapStateToProps = state => ({
  pricePlans: state.settings.pricePlans,
  premiumUser: state.payment.premiumUser,
  membershipDetails: state.payment.membershipDetails,
  loading: state.app.loading,
  paymentLoader: state.payment.paymentLoader,
  cards: state.settings.cards,
  creditCardLoader: state.payment.creditCardLoader,
});

export default compose(
  connect(
    mapStateToProps,
    { getMembershipDetails, getAllCreditCards, cancelSubscription }
  )
)(Membership);
