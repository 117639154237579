import React from 'react';

const Next = ({ className, style, onClick }) => (
  <i
    className={[className, 'c-slick-next'].join(' ')}
    style={{ ...style }}
    onClick={onClick}
    role="presentation"
  />
);

const Prev = ({ className, style, onClick }) => (
  <i
    className={[className, 'c-slick-prev'].join(' ')}
    style={{ ...style, display: 'block' }}
    onClick={onClick}
    role="presentation"
  />
);

const settings = {
  dots: false,
  infinite: false,
  speed: 400,
  slidesToShow: 5.5,
  slidesToScroll: 2,
  nextArrow: <Next />,
  prevArrow: <Prev />,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 346,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

export default settings;
