/* eslint-disable no-class-assign */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmailValid, isEmpty } from '../../../utils/validators';
import { requestResetPasswordLink } from '../../../store/actions/authActions';
import Input from '../../common/Input/Input';
import './ForgotPassword.css';

const validater = values => {
  const errors = {};
  if (!isEmpty(values.email)) {
    errors.email = 'This Field is required';
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Invalid Email';
  }
  return errors;
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSent: false,
    };
  }

  submitHandler = values => {
    const { requestResetPasswordLink } = this.props;
    requestResetPasswordLink(values.email, err => {
      if (!err) {
        this.setState({ emailSent: true });
      }
    });
  };

  render() {
    const { submitting, handleSubmit, invalid, email } = this.props;
    const { emailSent } = this.state;
    return (
      <div className="container">
        <div
          className="row justify-content-center align-items-center py-4"
          style={{ minHeight: '100vh' }}
        >
          <div className="col-md-7 col-11">
            <div id="forgot-password">
              <Link to="/">
                <img className="img-fluid" src="/icons/logo.svg" alt="logo" />
              </Link>
              <div className="border rounded">
                {!emailSent && (
                  <form className="" onSubmit={handleSubmit(this.submitHandler)} autoComplete="off">
                    <h1 className="m-0 h1 text-dark">Forgot Password ?</h1>
                    <p className="mb-5">Enter the email address associated with your account.</p>
                    <div className="mb-4">
                      <Field name="email" icon="icon-mail" placeholder="Email" component={Input} />
                    </div>
                    <button type="submit" className="btn gt-btn" disabled={submitting || invalid}>
                      Send Reset Link
                    </button>
                  </form>
                )}
                {emailSent && (
                  <div className="text-dark link-sent-message d-flex align-items-center py-5">
                    <i className="icon-check_circle text-success mr-2" /> A link to reset your
                    password has been sent to {email}.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  requestResetPasswordLink: PropTypes.func.isRequired,
};

ForgotPassword = reduxForm({
  form: 'forgot-password',
  validate: validater,
})(ForgotPassword);

const mapStateToProps = state => {
  const selector = formValueSelector('forgot-password');
  const email = selector(state, 'email');
  return {
    email,
  };
};

export default connect(
  mapStateToProps,
  { requestResetPasswordLink }
)(ForgotPassword);
