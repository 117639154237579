/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SearchBox from './SearchBox';
import { logoutUser } from '../../../store/actions/authActions';
import ModalComponent from '../../shared/Modal/Modal';
import UserType from './UserType';
import { setUserTypeModal, setUserType } from '../../../store/actions/appActions';

class Navbars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
    };
  }

  logoutHandler = () => {
    const { logoutUser, history } = this.props;
    logoutUser();
    history.push('/signin');
  };

  render() {
    const { isCollapsed } = this.state;
    const { show, setUserTypeModal, history } = this.props;
    return (
      <div className="container">
        <div className={isCollapsed ? 'navbar-res-view p-3 open' : 'navbar-res-view p-3'}>
          <div className="d-flex py-2">
            <img src="/images/guide-true.svg" alt="Logo" />
            <img
              src="/icons/close.svg"
              className="ml-auto"
              onClick={() => this.setState({ isCollapsed: false })}
              role="presentation"
              alt="Close"
            />
          </div>
          <ul>
            <li>
              <Link to="/search?type=practitioner">Find Practitioner</Link>
            </li>
            <li>
              <Link to="/search?type=content">Resources</Link>
            </li>
            <li>
              <Link to="/signin">Sign In</Link>
            </li>
            <li>
              <button
                type="button"
                className="btn signup"
                onClick={() => {
                  setUserTypeModal(true);
                  this.setState({ isCollapsed: false });
                }}
              >
                Signup
              </button>
            </li>
          </ul>
        </div>
        <div className="row landing-navbar">
          <div className="col-lg-2 col-2 d-lg-block d-none">
            <Link to="/" className="position-relative">
              <img className="mr-auto nav-logo" src="/icons/logo-white.svg" alt="Logo" />
            </Link>
          </div>
          <div className="col-lg-5 col-12">
            <div className="d-lg-block d-flex align-items-center">
              <SearchBox placeholder="What are you looking for ?" />
              <img
                className="d-lg-none d-block"
                onClick={() => this.setState({ isCollapsed: true })}
                src="/icons/hamburger.svg"
                alt="Hamburger"
                role="presentation"
                style={{ marginLeft: '1rem' }}
              />
            </div>
          </div>
          <div className="col-lg-5 d-lg-block d-none">
            <div className="d-flex">
              <div className="ml-auto navbar-links" style={{ whiteSpace: 'nowrap' }}>
                <Link to="/search?type=practitioner">Find Practitioner</Link>
                <Link to="/search?type=content">Resources</Link>
                <button type="button" className="btn signup" onClick={() => setUserTypeModal(true)}>
                  Signup
                </button>
                <Link to="/signin">Sign In</Link>
              </div>
            </div>
          </div>
        </div>

        <ModalComponent title="Signup" show={show} size="lg" onHide={() => setUserTypeModal(false)}>
          <div className="row justify-content-center py-md-5">
            <div className="col-md-5 col-12 mb-3">
              <UserType
                type="practitioner"
                image="/images/practitioner-signup.svg"
                onClick={() => {
                  history.push('/signup?u_type=practitioner');
                  setUserType('practitioner');
                  setUserTypeModal(false);
                }}
              />
            </div>
            <div className="col-md-5 col-12">
              <UserType
                type="member"
                image="/images/member-signup.svg"
                onClick={() => {
                  history.push('/signup?u_type=member');
                  setUserType('member');
                  setUserTypeModal(false);
                }}
              />
            </div>
          </div>
        </ModalComponent>
      </div>
    );
  }
}

Navbars.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  logoutUser: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setUserTypeModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  show: state.app.userTypeModal,
});

export default withRouter(
  connect(
    mapStateToProps,
    { logoutUser, setUserTypeModal, setUserType }
  )(Navbars)
);
