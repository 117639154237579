import React from 'react'
import './SelectionCard.css'

export default function SelectionCard(props) {
    return (
        <div className="selection-card text-center p-4">
          <div className="p-2">
              <img src={props.selectionType.img_url} alt="selection card" width="190px" height="190px"/>
          </div>
          <p className="type mt-3 mb-1">{props.selectionType.type}</p>
          <span>{props.selectionType.description}</span>
        </div>
    )
}
