/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import axios from '../../axios';
import { setLoader } from './appActions';
import {
  SET_PROMOTIONS,
  SET_PROMOTION,
  ADD_PROMOTION,
  EDIT_PROMOTION,
  DELETE_PROMOTION,
  SET_SHARED_RESULTS,
  SET_NEWRESULT_FIRST,
  SET_OLDRESULT_FIRST,
  UPDATE_SHARED_RESULT,
  SET_USER_PROFILES,
  SET_ALL_CARDS,
  TOGGLE_ADD_CARD,
  SET_PRICE_PLANS,
  MEMBERSHIP_DETAILS,
  SET_PAYMENT_LOADER,
  SET_CREDIT_CARD_LOADER,
  SET_ORDERS_LIST,
  SET_SALES_LIST,
  SET_BUSINESS_INFO,
  CHANGE_BUSINESS_LOGO,
} from './types';
import { setCurrentUser } from './authActions';

export const getUserProfiles = callback => async dispatch => {
  try {
    const response = await axios.get('/api/user/profiles');
    console.log('response', response);
    const { user } = response.data;
    callback(user);
    dispatch({
      type: SET_USER_PROFILES,
      payload: user,
    });
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = (currentPassword, newPassword) => async dispatch => {
  dispatch(setLoader(true));
  const params = {
    old_password: currentPassword,
    new_password: newPassword,
  };
  try {
    await axios.put('/api/user/change_password', params);
    toast.success('Password updated');
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    if (error.response.status === 400) {
      toast.error('Old Password Wrong');
    }
  }
};

export const updateUserProfile = (params, type) => async dispatch => {
  dispatch(setLoader(true));
  try {
    const response = await axios.put('/api/user/update_user_profile', params);
    const { user, profile } = response.data;
    localStorage.setItem('user', JSON.stringify(user));
    dispatch(setCurrentUser(user));
    if (!type) {
      window.location.reload();
      return;
    }
    dispatch({
      type: SET_USER_PROFILES,
      payload: profile,
    });
    if (type === 'links') toast.success('Links updated');
    else if (type === 'username') toast.success('Username updated');
    else toast.success('Profile updated');
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const deleteUserAccount = (params, cb) => async dispatch => {
  dispatch(setLoader(true));
  try {
    await axios.put('/api/user/delete_user_account', params);
    dispatch(setLoader(false));
    cb(null);
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const addPromotions = params => async dispatch => {
  dispatch(setLoader(true));
  try {
    const response = await axios.post('/api/practitioner/add_promotions', params);
    const { promotion } = response.data;
    dispatch({
      type: ADD_PROMOTION,
      payload: promotion,
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getPromotions = () => async dispatch => {
  dispatch(setLoader(true));
  try {
    const response = await axios.get(`/api/practitioner/get_promotions`);
    const allPromotions = response.data.promotions;
    dispatch(setLoader(false));
    dispatch({
      type: SET_PROMOTIONS,
      payload: allPromotions,
    });
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const deletePromotion = promo_id => async dispatch => {
  dispatch(setLoader(true));
  try {
    const response = await axios.put(`/api/practitioner/delete_promotion/${promo_id}`);
    if (response.data.status) {
      dispatch({
        type: DELETE_PROMOTION,
        payload: promo_id,
      });
    }
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const setPromotion = promotion => async dispatch => {
  dispatch({
    type: SET_PROMOTION,
    payload: promotion,
  });
};

export const editPromotion = (promo_id, params) => async dispatch => {
  dispatch(setLoader(true));
  try {
    const response = await axios.put(`/api/practitioner/edit_promotion/${promo_id}`, params);
    const { promotion } = response.data;
    // console.log('edit--->>>', response.data);

    dispatch(setLoader(false));
    dispatch({
      type: EDIT_PROMOTION,
      payload: promotion,
    });
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getSharedResults = () => async dispatch => {
  dispatch(setLoader(true));
  try {
    const response = await axios.get('/api/user/shared_result');
    const { sharedByMe, sharedWith } = response.data;
    dispatch({
      type: SET_SHARED_RESULTS,
      payload: { sharedByMe, sharedWith },
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const sortSharedResults = (results, type) => async dispatch => {
  if (type.newDate) {
    const sortedResult = results.sort((a, b) => {
      return new Date(b.shared_at) - new Date(a.shared_at);
    });
    dispatch({
      type: SET_NEWRESULT_FIRST,
      payload: sortedResult,
    });
  } else {
    const sortedResult = results.sort((a, b) => {
      return new Date(a.shared_at) - new Date(b.shared_at);
    });
    dispatch({
      type: SET_OLDRESULT_FIRST,
      payload: sortedResult,
    });
  }
};

export const updateAssessmentResultStatus = result => async dispatch => {
  dispatch(setLoader(true));
  try {
    await axios.post('/api/member/share_result', { practitioner_id: result.shared_with._id });
    dispatch(setLoader(false));
    dispatch({
      type: UPDATE_SHARED_RESULT,
      payload: {
        _id: result._id,
        share_status: result.share_status === 'SHARED' ? 'PRIVATE' : 'SHARED',
      },
    });
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getAllCreditCards = () => dispatch => {
  dispatch({
    type: SET_CREDIT_CARD_LOADER,
    payload: true,
  });
  dispatch({
    type: SET_ALL_CARDS,
    payload: [],
  });
  axios
    .get('/api/user/get-credit-cards')
    .then(res => {
      dispatch({
        type: SET_CREDIT_CARD_LOADER,
        payload: false,
      });
      const { data } = res;
      if (data.status) {
        if (data.cards.length) {
          dispatch({
            type: SET_ALL_CARDS,
            payload: data.cards,
          });
          dispatch({
            type: TOGGLE_ADD_CARD,
            payload: false,
          });
        } else {
          dispatch({
            type: TOGGLE_ADD_CARD,
            payload: true,
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: SET_CREDIT_CARD_LOADER,
        payload: false,
      });
      dispatch({
        type: TOGGLE_ADD_CARD,
        payload: true,
      });
    });
};

export const toggleAddCard = toggle => dispatch => {
  dispatch({
    type: TOGGLE_ADD_CARD,
    payload: toggle,
  });
};

export const getBusinessDetailes = callback => async dispatch => {
  try {
    const response = await axios.get('/api/practitioner/get-my-business');
    const { business } = response.data;
    dispatch({
      type: SET_BUSINESS_INFO,
      payload: business,
    });
    callback(business);
  } catch (error) {
    callback(error);
    toast.error('Somthing went wrong');
  }
};

export const updateBusinessInfo = (businessInfo, businessId) => async dispatch => {
  try {
    const response = await axios.put(
      `/api/practitioner/update-business/${businessId}`,
      businessInfo
    );
  } catch (error) {
    toast.error('Somthing went wrong');
  }
};

export const updateBusinessLogo = (businessId, logo) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('logo', logo);
    dispatch(setLoader(true));
    const res = await axios.post(`/api/practitioner/upload-business-logo/${businessId}`, formData);
    const { logoUrl } = res.data;
    dispatch({
      type: CHANGE_BUSINESS_LOGO,
      payload: logoUrl,
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    toast.error('somthing wrong');
  }
};

export const removeBusinessLogo = businessId => async dispatch => {
  try {
    dispatch(setLoader(true));
    await axios.post(`/api/practitioner/remove-business-logo/${businessId}`);
    dispatch({
      type: CHANGE_BUSINESS_LOGO,
      payload: '',
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    toast.error('somthing wrong');
  }
};

export const getPricePlans = dispatch => {
  dispatch(setLoader(true));
  axios
    .get('/api/meta/price-plans')
    .then(res => {
      const { data } = res;
      dispatch(setLoader(false));
      if (data.status) {
        dispatch({
          type: SET_PRICE_PLANS,
          payload: data.pricePlans.data,
        });
      }
    })
    .catch(err => {
      const { response } = err;
      if (response && !response.data.status) {
        dispatch(setLoader(false));
      }
    });
};

export const getMembershipDetails = () => dispatch => {
  dispatch({
    type: SET_PAYMENT_LOADER,
    payload: true,
  });
  axios
    .get('/api/user/get-membership-detail')
    .then(res => {
      dispatch({
        type: SET_PAYMENT_LOADER,
        payload: false,
      });
      const { data } = res;
      if (data.status) {
        dispatch({
          type: MEMBERSHIP_DETAILS,
          payload: { premiumUser: data.userMembership, membershipDetails: data.subscription },
        });
      }
    })
    .catch(err => {
      dispatch({
        type: SET_PAYMENT_LOADER,
        payload: false,
      });
      getPricePlans(dispatch);
      const { data } = err.response;
      dispatch({
        type: MEMBERSHIP_DETAILS,
        payload: { premiumUser: data.userMembership, membershipDetails: data.subscription },
      });
    });
};

export const getOrderHistory = callback => async dispatch => {
  try {
    dispatch(setLoader(true));
    const response = await axios.get('/api/user/order-history');
    const { orders } = response.data;
    dispatch({
      type: SET_ORDERS_LIST,
      payload: orders,
    });
    callback();
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    callback();
    toast.error('Something went wrong. Please try again');
  }
};

export const getSalesHistory = callback => async dispatch => {
  try {
    dispatch(setLoader(true));
    const response = await axios.get('/api/user/sales-history');
    const { sales } = response.data;
    dispatch({
      type: SET_SALES_LIST,
      payload: sales,
    });
    dispatch(setLoader(false));
    callback();
  } catch (error) {
    dispatch(setLoader(false));
    callback();
    toast.error('Something went wrong. Please try again');
  }
};
