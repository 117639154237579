import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../../common/Button/Button';
import './AssesmentWelcome.css';
import { setPage } from '../../../../../store/actions/assessmentActions';

const AssesmentWelcome = ({ dispatch, user }) => {
  const purposes = [
    ` Our purpose is to help you achieve holistic wellness and actualize your goals in an affordable,
    personalized, and effective way.`,
    `To begin, please fill out this short questionnaire to help match you with the best practitioners and resources to help you achieve your goals.`,
    `Your answers can also give practitioners you connect with a good starting point in getting to know you, should you decide to share your results with them. This will save you time, money, and energy in your personal journey.`,
  ];

  const startAssessment = () => {
    if (user.u_member_profile_completion_status) {
      dispatch(setPage(5));
      return;
    }
    dispatch(setPage(2));
  };

  return (
    <div className="d-flex h-100 welcome-container w-100">
      <div className="py-3 my-auto w-100" id="assessment-welcome-page">
        <h1>
          <span className="welcome-combined">WELCOME TO YOUR</span>
          PERSONAL ASSESSMENT
        </h1>
        <div className="text-center container-fluid">
          <div className="mt-3 mb-4">
            <Button onClick={startAssessment} varient="large">
              Start Assessment
            </Button>
          </div>
          <h2 className="mb-4">Why is this important ?</h2>
          <div className="row justify-content-center mb-4">
            <div className="col-md-8">
              <div className="purpose">
                {purposes.map((p, idx) => (
                  <p className="mb-3">{p}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AssesmentWelcome.propTypes = {
  user: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
};

AssesmentWelcome.defaultPropTypes = {
  user: null,
};

const mapStateToProps = state => ({
  user: state.auth.currentUser,
});

export default connect(mapStateToProps)(AssesmentWelcome);
