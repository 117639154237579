/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';

const ListPrivacyPolicy = ({ data }) => {
  const privacyPolicyList = data.map(item => {
    const expMatch = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const elementContent = item.data.replace(expMatch, "<a href=$1>$1</a>");
    const newExpMatch = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    const newContent = elementContent.replace(newExpMatch, '$1<a target="_blank" href=https:\\$2>$2</a>');
    return <li key={item.id} dangerouslySetInnerHTML={{ __html: newContent }} />;
    // return <li key={item.id}>{newContent}</li>;
  });
  return <ul>{privacyPolicyList}</ul>;
};

ListPrivacyPolicy.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ListPrivacyPolicy;
