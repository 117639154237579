import axios from 'axios';

if (process.env.REACT_APP_ENV === 'development') axios.defaults.baseURL = 'http://localhost:7000';
else if (process.env.REACT_APP_ENV === 'production')
  axios.defaults.baseURL = 'https://api.guidetrue.com';
else axios.defaults.baseURL = 'https://dev-api.guidetrue.com';

console.log('[Environment]=', process.env.REACT_APP_ENV);

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      const item = { ...config };
      item.headers.Authorization = `Bearer ${token}`;
      return item; // replace original with new instance
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default axios;
