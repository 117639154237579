/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, FieldArray, formValueSelector } from 'redux-form';
// import RadioSelect from '../../../../common/RadioSelect/RadioSelect';
import PractitionerRegistrationHeader from './PractitionerRegistrationHeader';
import BestOwedInstitutions from './BestOwedInstitutions';
// import SelectInput from '../../../../common/SelectInput/SelectInput';
import MultiSearchDropDown from '../../../../common/MultiSearchDropDown/MultiSearchDropDown';
import Input from '../../../../common/Input/Input';

const typeOfServices = [
  { label: 'Individual', value: 'individual' },
  { label: 'Group', value: 'group' },
  { label: 'Parties', value: 'parties' },
  { label: 'Remote', value: 'remote' },
  { label: 'Retreats', value: 'retreats' },
  { label: 'Workshops', value: 'work-shops' },
];

const sessionTypes = [
  { label: 'In Office', value: 'in-office' },
  { label: 'Outcall', value: 'out-call' },
  { label: 'Video Chat', value: 'video-chat' },
  { label: 'Phone', value: 'phone' },
  { label: 'Text', value: 'text' },
];

const PractitinerRegistration4 = ({ p_licensed, p_certified }) => (
  <div id="practitioner-registration-1" className="py-md-5 py-4 mt-md-5">
    <div className="mb-5">
      <PractitionerRegistrationHeader
        title="Professional Services"
        info="Choose your professional service."
      />
      <div className="row">
        <div className="col-6 mb-5">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">
            Type of Service
          </label>
          {/* <Field name="p_service_type" varient="material" component={SelectInput}>
            <option value="">Type of Service</option>
            <option value="individual">Individual</option>
            <option value="group">Group</option>
            <option value="parties">Parties</option>
            <option value="remote">Remote</option>
            <option value="retreats">Retreats</option>
            <option value="work-shops">Workshops</option>
          </Field> */}
          <Field
            name="p_service_type"
            type="select-multi"
            component={prop => (
              <MultiSearchDropDown
                placeholder="Type of Service"
                options={typeOfServices}
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
                {...prop}
              />
            )}
          />
        </div>
        <div className="col-6 mb-5">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">
            Session Type
          </label>
          {/* <Field name="p_session_type" varient="material" component={SelectInput}>
            <option value="">Session Type</option>
            <option value="in-office">In Office</option>
            <option value="out-call">Outcall</option>
            <option value="video-chat">Video Chat</option>
            <option value="phone">Phone</option>
            <option value="text">Text</option>
          </Field> */}
          <Field
            name="p_session_type"
            type="select-multi"
            component={prop => (
              <MultiSearchDropDown
                placeholder="Session Type"
                options={sessionTypes}
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
                {...prop}
              />
            )}
          />
        </div>
      </div>
    </div>
    <div>
      <PractitionerRegistrationHeader
        title="Professional Background"
        info="Choose your professional background"
      />
      {/* <Field
        name="p_professional_type"
        component={props => (
          <RadioSelect options={['licensed', 'certified', 'self-taught']} {...props} />
        )}
      />
      {(pb_type === 'licensed' || pb_type === 'certified') && (
        <FieldArray name="p_bestowed_institutions" component={BestOwedInstitutions} />
      )} */}
      <div className="mb-4 pb-2">
        <Field
          name="p_licensed"
          type="checkbox"
          varient="material"
          component={Input}
          label={
            <p className="mb-0 ml-2" style={{ lineHeight: 1 }}>
              I am a licensed practitioner
            </p>
          }
        />
        {p_licensed && (
          <div className="p-3 ml-4">
            <FieldArray name="p_licenses" component={BestOwedInstitutions} />
          </div>
        )}
      </div>
      <div className="mb-4 pb-2">
        <Field
          name="p_certified"
          type="checkbox"
          varient="material"
          component={Input}
          label={
            <p className="mb-0 ml-2" style={{ lineHeight: 1 }}>
              I am a certified practitioner
            </p>
          }
        />
        {p_certified && (
          <div className="p-3 ml-4">
            <FieldArray name="p_certifications" component={BestOwedInstitutions} />
          </div>
        )}
      </div>
      <div className="mb-4 pb-2">
        <Field
          name="p_self_taught"
          type="checkbox"
          varient="material"
          component={Input}
          label={
            <p className="mb-0 ml-2" style={{ lineHeight: 1 }}>
              I am a self taught practitioner
            </p>
          }
        />
      </div>
    </div>
  </div>
);

PractitinerRegistration4.propTypes = {
  p_licensed: PropTypes.bool,
  p_certified: PropTypes.bool,
  p_self_taught: PropTypes.bool,
};

PractitinerRegistration4.defaultProps = {
  p_licensed: false,
  p_certified: false,
  p_self_taught: false,
};

const mapStateToProps = state => {
  const selector = formValueSelector('practitionerProfileForm');
  const p_licensed = selector(state, 'p_licensed');
  const p_certified = selector(state, 'p_certified');
  const p_self_taught = selector(state, 'p_self_taught');
  return {
    p_licensed,
    p_certified,
    p_self_taught,
  };
};

export default connect(mapStateToProps)(PractitinerRegistration4);
