import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SearchDropDown.css';

class SearchDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options || [],
      filteredOptions: props.options || [],
      activeIndex: 0,
      isOpen: false,
      value: props.value || '',
    };

    this.wrapperRef = React.createRef();
    this.dropdown = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      const { options } = this.state;
      const index = options.findIndex(opt => opt.value === value);
      if (index > -1) {
        this.setState({ value: options[index].label });
      } else {
        this.setState({ value });
      }
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProp) {
    const { options } = this.props;
    if (options.length !== prevProp.options.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        options,
        filteredOptions: options,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  onChange = event => {
    const { options } = this.state;
    const { value } = event.target;
    const filteredOptions = options.filter(
      opt => opt.label && opt.label.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({
      activeIndex: 0,
      isOpen: true,
      value,
      filteredOptions,
    });
  };

  onFocus = () => this.setState({ isOpen: true });

  onBlur = () => {
    // const { value } = this.state;
    // const { other, onSelect } = this.props;
    // if (other && value && value.trim()) {
    //   onSelect(value);
    // }
    const { onSelect, other } = this.props;
    const { isOpen, filteredOptions, value } = this.state;
    if (!isOpen || filteredOptions.length === 0) {
      if (other === true) {
        onSelect(value);
      }
    }
    if (filteredOptions.length === 0 && !other) {
      onSelect('');
    }
  };

  onKeyDown = event => {
    const { filteredOptions, activeIndex, isOpen, value } = this.state;
    const { onSelect, other } = this.props;

    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      if (!isOpen || filteredOptions.length === 0) {
        if (other === true && onSelect) {
          onSelect(value);
        }
        return;
      }

      this.setState({
        activeIndex: 0,
        isOpen: false,
        value: filteredOptions[activeIndex].label,
      });
      if (onSelect) {
        onSelect(filteredOptions[activeIndex].value);
      }
    } else if (event.keyCode === 40) {
      // Down Arrow
      if (activeIndex + 1 === filteredOptions.length) {
        return;
      }
      this.setState({ activeIndex: activeIndex + 1 });
    } else if (event.keyCode === 38) {
      // Up Arrow
      if (activeIndex === 0) {
        return;
      }
      this.setState({ activeIndex: activeIndex - 1 });
    }

    let listItems;
    if (this.dropdown.current) {
      listItems = this.dropdown.current.childNodes;
      this.dropdown.current.scrollTop = 0;
    }
    if (listItems && listItems.length) {
      // eslint-disable-next-line react/destructuring-assignment
      const activeElement = listItems[this.state.activeIndex + 1];
      if (activeElement) {
        this.dropdown.current.scrollTop =
          activeElement.offsetTop - (this.dropdown.current.clientHeight - 34.38);
      }
    }
  };

  onClick = option => {
    const { onSelect } = this.props;
    this.setState({
      activeIndex: 0,
      isOpen: false,
      value: option.label,
    });
    if (onSelect) {
      onSelect(option.value);
    }
  };

  render() {
    const { filteredOptions, isOpen, activeIndex, value } = this.state;
    const { placeholder, meta, input, varient, other } = this.props;
    let listItems = filteredOptions.map((opt, i) => (
      <li
        key={opt.value}
        className={[i === activeIndex ? 'active' : '']}
        onClick={() => this.onClick(opt)}
        role="presentation"
      >
        {opt.label}
      </li>
    ));
    if (listItems.length === 0 && !other) {
      listItems = (
        <li style={{ backgroundColor: '#c1c1c1' }} role="presentation">
          No Items
        </li>
      );
    }
    return (
      <div ref={this.wrapperRef} className="position-relative" name={input && input.name}>
        <div className="d-flex">
          <input
            className={[
              'form-control',
              varient === 'primary' ? 'gt-form-control' : 'search-dropdown-input',
            ].join(' ')}
            value={value}
            placeholder={placeholder}
            onFocus={this.onFocus}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            autoComplete="new-password"
          />
          {/* <img
            className={isOpen ? 'ml-auto rotate' : 'ml-auto'}
            src="/images/down.svg"
            alt="down-arrow"
          /> */}
        </div>
        {meta && meta.error && meta.touched && (
          <span
            className={['invalid-feedback d-block', !varient && 'gtm-invalid-feedback'].join(' ')}
          >
            {meta.error}
          </span>
        )}
        {isOpen && (listItems.length > 0) && (
          <ul
            ref={this.dropdown}
            className={['search-dropdown-box', isOpen ? 'opened' : ''].join(' ')}
          >
            {listItems}
          </ul>
        )}
      </div>
    );
  }
}

SearchDropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    name: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  varient: PropTypes.string,
  input: PropTypes.shape({
    onBlur: PropTypes.func.isRequired,
  }),
  other: PropTypes.bool,
  value: PropTypes.string,
};

SearchDropDown.defaultProps = {
  other: false,
  value: '',
  varient: '',
  meta: null,
  input: null,
};

export default SearchDropDown;
