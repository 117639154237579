/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import * as metaData from '../../../../data/meta.json';
import SelectInput from '../../../common/SelectInput/SelectInput';
// import MatInput from '../../../common/MatInput/MatInput';
import Button from '../../../common/Button/Button';
// eslint-disable-next-line import/no-named-as-default
import MultiSearchDropDown from '../../../common/MultiSearchDropDown/MultiSearchDropDown';
import { getMemberIntrestList } from '../../../../store/actions/appActions';
import { updateUser } from '../../../../store/actions/assessmentActions';

class MemberCreateProfileForm extends Component {
  componentDidMount() {
    const { getMemberIntrestList } = this.props;
    getMemberIntrestList();
  }

  submitHandler = values => {
    const { updateUser } = this.props;
    updateUser(values);
  };

  render() {
    const { handleSubmit, categories } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.submitHandler)} className="col-md-10 py-5 my-md-5">
        <h3 className="mb-1 text-white font-weight-bold pr-lg-5">
          To get started, we’d like to ask a few questions to help us understand your needs
        </h3>
        <p className="text-white mb-4">Fill this out to save your progress & results </p>
        <div className="row" style={{ fontSize: '1.2rem' }}>
          {/* <div className="col-6 mb-4">
            <Field name="c_zipcode" placeholder="Zip code" component={MatInput} />
          </div> */}
          <div className="col-md-6 mb-4">
            <label className="text-light font-medium mb-1 display2">Relationship Status</label>
            <Field name="c_relationship_status" varient="material" component={SelectInput}>
              <option value="">Relationship Status</option>
              <option value="single">Single</option>
              <option value="married">Married</option>
              <option value="divorced">Divorced</option>
              <option value="other">Other</option>
            </Field>
          </div>
          <div className="col-md-6 mb-4">
            <label className="text-light font-medium mb-1 display2">Sexual Orientation</label>
            <Field name="c_sexual_orientation" varient="material" component={SelectInput}>
              <option value="">Sexual Orientation</option>
              {metaData.default.sexualOrientation.map(so => (
                <option key={so} value={so.toLowerCase()}>
                  {so}
                </option>
              ))}
            </Field>
          </div>
          <div className="col-12 mt-5">
            <h3 className="mb-1 text-white font-weight-bold pr-lg-5">
              What are the areas you are looking to improve?
            </h3>
            <p className="text-white mb-4" style={{ fontSize: '1rem' }}>
              Choose as many as you like
            </p>
            <Field
              name="c_intrests"
              component={props => (
                <MultiSearchDropDown
                  placeholder="Select your area of interest"
                  options={categories}
                  other
                  onSelect={value => {
                    props.input.onChange(value);
                  }}
                  value={props.input.value}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-5 py-4">
          <Button type="submit">Let's Get Started</Button>
        </div>
      </Form>
    );
  }
}

// eslint-disable-next-line no-class-assign
MemberCreateProfileForm = reduxForm({
  form: 'memberProfileForm',
  // destroyOnUnmount: false,
  // validate: validators
})(MemberCreateProfileForm);

MemberCreateProfileForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateUser: PropTypes.func.isRequired,
  getMemberIntrestList: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
};

const mapStateToProps = state => ({
  categories: state.app.interest_list,
});

export default connect(
  mapStateToProps,
  {
    getMemberIntrestList,
    updateUser,
  }
)(MemberCreateProfileForm);
