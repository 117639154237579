/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './PractitionerAvailability.css';

class PractitionerAvailability extends Component {
  formateTime = time => {
    if (time === 24) {
      return '12:00 AM';
    }
    const temp = time.toString().split('.');
    if (temp[0].length === 1) temp[0] = `0${temp[0]}`;
    temp[1] ? (temp[1] = 60 * Number(`.${temp[1]}`)) : (temp[1] = '00');
    time = temp.join(':');
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  };

  render() {
    const { availability } = this.props;
    const times = availability.map(time => (
      <div key={time._id} className="prac_schedule_section justify-content-center d-flex">
        <p className="col-5 px-0">{time.day}</p>
        <div className="d-flex col-7 px-0 justify-content-between">
          <p>{this.formateTime(time.time.min)}</p>
          <p>-</p>
          <p>{this.formateTime(time.time.max)}</p>
        </div>
      </div>
    ));

    return (
      <div className="prac_availability">
        <div className="prac_availability_header d-flex justify-content-between">
          <h6 className="m-0">Availability</h6>
        </div>
        <div className="prac_availability_body">
          {/* <p>
            Sed ut perspiciatis unde omnis iste
            natus error sit voluptatem accusantium
            doloremque laudantium, 
          </p> */}
          <div className="prac_avail_schedule">{times}</div>
        </div>
      </div>
    );
  }
}

PractitionerAvailability.propTypes = {
  availability: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
  };
};

export default connect(mapStateToProps)(PractitionerAvailability);
