/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  uploadProfilePicture,
  removeProfilePicture,
} from '../../../../../store/actions/authActions';

class ProfileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImg: '',
    };
    this.fileUpload = React.createRef();
  }

  componentDidMount() {
    // const { selected } = this.props;
    // if(typeof selected === 'object'){
    //     this.setState({
    //         profileImg: URL.createObjectURL(selected)
    //     })
    // }
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.u_image_url) {
      this.setState({
        profileImg: user.u_image_url,
      });
    }
  }

  handleChange = () => {
    const { uploadProfilePicture } = this.props;
    // this.setState({
    //     profileImg: URL.createObjectURL(this.fileUpload.current.files[0])
    // }, ()=> onSelect(this.fileUpload.current.files[0]))
    uploadProfilePicture(this.fileUpload.current.files[0], profile => {
      this.setState({ profileImg: profile });
    });
  };

  deletePictureHandler = event => {
    event.preventDefault();
    // const { onSelect } = this.props;
    // onSelect(null);
    const { removeProfilePicture } = this.props;
    removeProfilePicture(() => {
      this.setState({ profileImg: null });
    });
  };

  render() {
    const { profileImg } = this.state;
    const placeholderImg = '/images/user.svg';
    const { varient } = this.props;
    return (
      <div className="d-flex align-items-center flex-md-row flex-column">
        {/* <div className='profile-image' style={{ backgroundImage: `url(${profileImg})`}}></div> */}
        <img
          className="profile-img mb-md-0 mb-3"
          src={profileImg || placeholderImg}
          alt="profile"
        />
        <div className="ml-md-3">
          <input
            type="file"
            accept="image/*"
            ref={this.fileUpload}
            onChange={this.handleChange}
            className="d-none"
            id="profile-upload"
          />
          <label
            htmlFor="profile-upload"
            className={varient ? 'btn cursor-pointer gt-btn' : 'btn btn-light cursor-pointer'}
          >
            Upload New Picture
          </label>
        </div>
        {profileImg && (
          <button
            type="button"
            className={varient ? 'btn btn-link text-secondary' : 'btn btn-link text-light'}
            onClick={this.deletePictureHandler}
          >
            Delete Picture
          </button>
        )}
      </div>
    );
  }
}

ProfileUpload.propTypes = {
  uploadProfilePicture: PropTypes.func.isRequired,
  removeProfilePicture: PropTypes.func.isRequired,
  varient: PropTypes.string,
};

ProfileUpload.defaultProps = {
  varient: '',
};

export default connect(
  null,
  { uploadProfilePicture, removeProfilePicture }
)(ProfileUpload);
