/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import axios from '../../axios';
import { setLoader } from './appActions';
import {
  SET_AUTH_ERROR,
  SET_CURRENT_USER,
  SET_OAUTH_USER,
  SET_PROFILE,
  SET_SESSION_STATUS,
  SET_PRAC_BOOKMARK_STATUS,
  SET_BOOKMARKED_PRAC_LIST,
  SET_SHARE_RESULT_STATUS,
  SET_USER_REVIEW_LIST,
  SET_REQUEST_RESULT_STATUS,
  SET_PURCHASED_CONTENT_LIST,
  SET_BOOKMARKED_CONTENT_LIST,
  SET_PRACTITIONER_CONTENT_LIST,
  SIGNOUT_REQUEST,
} from './types';

export const setCurrentUser = user => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

export const setError = error => {
  return {
    type: SET_AUTH_ERROR,
    payload: error,
  };
};

export const clearError = () => dispatch => dispatch(setError({}));

export const saveSocialUser = user => dispatch => {
  dispatch({
    type: SET_OAUTH_USER,
    payload: user,
  });
};

export const uploadProfilePicture = (file, cb) => async dispatch => {
  try {
    dispatch(setLoader(true));
    const formData = new FormData();
    formData.append('profile', file);
    const response = await axios.put('/api/user/profile_image', formData);
    const { user } = response.data;
    localStorage.setItem('user', JSON.stringify(user));
    dispatch(setCurrentUser(user));
    cb(user.u_image_url);
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    toast.error('Something went wrong');
  }
};

export const removeProfilePicture = cb => async dispatch => {
  try {
    dispatch(setLoader(true));
    const response = await axios.put('/api/user/profile_image/remove');
    const { user } = response.data;
    localStorage.setItem('user', JSON.stringify(user));
    dispatch(setCurrentUser(user));
    cb();
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    toast.error('Something went wrong');
  }
};

export const login = (loginInfo, cb) => dispatch => {
  dispatch(setError({}));
  dispatch(setLoader(true));
  axios
    .post('/auth/login', loginInfo)
    .then(resp => {
      // if(loginInfo.remember_me){
      const { user, token } = resp.data;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      // }
      dispatch(setCurrentUser(user));
      dispatch(setLoader(false));
      cb(null, user);
    })
    // eslint-disable-next-line consistent-return
    .catch(error => {
      dispatch(setLoader(false));
      if (error && error.response && error.response.status === 403) {
        dispatch(
          setError({
            errorCode: 403,
            errorMsg: error.response.data.message,
          })
        );
        return false;
      }
      dispatch(
        setError({
          errorCode: error.response.status,
          errorMsg: 'Invalid email or password.',
        })
      );
    });
};

export const socialLogin = (userInfo, cb) => dispatch => {
  dispatch(setLoader(true));
  axios
    .post('/auth/social/login', userInfo)
    .then(response => {
      dispatch(setLoader(false));
      const { status, user, token } = response.data;
      if (status) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setCurrentUser(user));
        cb(null, user);
        // router.push('/home');
      }
    })
    .catch(error => {
      dispatch(setLoader(false));
      if (error.response.status === 406) {
        const err = new Error('No Account Found');
        cb(err, null);
      }
      // dispatch(
      //   setError({
      //     errorCode: error.response.status,
      //     errorMsg: 'Something went wrong.Please try again!',
      //   })
      // );
    });
};

export const socialSignup = (userInfo, cb) => async dispatch => {
  try {
    const response = await axios.post('/auth/social/signup', userInfo);
    const { user, token } = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    dispatch(setCurrentUser(user));
    cb(null, user);
  } catch (error) {
    if (error && error.response && error.response.status === 409) {
      dispatch(
        setError({
          errorCode: error.response.status,
          errorMsg: error.response.data.message,
        })
      );
    } else {
      dispatch(
        setError({
          errorCode: error.response.status,
          errorMsg: 'Something went wrong.Please try again!',
        })
      );
    }
  }
};

// eslint-disable-next-line no-unused-vars
export const isUsernameAvailable = (username, cb) => async dispatch => {
  try {
    await axios.get(`/auth/isUsernameExist/${username}`);
    dispatch(setError({}));
    cb(null);
  } catch (error) {
    dispatch(
      setError({
        errorCode: 'NOUSERNAME',
        errorMsg: 'Invalid Username',
      })
    );
    cb(new Error('Not Available'));
  }
};

export const registerUser = (userInfo, callback) => dispatch => {
  dispatch(setError({}));
  dispatch(setLoader(true));
  axios
    .post('/auth/register', userInfo)
    .then(() => {
      dispatch(setLoader(false));
      callback();
    })
    .catch(error => {
      dispatch(setLoader(false));
      if (error.response.status === 409) {
        dispatch(
          setError({
            errorCode: error.response.status,
            errorMsg: error.response.data.message,
          })
        );
      } else {
        dispatch(
          setError({
            errorCode: error.response.status,
            errorMsg: 'Something went wrong.Please try again!',
          })
        );
      }
    });
};

export const confirmEmail = (v_token, router) => async dispatch => {
  dispatch(setError(null));
  try {
    const response = await axios.put(`/api/user/verify/${v_token}`);
    const { user, token } = response.data;
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    dispatch(setCurrentUser(user));
    const params = new URLSearchParams(router.location.search);
    const join_token = params.get('join-token');
    if (join_token) {
      const url = `/join-business?token=${join_token}&newUser=true`;
      router.push(url);
      return;
    }
    if (params.get('target') === 'assessment') {
      router.push('/assessment');
      return;
    }
    if (params.get('u_type') === 'member') {
      router.push('/user-feeds');
      return;
    }
    if (params.get('u_type') === 'practitioner') {
      router.push('/create-practitioner-profile');
      return;
    }
    router.push('/user-feeds');
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(
        setError({
          errorCode: 400,
          errorMsg: 'Invalid token',
        })
      );
    }
  }
};

export const logoutUser = () => dispatch => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  // dispatch(setCurrentUser(null));
  dispatch({ type: SIGNOUT_REQUEST });
};

export const updateUser = (userInfo, router) => async dispatch => {
  dispatch(setLoader(true));
  try {
    await axios.put('/api/user', userInfo);
    dispatch(setLoader(false));
    const user = JSON.parse(localStorage.getItem('user'));
    router.push(`/${user.u_username}`);
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const requestResetPasswordLink = (email, cb) => async dispatch => {
  dispatch(setLoader(true));
  try {
    await axios.post('/auth/forgot-password', { email });
    dispatch(setLoader(false));
    cb(null);
  } catch (error) {
    dispatch(setLoader(false));
    if (error.response.status === 400) {
      toast.error('Account Not Found with this email address');
    }
    const err = new Error('Email not Sent');
    cb(err);
  }
};

export const resetPassword = (body, cb) => async dispatch => {
  dispatch(setLoader(true));
  try {
    await axios.put('/auth/reset-password', body);
    dispatch(setLoader(false));
    cb(null);
  } catch (error) {
    dispatch(setLoader(false));
    if (error.response.status === 400) {
      toast.error('Invalid Token');
    }
    cb(error);
  }
};

export const getProfile = (username, cb) => async dispatch => {
  try {
    dispatch(setLoader(true));
    const response = await axios.get(`/api/user/profile/${username}`);
    const { profile } = response.data;
    dispatch({
      type: SET_PROFILE,
      payload: profile,
    });
    dispatch(setLoader(false));
    console.log('========================');
    console.log('fetched profile', profile);
    console.log('========================');

    if (profile.profileType === 'practitioner') {
      dispatch({
        type: SET_USER_REVIEW_LIST,
        payload: profile.userReviews,
      });
      dispatch({
        type: SET_PRACTITIONER_CONTENT_LIST,
        payload: profile.contents,
      });
    }

    if (profile.profileType === 'practitioner' && !profile.ownPerspective) {
      dispatch({
        type: SET_SESSION_STATUS,
        payload: profile.sessionStatus,
      });
      dispatch({
        type: SET_PRAC_BOOKMARK_STATUS,
        payload: profile.bookmarkStatus,
      });
      dispatch({
        type: SET_SHARE_RESULT_STATUS,
        payload: profile.shareStatus,
      });
    }

    if (profile.profileType === 'member' && profile.ownPerspective) {
      dispatch({
        type: SET_BOOKMARKED_PRAC_LIST,
        payload: profile.bookmarkedPractitioners,
      });
      dispatch({
        type: SET_PURCHASED_CONTENT_LIST,
        payload: profile.purchasedContents,
      });
      dispatch({
        type: SET_BOOKMARKED_CONTENT_LIST,
        payload: profile.bookmarkedContents,
      });
    }
    if (profile.profileType === 'member' && !profile.ownPerspective) {
      dispatch({
        type: SET_REQUEST_RESULT_STATUS,
        payload: profile.requestResultStatus,
      });
    }
    if (cb) cb(null);
  } catch (error) {
    console.log('errrrrrrrrrr', error);
    dispatch(setLoader(false));
    if (error && error.response && error.response.status === 400) {
      const err = new Error('Not Found');
      if (cb) cb(err);
    }
  }
};
