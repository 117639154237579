import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Button from '../../../common/Button/Button';
import { calculateProgressCheckResults } from '../../../../store/actions/assessmentActions';
import './ProgressCheckSuccess.css';

class ProgressCheckSuccess extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
    }
  }
  componentDidMount() {
    const { calculateProgressCheckResults, questions } = this.props;
      this.setState({ loading: true });
    calculateProgressCheckResults(questions[0], (err) => {
      if(!err){
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { user } = this.props;
    const { loading } = this.state;
    if(loading) return null;
    return (
      <div className="col-5 d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center">
          <img className="success" src="/images/progress_success.svg" alt="sucess" />
          <h1 className="pt-3">Success</h1>
          <p className="pb-4 text-center">Your progress check-in is complete!</p>
          <div className="mt-5">
            {user && (
              <Link to={`/${user.u_username}`}>
                <Button varient="large">Go to Results</Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProgressCheckSuccess.propTypes = {
  user: PropTypes.shape({
    u_username: PropTypes.string,
  }),
};
ProgressCheckSuccess.defaultProps = {
  user: null,
};

const mapStateToProps = state => {
  return {
    questions: state.assessment.progressCheckQuestions,
    user: state.auth.currentUser,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { calculateProgressCheckResults }
  )(ProgressCheckSuccess)
);
