import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className="position-fixed" style={{ top: 0, left: 0, right: 0, zIndex: 2000 }}>
      <div className="load-bar">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
    </div>
  );
};

export default Loader;
