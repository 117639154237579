/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PractitionerRegistrationHeader from './PractitionerRegistrationHeader';
import TextArea from '../../../../common/TextArea/TextArea';
import ProfileUpload from './ProfileUpload';
import MatInput from '../../../../common/MatInput/MatInput';
import { isUsernameAvailable } from '../../../../../store/actions/authActions';
import { isValidUsername } from '../../../../../utils/validators';

const PractitionerRegistration0 = ({ u_prac_username, isUsernameAvailable }) => {
  const [available, setAvailability] = useState('');

  const checkAvailability = event => {
    const username = event.target.value;
    if (!isValidUsername(username)) setAvailability('');
    if (isValidUsername(username)) {
      isUsernameAvailable(username, err => {
        if (err) {
          setAvailability('error');
        } else {
          setAvailability('success');
        }
      });
    }
  };

  return (
    <div id="practitioner-registration-0" className="py-md-5 py-4 mt-md-5">
      <div className="mb-5">
        <PractitionerRegistrationHeader
          title="Profile Picture"
          info="Make your first impression a good one!"
        />
        <ProfileUpload />
      </div>
      <div className="col-lg-6 p-0 mb-5">
        <PractitionerRegistrationHeader
          title="Username"
          info="Enter your unique profile ID for sharing with others"
        />
        <div className="position-relative">
          {available === 'success' && u_prac_username && (
            <i className="icon-check_circle text-success validation-badge material" />
          )}
          {available === 'error' && (
            <i className="icon-times-circle text-danger validation-badge material" />
          )}
          <Field
            name="u_prac_username"
            placeholder="Practitioner Username"
            maxLength="16"
            onChange={checkAvailability}
            component={MatInput}
          />
          <span className="d-block display2">
            Your GuideTrue URL: <strong> https://guidetrue.com/{u_prac_username}</strong>
          </span>
        </div>
      </div>
      <div className="mb-4">
        <PractitionerRegistrationHeader
          title="About You"
          info="Write a brief professional description for yourself"
        />
        <Field
          name="p_about"
          varient="material"
          rows={2}
          info="(Should not exceed more than 255 Characters)"
          maxLength={255}
          placeholder="Remember to impress and entice"
          component={TextArea}
        />
      </div>
    </div>
  );
};

PractitionerRegistration0.propTypes = {
  u_prac_username: PropTypes.string,
  isUsernameAvailable: PropTypes.func.isRequired,
};

PractitionerRegistration0.defaultProps = {
  u_prac_username: '',
};

const mapStateToProps = state => {
  const selector = formValueSelector('practitionerProfileForm');
  const u_prac_username = selector(state, 'u_prac_username');
  return {
    u_prac_username,
  };
};
export default connect(
  mapStateToProps,
  {
    isUsernameAvailable,
  }
)(PractitionerRegistration0);
