import styled from "styled-components";

export const Container = styled.div`
  /* padding: 0.75rem 0.875rem; */
  border-left: ${props =>
    props.active ? " solid 0.3125rem var(--primary)" : "#ffffff"};
  background: ${props => (props.active ? "#fdfbf3" : "#ffffff")};
  font-size: 0.875rem;
  font-weight: 600;
  color: #d4af37;
  border-bottom: ${props =>
    props.active ? "solid 0 #eaedf3;" : "solid 0.0625rem #eaedf3;"};
  border-right: 0;
  cursor: pointer;
  &:hover {
    background: #fdfbf3;
  }
`;
export const Section = styled.div`
  padding: 0.75rem 0.875rem;
`;
export const Name = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: #d4af37;
`;

export const Time = styled.div`
  font-size: 0.75rem;
  color: #9c9c9c;
  font-weight: normal;
`;

export const List = styled.div`
  /* border: solid 0.0625rem #eaedf3;
  border-bottom: 0;
  border-right: 0; */
`;

export const Active = styled.div`
  background: ${props => (props.active ? "#d4af37" : "#ffffff")};
  width: 0.3125rem;
  padding: 0.5625rem;
`;
export const Avatar = styled.img`
  height: 2.25rem;
  width: 2.25rem;
  object-fit: cover;
`;

export const HimAvatar = styled.img`
  height: 1.8125rem;
  width: 1.8125rem;
  margin-left: 2rem;
  object-fit: cover;
`;

export const MeAvatar = styled.img`
  height: 1.8125rem;
  width: 1.8125rem;
  margin-right: 2rem;
  margin-left: 1rem;
  object-fit: cover;
`;

export const Talktext = styled.div`
  padding: 0.75rem;
  padding-right: 1.5rem;
  text-align: left;
  line-height: 1.5em;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #031121;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const Talkbubble = styled.div`
  /* margin: 40px; */
  display: inline-block;
  position: relative;
  height: auto;
  border-radius: 0.5rem;
  background-color: #fcf6df;
  margin-left:${props => (props.me ? '0.5rem' : '1rem')};
  background-color: ${props => (props.me ? "#ffffff" : "#fcf6df")};
  border: ${props =>
    props.me ? "solid 0.0625rem #e9e9e9;" : "solid 0 #e9e9e9"};
`;

export const ChatBubble = styled.div`
  margin-top: 0.9375rem;
  margin-bottom: 0.3125rem;
`;

export const Texttime = styled.div`
  font-size: 0.75rem;
  color:#9c9c9c;
  margin-left:${props =>
    props.me ? '.65rem':'1.66rem'};
`;

export const Datetext = styled.div`
  color: #9c9c9c;
  font-size: 0.875rem;
`;