/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserComments from '../UserComments/UserComments';
import './Comment.css';
import { addComment, updateComment } from '../../../store/actions/contentAction';
import { commentNameSpace } from '../../../socket.client';

// const comments = data.default.comments;
class CommentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: props.comments.length > 10,
      commentsToShow: 10,
      comment: '',
      commenting: false,
    };
  }

  componentDidMount() {
    const { contentId, updateComment } = this.props;
    commentNameSpace.emit('room', `room:${contentId}`);
    commentNameSpace.on('new-comment', comment => {
      updateComment(comment);
    });
  }

  addCommentHandler = () => {
    const { addComment, contentId } = this.props;
    const { comment } = this.state;
    if (!comment.trim()) return;
    this.setState({ commenting: true });
    addComment(contentId, comment, () => {
      this.setState({ comment: '', commenting: false });
    });
  };

  renderUserComments = () => {
    const { comments } = this.props;
    const userComment = comments.map((comment, i) => {
      let commentList;
      if (i < this.state.commentsToShow - 1)
        commentList = <UserComments key={comment._id} comment={comment} />;
      return commentList;
    });
    return userComment;
  };

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if(code === 13 && !(event.key === 'Enter' && event.shiftKey)) {
        this.addCommentHandler();
    } 
  }

  render() {
    const { comments } = this.props;
    const { showMore, commentsToShow, comment, commenting } = this.state;
    return (
      <div className="commentComponent pb-3">
        <h6>{comments.length} Comments</h6>
        <textarea
          className="form-control comentBox"
          value={comment}
          onChange={e => this.setState({ comment: e.target.value })}
          placeholder="Enter your feedback your feedbacks……"
          onKeyPress={this.enterPressed.bind(this)}
          disabled = {commenting}
        />
        <div className="d-flex justify-content-end mt-2">
          <button type="button" className="btn gt-btn btn-sm p-2" onClick={this.addCommentHandler}>
            Comment
            {commenting && <span className="spinner-border spinner-border-sm ml-2" />}
          </button>
        </div>
        {this.renderUserComments()}
        {showMore || comments.length > 10 ? (
          <div>
            <h6
              style={{ cursor: 'pointer' }}
              className="pl-5 pt-4"
              role="presentation"
              onClick={() =>
                this.setState({
                  commentsToShow: commentsToShow + 10,
                  showMore: !(commentsToShow + 10 >= comments.length),
                })
              }
            >
              SHOW MORE
            </h6>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  comments: state.content.comments,
});

CommentComponent.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  contentId: PropTypes.string.isRequired,
  addComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
};

CommentComponent.defaultProps = {
  comments: [],
};

export default connect(
  mapStateToProps,
  {
    addComment,
    updateComment,
  }
)(CommentComponent);
