/* eslint-disable no-class-assign */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../utils/validators';
import { resetPassword } from '../../../store/actions/authActions';
import Input from '../../common/Input/Input';
import './ResetPassword.css';

const validater = values => {
  const errors = {};
  if (!isEmpty(values.password)) {
    errors.password = 'This Field is required';
  }
  if (!isEmpty(values.cpassword)) {
    errors.cpassword = 'This field is required';
  } else if (values.password !== values.cpassword) {
    errors.cpassword = 'Password miss match';
  }
  return errors;
};

class ResetPassword extends Component {
  submitHandler = values => {
    const { resetPassword, match, history } = this.props;
    const { token } = match.params;
    const body = {
      password: values.password,
      token,
    };
    resetPassword(body, err => {
      if (!err) {
        history.push('/signin');
      }
    });
  };

  render() {
    const { submitting, handleSubmit, invalid } = this.props;
    return (
      <div className="container">
        <div
          className="row justify-content-center align-items-center py-4"
          style={{ minHeight: '100vh' }}
        >
          <div className="col-md-7 col-11">
            <div id="reset-password">
              <Link to="/">
                <img className="img-fluid" src="/icons/logo.svg" alt="logo" />
              </Link>
              <form className="border rounded" onSubmit={handleSubmit(this.submitHandler)}>
                <h1 className="m-0 h1 font-weight-bold">Reset Password</h1>
                <p className="mb-5">Type in your new password to continue.</p>
                <div className="mb-3">
                  <Field
                    name="password"
                    type="password"
                    icon="icon-lock-alt"
                    placeholder="New Password"
                    component={Input}
                  />
                </div>
                <div className="mb-4">
                  <Field
                    name="cpassword"
                    type="password"
                    icon="icon-lock-alt"
                    placeholder="Confirm Password"
                    component={Input}
                  />
                </div>
                <button type="submit" className="btn gt-btn" disabled={submitting || invalid}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape.isRequired,
  }).isRequired,
};

ResetPassword = reduxForm({
  form: 'reset-password',
  validate: validater,
})(ResetPassword);

export default connect(
  null,
  { resetPassword }
)(ResetPassword);
