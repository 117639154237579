/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import './Switch.css';

const Switch = ({ id, onChange, classType = '', ...rest }) => (
  <div className="d-inline-flex cursor-pointer">
    <input
      type="checkbox"
      onChange={onChange}
      className="switch-checkbox"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      id={`toggle-${id}`}
    />
    <label htmlFor={`toggle-${id}`} className={`switch ${classType}`} />
  </div>
);

Switch.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  classType: PropTypes.string,
};

Switch.defaultProps = {
  id: '',
  onChange: () => {},
  classType: '',
};

export default Switch;
