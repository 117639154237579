/* eslint-disable no-shadow */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './CreditCardDetails.css';
import PaymentIcon from 'react-payment-icons';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ModalComponent from '../../shared/Modal/Modal';
import { changeDefaultCard, deleteCard } from '../../../store/actions/paymentActions';
import AddCreditCard from '../AddCard/AddCreditCard';

class CreditCardDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      showConfirmation: false,
      cardIndex: 0,
      cardId: '',
      modalTitle: '',
      modalContent: '',
      modalType: '',
      modalSubContent: '',
    };
    this.containerRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { cardUpdated } = this.props;
    if (prevProps.cardUpdated !== cardUpdated && cardUpdated) {
      this.setState({ showConfirmation: false });
    }
  }

  onConfimation = () => {
    const { cardId, cardIndex, modalType } = this.state;
    const { changeDefaultCard, deleteCard } = this.props;
    this.setState({ showConfirmation: false });
    switch (modalType) {
      case 'default':
        changeDefaultCard(cardId);
        break;
      case 'delete':
        deleteCard(cardIndex, cardId);
        break;
      default:
        break;
    }
  };

  onDelete = (index, card) => {
    document.body.click();
    if (card.default_card) {
      toast.error('Cannot Delete Default Card');
      return;
    }
    this.setState({
      cardId: card.id,
      cardIndex: index,
      modalType: 'delete',
      modalContent: 'Delete the Card ?',
      modalSubContent: 'Do you want to delete this card for Permanently ?',
      showConfirmation: true,
    });
  };

  changeDefaultCard = id => {
    document.body.click();
    this.setState({
      showConfirmation: true,
      cardId: id,
      modalType: 'default',
      modalContent: 'Set Default Card',
      modalSubContent: 'Do you want to set this card as your default card ?',
    });
  };

  editCard = () => {
    document.body.click();
    this.setState({
      showConfirmation: true,
      modalType: 'edit',
      modalTitle: 'Edit Card',
    });
  };

  render() {
    const { index, card, membership } = this.props;
    const { showConfirmation, modalContent, modalTitle, modalType, modalSubContent } = this.state;
    return (
      <>
        <div
          ref={this.containerRef.current}
          className={`card-container p-2 pl-3 pr-3 ${
            membership ? 'mt-0 card-container-membership' : 'mt-3'
          }`}
        >
          <div className="d-flex align-items-center justify-content-between position-relative">
            <div className="d-flex align-items-center">
              <p className="font-weight-bold mb-0 text-capitalize">{card.name}</p>
              {card.default_card && (
                <div className="default-card px-1 ml-2">
                  <p className="mb-0">DEFAULT</p>
                </div>
              )}
            </div>
            {!membership && (
              <OverlayTrigger
                trigger="click"
                placement="bottom-end"
                overlay={
                  <Popover
                    id="card-details-popover"
                    arrowProps="triangle"
                    container={this.containerRef.current}
                  >
                    <div className="popover-links">
                      {!card.default_card && (
                        <div
                          onClick={() => {
                            this.changeDefaultCard(card.id);
                          }}
                          onKeyDown={() => this.changeDefaultCard(card.id)}
                        >
                          <p className="mb-0">Make Default</p>
                        </div>
                      )}
                      <div onClick={this.editCard} onKeyDown={this.editCard}>
                        <p className="mb-0">Edit Card</p>
                      </div>
                      <div
                        onClick={() => {
                          this.onDelete(index, card);
                        }}
                        onKeyDown={() => this.onDelete(index, card)}
                      >
                        <p className="mb-0">Delete Card</p>
                      </div>
                    </div>
                  </Popover>
                }
                rootClose
              >
                <img src="/icons/more.svg" alt="more" className="more-icon" />
              </OverlayTrigger>
            )}
          </div>
          <div className="pt-3 d-flex align-items-center">
            <PaymentIcon id={card.brand.toLowerCase()} className="card-icon" />
            <p className="mb-0 pl-2">XXXX XXXX XXXX {card.last4}</p>
          </div>
        </div>
        <ModalComponent
          size={modalType === 'edit' ? 'lg' : 'small'}
          onHide={() => this.setState({ showConfirmation: false })}
          show={showConfirmation}
          title={modalTitle}
          noHeader={!(modalType === 'edit')}
        >
          <div className="d-flex flex-column p-2">
            {modalType !== 'edit' ? (
              <>
                <div className="d-flex align-items-center">
                  {modalType === 'delete' && <img src="/icons/delete.svg" alt="delete" />}
                  {modalType === 'default' && (
                    <img src="/icons/default-card.svg" alt="default" style={{ width: '4rem' }} />
                  )}
                  <div className="d-flex flex-column align-items-baseline pl-3 ml-2">
                    <h5 className="text-center font-weight-bold mb-0">{modalContent}</h5>
                    <p className="text-black-50 mb-0">{modalSubContent}</p>
                  </div>
                </div>
                <div className="d-flex pt-4 justify-content-end">
                  <button
                    type="submit"
                    onClick={() => this.setState({ showConfirmation: false })}
                    className="btn p-2 btn-outline-white px-4 font-weight-normal"
                  >
                    No
                  </button>
                  <button
                    type="submit"
                    onClick={() => this.onConfimation()}
                    className="btn ml-3  btn-signup p-2 px-4 font-weight-normal"
                  >
                    Yes
                  </button>
                </div>
              </>
            ) : (
              <AddCreditCard update card={card} />
            )}
          </div>
        </ModalComponent>
      </>
    );
  }
}

CreditCardDetails.propTypes = {
  cardUpdated: PropTypes.bool.isRequired,
  deleteCard: PropTypes.func.isRequired,
  changeDefaultCard: PropTypes.func.isRequired,
  card: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    default_card: PropTypes.bool,
    brand: PropTypes.string,
    last4: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
  membership: PropTypes.bool,
};

CreditCardDetails.defaultProps = {
  membership: false,
  index: 0,
};

const mapStateToProps = state => ({
  cardUpdated: state.payment.cardUpdated,
});

export default compose(
  connect(
    mapStateToProps,
    { deleteCard, changeDefaultCard }
  )
)(CreditCardDetails);
