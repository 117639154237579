import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import assessmentReducer from './assessmentReducer';
import practitionerReducer from './practitionerRegReducer';
import appReducer from './appReducer';
import publishReducer from './publishReducer';
import linkBusinessReducer from './linkBusinessReducer';
import memberReducer from './memberReducer';
import notificationReducer from './notificationReducer';
import searchReducer from './searchReducer';
import contentReducer from './contentReducer';
import settingsReducer from './settingsReducer';
import messageReducer from './messageReducer';
import paymentReducer from './paymentReducer';
import categoryReducer from './categoryReducer';
import {SIGNOUT_REQUEST} from '../actions/types'

const reducers =  combineReducers({
  auth: authReducer,
  form: formReducer,
  assessment: assessmentReducer,
  linkBusiness: linkBusinessReducer,
  practitionerReg: practitionerReducer,
  member: memberReducer,
  publish: publishReducer,
  notification: notificationReducer,
  search: searchReducer,
  content: contentReducer,
  app: appReducer,
  settings: settingsReducer,
  message: messageReducer,
  payment: paymentReducer,
  category: categoryReducer,
});


 const rootReducer = (state, action) => {
  if (action.type === SIGNOUT_REQUEST) {
      state = undefined;
  }
  return reducers(state, action);
};
export default rootReducer;