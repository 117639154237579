/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import SettingsList from '../../shared/SettingsList/SettingsList';
import SettingsContainer from './SettingsContainer';
import './UserSettings.css';
import { getUserProfiles } from '../../../store/actions/settingsActions';
import { getStates, getCities } from '../../../store/actions/appActions';

class UserSettings extends Component {
  // state = {
  //   loading: false,
  // };

  componentDidMount() {
    const { getUserProfiles, getStates, getCities } = this.props;
    // this.setState({ loading: true });
    getUserProfiles(profile => {
      if (profile && profile.practitionerProfile) {
        getStates(profile.practitionerProfile.p_country);
        getCities(profile.practitionerProfile.p_state);
        // this.setState({ loading: false });
      }
    });
  }

  render() {
    const { match } = this.props;
    // const { loading } = this.state;
    return (
      <div style={{ backgroundColor: '#f2f4f8' }}>
        <Navbar />
        {
          <div>
            <div className="container" id="user-settings">
              <h1 className="title">Account Settings</h1>
              <div className="row">
                <div className="col-lg-3 col-12" id="settings">
                  <SettingsList />
                </div>
                <div className="col-lg-9 col-12" id="settings-expanded">
                  <Switch>
                    <Route path={`${match.path}/:settings`} component={SettingsContainer} />
                    <Redirect from="/settings" to="/settings/general-settings" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        }
        {/* {loading && (
          <div style={{ height: '50vh' }}>
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )} */}
        <div className="user-settings-footer">
          <Footer />
        </div>
      </div>
    );
  }
}

UserSettings.propTypes = {
  getUserProfiles: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default withRouter(
  connect(
    null,
    { getUserProfiles, getStates, getCities }
  )(UserSettings)
);
