import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PractitionerRegistrationComplete = ({ user, location }) => {
  const params = new URLSearchParams(location.search);
  const join_business = params.get('join-business');
  return (
    <div id="pract-reg-complete" className="text-center d-flex flex-column  justify-content-center" style={{ minHeight: '100vh'}}>
      <img src="/images/complete.png" className="mb-4 mx-auto success" width="120" alt="Success" />
      <h2>CONGRATULATIONS! Your practitioner registration is complete!</h2>
      <h4>Are you affiliated with another business ?</h4>
      <p>
        If you are part of a group, agency, or collective, you can list it and link it with your
        profile!
      </p>
      <div className="links">
        {!join_business && (
          <Link to="/link-business" className="mr-4">
            Link Business
          </Link>
        )}
        {user && user.u_prac_username && <Link to={`/${user.u_prac_username}`}>Go to Profile</Link>}
      </div>
    </div>
  );
};

PractitionerRegistrationComplete.propTypes = {
  user: PropTypes.shape({
    u_prac_username: PropTypes.string,
  }),
};

PractitionerRegistrationComplete.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  user: state.auth.currentUser,
});

export default withRouter(connect(mapStateToProps)(PractitionerRegistrationComplete));
