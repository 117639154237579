/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextArea from '../common/TextArea/TextArea';
import Input from '../common/Input/Input';
import Button from '../common/Button/Button';
import SelectInput from '../common/SelectInput/SelectInput';
import { isEmailValid, isEmpty } from '../../utils/validators';
import './Feedback.css';
import { createFeedback } from '../../store/actions/appActions';

const feedbackType = [
  'General Comment',
  'Feature Request',
  'Design Issue',
  'Bug Report',
  'Performance Issue',
  'Hardware Compatibility',
  'Other',
];

const validators = values => {
  const errors = {};
  if (!isEmpty(values.feedback_sender_name)) {
    errors.feedback_sender_name = 'This field is required';
  }
  if (!isEmpty(values.feedback_sender_email)) {
    errors.feedback_sender_email = 'This field is required';
  } else if (!isEmailValid(values.feedback_sender_email)) {
    errors.feedback_sender_email = 'Enter an valid email address';
  }
  if (!isEmpty(values.feedback_type)) {
    errors.feedback_type = 'This field is required';
  }
  if (!isEmpty(values.feedback_comment)) {
    errors.feedback_comment = 'This field is required';
  }
  return errors;
};

let Feedback = ({ createFeedback, handleSubmit, submitting }) => {
  const [isSubmitted, setSubmit] = useState(false);

  const onSubmit = values => createFeedback(values, err => {
    if (!err) {
      setSubmit(true);
    }
  });

  return (
    <div className="feedback-modal p-2">
      {isSubmitted ? (
        <div className="text-center pt-4">
          <img src="/images/feedSucces.svg" alt="feedback" />
          <h5 className="pt-2">THANK YOU!</h5>
          <p style={{ color: '#9c9c9c' }}>
            Thanks for reaching out. We’ll respond as quickly as possible. In the mean time,
            remember to also connect with us online:
          </p>
          <div className="icons">
          <a
            href="https://www.facebook.com/GuideTrue.Online"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="mr-3"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="#9c9c9c"
                fillRule="nonzero"
                d="M21.188 0H2.813A2.816 2.816 0 0 0 0 2.813v18.375A2.816 2.816 0 0 0 2.813 24h18.375A2.816 2.816 0 0 0 24 21.187V2.813A2.816 2.816 0 0 0 21.187 0zm.937 21.188c0 .516-.42.937-.938.937h-5.343v-7.64h2.896l.479-2.907h-3.375V9.563c0-.796.61-1.407 1.406-1.407h1.922V5.25H17.25a4.312 4.312 0 0 0-4.311 4.32v2.008h-2.814v2.906h2.814v7.641H2.812a.939.939 0 0 1-.937-.938V2.813c0-.516.42-.937.938-.937h18.375c.516 0 .937.42.937.938v18.375z"
              />
            </svg>
            </a>
            <a
            href="https://www.instagram.com/guidetrue_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="mr-3"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="#9c9c9c" fillRule="evenodd">
                <path
                  fillRule="nonzero"
                  d="M17.028 0H6.972A6.98 6.98 0 0 0 0 6.972v10.056A6.98 6.98 0 0 0 6.972 24h10.056A6.98 6.98 0 0 0 24 17.028V6.972A6.98 6.98 0 0 0 17.028 0zm5.097 17.028a5.102 5.102 0 0 1-5.097 5.097H6.972a5.102 5.102 0 0 1-5.097-5.097V6.972a5.102 5.102 0 0 1 5.097-5.097h10.056a5.102 5.102 0 0 1 5.097 5.097v10.056z"
                />
                <path
                  fillRule="nonzero"
                  d="M12 5.531A6.476 6.476 0 0 0 5.531 12 6.476 6.476 0 0 0 12 18.469 6.476 6.476 0 0 0 18.469 12 6.476 6.476 0 0 0 12 5.531zm0 11.063A4.599 4.599 0 0 1 7.406 12 4.599 4.599 0 0 1 12 7.406 4.599 4.599 0 0 1 16.594 12 4.599 4.599 0 0 1 12 16.594z"
                />
                <circle cx="18.563" cy="5.438" r="1" />
              </g>
            </svg>
            </a>
            <a href="https://twitter.com/guide_true" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20">
              <path
                fill="#9c9c9c"
                fillRule="evenodd"
                d="M25 2.38c-.92.4-1.907.673-2.945.794A5.08 5.08 0 0 0 24.31.386c-.993.578-2.09.997-3.258 1.224A5.155 5.155 0 0 0 17.308.018c-2.833 0-5.13 2.257-5.13 5.04 0 .395.046.78.134 1.15A14.644 14.644 0 0 1 1.742.938a4.94 4.94 0 0 0-.694 2.534c0 1.75.906 3.293 2.281 4.196a5.196 5.196 0 0 1-2.323-.632V7.1c0 2.442 1.769 4.48 4.113 4.943-.43.114-.882.177-1.35.177-.332 0-.652-.032-.966-.094.653 2.005 2.546 3.462 4.79 3.502a10.402 10.402 0 0 1-6.37 2.155c-.414 0-.821-.025-1.223-.07a14.678 14.678 0 0 0 7.861 2.27c9.434 0 14.591-7.683 14.591-14.346l-.017-.652A10.148 10.148 0 0 0 25 2.38z"
              />
            </svg>
            </a>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-center pt-4">
            <img src="/images/feedback.svg" alt="feedback" />
            <h5 className="pt-2">We love hearing from you!</h5>
            <p style={{ color: '#9c9c9c' }}>
              Thank you for taking time to share your suggestions and ideas. We constantly strive to
              improve our features and services. Even though we can't respond to everyone, we review
              your feedback to improve the GuideTrue experience for everyone.
            </p>
            <p style={{ color: '#9c9c9c' }}>
              If you're not looking to give feedback but you need help with a specific issue, you
              can find answers to common questions in the <Link to="/FAQ" target="_blank"> FAQs.</Link>
            </p>
          </div>
          <div>
            <form className="content-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 mb-3">
                  <h6>NAME</h6>
                  <Field name="feedback_sender_name" type="text" component={Input} />
                </div>
                <div className="col-12 mb-3">
                  <h6>EMAIL</h6>
                  <Field name="feedback_sender_email" type="text" component={Input} />
                </div>
                <div className="col-12 mb-3">
                  <h6>Feedback Type</h6>
                  <Field name="feedback_type" component={SelectInput}>
                    <option value="">Please select one type</option>
                    {feedbackType.map(type => (
                      <option key={type} value={type.toLowerCase()}>
                        {type}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="col-12 mb-3">
                  <h6>COMMENT</h6>
                  <Field name="feedback_comment" component={TextArea} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <span style={{ color: '#9c9c9c', fontSize: '0.75rem' }}>
                  Note: Feedback and suggestions may be used freely without obligation to provide
                  compensation or confidentiality
                </span>
                <Button type="submit" disable={submitting}>
                  {!submitting && <span>Send</span>}
                  {submitting && (
                  <span>
                    <span class="spinner-border spinner-border-sm mr-2" role="status"></span>
                      Sending...
                    </span>)
                  }
                  </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

Feedback.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

Feedback = reduxForm({
  form: 'feedbackForm',
  validate: validators,
})(Feedback);

export default connect(null, { createFeedback })(Feedback);
