// import lodash from 'lodash';
import { toast } from 'react-toastify';
import axios from '../../axios';
import {
  SET_CONTENT_VIEW,
  // SET_CONTENT_FEEDS,
  SET_LOADER,
  SET_CONTENT_LOADER,
  SET_PREMIUM_USER,
  SET_PURCHASED_CONTENT,
  SET_COMMENT_LIST,
  ADD_COMMENT,
  SET_CONTENT_BOOKMARK_STATUS,
  DELETE_CONTENT,
  CHANGE_CONTENT_PUBLISH_STATUS,
  INCREMENT_RATING_COUNT,
  SET_FOCUS_LIST,
  SET_RECOMMENDED_FEED_CONTENT,
  SET_POPULAR_FEED_CONTENTS,
  SET_HOT_NEW_CONTENTS,
  SET_RECOMMENDED_FEED_PRACT,
} from './types';

export const updateComment = comment => dispatch => {
  dispatch({
    type: ADD_COMMENT,
    payload: comment,
  });
};

export const getContent = (contentId, cb) => async dispatch => {
  try {
    dispatch({
      type: SET_CONTENT_LOADER,
      payload: true,
    });
    const [response, resp1] = await axios.all([axios.get(`/api/content/${contentId}`), axios.get('/api/meta/focuses')]);
    const { content, premiumUser, purchased } = response.data;
    if (premiumUser) {
      dispatch({
        type: SET_PREMIUM_USER,
        payload: premiumUser,
      });
    }
    dispatch({
      type: SET_PURCHASED_CONTENT,
      payload: purchased,
    });
    dispatch({
      type: SET_CONTENT_VIEW,
      payload: content,
    });
    dispatch({
      type: SET_COMMENT_LIST,
      payload: content.comments,
    });
    dispatch({
      type: SET_CONTENT_BOOKMARK_STATUS,
      payload: content.bookmarkStatus,
    });
    dispatch({
      type: SET_FOCUS_LIST,
      payload: resp1.data.focuses,
    });
    cb(null);
  } catch (error) {
    console.log('@@@@@@@@@@@@@@@@', error);
    dispatch({
      type: SET_CONTENT_LOADER,
      payload: false,
    });
    if (error && error.response && error.response.status === 400) {
      const err = new Error('Not Found');
      if (cb) cb(err);
    }
  }
};

export const setLoader = loaderState => {
  return {
    type: SET_LOADER,
    payload: loaderState,
  };
};

export const getFeedContents = cb => dispatch => {
  const location = JSON.parse(localStorage.getItem('location')) || {};    
  dispatch(setLoader(true));
  axios
    .all([axios.get(`/api/content/get-user-feeds?lat=${location.lat}&lng=${location.lng}`), axios.get('/api/meta/focuses')])
    .then(([res, res1]) => {
      const { data } = res;
      dispatch(setRecommendedFeedContents(data.recommended));
      dispatch({
        type: SET_POPULAR_FEED_CONTENTS,
        payload: data.popular,
      });
      dispatch({
        type: SET_HOT_NEW_CONTENTS,
        payload: data.hot_new,
      });
      dispatch(setRecommendedFeedPrac(data.practitioners));
      dispatch({
        type: SET_FOCUS_LIST,
        payload: res1.data.focuses,
      });
      dispatch(setLoader(false));
      cb(null);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        cb({ errorCode: 401 });
      }
    });
};

export const setRecommendedFeedContents = contents => ({
  type: SET_RECOMMENDED_FEED_CONTENT,
  payload: contents,
});

export const setRecommendedFeedPrac = practitioners => ({
  type: SET_RECOMMENDED_FEED_PRACT,
  payload: practitioners,
});

export const getRecommendedFeedContents = (page, cb) => async (dispatch, getState) => {
  try {
    const resp = await axios.get(`/api/content/recommended-feeds?page=${page}`);
    const { contents, hasMore } = resp.data;
    const { recommendedFeeds } = getState().content;
    const updated = [...recommendedFeeds, ...contents];
    dispatch(setRecommendedFeedContents(updated));
    cb(hasMore);
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const getRecommendedFeedPrac = (page, cb) => async (dispatch, getState) => {
  try {
    const location = JSON.parse(localStorage.getItem('location')) || {};
    const resp = await axios.get(`/api/practitioner/recommended-feed?page=${page}&lat=${location.lat}&lng=${location.lng}`);
    const { practitioners, hasMore } = resp.data;
    const { recommendedPracFeeds } = getState().content;
    const updated = [...recommendedPracFeeds, ...practitioners];
    dispatch(setRecommendedFeedPrac(updated));
    cb(hasMore);
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const setHotAndNewContents = contents => ({
  type: SET_HOT_NEW_CONTENTS,
  payload: contents,
});

export const getHotandNewContents = (page, cb) => async (dispatch, getState) => {
  try {
    const resp = await axios.get(`/api/content/hot-and-new?page=${page}`);
    const { contents, hasMore } = resp.data;
    const { hotNew } = getState().content;
    const updated = [...hotNew, ...contents];
    dispatch(setHotAndNewContents(updated));
    cb(hasMore);
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const deleteContent = (contentId, callback) => async dispatch => {
  try {
    dispatch(setLoader(true));
    const response = await axios.delete(`/api/content/${contentId}`);
    const { status } = response.data;
    if (status) {
      dispatch({
        type: DELETE_CONTENT,
        payload: contentId,
      });
    }
    dispatch(setLoader(false));
    callback();
    toast.success('Content deleted');
  } catch (error) {
    toast.error('Invalid Request');
    dispatch(setLoader(false));
    callback();
  }
};

export const changeStatus = (contentId, status) => async dispatch => {
  try {
    dispatch(setLoader(true));
    const response = await axios.put(`/api/content/change-status/${contentId}`, { status });
    const { publishStatus } = response.data;
    dispatch({
      type: CHANGE_CONTENT_PUBLISH_STATUS,
      payload: {
        contentId,
        status: publishStatus,
      },
    });
    dispatch(setLoader(false));
    toast.success(`CONTENT ${publishStatus}`);
  } catch (error) {
    dispatch(setLoader(false));
    toast.error('Something went wrong, please try again');
  }
};

export const addComment = (contentId, commentContent, cb) => async dispatch => {
  const data = {
    content_id: contentId,
    comment: commentContent,
  };
  try {
    await axios.post('/api/content/add-comment', data);
    cb();
  } catch (error) {
    cb();
    if (error.response.status === 401) {
      toast.error('Sign in to leave a comment');
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const rateContent = (contentId, rating, newRating) => async dispatch => {
  const data = {
    content_id: contentId,
    rating,
  };
  try {
    if (newRating) dispatch({ type: INCREMENT_RATING_COUNT, payload: rating });
    await axios.post('/api/content/rating', data);
  } catch (error) {
    if (error.response.status === 401) {
      toast.error('Sign in to leave a review');
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const reportContent = (contentId, reason, cb) => async dispatch => {
  const data = {
    content_id: contentId,
    report_reason: reason,
  };
  try {
    const response = await axios.post('/api/content/report', data);
    console.log('####', response);
    cb(null);
  } catch (error) {
    if (error && error.response && error.response.status === 409) {
      toast.error('You already reported this content');
    }
    cb(error);
  }
};

export const bookmarkContent = contentId => async dispatch => {
  const data = {
    content_id: contentId,
  };
  try {
    const response = await axios.post('/api/content/bookmark', data);
    const { bookmarkStatus } = response.data;
    dispatch({
      type: SET_CONTENT_BOOKMARK_STATUS,
      payload: bookmarkStatus,
    });
  } catch (error) {
    toast.error('Something went wrong!');
  }
};
