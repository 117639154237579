/* eslint-disable camelcase */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ContentPublished = ({ content_id, clearState, history }) => {
  return (
    <div className="d-flex flex-column align-items-center py-4">
      <img
        className="img-fluid py-5 publish-success success"
        src="/images/content-published.svg"
        alt="Publish"
      />
      <h5 className="mb-4 mt-3 font-weight-bold">Content successfully published.</h5>
      <button
        type="button"
        className="btn btn-link"
        onClick={() => {
          history.push(`/content/${content_id}`);
          clearState();
        }}
      >
        View My New Content
      </button>
    </div>
  );
};

ContentPublished.propTypes = {
  content_id: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  content_id: state.publish.publishedContentId,
});

export default withRouter(connect(mapStateToProps)(ContentPublished));
