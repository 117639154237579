/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import ListPrivacyPolicy from './ListPrivacyPolicy';
import './PrivacyPolicy.css';

import * as privacyPolicyData from './privacypolicy_data.json';

const { personalData } = privacyPolicyData.default;
const transferOfData = privacyPolicyData.default.transferOfPersonalData;
const { complaintsData } = privacyPolicyData.default;
const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row" id="privacy-policy">
          <div className="col-md-8 col-12">
            <div>
              <h1 className="title">PRIVACY POLICY</h1>
              <p className="privacy-info">
                Thank you for your interest in GuideTrue{' '}
                <a href="https://www.guidetrue.com">www.guidetrue.com</a> (hereinafter “Website”). We
                respect your privacy and will collect and process your personal data in compliance
                with the applicable legal requirements at all times. We will never rent or sell your
                personal data for marketing or other purposes.
                <br />
                <br />
                This privacy policy explains what kind of personal data we collect when you use the
                Website and how we handle such personal data.
              </p>
            </div>

            <div>
              <h1>1. DATA CONTROLLER, AND DATA PROTECTION OFFICER</h1>
              <p>
                1.1 Joint data controllers within the meaning of the European General Data
                Protection Regulation (GDPR) are GuideTrue Inc., 1126 Rosario Dr. Topanga, CA, and
                Bititude, Ground Floor, UL Cyberpark, Nellikode PO, Kozhikode, Kerala 673016, India;
                phone 1 (310) 941-4626, e-mail{' '}
                <a href="mailto:privacy@guidetrue.com">privacy@guidetrue.com</a> (jointly here in
                after referred to as “GuideTrue”, “we”, “our”, “us” etc.).
                <br />
                <br />
                1.2 Our data protection officer can be contacted under GuideTrue Data Protection
                Officer, Bititude, Ground Floor, UL Cyberpark, Nellikode PO, Kozhikode, Kerala
                673016, India, e-mail{' '}
                <a href="mailto:mahasooq@bititude.com">mahasooq@bititude.com</a> .
              </p>
            </div>

            <div>
              <h1>2. WHAT IS PERSONAL DATA</h1>
              <p>
                Personal data means any information relating to you, provided that you can be
                identified or are identifiable (directly or indirectly) with such information. For
                example, personal data includes your name, address, phone, fax or mobile phone
                number, your e-mail address, and any personal information transmitted from the
                Personal Assessment or communications between members and practitioners. Personal
                data does not include information of an indirect, general nature that does not
                identify you; for example, the number of users of the Website.
                <br />
                <br />
                For example, we collect <br />
                (a) your user name and e-mail address when you create an user account and register
                for the Website; <br />
                (b) your name, address, phone number, e-mail address, and bank account details to
                sell content via the Website; and <br />
                (c) your e- mail address when you complete a form or send us an e-mail. When we
                collect your personal data, we inform you whether the designation of certain
                personal data is compulsory or optional.
              </p>
            </div>

            <div>
              <h1>3. WHAT TYPES OF PERSONAL DATA WE COLLECT</h1>
              <p>
                You may generally visit the Website without providing information to us which
                directly identifies you. However, please be aware that in this case you may not be
                able to use certain parts of the Website or particular services offered by us.
                <br />
                <br />
                <span style={{ color: '#031121' }}>
                  3.1 WEB SERVER PROTOCOLS (INCLUDING IP ADDRESS)
                </span>
                <br />
                When you visit and use the Website our web server, due to technical reasons,
                automatically collects your IP address, the date and time of your visit of the
                Website, the sites visited on the Website, the referrer website, your browser type
                (e.g. Microsoft Explorer or Mozilla Firefox), your operating system (e.g. Microsoft
                Windows 10), the domain name and the address of your internet access provider.
                <br />
                <br />
                <span style={{ color: '#031121' }}>
                  3.2 PERSONAL DATA SUBMITTED BY YOU IN THE FRAMEWORK OF OUR SERVICES
                </span>
                <br />
                (a) We collect personal data that you have provided to us on a voluntary basis for
                the purposes to provide, operate and manage the Website as well as to provide our
                services in accordance with our user agreement (cf. under{' '}
                <a href="/termsofservice">guidetrue.com/agreement</a>).
                <br />
              </p>

              <ListPrivacyPolicy data={personalData} />
              <p>
                (b) Provided that you have given us your consent, we further collect your e-mail
                address when you register for our newsletter and promotional messages.
              </p>
            </div>

            <div>
              <h1>4. HOW WE PROCESS YOUR PERSONAL DATA</h1>
              <p>
                4.1 We basically process your personal data in order to provide and operate the
                Website and render our services to you in accordance with our user agreement (cf.
                under https://www.guidetrue.com/agreement), in particular, (a) to facilitate and
                coordinate sales and purchases via the Website; (b) to provide you, to the extent
                possible and reasonable, with a smoothly and accurately functioning Website by
                monitoring and maintaining its performance (in particular, detecting and resolving
                issues and errors); (c) to enable and process communication with our support service
                in case of queries via live chat, e-mail, web forum, social media or phone; and (d)
                to enable you to communicate and/or exchange ideas with other users via the Website
                through comments, messages, or other interactive online forums and/or to provide
                reviews regarding the products offered via the Website.
                <br />
                <br />
                This processing is necessary for the performance of our contract.
                <br />
                <br />
                With respect to your communication with other users via our interactive methods as
                set out under item 4.1 lit. d above please be aware that if you post a comment on
                content or a profile, this information will be made available to the public in an
                online environment. Each user is solely responsible for its posted comments. If you
                choose to use any such interactive area, please be aware that this environment and
                all personal data posted there is publicly accessible. We cannot control how other
                visitors of the Website will use this information. In particular, we are unable to
                prevent you from receiving unsolicited messages from third parties.
                <br />
                <br />
                4.2 Further we may process your personal data for the purpose of preventing fraud on
                the Website.
                <br />
                <br />
                This processing is necessary for the purpose of our legitimate interests. Our
                legitimate interests in this context are the protection of the integrity of the
                Website, our services, our system as well as our users.
                <br />
                <br />
                4.3 Further, we process your personal data for our endeavors (a) to provide you with
                an optimal and meaningful user experience on the Website and within our services;
                and (b) to improve and optimize the Website and our services, the layout and content
                of the Website.
                <br />
                <br />
                This processing is necessary for the purpose of our legitimate interests. Our
                legitimate interests are to provide an optimal and meaningful user experience on the
                Website which fulfils your expectations and needs as well as our commercial
                interests.
                <br />
                <br />
                4.4 Provided that you have given us your consent, we will further use the personal
                data collected for the provision and optimisation of our marketing measures via
                e-mail, in particular, the provision of our newsletter and other promotional
                messages on GuideTrue events, services, products and/or special offers. You may
                withdraw your consent at any time with future effect.
                <br />
                <br />
                You may, at any time, opt out from receiving such communications by following the
                instructions included in each e-mail or contacting GuideTrue customer service e.g.
                by sending us an e-mail to the contact details set out under item 1 above or by
                visiting the GuideTrue Help Centre at www.guidetrue.com/help (which can also be
                reached by clicking “Help” on the bottom of the homepage of the Website). If you
                have consented to it, we will also use your e-mail address to send you useful
                information about using the GuideTrue services from time to time. You may at any
                time opt out from such use by deactivating the receipt of specific kinds of messages
                in your user account settings.
                <br />
                <br />
                This processing is based on consent given by you.
                <br />
                <br />
                4.5 Further, we may process your web server information (cf. item 3.1 above) in
                cooperation with your internet access provider and/or local authorities in cases of
                a system misuse in order to investigate and identify the originator of such system
                misuse.
                <br />
                <br />
                This processing is necessary for the purpose of our legitimate interests. Our
                legitimate interests in this context are the protection of the integrity of the
                Website, our services, our system as well as our users.
              </p>
            </div>

            <div>
              <h1>5. TRANSFER OF PERSONAL DATA</h1>
              <p>
                Your personal data is very important and helpful for us to provide and optimize our
                services and the Website. We do not share personal data with any third party, unless
                this (a) is necessary to fulfil our services and/or the provision of the Website;
                (b) is permitted by applicable law; or (c) has been agreed by you. Insofar, we share
                personal data with third parties within the scope specified below:
                <br />
                <br />
                5.1 In order to properly render our services in the form of facilitating sales
                purchases respectively made between users via the Website, we may have to share your
                name, the email address and phone number on behalf of the selling user with
                third-party service providers respectively for logistics or processing services.
                Please be aware that the provision of such personal data solely takes place to
                process the sale of a product made between users via the Website. Under no
                circumstances do we provide such personal data to these service providers for
                marketing purposes.
                <br />
                <br />
                This processing is necessary for the performance of our contract.
                <br />
                <br />
                5.2 In case third-parties address GuideTrue with the allegation that content
                submitted by you onto the Marketplace infringes applicable law, intellectual
                property rights of the third party (including without limitation, copyrights and
                ancillary copyrights, patents, trademarks, company symbols, work titles, or designs)
                or any other rights of the third party (including without limitation, the general
                right of privacy and the right of one’s own image), we are entitled pursuant to our
                user agreement (cf. under{' '}
                <a href="termsofservice">
                  https://www.guidetrue.com/agreement
                </a>
                ) to provide the third party with your name, address and/or information relating to
                the content which has been objected, in order to enable the third party to claim
                such rights vis-à-vis you directly.
                <br />
                <br />
                This processing is necessary for the performance of our contract.
                <br />
                <br />
                5.3 Further, we are entitled to outsource the processing of personal data
                (completely or partially) to external service providers which are acting on our
                behalf as data processors in the meaning of Art. 4 no. 8 GDPR. When such third-party
                service providers are located outside of the America (US), European Union (EU) or
                the European Economic Area (EEA), we will put in place appropriate safeguards in
                accordance with the requirements set by law and data protection authorities to
                ensure that your personal data is duly protected.
                <br />
                <br />
                In particular, we use external service providers for the following purposes in the
                framework of providing and operating the Website as well as our respective services:
                <br />
              </p>

              <ListPrivacyPolicy data={transferOfData} />
            </div>

            <div>
              <h1>6. HOW WE KEEP PERSONAL DATA SECURE</h1>
              <p>
                While we take all necessary and reasonable steps to keep your information secure, no
                security system is impenetrable and, due to the inherent nature of the internet, we
                cannot guarantee that information, during transmission through the internet or while
                stored on our systems or otherwise, will be absolutely safe from unauthorized access
                by others. Your payments are handled over an encrypted connection or via a secure
                third-party data processor. Access to personal data on our databases is subject to
                reasonable technical safeguards and is restricted to authorized staff on a strict
                need-to-know basis. Further, we require our external service providers with access
                to personal data to sign data processing agreements (Art. 28 GDPR) that require them
                to take the necessary and reasonable steps to protect the personal data provided to
                them.
              </p>
            </div>

            <div>
              <h1>7. STORAGE TIME</h1>
              <p>
                We will only store your personal data as long as necessary to fulfil the purposes
                for which they were collected or – where the law provides for longer retention
                periods – for the duration of the retention period required by law. After that your
                personal data will be deleted.
              </p>
            </div>

            <div>
              <h1>8. WEB ANALYSES SERVICES, COOKIES AND OTHER TECHNOLOGIES</h1>
              <p>
                8.1 We want to provide you with an optimal and meaningful user experience.
                Therefore, we use cookies and other technologies on the Website and within our
                services (a) in order to better understand how our users use the Website and our
                services; (b) for the optimization of the Website and our services; (c) in order to
                provide and maintain, to the extent possible and reasonable, a smoothly and
                accurately functioning Website; as well as (d) for marketing purposes and market
                research.
                <br />
                <br />
                Cookies and other technologies help us in many ways to make your visit of the
                Website more enjoyable and meaningful.
                <br />
                <br />
                Cookies are text information files that our web server sends to and places on your
                computer when you visit the Website. Most browsers accept cookies automatically, but
                can be configured in their settings not to accept cookies or to indicate when a
                cookie is being sent. For more information please check the help menu of your
                browser. Some or all of our cookies may be disabled or deleted later. You can either
                disable our cookies via the browser settings or via the opt-out possibilities as set
                out in the table under item 8.3 below. Please note that you do not have to accept
                our cookies in order to use the Website. However, if you opt out of the cookie
                function, some areas and functions of the Website may be disabled.
                <br />
                <br />
                8.2 We may use the following types of cookies on the Website for the following
                purposes:
                <br />
                <br />
                (a) Technical cookies
                <br />
                <br />
                Cookies that allow the navigation through the Website and the use of different
                options or services that exist in it, such as allowing the communication of data,
                identifying your user account, accessing restricted access Website areas, storing
                products from orders, completing the purchase process of an order or storing content
                for the broadcast of videos and sound and share content.
                <br />
                <br />
                This processing is necessary for the performance of our contract.
                <br />
                <br />
                (b) Customization cookies
                <br />
                <br />
                Cookies that allow the access to the Website with some general predetermined
                characteristics according to several criteria out of the user’s device, such as the
                language, the web browser, the regional settings etc.
                <br />
                <br />
                This processing is necessary for the performance of our contract.
                <br />
                <br />
                (c) Advertising cookies
                <br />
                <br />
                Cookies that allow GuideTrue to analyze and manage the information related to any
                advertisements or suggestions displayed to each user on the Website. Those cookies
                allow to manage, in the most efficient way, the advertising spaces of the Website
                pursuant to different information such as the edited content or the frequency in
                which such advertisements are displayed.
                <br />
                <br />
                This processing is necessary for the purpose of our legitimate interests. Our
                legitimate interests are to provide a user-friendly and meaningful Website which
                fulfills your expectations and needs as well as our commercial interests.
                <br />
                <br />
                (d) Conduct or behavior advertising cookies
                <br />
                <br />
                These cookies store information on the behavior of users obtained through the
                continuous observation of their browsing habits, which allows the development of a
                specific profile in order to display different advertisements based on such
                profiling process.
                <br />
                <br />
                It is also possible that by visiting a website or by opening an e-mail where an
                advertisement or promotion about our services is displayed, a cookie will be
                installed in your browser that will be useful to show to the user some other
                advertisements related to the searches that it has carried out, to develop a control
                of our advertising in connection, for example, with the number of times each
                advertisement is displayed, where or at what time it is displayed etc.
                <br />
                <br />
                This processing is necessary for the purpose of our legitimate interests. Our
                legitimate interests are to provide a user-friendly and meaningful Website which
                fulfills your expectations and needs as well as our commercial interests.
                <br />
                <br />
                (e) Third-party analytics cookies
                <br />
                <br />
                Together with our server logs and other web analysis programs, analytical cookies
                enable GuideTrue to ascertain the number of users visiting the website and the most
                popular areas. Thanks to them, GuideTrue can obtain information to improve browsing
                and offer a better service to users.
                <br />
                <br />
                This processing is necessary for the purpose of our legitimate interests. Our
                legitimate interests are to provide a user-friendly and meaningful Website which
                fulfills your expectations and needs as well as our commercial interests.
                <br />
                <br />
                (f) Third-party social networks cookies
                <br />
                <br />
                If you interact with the content of the Website, third-party cookies may also be
                installed (for example, by clicking on social media buttons or viewing videos housed
                on other websites). Third-party cookies are those established by a domain different
                to the Website and subject to the relevant social network’s policies.
                <br />
                <br />
                This processing is necessary for the purpose of our legitimate interests. Our
                legitimate interests are to provide a user-friendly and meaningful Website which
                fulfills your expectations and needs as well as our commercial interests.
                <br />
                <br />
                8.4. We use a number of social plug-ins on the Website. Plug-ins are small extension
                modules used by different social networks that allow you to inform other users in
                the social network that you are on the Website. Please note that we have only
                limited control over the data transmitted by these social plug-ins. Please read the
                following information to learn how the social plug-ins work:
                <br />
                <br />
                When you visit a site of our Website that contains a social plug-in, your browser
                will establish a direct connection to the servers of the social network. The social
                network is informed about the site that you are currently visiting on the Website
                and the social network stores this information in accordance with its privacy
                policy. The social network may also log your browser history or further information.
                If you interact with (click on) the social plug-in while you are logged onto the
                related social network, this information may be matched to your profile and shown on
                other sites of the internet. If you do not wish the social network to collect data
                on you through the Website, it may be helpful to log off from the social network
                before you visit our Website. For further information about the purpose and extent
                of the data collection and further use of your data by the social network, as well
                as your profile setting options to protect your privacy, please view the data
                privacy notices of the relevant social networks.
              </p>
            </div>

            <div>
              <h1>9. YOUR RIGHTS UNDER DATA PROTECTION LEGISLATION</h1>
              <p>
                In particular, but without limitation, the following rights are vested in you by
                applicable data protection law:
                <br />
                <br />
                (a) Right of access, rectification, erasure and restriction of processing: You have
                the right to obtain at any time access to your personal data stored by us. If we
                process or use your personal data, we shall endeavor to ensure by implementing
                suitable measures that your personal data is accurate and up-to-date for the
                purposes for which they were collected. If your personal data is inaccurate or
                incomplete, you have the right to obtain the rectification of such personal data.
                Furthermore, you may have the right to obtain the erasure or restriction of
                processing of your personal data, for example if no legitimate business purpose
                exists anymore for the data processing under this privacy policy or applicable law
                and the further storage is not necessary under statutory storage obligations.
                <br />
                <br />
                Your user account essentially shows the personal data you have stored with us. You
                may view, change and/or erase the data as needed. Furthermore, to obtain access to
                or the rectification, erasure or restriction of processing of your personal data you
                may at any time contact us as provided in item 1 above.
                <br />
                <br />
                (b) Right to data portability: You may have the right to receive the personal data
                concerning you, which you have provided to us, in a structured, commonly used and
                machine-readable format or to transmit this data to another controller. To exercise
                this right you may at any time contact us as provided in item 1 above.
                <br />
                <br />
                (c) Right to object: You may have the right to object, on grounds relating to your
                particular situation, to the processing of your personal data. To exercise this
                right you may at any time contact us as provided in item 1 above.
                <br />
                <br />
                (d) Right to withdraw your consent: If you have given your consent to the collection
                or processing of your personal data, you have the right to withdraw your consent at
                any time on a prospective basis without affecting the lawfulness of processing based
                on the consent before its withdrawal. You may also object to use of your personal
                data for purposes of market research and public opinion polling as well as
                advertising and unsubscribe from our newsletter (please cf. under item 4.3 above).
                To exercise any of these rights you may at any time contact us as provided in item 1
                above.
              </p>
            </div>

            <div>
              <h1>10. COMPLAINTS</h1>
              <p>
                If you have any questions, concerns or complaints relating to this privacy policy or
                GuideTrue's privacy/data protection practices, you are welcome to contact the
                GuideTrue Data Protection Officer at the contact details listed in Section 1. Just
                send us a simple personal message. We're very understanding and would be happy to
                make any accommodations that are necessary.
                <br />
                <br />
                The relevant supervisory authority for the making of complaints depends on your
                location:
                <br />
              </p>

              <ListPrivacyPolicy data={complaintsData} />
            </div>

            <div>
              <h1>11. CHANGES</h1>
              <p>
                GuideTrue reserves the right to change this privacy policy from time to time for any
                reason, especially in compliance with the legal requirements, for example to be
                compliant with new laws or to add new services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '3rem' }}>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
