import {
  LOGIN_SUCCESS,
  SET_CURRENT_USER,
  SET_AUTH_ERROR,
  SET_OAUTH_USER,
  SET_PROFILE,
  SET_USER_TYPE,
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  isOAuthenticated: false,
  userType: '',
  socialUser: null,
  profile: null,
  currentUser: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.user,
        error: null,
      };
    case SET_AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        error: null,
        currentUser: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_OAUTH_USER:
      return {
        ...state,
        socialUser: action.payload,
        // error: null,
        // currentUser: action.payload,
        // isOAuthenticated: true,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      }
    default:
      return state;
  }
};

export default authReducer;
