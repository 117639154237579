import React from 'react';
import PropTypes from 'prop-types';

const statusChartData = [
  { number: 0, text: 'Horrific' },
  { number: 10, text: 'Terrible' },
  { number: 20, text: 'Awful' },
  { number: 30, text: 'Very Bad' },
  { number: 40, text: 'Bad' },
  { number: 50, text: 'Poor' },
  { number: 60, text: 'Fair' },
  { number: 70, text: 'Good' },
  { number: 80, text: 'Great' },
  { number: 90, text: 'Excellent' },
];

function AssessmentResultStatusChart({ progress }) {
  const score = Math.round(progress);
  return (
    <div className="assessment-status-chart my-5">
      <div className="asessment-score" style={{ left: `${score}%` }}>
        {score}
      </div>
      <div className="assessment-status-wrapper">
        {statusChartData.map(data => {
          let width = 0;
          const diff = score - (data.number + 10);
          if (diff >= 0) {
            width = 100;
          } else if (Math.abs(diff) < 10) {
            width = (10 - Math.abs(diff)) * 10;
          }
          return (
            <div key={`asc_${data.number}`} className="position-relative assessment-status">
              <span className="status-number text-secondary display2">{data.number}</span>
              <span className="status-progress" style={{ width: `${width}%` }} />
              <span className="status-text display2">{data.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

AssessmentResultStatusChart.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default AssessmentResultStatusChart;
