/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { reduxForm, Field, Form } from 'redux-form';
import PractitionerRegistrationHeader from '../PractitionerRegistrationContainer/PractitinerRegistration/PractitionerRegistrationHeader';
import Input from '../../../common/MatInput/MatInput';
// import SelectInput from '../../../common/SelectInput/SelectInput';
import validators from './businessFormValidator';
import Location from '../../../common/Location/Location';


let LinkBusinessForm = ({ handleSubmit }) => (
  <div className="py-5">
    <Form className="row" onSubmit={handleSubmit} style={{ fontSize: '1.25rem' }}>
      <div className="col-12">
        <PractitionerRegistrationHeader
          title="Add Business"
          info="Enter your business details to work as an organization."
        />
      </div>
      <div className="col-6 mb-4">
        <label className="text-light font-medium mb-0 display2 prac-reg-label">
          Business Name
        </label>
        <Field name="business_name" placeholder="Business Name" component={Input} />
      </div>
      <div className="col-6 mb-4">
        <label className="text-light font-medium mb-0 display2 prac-reg-label">Phone Number</label>
        <Field name="business_phone_number" placeholder="Phone Number" component={Input} />
      </div>
      <div className="col-6 mb-4">
        <label className="text-light font-medium mb-0 display2 prac-reg-label">Address Line</label>
        <Field name="business_address_line1" placeholder="Address Line" component={Input} />
      </div>
      {/* <div className="col-6 mb-4">
        <label className="text-light font-medium mb-0 display2 prac-reg-label">
          Address Line 2
        </label>
        <Field name="business_address_line2" placeholder="Address Line 2" component={Input} />
      </div> */}
      <div className="col-6 mb-4">
        <label className="text-light font-medium mb-0 display2 prac-reg-label">Zip Code</label>
        <Field name="business_zip_code" placeholder="Zip Code" component={Input} />
      </div>
      <div className="col-6 mb-4">
        <label className="text-light font-medium mb-0 display2 prac-reg-label">Website</label>
        <Field name="business_website" placeholder="http://www.example.com" component={Input} />
      </div>
      <div className="col-6 mb-4">
        <label className="text-light font-medium mb-0 display2 prac-reg-label">
          Location
        </label>
        <Field
          name="business_location"
          component={({ input, ...rest }) => 
            <Location onLocationSelect={(loc)=> input.onChange(loc)} value={input.value} varient="material" {...rest} />
          }
        />
      </div>
    </Form>
  </div>
);


LinkBusinessForm = reduxForm({
  form: 'businessLinkForm',
  validate: validators,
})(LinkBusinessForm);

export default LinkBusinessForm;
