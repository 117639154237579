/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import './UserCard.css';

const UserCard = ({ user }) => {
  const { u_first_name, u_last_name = '', u_image_url = '', u_email } = user;
  const profileImage = u_image_url || '/images/user.svg';
  return (
    <div className="user-card">
      <div className="user-card-body p-3 pt-4">
        <div className="d-flex flex-column align-items-center">
          <img className="avatar mb-3" src={profileImage} alt="Profile" />
          <div className="d-flex flex-column align-items-center">
            <p className="user-name">{`${u_first_name} ${u_last_name || ''}`}</p>
            {u_email && <p className="user-info text-secondary">{u_email}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

UserCard.propTypes = {
  user: PropTypes.shape({
    u_email: PropTypes.string,
    u_first_name: PropTypes.string.isRequired,
    u_last_name: PropTypes.string,
    u_image_url: PropTypes.string,
  }).isRequired,
};

export default UserCard;
