/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RadioSelect from '../../../../common/RadioSelect/RadioSelect';
import PractitionerRegistrationHeader from './PractitionerRegistrationHeader';
// eslint-disable-next-line import/no-named-as-default
import MultiSearchDropDown from '../../../../common/MultiSearchDropDown/MultiSearchDropDown';

const sexOrient = ['Straight', 'Gay', 'Lesbian', 'Bisexual', 'Asexual', 'Prefer not to say'];

const PractitionerRegistration3 = ({ spiritual_orientations, cultural_heritages }) => (
  <div className="py-md-5 py-4 mt-md-5">
    <div className="mb-5 mt-lg-0 mt-5">
      <PractitionerRegistrationHeader
        title="Sexual Orientation"
        info="Select your sexual orientation"
      />
      <Field
        name="p_sexual_orientation"
        component={props => <RadioSelect options={sexOrient} other {...props} />}
      />
    </div>
    <div className="mt-4">
      <PractitionerRegistrationHeader
        title="Personal Background"
        info="Choose your personal background"
      />
      <div className="row">
        <div className="col-12 mb-5">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">
            Cultural Heritage
          </label>
          <Field
            name="p_cultural_heritages"
            placeholder="Cultural Heritage"
            component={prop => (
              <MultiSearchDropDown
                placeholder="Cultural Heritage"
                options={cultural_heritages}
                other
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
                {...prop}
              />
            )}
          />
        </div>
        <div className="col-12 mb-4">
          <label className="text-light font-medium mb-0 display2 prac-reg-label">
            Spiritual Orientation
          </label>
          <Field
            name="p_spiritual_orientations"
            component={prop => (
              <MultiSearchDropDown
                placeholder="Spiritual Orientation"
                options={spiritual_orientations}
                other
                onSelect={value => {
                  prop.input.onChange(value);
                }}
                value={prop.input.value}
              />
            )}
          />
        </div>
      </div>
    </div>
  </div>
);

PractitionerRegistration3.propTypes = {
  cultural_heritages: PropTypes.arrayOf(PropTypes.object).isRequired,
  spiritual_orientations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  return {
    cultural_heritages: state.app.cultural_heritages,
    spiritual_orientations: state.app.spiritual_orientations,
  };
};

export default connect(mapStateToProps)(PractitionerRegistration3);
