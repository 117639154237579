import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmailValid, isEmpty } from '../../../../utils/validators';
import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';
import './LoginForm.css';

const validators = values => {
  const errors = {};
  if (!values.u_email) {
    errors.u_email = 'This field is required';
  } else if (!isEmailValid(values.u_email)) {
    errors.u_email = 'Enter a valid email address';
  }
  if (!isEmpty(values.u_password)) {
    errors.u_password = 'This field is required';
  }
  return errors;
};

const LoginForm = ({ handleSubmit, submitting, onSubmit }) => (
  <form onSubmit={handleSubmit(onSubmit)} className="login-form">
    <div className="mb-3">
      <Field name="u_email" type="text" icon="icon-mail" placeholder="Email" component={Input} />
    </div>
    <div className="mb-3">
      <Field
        name="u_password"
        type="password"
        icon="icon-lock-alt"
        placeholder="Password"
        component={Input}
      />
    </div>
    <div className="mb-3 d-flex">
      {/* <Field
        name="remember_me"
        type="checkbox"
        label={<span className="remember-me">Remember me</span>}
        component={Input}
      /> */}

      <Link className="ml-auto forgot-password" to="/forgot-password">
        Forgot Password ?
      </Link>
      {/* <span className='ml-auto forgot-password'>Forgot Password ?</span> */}
    </div>
    <div className="mt-4">
      <Button type="submit" disabled={submitting}>
        Sign In
      </Button>
    </div>
  </form>
);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'loginForm',
  validate: validators,
})(LoginForm);
