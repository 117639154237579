import React from 'react';
import PropTypes from 'prop-types';

const PractitionerRegistrationFooter = ({ onNext, onBack, step, totalSteps }) => (
  <div className="prac-reg-footer d-flex align-items-center">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10 col-12 position-relative d-flex align-items-center h-100">
          <div>
            <p className="m-0">PROGRESS</p>
            <p className="m-0">
              Step
              <span className="ml-2">{step + 1 || 0} /</span>
              <span className="font-medium"> {totalSteps + 1}</span>
            </p>
          </div>
          <div className="ml-auto">
            {step >= 1 && (
              <button type="button" onClick={onBack} className="nav-arrow-btn mr-3">
                Back
              </button>
            )}
            <button type="button" onClick={onNext} className="nav-arrow-btn">
              {step === 6 ? 'Submit' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

PractitionerRegistrationFooter.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default PractitionerRegistrationFooter;
